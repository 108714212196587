import { enUS, ja, ko, zhTW, zhCN } from 'date-fns/locale';
import i18next, { LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AnalyticsSubscriptionPlanType } from '@/graphql';
import { EN_JA, EN_US, JA_JP, KO_KR, ZH_TW, ZH_CN } from '@/shared/assets';
import { SUBSCRIPTIONS_TYPES_OPTIONS } from '@/shared/constants';
import { AppLanguage, Language } from '@/shared/types';
import { manageLocalStorage } from '@/router';

const getLanguageFromLocalStorage = () => manageLocalStorage('get', 'lang');
const setLanguageToLocalStorage = (lng: string) => manageLocalStorage('set', 'lang', lng);
// Ref: https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/navigator.js
const getLanguageFromNavigator = (): string => {
  if (typeof navigator === 'undefined') {
    return '';
  }

  if (navigator.languages && navigator.languages.length > 0) {
    const japaneseLang = navigator.languages.find((language) => language === 'ja');

    return japaneseLang || navigator.languages.at(0) || '';
  }

  if ('userLanguage' in navigator && typeof navigator.userLanguage === 'string') {
    return navigator.userLanguage;
  }

  if (navigator.language) {
    return navigator.language;
  }

  return '';
};
export const getSubscriptionTypeLabel = (plan?: AnalyticsSubscriptionPlanType | '') => {
  const planLabel = SUBSCRIPTIONS_TYPES_OPTIONS.find((type) => type.value === plan)?.label;

  return planLabel ? `${planLabel} plan` : '';
};
export const convertLanguageCode = (code: string): AppLanguage => {
  switch (code) {
    case 'ja':
      return Language.ja;
    case 'zh-TW':
      return Language.tw;
    case 'zh-CN':
      return Language.cn;
    case 'ko':
      return Language.ko;
    default:
      return Language.en;
  }
};

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => undefined,
  detect: () => {
    const storageLanguage = getLanguageFromLocalStorage();
    if (storageLanguage) {
      return storageLanguage === Language['en-JA'] ? '' : storageLanguage;
    }

    const browserLanguage = getLanguageFromNavigator();
    if (browserLanguage) {
      return convertLanguageCode(browserLanguage);
    }

    return '';
  },
  cacheUserLanguage: (lng: string) => {
    setLanguageToLocalStorage(lng);

    return;
  }
};

const resources: Record<
  AppLanguage,
  { translation: typeof EN_US | typeof JA_JP | typeof ZH_CN | typeof ZH_TW | typeof EN_JA | typeof KO_KR }
> = {
  [Language.en]: { translation: EN_US },
  [Language.ja]: { translation: JA_JP },
  [Language.cn]: { translation: ZH_CN },
  [Language.tw]: { translation: ZH_TW },
  [Language.ko]: { translation: KO_KR },
  [Language['en-JA']]: { translation: EN_JA }
};

export const initI18Next = () => {
  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      initImmediate: false,
      fallbackLng: Language.en,
      supportedLngs: Object.keys(resources),
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      resources
    });
};

export const getDateLocal = (locale?: AppLanguage) => {
  switch (locale) {
    case 'ja':
      return ja;
    case 'cn':
      return zhCN;
    case 'tw':
      return zhTW;
    case 'ko':
      return ko;

    case 'en':
    default:
      return enUS;
  }
};
