import { useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInSignUpLayout, TermsAnytagEn, TermsSelfServiceEn } from '@/shared/organisms';
import { useAuthData } from '@/auth';

export const TermsOfServiceCombined = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Terms' });
  const { defaultRoute } = useAuthData();

  return (
    <SignInSignUpLayout
      footerProps={{ className: { paddingBottom: '85px' } }}
      headerProps={{ to: defaultRoute, className: { paddingTop: '40px' } }}
    >
      <div>
        <TermsAnytagEn />
        <TermsSelfServiceEn css={{ marginTop: '0' }} />
      </div>
    </SignInSignUpLayout>
  );
};
