import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokTagPostDetails.graphql';
import { Query, QuerytiktokTagPostDetailsArgs } from '../../__generated__/globalTypes';

export const useTiktokTagPostDetailsQuery = (options?: QueryHookOptions<Query, QuerytiktokTagPostDetailsArgs>) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokTagPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokTagPostDetailsLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokTagPostDetailsArgs>
): QueryResult<Query, QuerytiktokTagPostDetailsArgs> & {
  getTiktokTagPostDetails: LazyQueryExecFunction<Query, QuerytiktokTagPostDetailsArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokTagPostDetails, result] = useLazyQuery<Query, QuerytiktokTagPostDetailsArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokTagPostDetails, ...result };
};

export const useTiktokTagPostDetailsPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokTagPostDetails = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokTagPostDetailsArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokTagPostDetailsArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokTagPostDetails };
};
