import { isAfter } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { LocalStorageKey, DecodedTokenShape } from '../types';

export const setToken = (token: string) => {
  if (window?.localStorage) {
    localStorage.setItem('accessToken', token);
  }
};
export const getToken = () => localStorage.getItem('accessToken');
export const removeToken = () => localStorage.removeItem('accessToken');
export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  try {
    const decoded = decodeAuthToken(token);

    return decoded ? isAfter(decoded.exp * 1000, new Date()) : false;
  } catch (e) {
    removeToken();

    return false;
  }
};
export const getAuthHeaders = () => {
  const token = getToken();

  const authHeaders = new Headers();
  authHeaders.append('Authorization', `${token}`);

  return authHeaders;
};
export const decodeAuthToken = (token?: string | null) => {
  let decoded: DecodedTokenShape | null = null;

  if (!token) {
    return null;
  }

  try {
    decoded = jwtDecode<DecodedTokenShape>(token);
  } catch (e) {
    console.error('Invalid token provided', e);
  }

  return decoded;
};

export const manageLocalStorage = (method: 'set' | 'get' | 'remove', key: LocalStorageKey, value?: string) => {
  try {
    switch (method) {
      case 'set':
        localStorage.setItem(key, value || '');
        return undefined;
      case 'get':
        return localStorage.getItem(key);
      case 'remove':
        localStorage.removeItem(key);
        return undefined;
      default:
        return undefined;
    }
  } catch (error) {
    console.error(error);

    return undefined;
  }
};
