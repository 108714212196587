import { Outlet, useLocation, useMatches, useMatchRoute, useRouter } from '@tanstack/react-router';
import { useIntercomInitializer, useGaTracker, useSentry } from '@/shared/hooks';
import { Layout, LayoutProps } from '@/shared/organisms';
import { LinkRouterProps, MenuTitle } from '@/router';
import { PartialRouter } from '../types';
import { AdvertiserRouter } from './advertiser';
import { AgencyRouter } from './agency';
import { AnalyticsRouter } from './analytics';
import { AssetPreviewRouter } from './asset-preview';
import { AutoManagedRouter } from './auto-managed';
import { CampaignPostsDiscoveryRouter } from './campaign-posts-discovery';
import { ChatDemoRouter } from './chat-demo';
import { EngagementRouter } from './engagement';
import { HashtagAnalyticsRouter } from './hashtag-analytics';
import { InfluencerRouter } from './influencer';
import { InfluencersRouter } from './influencers';
import { InvoicesRouter } from './invoices';
import { MarketplaceRouter } from './marketplace';
import { PackageRouter } from './package';
import { PartnerRouter } from './partner';
import { PaymentsRouter } from './payments';
import { PostsRouter } from './posts';
import { PostsInsightRouter } from './posts-insight';
import { RedirectRouter } from './redirect';
import { ReportRouter } from './report';
import { SettingsRouter } from './settings/settings';
import { TalentAgencyRouter } from './talent-agency';
import { TikTokRouter } from './tiktok';
import { TrendingRouter } from './trending';
import { UserRouter } from './user';
import { UserSettingsRouter } from './user-settings';
import { YoutubeCmsRouter } from './youtube-cms';

const protectedRoutesWithoutLayout = [
  '/posts-insight',
  '/posts-insight/$id',
  '/posts-insight/$id/$postId',
  '/redirect/hidden/youtube-list-connect'
] as const satisfies Array<LinkRouterProps['to']>;

const cmsDashboardLayout = [
  '/invoices',
  '/youtube-cms',
  '/youtube-cms/$cmsChannelId',
  '/youtube-cms/confirmed-revenue'
] as const satisfies Array<LinkRouterProps['to']>;

const hashtagsDashboardLayout = [
  '/hashtag-analytics',
  '/hashtag-analytics/instagram',
  '/hashtag-analytics/tiktok',
  '/hashtag-analytics/tiktok/keyword',
  '/hashtag-analytics/tiktok/hashtag'
] as const satisfies Array<LinkRouterProps['to']>;

const PrivateRoutes = () => {
  useSentry();
  useGaTracker();
  useIntercomInitializer();
  const matches = useMatches();
  const matchRoute = useMatchRoute();
  const location = useLocation();
  const router = useRouter();

  router.history.subscribe(({ action }) => {
    const prevLocation = sessionStorage.getItem('location');
    try {
      const parsedPrevLocation = prevLocation ? JSON.parse(prevLocation) : null;
      if (action.type === 'PUSH' && parsedPrevLocation?.state?.key !== location.state?.key) {
        sessionStorage.setItem('location', JSON.stringify(location));
      }
    } catch (e) {
      console.warn('Error parsing location from sessionStorage:', e);
    }
  });

  const currentMatch = matches.find((m) => matchRoute({ to: m.fullPath as LinkRouterProps['to'], params: m.params }));

  if (
    !currentMatch ||
    (currentMatch?.fullPath &&
      protectedRoutesWithoutLayout.includes(currentMatch.fullPath as (typeof protectedRoutesWithoutLayout)[number]))
  ) {
    return <Outlet />;
  }

  const staticData = currentMatch?.staticData as { hasCombinedTerms: boolean; menuTitle: MenuTitle };
  const menuType: LayoutProps['menuType'] = (() => {
    if (!currentMatch?.fullPath) return undefined;

    if (cmsDashboardLayout.includes(currentMatch.fullPath as (typeof cmsDashboardLayout)[number])) return 'cms';

    if (hashtagsDashboardLayout.includes(currentMatch.fullPath as (typeof hashtagsDashboardLayout)[number]))
      return 'hashtags';

    return undefined;
  })();

  return <Layout menuType={menuType} title={staticData?.menuTitle} hasCombinedTerms={staticData?.hasCombinedTerms} />;
};

export const PrivateRouter = {
  '/_private-routes': [PrivateRoutes, {}],
  ...AdvertiserRouter,
  ...AgencyRouter,
  ...AnalyticsRouter,
  ...AutoManagedRouter,
  ...ChatDemoRouter,
  ...EngagementRouter,
  ...AssetPreviewRouter,
  ...CampaignPostsDiscoveryRouter,
  ...HashtagAnalyticsRouter,
  ...InfluencerRouter,
  ...InfluencersRouter,
  ...InvoicesRouter,
  ...MarketplaceRouter,
  ...PackageRouter,
  ...PartnerRouter,
  ...PaymentsRouter,
  ...PostsInsightRouter,
  ...PostsRouter,
  ...RedirectRouter,
  ...ReportRouter,
  ...SettingsRouter,
  ...TalentAgencyRouter,
  ...TikTokRouter,
  ...TrendingRouter,
  ...UserSettingsRouter,
  ...UserRouter,
  ...YoutubeCmsRouter
} as const satisfies PartialRouter;
