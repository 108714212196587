import { Link } from '@/router';
import { TextCutter } from '@/shared/atoms';
import { InstagramCompareAccount } from '@/graphql';
import { InfluencerAvatar } from '@/shared/molecules';
import { ProfilePostsTabs, ProfilePostsTabType } from '@/shared/organisms';
import { AnalyticsInstagramPostsTemplate } from '../AnalyticsInstagramPosts';
import { AnalyticsIgFeedPostsListSearchSchemaType } from '../hooks';
import { AnalyticsInstagramComparePostsTemplate } from './AnalyticsInstagramComparePostsTemplate';
import { useAnalyticsIgComparePostsFilter } from './hooks';

export interface TemplateProps {
  mainSocialAccountId: number;
  comparableAccounts: InstagramCompareAccount[];
}

export const Template = ({ comparableAccounts, mainSocialAccountId }: TemplateProps) => {
  const { filter } = useAnalyticsIgComparePostsFilter();

  const isMainAccount = mainSocialAccountId === filter.accountId;
  const tabOptions = comparableAccounts.map<ProfilePostsTabType>(({ id, username, avatar }) => ({
    value: id,
    label: (props) => (
      <Link
        key={id}
        to="/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post"
        activeOptions={{ exact: true, includeSearch: true }}
        search={(curr: AnalyticsIgFeedPostsListSearchSchemaType) => ({
          ...curr,
          filter: {
            ...curr.filter,
            accountId: id
          }
        })}
        {...props}
      >
        <InfluencerAvatar size="32px" css={{ marginRight: '4px', borderRadius: '50%' }} src={avatar} asChild />
        <TextCutter text={username} />
      </Link>
    )
  }));

  return filter.accountId ? (
    <>
      <ProfilePostsTabs options={tabOptions} />

      {isMainAccount ? (
        <AnalyticsInstagramPostsTemplate mainSocialAccountId={filter.accountId} isComparePage />
      ) : (
        <AnalyticsInstagramComparePostsTemplate socialAccountId={filter.accountId} />
      )}
    </>
  ) : null;
};
