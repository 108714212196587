import {
  heartImage,
  pictureImage,
  userPinkImage,
  eyePurpleImage,
  chatGreenImage,
  websiteBlueImage,
  notebookLightPurple,
  bulbLightYellowImage,
  chartLightYellowImage,
  bookmarkLightBlueImage,
  thunderLightYellowImage
} from '@/shared/assets';
import {
  AnalyticsInstagramDashboardGraphKeys,
  AnalyticsInstagramDashboardLegendType,
  AnalyticsInstagramDashboardOverviewEngagementType
} from './types';

export const getDropdownLegendKeys = (isInfluencerProfile?: boolean): AnalyticsInstagramDashboardGraphKeys[] =>
  isInfluencerProfile
    ? ['impressions', 'profileViews', 'websiteClicks']
    : ['impressions', 'engagement', 'engagementRate', 'profileViews', 'websiteClicks'];

export const legendColors: Record<AnalyticsInstagramDashboardGraphKeys, string> = {
  posts: '#ffd7c1',
  reach: '#ff96ae',
  likes: '#e9b8d7',
  saved: '#a0c8df',
  comments: '#aec0e4',
  followers: '#ff2b52',
  engagement: '#a0dfd5',
  impressions: '#ffb6b7',
  profileViews: '#c484eb',
  websiteClicks: '#ffaae1',
  engagementRate: '#a5a8e0'
};

export const getAllLegendOptions = (isInfluencerProfile?: boolean): AnalyticsInstagramDashboardLegendType[] => [
  { label: 'Follower', value: 'followers', color: legendColors.followers },
  { label: 'Post', value: 'posts', color: legendColors.posts },
  { label: 'Impression', value: 'impressions', color: legendColors.impressions },
  { label: 'Reach', value: 'reach', color: legendColors.reach },
  ...(!isInfluencerProfile
    ? ([
        { label: 'Engagement', value: 'engagement', color: legendColors.engagement },
        { label: 'Engagement Rate', value: 'engagementRate', color: legendColors.engagementRate }
      ] as AnalyticsInstagramDashboardLegendType[])
    : []),
  { label: 'Like', value: 'likes', color: legendColors.likes },
  { label: 'Comment', value: 'comments', color: legendColors.comments },
  { label: 'Save', value: 'saved', color: legendColors.saved },
  { label: 'Profile views', value: 'profileViews', color: legendColors.profileViews },
  { label: 'Website Click', value: 'websiteClicks', color: legendColors.websiteClicks }
];

export const allOverviewEngagements: Pick<
  AnalyticsInstagramDashboardOverviewEngagementType,
  'key' | 'title' | 'icon' | 'css' | 'isPercentage'
>[] = [
  { key: 'followers', title: 'Follower (Total)', icon: userPinkImage },
  { key: 'posts', title: 'Post', icon: pictureImage },
  { key: 'reach', title: 'Reach', icon: thunderLightYellowImage },
  { key: 'likes', title: 'Like', icon: heartImage },
  { key: 'comments', title: 'Comment', icon: chatGreenImage },
  { key: 'saved', title: 'Save', icon: bookmarkLightBlueImage },
  { key: 'impressions', title: 'Impression', icon: eyePurpleImage },
  { key: 'engagement', title: 'Engagement', icon: bulbLightYellowImage },
  { key: 'engagementRate', title: 'Engagement Rate', icon: chartLightYellowImage, isPercentage: true },
  { key: 'profileViews', title: 'Profile views', icon: notebookLightPurple },
  { key: 'websiteClicks', title: 'Website Click', icon: websiteBlueImage }
];
