import { getOffset, getValuableVariables } from '@/shared/utils';
import { TiktokUserHashtag, useTiktokUserHashtagPostListQuery } from '@/graphql';
import {
  TagType,
  TagAnalyticsType,
  TiktokTagsAnalyticsFilter,
  TiktokTagsAnalyticsPostsList,
  useTiktokTagsAnalyticsFilter
} from '@/shared/organisms';
import { TiktokTagsAnalyticsTemplate } from '../shared';

interface TemplateProps {
  hashtags: readonly TiktokUserHashtag[];
}

export const Template = ({ hashtags }: TemplateProps) => {
  const { page, limit, sort, filter } = useTiktokTagsAnalyticsFilter();
  const { data, loading, previousData } = useTiktokUserHashtagPostListQuery({
    skip: !filter.tagId,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      date: filter.date,
      orderBy: sort,
      keyword: filter.keyword,
      tagId: Number(filter.tagId),
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const hashtagData = result?.tiktokUserHashtagPostList;

  return (
    <TiktokTagsAnalyticsTemplate>
      <TiktokTagsAnalyticsFilter
        tagType={TagType.HASHTAG}
        tags={hashtags.map<TagAnalyticsType>(({ id, hashtag }) => ({ id, label: `#${hashtag}` }))}
      />

      <TiktokTagsAnalyticsPostsList data={hashtagData?.posts} loading={loading} totalRecords={hashtagData?.count} />
    </TiktokTagsAnalyticsTemplate>
  );
};
