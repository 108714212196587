// TODO: Refactor. This component replaced for anchor tag and Link component
// TODO: improvement. This component just cover few cases, we need to improve it
import { css, Interpolation, Theme } from '@emotion/react';
import React, { CSSProperties, forwardRef } from 'react';
import { THEME } from '@/shared/constants';
import { LinkRouterProps, Link } from '@/router';
import { TextCutter } from '../TextCutter';

interface BaseAnchorProps {
  lines?: number;
  disabled?: boolean;
  className?: string;
  label?: string | null;
  variant?: keyof typeof styles;
  style?: CSSProperties;
  title?: string;
  css?: Interpolation<Theme>;
}

export type InternalLinkProps = LinkRouterProps;
type ExternalLinkProps = Pick<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'onClick' | 'href' | 'target' | 'rel' | 'onMouseEnter' | 'onMouseLeave'
>;

export type AnchorPropsInternal = InternalLinkProps & BaseAnchorProps;
export type AnchorProps = BaseAnchorProps & ExternalLinkProps & InternalLinkProps;

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    { lines, target, onClick, children, disabled, label = '', variant = 'default', to, href, className, ...restProps },
    ref
  ) => {
    const customDisabled = disabled || (!(href || to) && !onClick);
    const commonProps = {
      ref,
      target,
      disabled: customDisabled,
      css: [styles.wrapper, styles[variant], customDisabled && styles.disabled],
      className,
      onClick:
        !(href || to) && !onClick
          ? (e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
            }
          : onClick
    };
    const content = children || <TextCutter text={label} lines={lines} css={{ flex: 1 }} />;

    if (href) {
      return (
        <a {...commonProps} href={href} rel="noopener noreferrer">
          {content as React.ReactNode}
        </a>
      );
    } else {
      const { state, replace, search, params, href, ...rest } = restProps as InternalLinkProps;
      return (
        <Link {...commonProps} to={to} state={state} replace={replace} search={search} params={params} {...rest}>
          {content}
        </Link>
      );
    }
  }
);

const styles = {
  inherit: css({ color: 'inherit' }),
  disabled: css({ pointerEvents: 'none' }),
  blue: css({ color: THEME.text.colors.blue }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer !important',
    '& *': { cursor: 'pointer !important' },
    '&:hover': {
      cursor: 'pointer !important',
      '& *': { cursor: 'pointer !important' }
    }
  }),
  default: css({ color: THEME.text.colors.black.lv1, '&:hover': { color: THEME.text.colors.blue } })
} as const;
