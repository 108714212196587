import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportTiktokKeywordPostsToGoogleSpreadsheet.graphql';
import { Mutation, MutationexportTiktokKeywordPostsToGoogleSpreadsheetArgs } from '../../__generated__/globalTypes';

export const useExportTiktokKeywordPostsToGoogleSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationexportTiktokKeywordPostsToGoogleSpreadsheetArgs>
) => {
  const [callExportTiktokKeywordPostsToGoogleSpreadsheet, result] = useMutation<
    Mutation,
    MutationexportTiktokKeywordPostsToGoogleSpreadsheetArgs
  >(MUTATION, options);

  return { callExportTiktokKeywordPostsToGoogleSpreadsheet, ...result };
};

export type ExportTiktokKeywordPostsToGoogleSpreadsheetMutationFunction =
  Mutation['exportTiktokKeywordPostsToGoogleSpreadsheet'];
