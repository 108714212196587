import { RenderDataWithFallback } from '@/shared/atoms';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { useInstagramUserHashtagsQuery } from '@/graphql';
import { useInstagramHashtagsAnalyticsFilter } from '@/shared/organisms';
import { Template } from './Template';

export const InstagramHashtagsAnalytics = () => {
  useUpdateDocumentTitle({
    title: 'appHeader.Instagram Hashtag Analytics',
    appHeader: 'appHeader.Instagram Hashtag Analytics'
  });

  const { filter, setFilter } = useInstagramHashtagsAnalyticsFilter();
  const { data, loading, called } = useInstagramUserHashtagsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ instagramUserHashtags }) => {
      const isValidHashtag =
        instagramUserHashtags?.hashtags?.findIndex((hashtag) => hashtag.hashtagId === filter.tagId) !== -1;

      if (!filter.tagId || !isValidHashtag) {
        setFilter({ tagId: instagramUserHashtags?.hashtags?.at(0)?.hashtagId || '' }, { replace: true });
      }
    }
  });

  return (
    <RenderDataWithFallback loading={loading && !called} hasNoData={!filter.date}>
      <Template hashtags={data?.instagramUserHashtags.hashtags || []} />
    </RenderDataWithFallback>
  );
};
