type JsonParseOrStringifyBase = Record<string, any>;

export function jsonParseOrStringify<P extends JsonParseOrStringifyBase>(method: 'stringify', props?: P): string;
export function jsonParseOrStringify<P extends JsonParseOrStringifyBase, R extends JsonParseOrStringifyBase>(
  method: 'parse',
  props?: P,
  onParseErrorCb?: () => void
): R;

export function jsonParseOrStringify<P extends JsonParseOrStringifyBase>(
  method: 'parse' | 'stringify',
  props?: P,
  onParseErrorCb?: () => void
) {
  try {
    if (method === 'parse' && props?.stringifiedData) {
      return JSON.parse(props.stringifiedData);
    } else if (method === 'stringify') {
      return JSON.stringify(props?.params) as string;
    }
  } catch (error) {
    console.error('Error accessing AnalyticsAccountFormState:', error);
    onParseErrorCb?.();
  }
}
