import { useEffect } from 'react';
import { useParams } from '@tanstack/react-router';
import {
  RedirectType,
  SocialAccountType,
  SocialAccountStatus,
  useSocialAuthTalentSignInTwitterMutation,
  useSocialAuthTalentSignInYouTubeMutation,
  useSocialAuthTalentSignInFacebookMutation,
  useSocialAuthTalentSignInInstagramMutation
} from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { TalentInfluencerFormValues, TalentUserAddLocationStateType } from '@/shared/organisms';
import { getCallbackUrl } from '@/shared/utils';
import { LinkRouterProps, manageLocalStorage } from '@/router';
import { RedirectProviderParamsSchemaType } from './schemaTypes';

const locationState: TalentUserAddLocationStateType = {
  connectType: undefined,
  connectableFbFanpages: undefined,
  connectableIgAccounts: undefined,
  connectableYtChannels: undefined,
  connectableTwAccount: undefined,
  connectableFbAccount: undefined,
  parsedScrappingAccountInfo: undefined,
  talentAgencyUserFormValues: undefined
};
export const navigateTo = ['/influencer/add', '/influencer/$id'] as const satisfies ReadonlyArray<
  LinkRouterProps['to']
>;
type RedirectBackType = (typeof navigateTo)[number];

export const RedirectTalentInfluencerSignIn = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { provider } = useParams({
    from: '/_private-routes/redirect/talent-signin/$provider'
  }) satisfies RedirectProviderParamsSchemaType;
  const response = window.location.search.slice(1);
  const { callSocialAuthTalentSignInYouTube } = useSocialAuthTalentSignInYouTubeMutation();
  const { callSocialAuthTalentSignInTwitter } = useSocialAuthTalentSignInTwitterMutation();
  const { callSocialAuthTalentSignInFacebook } = useSocialAuthTalentSignInFacebookMutation();
  const { callSocialAuthTalentSignInInstagram } = useSocialAuthTalentSignInInstagramMutation();

  useEffect(() => {
    switch (provider) {
      case 'youtube':
        fetchYtSignYtChannels();
        break;
      case 'twitter':
        fetchTwSignAccount();
        break;
      case 'instagram':
        fetchIgSignInAccounts();
        break;
      case 'facebook':
        fetchFbSignInAccount();
        break;
      default:
        break;
    }

    return () => {
      manageLocalStorage('remove', 'scrappingAccountInfo');
      manageLocalStorage('remove', 'talentInfluencerId');
      manageLocalStorage('remove', 'talentAgencyUserFormValues');
    };
  }, []);

  let savedFormValues: TalentInfluencerFormValues | undefined;
  let prevSavedFbAccount: TalentInfluencerFormValues['connectedFacebook'];
  let prevSavedTwAccounts: TalentInfluencerFormValues['connectedTwitters'] = [];
  locationState.talentAgencyUserFormValues = savedFormValues;

  const talentInfluencerId = manageLocalStorage('get', 'talentInfluencerId');
  const redirectBack: RedirectBackType = talentInfluencerId ? '/influencer/$id' : '/influencer/add';
  const params = {
    id: talentInfluencerId
  };
  const scrappingAccountInfo = manageLocalStorage('get', 'scrappingAccountInfo') || ''; // for TA scrapping accounts management
  const savedUserFormValuesFromLocalStorage = manageLocalStorage('get', 'talentAgencyUserFormValues') || '';
  const variables = { input: { response, callbackUrl: getCallbackUrl(provider, RedirectType.TALENT_SIGNIN) } };

  if (savedUserFormValuesFromLocalStorage) {
    try {
      savedFormValues = JSON.parse(savedUserFormValuesFromLocalStorage) as TalentInfluencerFormValues;
      prevSavedFbAccount = savedFormValues.connectedFacebook;
      prevSavedTwAccounts = savedFormValues.connectedTwitters || [];
    } catch (error) {
      enqueueSnackbar(t('Cannot restore your previously selected Facebook account'), { variant: 'error' });
    }
  }

  if (scrappingAccountInfo) {
    try {
      locationState.parsedScrappingAccountInfo = JSON.parse(scrappingAccountInfo);
    } catch (error) {
      enqueueSnackbar(t('Cannot restore scrapping account information'), { variant: 'error' });
    }
  }

  const fetchIgSignInAccounts = async () => {
    try {
      const { data } = await callSocialAuthTalentSignInInstagram({ variables });
      locationState.connectableFbAccount = data?.socialAuthTalentSignInInstagram?.facebook;
      locationState.connectableIgAccounts =
        data?.socialAuthTalentSignInInstagram?.pagesAndIgAccounts.map((acc) => ({
          ...acc,
          isPageAlreadyConnected: acc.isAlreadyUsed
        })) || [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      navigate({
        to: redirectBack,
        params,
        search: {
          state: { ...locationState, connectType: SocialAccountType.INSTAGRAM }
        }
      });
    }
  };

  const fetchFbSignInAccount = async () => {
    try {
      const { data } = await callSocialAuthTalentSignInFacebook({
        variables: {
          input: { ...variables.input, influencerId: talentInfluencerId ? Number(talentInfluencerId) : null }
        }
      });
      const { fbName, fbUserId, fbAvatar, fbFollowersCount, pages } = data?.socialAuthTalentSignInFacebook || {};
      locationState.connectableFbAccount =
        fbName && fbUserId
          ? { fbName, fbUserId, fbAvatar, fbFollowersCount, status: SocialAccountStatus.SIGNED_UP }
          : undefined;

      locationState.connectableFbFanpages =
        pages?.map((el) => ({
          ...el.page,
          status: SocialAccountStatus.SIGNED_UP,
          isPageAlreadyConnected: el.isPageAlreadyConnected
        })) || [];
      locationState.connectableIgAccounts = pages || [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      if (
        prevSavedFbAccount &&
        prevSavedFbAccount.status !== SocialAccountStatus.SCRAPING &&
        prevSavedFbAccount.fbUserId !== locationState.connectableFbAccount?.fbUserId
      ) {
        navigate({ to: redirectBack, params });
        enqueueSnackbar(t('Cannot sign in with selected Facebook account'), { variant: 'error' });
      } else {
        navigate({
          to: redirectBack,
          params,
          search: {
            state: { ...locationState, connectType: SocialAccountType.FACEBOOK }
          }
        });
      }
    }
  };

  const fetchYtSignYtChannels = async () => {
    try {
      const { data } = await callSocialAuthTalentSignInYouTube({
        variables: {
          input: {
            ...variables.input,
            channelId: locationState.parsedScrappingAccountInfo?.channelId
              ? String(locationState.parsedScrappingAccountInfo.channelId)
              : null
          }
        }
      });
      locationState.connectableYtChannels = data?.socialAuthTalentSignInYouTube?.channels || [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      navigate({
        to: redirectBack,
        params,
        search: {
          state: {
            ...locationState,
            connectType: SocialAccountType.YOUTUBE
          }
        }
      });
    }
  };

  const fetchTwSignAccount = async () => {
    try {
      const { data } = await callSocialAuthTalentSignInTwitter({
        variables: {
          input: {
            ...variables.input,
            screenName: locationState.parsedScrappingAccountInfo?.screenName || null
          }
        }
      });
      const { twName, twUserId, status, twScreenName } = data?.socialAuthTalentSignInTwitter || {};

      if (prevSavedTwAccounts.length && prevSavedTwAccounts.some((acc) => acc.twUserId === twUserId)) {
        // means this account is already connected but not saved
        locationState.connectableTwAccount = undefined;
      } else {
        locationState.connectableTwAccount =
          twName && twUserId && status && twScreenName ? { twName, twUserId, status, twScreenName } : undefined;
        enqueueSnackbar(t('succeededInConnecting', { name: twName }), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      navigate({
        to: redirectBack,
        params,
        search: {
          state: { ...locationState, connectType: SocialAccountType.TWITTER }
        }
      });
    }
  };

  return <Loading />;
};
