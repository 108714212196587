import { InstagramDashboardOverview } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useDeepCompareEffect, useSelectItemIds } from '@/shared/hooks';
import { InstagramPostInDateDetailsModal } from '@/shared/molecules';
import { AnalyticsSelectedAccountInfo, NoteAggregateLastThirtyDays } from '@/shared/organisms';
import {
  AnalyticsOverviewChart,
  AnalyticsOverviewLegend,
  AnalyticsOverviewWrapper,
  AnalyticsOverviewEngagement
} from '../../../shared';
import {
  useAnalyticsInstagramDashboardOverviewData,
  useAnalyticsInstagramDashboardOverviewPosts,
  useAnalyticsInstagramDashboardOverviewLegend
} from './hooks';
import { AnalyticsInstagramDashboardGraphKeys as Keys } from './types';

interface TemplateProps {
  data: InstagramDashboardOverview;
  accountId: AnalyticsSelectedAccountInfo['id'];
  isInfluencerProfile?: boolean;
}

const Template = ({ data, accountId, isInfluencerProfile }: TemplateProps) => {
  const { availableLegendOptions, ButtonSelectMoreLegendOptions } = useAnalyticsInstagramDashboardOverviewLegend({
    isInfluencerProfile
  });
  const {
    selectItem: setSelectedLegendKey,
    selectedItemIds: selectedLegendKeys,
    setSelectedItemIds: setSelectedLegendKeys
  } = useSelectItemIds<Keys>({ defaultSelectedIds: ['followers', 'posts'] });
  const { chartData, categories, overviewEngagements, availableOverviewEngagements } =
    useAnalyticsInstagramDashboardOverviewData({
      data,
      selectedLegendKeys,
      isInfluencerProfile,
      availableEngagementKeys: availableLegendOptions.map((option) => option.value)
    });
  const { dailyPosts, customChartOptions } = useAnalyticsInstagramDashboardOverviewPosts({
    chartData,
    accountId,
    isInfluencerProfile
  });

  useDeepCompareEffect(() => {
    // Reset selected legend keys when user unselect dropdown option
    const availableLegendKeys = availableLegendOptions.map<Keys>((option) => option.value);
    const newSelectedLegendKeys = selectedLegendKeys.filter((key) => availableLegendKeys.includes(key));
    setSelectedLegendKeys(newSelectedLegendKeys);
  }, [availableLegendOptions]);

  const hasNoData = !overviewEngagements.some((item) => !!item.percentage);

  return (
    <>
      <AnalyticsOverviewWrapper
        dataNotAvailable={hasNoData}
        headerChildren={
          !hasNoData ? (
            <>
              {isInfluencerProfile ? <NoteAggregateLastThirtyDays /> : null}
              {isInfluencerProfile ? ButtonSelectMoreLegendOptions : null}
            </>
          ) : null
        }
      >
        <AnalyticsOverviewEngagement engagement={availableOverviewEngagements} />
        <AnalyticsOverviewLegend
          options={availableLegendOptions}
          selectedOptions={selectedLegendKeys}
          onSelectOption={({ value }) => setSelectedLegendKey(value)}
        />
        <AnalyticsOverviewChart
          data={chartData}
          categories={categories}
          customTooltip={{ itemsPerRow: 3 }}
          customOptions={customChartOptions}
        />
      </AnalyticsOverviewWrapper>

      <InstagramPostInDateDetailsModal data={dailyPosts} paramKey={DAILY_POST_PARAM_KEY} />
    </>
  );
};

export interface AnalyticsInstagramDashboardOverviewTemplateProps
  extends Pick<TemplateProps, 'accountId' | 'isInfluencerProfile'> {
  loading?: boolean;
  data?: InstagramDashboardOverview | null;
}
export const AnalyticsInstagramDashboardOverviewTemplate = ({
  data,
  loading,
  ...restProps
}: AnalyticsInstagramDashboardOverviewTemplateProps) => (
  <RenderDataWithFallback loading={loading} skipDelayed>
    {data ? <Template {...restProps} data={data} /> : null}
  </RenderDataWithFallback>
);
