import { useEffect } from 'react';
import { useParams } from '@tanstack/react-router';
import {
  AnalyticsRedirectType,
  useSocialAuthReconnectYouTubeAccountAnalyticsV2Mutation,
  useSocialAuthReconnectTwitterAccountAnalyticsV2Mutation,
  useSocialAuthReconnectInstagramAccountAnalyticsMutation,
  useSocialAuthReconnectFacebookPageAccountAnalyticsMutation
} from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { getCallbackUrl } from '@/shared/utils';
import { manageLocalStorage } from '@/router';
import { getAnalyticsListReconnectState } from '@/shared/organisms';

export const RedirectAnalyticsListConnect = () => {
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { provider } = useParams({ from: '/_private-routes/redirect/analytics-list-connect/$provider' });
  const response = window.location.search.slice(1);
  const { callSocialAuthReconnectYouTubeAccountAnalyticsV2 } =
    useSocialAuthReconnectYouTubeAccountAnalyticsV2Mutation();
  const { callSocialAuthReconnectTwitterAccountAnalyticsV2 } =
    useSocialAuthReconnectTwitterAccountAnalyticsV2Mutation();
  const { callSocialAuthReconnectInstagramAccountAnalytics } =
    useSocialAuthReconnectInstagramAccountAnalyticsMutation();
  const { callSocialAuthReconnectFacebookPageAccountAnalytics } =
    useSocialAuthReconnectFacebookPageAccountAnalyticsMutation();

  useEffect(() => {
    reconnectAccount();
  }, []);

  const stringifiedData = manageLocalStorage('get', 'analyticsReconnectFromListState_json');
  const { page, filter, reconnectingAccount } = getAnalyticsListReconnectState('parse', { stringifiedData });

  const reconnectAccount = async () => {
    try {
      if (reconnectingAccount) {
        switch (provider) {
          case 'instagram':
            await callSocialAuthReconnectInstagramAccountAnalytics({
              variables: {
                input: {
                  response,
                  accountId: Number(reconnectingAccount.id),
                  username: reconnectingAccount.socialAccountName || '',
                  businessAccountId: reconnectingAccount.socialAccountId || '',
                  redirectType: AnalyticsRedirectType.ANALYTICS_LIST_CONNECT,
                  fbPageId: reconnectingAccount.linkedAccount?.socialAccountId || null,
                  callbackUrl: getCallbackUrl('instagram', AnalyticsRedirectType.ANALYTICS_LIST_CONNECT)
                }
              }
            });
            break;
          case 'youtube':
            await callSocialAuthReconnectYouTubeAccountAnalyticsV2({
              variables: {
                input: {
                  channelId: reconnectingAccount.socialAccountId || '',
                  response,
                  callbackUrl: getCallbackUrl('youtube', AnalyticsRedirectType.ANALYTICS_LIST_CONNECT)
                }
              }
            });
            break;
          case 'facebook':
            await callSocialAuthReconnectFacebookPageAccountAnalytics({
              variables: {
                input: {
                  response,
                  accountId: Number(reconnectingAccount.id),
                  fbPageId: reconnectingAccount.socialAccountId || '',
                  callbackUrl: getCallbackUrl('facebook', AnalyticsRedirectType.ANALYTICS_LIST_CONNECT)
                }
              }
            });
            break;
          case 'twitter':
            await callSocialAuthReconnectTwitterAccountAnalyticsV2({
              variables: {
                input: {
                  response,
                  callbackUrl: getCallbackUrl('twitter', AnalyticsRedirectType.ANALYTICS_LIST_CONNECT)
                }
              }
            });
            break;
          default:
            break;
        }

        manageLocalStorage('set', 'reconnectStatus', 'success');
        manageLocalStorage('set', 'reconnectType', provider);
        enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      navigate({
        to: '/analytics',
        search: {
          filter,
          page
        }
      });
    }
  };

  return <Loading />;
};
