import { useTranslation } from 'react-i18next';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { PrivacyEN, PrivacyJP, SignInSignUpLayout } from '@/shared/organisms';
import { Language } from '@/shared/types';
import { useAuthData } from '@/auth';

export const PrivacyPolicy = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Privacy' });
  const { i18n } = useTranslation();
  const { defaultRoute } = useAuthData();

  return (
    <SignInSignUpLayout
      footerProps={{ className: { paddingBottom: '85px' } }}
      headerProps={{ to: defaultRoute, className: { paddingTop: '40px' } }}
    >
      {i18n.language === Language.ja ? <PrivacyJP /> : <PrivacyEN />}
    </SignInSignUpLayout>
  );
};
