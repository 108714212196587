import { useTranslation } from 'react-i18next';
import { RangeDatePickerField, SelectorField } from '@/shared/molecules';
import { DownloadFileType, Option, SelectedRangeOption } from '@/shared/types';
import { DownloadSubmenuIcon, ModalContent, ModalFooterActions, ModalTitle } from '@/shared/atoms';
import { ExportTagsFormKeys } from './types';

export interface TemplateProps {
  onClose?: () => void;
  downloading?: boolean;
  title?: React.ReactNode;
}

export const Template = ({ title, onClose, downloading }: TemplateProps) => {
  const { t } = useTranslation();

  const fileOptions: Option[] = [
    { label: t<string>('CSV'), value: DownloadFileType.CSV, prefixIcon: <DownloadSubmenuIcon icon="csv" /> },
    { label: t<string>('Excel'), value: DownloadFileType.EXCEL, prefixIcon: <DownloadSubmenuIcon icon="excel" /> },
    {
      label: t<string>('Spreadsheet'),
      value: DownloadFileType.SPREADSHEET,
      prefixIcon: <DownloadSubmenuIcon icon="spreadsheet" />
    }
  ];

  return (
    <>
      <ModalContent>
        <ModalTitle>{title ?? t('HashTag Export')}</ModalTitle>

        <RangeDatePickerField<ExportTagsFormKeys>
          clearable={false}
          css={{ marginTop: '16px' }}
          title={t('Selector.Date Range')}
          disabledRange={{ after: new Date() }}
          name={{ startDate: 'startDate', endDate: 'endDate' }}
          rangeList={[
            SelectedRangeOption.TODAY,
            SelectedRangeOption.LAST_SEVEN_DAYS,
            SelectedRangeOption.TODAY_TO_MONTH_AGO,
            SelectedRangeOption.THIS_MONTH,
            SelectedRangeOption.LAST_MONTH,
            SelectedRangeOption.CUSTOM
          ]}
        />

        <SelectorField<ExportTagsFormKeys>
          name="type"
          title={t('Selector.File Type')}
          options={fileOptions}
          singleSelectorProps={{ hideInput: true, hideDeselectOption: true }}
        />
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ type: 'submit', loading: downloading, disabled: downloading, title: t('Button.Export') }}
      />
    </>
  );
};
