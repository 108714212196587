import { InstagramStoryPost } from '@/graphql';

export const defaultSelectedAnalyticsInstagramStoryPostsColumns: Array<keyof InstagramStoryPost> = [
  'accountsEngaged',
  'comments',
  'exits',
  'follow',
  'interaction',
  'likes',
  'linkClicks',
  'postDate',
  'productButtonClick',
  'productPageView',
  'profileActivity',
  'profileVisits',
  'reach',
  'shares',
  'stickerTaps',
  'swipeForward',
  'tapsBack',
  'tapsForward',
  'views',
  'viewsByFollowers',
  'viewsByNonFollowers'
];
