import { css } from '@emotion/react';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from '@/router';
import { useAuthData } from '@/auth';
import { Icon } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useLocationHistory, useQueryHelper } from '@/shared/hooks';

export interface HeaderProps {
  className?: string;
  children?: ReactNode;
}

export const Header = ({ className, children }: HeaderProps) => {
  const {
    auth: { href, title, alwaysBackToParent, backInHistory, search }
  } = useAuthData();
  const { lastLocation } = useLocationHistory();
  const [historyOnMount, setHistoryOnMount] = useState<typeof lastLocation>(null);
  const { pathname } = useQueryHelper();
  const backToParentUrl = href;

  useEffect(() => {
    if (lastLocation) {
      setHistoryOnMount(lastLocation);
    }
  }, []);

  /**
   * after we redirecting from FB or other external links we don't want user to go there or back to external domains
   * if lastLocation contains any "/redirect" means we just came from sign-up process and should use "backToParentUrl"
   * or lastLocation?.pathname && lastLocation?.search might be the same as current one - we should use "backToParentUrl" as well
   */
  const cannotGoBack = historyOnMount?.pathname?.includes('/redirect') || pathname === historyOnMount?.pathname;

  const goBackUrl =
    alwaysBackToParent && backToParentUrl // if we have alwaysBackToParent flag, we should always go back to parent url
      ? backToParentUrl
      : cannotGoBack // means that we have history path same as current path
        ? backInHistory?.fallback
        : historyOnMount?.pathname || backInHistory?.fallback; // if you copy url where we normally should have history, we should go back fallback

  return title ? (
    <header css={styles.header} className={className}>
      {href || backInHistory ? (
        <Link
          to={goBackUrl}
          search={backInHistory ? historyOnMount?.search : search}
          css={styles.backButton}
          replace={!!backInHistory}
        >
          <Icon icon="caret-left" size={11} />
        </Link>
      ) : null}
      <h1 css={styles.title}>{title}</h1>
      {children}
    </header>
  ) : null;
};

const styles = {
  header: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    paddingBottom: '16px',
    boxSizing: 'border-box',
    height: THEME.container.header.height,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  title: css({
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontSize: '18px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  backButton: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: THEME.button.base.borderRadius,
    border: `1px solid ${THEME.border.colors.gray.lv2}`,
    backgroundColor: THEME.background.colors.white,
    '&:hover': {
      borderColor: THEME.border.colors.gray.lv3
    }
  })
};
