import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useLazyQuery,
  useApolloClient,
  QueryOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryExecFunction
} from '@apollo/client';
import { useSnackbarHelper } from '../../useSnackbarHelper';
import QUERY from '../operations/TiktokUserKeywordPostList.graphql';
import { Query, QuerytiktokUserKeywordPostListArgs } from '../../__generated__/globalTypes';

export const useTiktokUserKeywordPostListQuery = (
  options?: QueryHookOptions<Query, QuerytiktokUserKeywordPostListArgs>
) => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const result = useQuery<Query, QuerytiktokUserKeywordPostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return result;
};

export const useTiktokUserKeywordPostListLazyQuery = (
  options?: LazyQueryHookOptions<Query, QuerytiktokUserKeywordPostListArgs>
): QueryResult<Query, QuerytiktokUserKeywordPostListArgs> & {
  getTiktokUserKeywordPostList: LazyQueryExecFunction<Query, QuerytiktokUserKeywordPostListArgs>;
} => {
  const { t, enqueueSnackbar } = useSnackbarHelper();
  const [getTiktokUserKeywordPostList, result] = useLazyQuery<Query, QuerytiktokUserKeywordPostListArgs>(QUERY, {
    onError: (error) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    ...options
  });

  return { getTiktokUserKeywordPostList, ...result };
};

export const useTiktokUserKeywordPostListPromiseQuery = () => {
  const client = useApolloClient();

  const getTiktokUserKeywordPostList = useCallback(
    (options?: Omit<QueryOptions<QuerytiktokUserKeywordPostListArgs, Query>, 'query'>) =>
      client.query<Query, QuerytiktokUserKeywordPostListArgs>({ ...options, query: QUERY }),
    [client]
  );

  return { getTiktokUserKeywordPostList };
};
