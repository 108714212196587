import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { Order, TiktokUserTagPostsSortOrder } from '@/graphql';
import { TagsAnalyticsSearchSchema } from '../../shared';

export const TiktokTagsAnalyticsSearchSchema = TagsAnalyticsSearchSchema.extend({
  sort: SearchFilterDefaults.sort(TiktokUserTagPostsSortOrder).default({
    order: Order.ASC,
    field: TiktokUserTagPostsSortOrder.RANK
  })
});
export type TiktokTagsAnalyticsSearchSchemaType = z.infer<typeof TiktokTagsAnalyticsSearchSchema>;
