import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from '@tanstack/react-router';
import { AppProviders, client } from '@/providers';
import { RouterContext } from '@/router';
import { useAuthData } from '@/auth';
import { NotFound } from '../pages/Public/NotFound';
import { router } from './init-router-pages';

declare const GA_TRACKING_CODE: string;
declare const GTM_ID: string;

const initializeReactGA = (trackingCode: string) => {
  ReactGA.initialize(trackingCode);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const root = document.getElementById('container');
    root?.setAttribute('lang', i18n.language);
  }, [i18n.language, i18n.isInitialized]);

  useEffect(() => {
    if (GA_TRACKING_CODE) {
      initializeReactGA(GA_TRACKING_CODE);
    }
    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
  }, []);

  return (
    <AppProviders>
      <InnerApp />
    </AppProviders>
  );
};

const InnerApp = () => {
  const { auth, dispatch } = useAuthData();

  return (
    <RouterProvider
      router={router}
      context={{ queryClient: client, auth, dispatch, invalidate: () => router.invalidate() } satisfies RouterContext}
      defaultNotFoundComponent={NotFound}
    />
  );
};
