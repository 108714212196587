import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { FormStyle } from '@/shared/atoms';
import { TextSearchField, BasicFilterWrapper, SingleDatePickerField } from '@/shared/molecules';
import { TagType, TagsList, TagsListProps, TagsAnalyticsFilterFormKeys } from '../../shared';
import { useCreateDeleteTiktokTag, useExportTiktokTagsAnalyticsFile } from './hooks';

export interface TemplateProps extends Pick<TagsListProps, 'tags' | 'onSubmit'> {
  tagType: TagType;
}

export const Template = ({ tags, tagType, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { ExportButton, ExportModal } = useExportTiktokTagsAnalyticsFile({ tagType });
  const { handleCreateTag, handleDeleteTag, creating, deleting } = useCreateDeleteTiktokTag({ tagType });

  return (
    <>
      <div css={{ padding: '16px 16px 4px', boxSizing: 'border-box', borderBottom: THEME.border.base }}>
        <TagsList
          tags={tags}
          tagType={tagType}
          onSubmit={onSubmit}
          creating={creating}
          deleting={deleting}
          onCreateTag={handleCreateTag}
          onDeleteTag={handleDeleteTag}
        />
        <BasicFilterWrapper css={{ padding: 0, marginTop: '8px' }}>
          <TextSearchField<TagsAnalyticsFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('hashtagsAnalyticsKeywordPlaceholder')}
          />

          <SingleDatePickerField<TagsAnalyticsFilterFormKeys>
            name="date"
            clearable={false}
            onSubmit={onSubmit}
            disabledDays={{ after: new Date() }}
            css={{ flex: 'none', width: '184px' }}
            required
          />

          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <ExportButton />
          </FormStyle.FieldWrapper>
        </BasicFilterWrapper>
      </div>

      {ExportModal}
    </>
  );
};
