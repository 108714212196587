import { useFormContext } from 'react-hook-form';
import { DownloadButton } from '@/shared/atoms';
import { DownloadFileType } from '@/shared/types';
import { getAccountManagementApiUrl } from '@/shared/utils';
import { ExportTagsModalProps, ExportTagsModal } from '@/shared/molecules';
import { useToggleState, useDownloadFile, useQueryHelper } from '@/shared/hooks';
import {
  useExportTiktokHashtagPostsToGoogleSpreadsheetMutation,
  useExportTiktokKeywordPostsToGoogleSpreadsheetMutation
} from '@/graphql';
import { TagsAnalyticsFilterFormValues, TagType } from '../../../shared';

interface Props {
  tagType: TagType;
}

export const useExportTiktokTagsAnalyticsFile = ({ tagType }: Props) => {
  const exportModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { watch } = useFormContext<TagsAnalyticsFilterFormValues>();
  const { loading: exportingHashtag, callExportTiktokHashtagPostsToGoogleSpreadsheet } =
    useExportTiktokHashtagPostsToGoogleSpreadsheetMutation();
  const { loading: exportingKeyword, callExportTiktokKeywordPostsToGoogleSpreadsheet } =
    useExportTiktokKeywordPostsToGoogleSpreadsheetMutation();

  const [dateValue, tagIdValue] = watch(['date', 'tagId']);
  const isKeywordTag = tagType === TagType.KEYWORD;

  const handleDownloadTags: ExportTagsModalProps['onSubmit'] = async ({ type, endDate, startDate }) => {
    const isSupportedType = [DownloadFileType.CSV, DownloadFileType.EXCEL, DownloadFileType.SPREADSHEET];

    if (!tagIdValue || !isSupportedType) return;

    if (type === DownloadFileType.SPREADSHEET) {
      if (isKeywordTag) {
        await callExportTiktokKeywordPostsToGoogleSpreadsheet({
          variables: {
            input: {
              keywordId: +tagIdValue,
              endDate: endDate.toString(),
              startDate: startDate.toString()
            }
          }
        });
      } else {
        await callExportTiktokHashtagPostsToGoogleSpreadsheet({
          variables: {
            input: {
              hashtagId: +tagIdValue,
              endDate: endDate.toString(),
              startDate: startDate.toString()
            }
          }
        });
      }

      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } else {
      await handleDownloadFile({
        method: 'GET',
        params: { endDate, startDate },
        filenamePrefix: `tiktok-${isKeywordTag ? 'keywords' : 'hashtags'}-${new Date().toUTCString()}`,
        url: getAccountManagementApiUrl(
          `api/analytics/tiktok/${isKeywordTag ? 'keyword' : 'hashtag'}/${type === DownloadFileType.EXCEL ? 'xlsx' : 'csv'}/${tagIdValue}`
        )
      });
    }

    exportModal.close();
  };

  return {
    ExportButton: () => (
      <DownloadButton
        onClick={exportModal.open}
        disabled={!dateValue || !tagIdValue}
        title={t(isKeywordTag ? 'Button.Keyword Export' : 'Button.HashTag Export')}
      />
    ),
    ExportModal: (
      <ExportTagsModal
        open={exportModal.status}
        onClose={exportModal.close}
        onSubmit={handleDownloadTags}
        title={t(isKeywordTag ? 'Keyword Export' : 'HashTag Export')}
        downloading={downloading || exportingHashtag || exportingKeyword}
      />
    )
  };
};
