import { Table, TableProps } from '@/shared/atoms';
import { useTableMaxHeight } from '@/shared/hooks';

export type TagsTableProps = TableProps;

export const TagsTable = ({ data, ...restProps }: TagsTableProps) => {
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();

  return (
    <Table
      {...restProps}
      ref={offsetRef}
      maxHeight={defaultTableMaxHeight}
      data={{ rowHeight: '72px', ...data }}
      noTopBorderRadius
      stickyHeader
    />
  );
};
