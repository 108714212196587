import { Outlet } from '@tanstack/react-router';
import { RouterIds, createRouter } from '@/router';
import { objectEntries } from '@/shared/assets';
import { client } from '@/providers';
import { InitialLoading } from '@/shared/molecules';
import { initialState } from '@/auth';
import { NotFound } from '../pages/Public';
import { VerifyTokenAsync } from '../components';
import { RouterMapType } from './types';
import { PrivateRouter } from './_private-routes';
import { PublicRoutes } from './_public-routes';

export const router = createRouter({
  auth: initialState,
  dispatch: () => null,
  invalidate: () => null,
  queryClient: client,
  defaultRoute: undefined
});

const routerMap = {
  __root__: [
    () => (
      <VerifyTokenAsync>
        <Outlet />
        {/* causes crash of HMR not sure why yet */}
        {/* <TanStackRouterDevtools position="bottom-right" /> */}
      </VerifyTokenAsync>
    ),
    {}
  ],
  '/': [() => <InitialLoading />, {}],
  /** @private */
  ...PrivateRouter,
  /** @public */
  ...PublicRoutes,
  '/not-found': [NotFound, {}]
} as const satisfies RouterMapType<RouterIds>;

objectEntries(routerMap).forEach(([path, [component, options]]) => {
  const foundRoute = router.routesById[path as RouterIds];

  foundRoute.update({
    component: component
  });
  if ('validateSearch' in options) {
    foundRoute.options.validateSearch = options.validateSearch;
  }
  if ('beforeLoad' in options) {
    foundRoute.options.beforeLoad = options.beforeLoad;
  }
  if ('loader' in options) {
    foundRoute.options.loader = options.loader;
  }
  if ('loaderDeps' in options) {
    foundRoute.options.loaderDeps = options.loaderDeps;
  }
  if ('params' in options) {
    foundRoute.options.params = options.params;
  }
});
