import { InterpolationOptions } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '@/auth';
import { GlobalGoBackAndDocumentTitleType } from '@/router';

export interface UpdateDocumentTitleProps extends GlobalGoBackAndDocumentTitleType {
  appHeader?: string;
  interpolation?: Record<string, string>;
  interpolationOptions?: InterpolationOptions;
  skipUpdate?: { title: boolean; appHeader: boolean };
}

export const useUpdateDocumentTitle = ({
  title,
  params,
  search,
  href = '',
  skipUpdate,
  interpolation,
  backInHistory,
  appHeader = '',
  interpolationOptions,
  alwaysBackToParent = false
}: UpdateDocumentTitleProps) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = useAuthData();

  // Update Document Title
  useEffect(() => {
    if (!skipUpdate?.title && title) {
      document.title = t(title, { ...interpolation, interpolation: interpolationOptions });
    }
  }, [i18n.language, i18n.isInitialized, interpolation?.name, skipUpdate?.title]);

  // Update Page Title
  useEffect(() => {
    if (!skipUpdate?.appHeader && appHeader) {
      dispatch({
        type: 'APP_USER',
        payload: {
          title: t<string>(appHeader, { ...interpolation, interpolation: interpolationOptions }),
          href,
          params,
          search,
          alwaysBackToParent,
          backInHistory
        }
      });
    }
  }, [href, appHeader, i18n.language, i18n.isInitialized, interpolation?.name, skipUpdate?.appHeader]);

  const setDocumentAndAppTitles = useCallback(
    (props: UpdateDocumentTitleProps) => {
      if (props.title && props.appHeader) {
        document.title = t(props.title, { ...props.interpolation, interpolation: interpolationOptions });
        dispatch({
          type: 'APP_USER',
          payload: {
            title: t<string>(props.appHeader, { ...props.interpolation, interpolation: interpolationOptions }),
            href: props.href,
            params: props.params,
            search: props.search,
            alwaysBackToParent: !!props.alwaysBackToParent,
            backInHistory: props.backInHistory
          }
        });
      }
    },
    [dispatch, interpolationOptions, t]
  );

  return { setDocumentAndAppTitles };
};
