import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';
import { InfluencerAvatar } from '@/shared/molecules';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { Anchor, ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import {
  SocialAccountType,
  InstagramUserHashtagPostsSortOrder,
  InstagramUserHashtagListPostsItemPayload
} from '@/graphql';
import { PostCell } from '../../../shared';

type CustomColumnProps = ColumnProps<InstagramUserHashtagPostsSortOrder>;
type Props = Pick<TableData<InstagramUserHashtagListPostsItemPayload>, 'data'>;

export const useInstagramHashtagsAnalyticsPostsListTable = ({ data = [] }: Props) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    getMetricColumnProps({
      title: t('HeaderColumn.Rank'),
      styles: { textAlign: 'center', paddingRight: '16px' },
      sortField: InstagramUserHashtagPostsSortOrder.RANK
    }),
    { title: t('HeaderColumn.Influencer') },
    { title: t('HeaderColumn.Post') },
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: InstagramUserHashtagPostsSortOrder.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: InstagramUserHashtagPostsSortOrder.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: InstagramUserHashtagPostsSortOrder.COMMENTS
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: InstagramUserHashtagPostsSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      name,
      rank,
      likes,
      media,
      postId,
      avatar,
      content,
      comments,
      postDate,
      accountId,
      engagement,
      influencerId,
      followersCount
    }) => {
      const profileLink = generateProfileLink(influencerId, {
        filter: { id: String(accountId), sm: SocialAccountType.INSTAGRAM }
      });

      return [
        <div>{formatIntNumber(rank + 1)}</div>,
        <div css={{ width: '200px', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <InfluencerAvatar {...profileLink} size="40px" src={avatar} css={{ borderRadius: 0 }} />
          <div>
            <Anchor {...profileLink} label={name || '-'} css={{ fontSize: '14px' }} />
            <div css={{ fontSize: '10px', marginTop: '4px' }}>
              {t('Follower')}:&nbsp;{formatIntNumber(followersCount)}
            </div>
          </div>
        </div>,
        <PostCell
          postId={postId}
          content={content}
          thumbnail={media.at(0)}
          counter={{ index: 1, total: media.length }}
        />,
        <TableCell.Number value={formatIntNumber(engagement)} />,
        <TableCell.Number value={formatIntNumber(likes)} />,
        <TableCell.Number value={formatIntNumber(comments)} />,
        <TableCell.Date value={postDate} />
      ];
    }
  );

  return { rows, columns };
};
