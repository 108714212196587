import { Navigate, useParams } from '@tanstack/react-router';
import { useResetPasswordMutation } from '@/graphql';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PasswordFormValues, ResetPasswordForm, SignInSignUpLayout } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const PasswordReset = () => {
  const { callResetPassword } = useResetPasswordMutation();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const params = useParams({
    from: '/_public-routes/_password/password_reset/$token'
  });

  const token = params.token;

  if (!token) {
    return <Navigate to="/sign-in" />;
  }

  const handleSubmit: FormAction<PasswordFormValues>['onSubmit'] = async (payload) => {
    try {
      const result = await callResetPassword({
        variables: { input: { token, password: payload.password } }
      });

      if (result?.data?.resetPassword) {
        enqueueSnackbar(t('succeededInUpdatingPassword'), { variant: 'success' });
        navigate({ to: '/sign-in' });
      } else {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('failedToUpdatePassword'), { variant: 'error' });
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <SignInSignUpLayout
      contentClassName={{ padding: '48px 40px' }}
      footerProps={{ className: { display: 'none' } }}
      css={{ paddingTop: '82px', justifyContent: 'flex-start' }}
    >
      <ResetPasswordForm onSubmit={handleSubmit} />
    </SignInSignUpLayout>
  );
};
