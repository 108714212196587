import { LinkRouterProps } from '@/router';
import { useInfluencerPermissions, useUserRoles } from '@/auth';

const INFLUENCER_ADD_ROUTES = ['/influencer/add'] as const satisfies LinkRouterProps['to'][];

export const useInfluencerAddRoute = () => {
  const { isAgency, isAdvertiser } = useUserRoles();
  const { hideAddInfluencerBtn } = useInfluencerPermissions();

  const allowAddInfluencer = !(isAdvertiser || isAgency || hideAddInfluencerBtn);

  return {
    allowAddInfluencer,
    addInfluencerRoute: '/influencer/add' satisfies (typeof INFLUENCER_ADD_ROUTES)[number]
  };
};
