import { useCreateSelfRegisteredAdvertiserUserMutation } from '@/graphql';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInSignUpLayout, SignUpForm, SignUpFormValues } from '@/shared/organisms';
import { manageLocalStorage } from '@/router';

export const SignUp = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignUp' });
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { callCreateSelfRegisteredAdvertiserUser } = useCreateSelfRegisteredAdvertiserUserMutation();

  const handleSubmit = async (payload: SignUpFormValues) => {
    manageLocalStorage('set', 'confirmationEmail', payload.email);

    try {
      await callCreateSelfRegisteredAdvertiserUser({
        variables: {
          input: {
            advertiserCompanyName: payload.companyName,
            advertiserCompanyUrl: payload.companyUrl,
            advertiserPhoneNumber: payload.phoneNumber,
            countryId: payload.countryId,
            userName: payload.name,
            userEmail: payload.email
          }
        }
      });

      enqueueSnackbar(t('Your account was created successfully'), {
        variant: 'success'
      });
      navigate({ to: '/sign-up/success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <SignInSignUpLayout
      footerProps={{ hasCopyRight: true }}
      headerProps={{
        signLink: { title: t('Log in'), to: '/sign-in', description: t('Already have an account?') }
      }}
    >
      <SignUpForm onSubmit={handleSubmit} />
    </SignInSignUpLayout>
  );
};
