import { useTiktokTagPostDetailsQuery } from '@/graphql';
import { THEME } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { PostDetailsType } from '@/shared/types';
import { DefaultPostDetailsTemplate, DefaultPostDetailsTemplateProps } from '../shared';

export interface TiktokTagPostDetailsProps extends Pick<PostDetailsType, 'id' | 'mainSocialAccountId'> {
  className?: string;
  onCloseModal?: () => void;
}

export const TiktokTagPostDetails = ({ id, className, onCloseModal }: TiktokTagPostDetailsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useTiktokTagPostDetailsQuery({
    skip: !id,
    variables: { postId: Number(id) },
    onError: (error) => {
      onCloseModal?.();
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const details = data?.tiktokTagPostDetails;
  const postDetails: DefaultPostDetailsTemplateProps['postDetails'] = details
    ? {
        socialMedia: 'TIKTOK',
        postUrl: details?.postUrl,
        caption: details?.content || '',
        images: details?.thumbnail ? [details.thumbnail] : [],
        statistics: {
          likes: details?.likes,
          views: details?.views,
          comments: details?.comments,
          saves: details?.saves,
          shares: details?.shares
        },
        info: {
          name: null,
          avatar: null,
          socialMedia: 'TIKTOK',
          postDate: details?.postDate
        }
      }
    : undefined;

  return (
    <DefaultPostDetailsTemplate
      loading={loading}
      className={className}
      postDetails={postDetails}
      css={{ width: THEME.modal.size.lv3 }}
    />
  );
};
