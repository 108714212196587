import { REST_URL, UPLOAD_URL, GG_STORAGE_URL, MANAGEMENT_URL, SEARCH_URL } from '@/shared/constants';
import { getAuthHeaders } from '@/router';
import { getNow } from './date';

export interface DownloadFileProps {
  url: URL;
  extension?: string;
  filenamePrefix: string;
  method?: 'POST' | 'GET';
  hasAuthHeader?: boolean;
  data?: Record<string, any>;
  params?: Record<string, any>;
}
export const downloadFile = async ({
  url,
  data,
  extension,
  params = {},
  method = 'GET',
  filenamePrefix,
  hasAuthHeader = true
}: DownloadFileProps) => {
  // Auth
  const headers = hasAuthHeader ? getAuthHeaders() : new Headers();
  headers.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

  // Filename
  const now = getNow('yyyyMMdd_HHmmss');
  const filename = `${filenamePrefix}_${now}${extension ? extension : ''}`;
  const paramsWithExtraOptions = {
    ...params,
    ...(params.offset !== undefined && { offset: params.offset }),
    ...(params.limit !== undefined && { limit: params.limit })
  };
  // Params
  if (Object.keys(paramsWithExtraOptions).length) {
    const filteredParams = removeEmpty(params);
    url.search = new URLSearchParams(filteredParams).toString();
  }

  const { response, error } = await fetch(url.toString(), {
    method,
    mode: 'cors',
    headers,
    ...(!!data && { body: JSON.stringify(data) })
  })
    .then((res) => ({ response: res, error: null }))
    .catch((e: any) =>
      // TODO: ErrorHandling
      ({ response: null, error: e })
    );

  if (!response || !response.ok || !!error) {
    const errorMessage = response ? response.statusText : error;

    return { ok: false, error: errorMessage };
  }

  await response.blob().then((blob) => {
    downloadFileFromUrl(window.URL.createObjectURL(blob), filename);

    return 'ok';
  });

  return { ok: true, error: null };
};

export const downloadFileFromUrl = async (url: string, filename: string) => {
  if (!url) return;

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

// https://github.com/microsoft/TypeScript/issues/31661#issuecomment-497138929
interface ObjectClone {
  [key: string]: any;
}
const removeEmpty = <T extends ObjectClone>(obj: T) =>
  Object.keys(obj).reduce((prev: ObjectClone, key) => {
    const newObj = prev;
    const value = obj[key];
    if (Array.isArray(value) && value.length > 0) {
      newObj[key] = value;
    } else if (value) {
      newObj[key] = value;
    }

    return newObj;
  }, {} as T);

export const getRestApiUrl = (path: string) => new URL(`${REST_URL}${path}`);
export const getUploadApiUrl = (path: string) => new URL(`${UPLOAD_URL}${path}`);
export const getGoogleStorageUrl = (path: string) => new URL(`${GG_STORAGE_URL}${path}`);
export const getAccountManagementApiUrl = (path: string) => new URL(`${MANAGEMENT_URL}${path}`);
export const getAdvancedSearchApiUrl = (path: string) => new URL(`${SEARCH_URL}${path}`);
