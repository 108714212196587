import { UserRoles } from '@/graphql';

export const adminRoles = [UserRoles.ADMINISTRATOR];
export const advertiserRoles = [UserRoles.ADVERTISER];
export const adminStaffRoles = [...adminRoles, UserRoles.STAFF];
export const adminStaffAgencyRoles = [...adminStaffRoles, UserRoles.AGENCY];
export const adminStaffPartnerRoles = [...adminStaffRoles, UserRoles.PARTNER];
export const adminStaffAdvertiserRoles = [...adminStaffRoles, UserRoles.ADVERTISER];
export const talentAgencyPartnerRoles = [UserRoles.TALENT_AGENCY, UserRoles.PARTNER];
export const adminStaffTalentAgencyRoles = [...adminStaffRoles, UserRoles.TALENT_AGENCY];
export const adminStaffAdvertiserAgencyRoles = [...adminStaffRoles, UserRoles.ADVERTISER, UserRoles.AGENCY];
export const adminStaffAgencyTalentAgencyRoles = [...adminStaffRoles, UserRoles.AGENCY, UserRoles.TALENT_AGENCY];
export const adminStaffTalentAgencyPartnerRoles = [...adminStaffRoles, UserRoles.PARTNER, UserRoles.TALENT_AGENCY];
export const adminStaffAgencyTalentAgencyPartnerRoles = [
  ...adminStaffRoles,
  UserRoles.AGENCY,
  UserRoles.PARTNER,
  UserRoles.TALENT_AGENCY
];
export const adminStaffAdvertiserAgencyTalentAgencyRoles = [
  ...adminStaffRoles,
  UserRoles.AGENCY,
  UserRoles.ADVERTISER,
  UserRoles.TALENT_AGENCY
];
export const adminStaffAgencyAdvertiserTalentAgencyPartnerRoles = [
  ...adminStaffRoles,
  UserRoles.AGENCY,
  UserRoles.PARTNER,
  UserRoles.ADVERTISER,
  UserRoles.TALENT_AGENCY
];
export const adminStaffAgencyPartnerAdvertiserRoles = [
  ...adminStaffRoles,
  UserRoles.AGENCY,
  UserRoles.PARTNER,
  UserRoles.ADVERTISER
];
