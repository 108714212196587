import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteTiktokUserHashtag.graphql';
import { Mutation, MutationdeleteTiktokUserHashtagArgs } from '../../__generated__/globalTypes';

export const useDeleteTiktokUserHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteTiktokUserHashtagArgs>
) => {
  const [callDeleteTiktokUserHashtag, result] = useMutation<Mutation, MutationdeleteTiktokUserHashtagArgs>(
    MUTATION,
    options
  );

  return { callDeleteTiktokUserHashtag, ...result };
};

export type DeleteTiktokUserHashtagMutationFunction = Mutation['deleteTiktokUserHashtag'];
