import { css } from '@emotion/react';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useUserRoles } from '@/auth';
import {
  mkpECommerceImage,
  mkpUrlTrackingImage,
  mkpReferralTrackingImage,
  mkpSocialMediaTrackingImage
} from '@/shared/assets';
import { ButtonLink, MediaPreview } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useDoubleClick, useQueryHelper } from '@/shared/hooks';
import { MarketplaceFormType } from '@/shared/types';
import { LinkRouterProps } from '@/router';

export const MarketplaceSelectCampaignType = () => {
  const { t, navigate } = useQueryHelper();

  const [selectedCampaignType, setSelectedCampaignType] = useState<MarketplaceFormType>(
    MarketplaceFormType.SOCIAL_MEDIA_TRACKING
  );

  const { isAdminStaff } = useUserRoles();
  const campaignTypeOptions = [
    {
      title: t('Social media campaign'),
      thumbnail: mkpSocialMediaTrackingImage,
      type: MarketplaceFormType.SOCIAL_MEDIA_TRACKING,
      description: <Trans i18nKey="socialMediaCampaignDescription" components={{ br: <br /> }} />
    },
    {
      title: t('Referral code tracking'),
      thumbnail: mkpReferralTrackingImage,
      type: MarketplaceFormType.REFERRAL_CODE_TRACKING,
      description: <Trans i18nKey="referralCodeTrackingDescription" components={{ br: <br /> }} />
    },
    {
      title: t('URL tracking'),
      thumbnail: mkpUrlTrackingImage,
      type: MarketplaceFormType.URL_TRACKING,
      description: <Trans i18nKey="urlTrackingDescription" components={{ br: <br /> }} />
    },
    ...(isAdminStaff
      ? [
          {
            title: t('E-commerce marketplace type'),
            thumbnail: mkpECommerceImage,
            type: MarketplaceFormType.E_COMMERCE,
            description: <Trans i18nKey="ecommerceCampaignDescription" components={{ br: <br /> }} />
          }
        ]
      : [])
  ];

  const handleSelectCampaignType = (type: MarketplaceFormType) => {
    if (type !== selectedCampaignType) {
      setSelectedCampaignType(type);
    }
  };

  const { onSingleClick, onDoubleClick } = useDoubleClick({
    singleClickCb: handleSelectCampaignType,
    doubleClickCb: (type: MarketplaceFormType) => {
      navigate({ to: getCampaignLinkByType(type) });
    }
  });

  return (
    <div css={styles.wrapper}>
      <div css={{ padding: '24px' }}>
        <h2 css={{ fontSize: '18px', fontWeight: 600 }}>{t('Choose campaign type')}</h2>
        <p css={{ fontSize: '14px', marginTop: '16px' }}>
          {t('Please choose the campaign type that you want to run with creators')}
        </p>

        <div
          css={{
            margin: '12px -12px -12px',
            display: 'grid',
            gridAutoRows: '1fr',
            gridTemplateColumns: '1fr 1fr 1fr'
          }}
        >
          {campaignTypeOptions.map(({ title, thumbnail, type, description }) => {
            const active = type === selectedCampaignType;

            return (
              <div key={type} css={{ padding: '12px', boxSizing: 'border-box', flexBasis: '33.33%' }}>
                <div
                  onClick={() => onSingleClick(type)}
                  onDoubleClick={() => onDoubleClick(type)}
                  css={styles.itemWrapper(active)}
                >
                  <div css={{ padding: '8px' }}>
                    <div css={styles.imageWrapper(active)}>
                      <MediaPreview src={thumbnail} objectFit="contain" css={{ backgroundColor: 'inherit' }} />
                    </div>
                  </div>
                  <div css={{ padding: '8px 24px 16px' }}>
                    <h2 css={{ fontSize: '14px', fontWeight: 600, textAlign: 'center' }}>{title}</h2>
                    <p
                      css={{
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: THEME.text.colors.gray.lv3,
                        marginTop: '8px'
                      }}
                    >
                      {description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div css={{ padding: '16px 24px', borderTop: '1px solid #EEF3F7' }}>
        <ButtonLink
          variant="blue"
          title={t('Button.Next')}
          disabled={!selectedCampaignType}
          css={{ padding: '0 24px', marginLeft: 'auto', width: 'max-content' }}
          to={getCampaignLinkByType(selectedCampaignType)}
        />
      </div>
    </div>
  );
};
const styles = {
  wrapper: css({
    borderRadius: '3px',
    boxSizing: 'border-box',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25)'
  }),
  imageWrapper: (active?: boolean) =>
    css({
      width: '100%',
      height: '80px',
      padding: '8px',
      borderRadius: '2px',
      boxSizing: 'border-box',
      backgroundColor: active ? '#C3DEF7' : '#F6F8FA'
    }),
  itemWrapper: (active?: boolean) =>
    css({
      height: '100%',
      cursor: 'pointer',
      borderRadius: '3px',
      boxSizing: 'border-box',
      background: active ? '#EBF4FC' : '#fff',
      border: `1px solid ${active ? '#3892E5' : '#eef3f7'}`
    })
};

const CAMPAIGN_TYPE_ROUTES = [
  '/marketplace/add/url-tracking',
  '/marketplace/add/e-commerce',
  '/marketplace/add/referral-code-tracking',
  '/marketplace/add/social-media-tracking'
] as const satisfies LinkRouterProps['to'][];

const getCampaignLinkByType = (type: MarketplaceFormType): (typeof CAMPAIGN_TYPE_ROUTES)[number] => {
  switch (type) {
    case MarketplaceFormType.URL_TRACKING:
      return '/marketplace/add/url-tracking';

    case MarketplaceFormType.E_COMMERCE:
      return '/marketplace/add/e-commerce';
    case MarketplaceFormType.REFERRAL_CODE_TRACKING:
      return '/marketplace/add/referral-code-tracking';

    case MarketplaceFormType.SOCIAL_MEDIA_TRACKING:
    default:
      return '/marketplace/add/social-media-tracking';
  }
};
