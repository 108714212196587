import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DayPicker, DayPickerProps, DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';
import { css } from '@emotion/react';
import { ClearIcon, Icon, Popover } from '@/shared/atoms';
import { DATE_PICKER_INPUT_FORMAT, DEFAULT_DATE_FNS_FORMAT } from '@/shared/constants';
import { AppLanguage } from '@/shared/types';
import { localeUtils, localizedDateFormatter } from '@/shared/utils';
import { ActionButtons } from '../ActionButtons';
import { CustomDayPickerNav, StyledPickerInput, WithGlobalStyles } from './Components';
import { getDatePickerLocale, getDefaultDate, placeholderSingle } from './helpers';
import { PickerDate } from './types';

export interface DayPickerSingleProps {
  value: string;
  handleChangeDay: (day: string) => void;
  hasError?: boolean;
  disabled?: boolean;
  className?: string;
  disabledDays?: DayPickerProps['disabled'];
  saveOnBlur?: boolean;
  clearable?: boolean;
  placeholder?: string;
}
export const DayPickerSingle = ({
  value,
  disabled,
  hasError,
  className,
  disabledDays,
  handleChangeDay,
  saveOnBlur = true,
  clearable = true,
  placeholder = placeholderSingle
}: DayPickerSingleProps) => {
  const defaultInputState: DateRange = {
    from: getDefaultDate(value)
  };
  const { i18n } = useTranslation();
  const [state, setState] = useState<DateRange>(defaultInputState);
  const [datePickerInput, setDatePickerInput] = useState<string>(placeholder);
  let handleClosePopover: () => void;
  const isPlaceholder = datePickerInput === placeholder;
  const resultInput = state.from ? format(state.from, DATE_PICKER_INPUT_FORMAT) : '';

  useEffect(() => {
    setState({ from: getDefaultDate(value) });
  }, [value]);

  // if form has default period values, than set picker input
  useEffect(() => {
    if (resultInput) {
      setDatePickerInput(resultInput);
    }
  }, [resultInput]);

  const handleDayClick = (day: PickerDate) => {
    setState({
      from: day || undefined
    });
  };

  const handleUpdate = () => {
    setDatePickerInput(resultInput);
    handleClosePopover();
    if (state.from) {
      handleChangeDay(format(state.from, DEFAULT_DATE_FNS_FORMAT));
    }
  };

  const handleClickAway = (popoverOpened?: boolean) => {
    if (saveOnBlur && popoverOpened) {
      handleUpdate();
    } else if (popoverOpened) {
      handleClosePopover();
    }
  };

  return (
    <div className={className}>
      <Popover
        id="dayPicker"
        onClose={handleClickAway}
        target={({ setOpen }) => {
          handleClosePopover = () => setOpen(false);

          return (
            <div>
              <StyledPickerInput
                onClick={() => setOpen(true)}
                disabled={!!disabled}
                hasError={hasError}
                className="date-picker-input"
                css={{ width: '150px' }}
              >
                <Icon icon="calendar" size="16px" color="#6e7c89" className="calendar-icon" />
                <p css={[styles.dateInput, isPlaceholder && { opacity: 0.2 }]}>
                  {isPlaceholder
                    ? datePickerInput
                    : state.from
                      ? localizedDateFormatter(state.from, 'PPP', i18n.language as AppLanguage)
                      : null}
                </p>
                {!isPlaceholder && datePickerInput && clearable ? (
                  <ClearIcon
                    id="clear-indicator"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDatePickerInput('');
                      handleChangeDay('');
                      setState({ from: undefined });
                    }}
                    css={{ position: 'absolute', right: 0, top: '7px' }}
                  />
                ) : null}
              </StyledPickerInput>
            </div>
          );
        }}
        position="bottom-start"
        styles={{
          dropdown: {
            padding: '16px'
          }
        }}
        withBorder
      >
        {() => (
          <WithGlobalStyles>
            <DayPicker
              mode="single"
              numberOfMonths={2}
              selected={state.from || undefined}
              onSelect={() => void 0} // http://github.com/gpbl/react-day-picker/issues/2690
              onDayClick={handleDayClick}
              locale={getDatePickerLocale(i18n.language)}
              defaultMonth={state.from || new Date()}
              weekStartsOn={1}
              disabled={disabledDays}
              components={{
                Nav: CustomDayPickerNav
              }}
              formatters={{
                formatCaption: (captionLabelDate) => localeUtils.formatMonthTitle(captionLabelDate, i18n.language)
              }}
            />
            <ActionButtons
              primaryButtonProps={{
                onClick: handleUpdate
              }}
              secondaryButtonProps={{
                onClick: handleClosePopover
              }}
              buttonHeight="32px"
              hasTopBorder
            >
              {resultInput}
            </ActionButtons>
          </WithGlobalStyles>
        )}
      </Popover>
    </div>
  );
};
const styles = {
  dateInput: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  })
};
