import { StateType, SUPPORTED_USER_ROLES } from '@/router';
import { useAuthData } from './useAuthData';

export const usePermissions = () => {
  const {
    dispatch,
    auth: { role, permissions }
  } = useAuthData();

  const dispatchPermissions = (permissionsToSave: StateType['permissions']) => {
    dispatch({
      type: 'PERMISSIONS',
      payload: {
        permissions: permissionsToSave
      }
    });
  };

  return {
    permissions,
    dispatchPermissions,
    canCallCustomPermissions: role ? SUPPORTED_USER_ROLES.includes(role) : false
  };
};
