import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { CrowdinButton, Icon } from '@/shared/atoms';
import { THEME, TIKTOK_HELP_LINK } from '@/shared/constants';
import { useGetHelpPageUrl, useGlobalLayoutProps } from '@/shared/hooks';
import { Logo } from '@/shared/molecules';
import { MenuTitle } from '@/router';
import { useAuthData, useUserRoles } from '@/auth';
import { useMenuConfigs } from './hooks';
import { MenuGroup } from './MenuGroup';
import { MenuItem } from './MenuItem';
import { UserPanel } from './UserPanel';
import { getCmsMenuItems, hashtagsMenuItems, tiktokMenuItems } from './utils';

export interface MenuProps {
  activeTitle?: MenuTitle;
  type?: 'cms' | 'hashtags';
}

export const Menu = ({ type, activeTitle }: MenuProps) => {
  const helpCenterUrl = useGetHelpPageUrl();
  const { auth } = useAuthData();
  const { menuItems, permissionLoading } = useMenuConfigs();
  const { updateGlobalLayout } = useGlobalLayoutProps();
  const { isAdvertiserFromTikTok, isAdminStaff } = useUserRoles();
  const navigate = useNavigate();

  const hasDisabledNavigation = isAdvertiserFromTikTok && auth.subscriptionPaymentFailed;
  const allAvailableMenuItems = (() => {
    if (isAdvertiserFromTikTok) return tiktokMenuItems;

    if (type === 'cms') return getCmsMenuItems(isAdminStaff);

    if (type === 'hashtags') return hashtagsMenuItems;

    return menuItems;
  })();

  useEffect(() => {
    if (hasDisabledNavigation) {
      navigate({ to: '/tiktok/failed-payment' });
    }
    updateGlobalLayout({ hasFailedSubscriptionPayment: !!hasDisabledNavigation });
  }, [hasDisabledNavigation]);

  return (
    <div css={styles.wrapper}>
      <div css={{ padding: '16px 16px 0', boxSizing: 'border-box' }}>
        <Logo type="black" to={auth.defaultRoute} css={{ width: 'auto', height: '24px' }} />
      </div>

      <div css={styles.listMenu(permissionLoading)}>
        {allAvailableMenuItems.map((item, i) =>
          item?.subMenus?.length ? (
            <MenuGroup key={i} {...item} activeTitle={activeTitle} disabled={hasDisabledNavigation} />
          ) : ![MenuTitle.ReleaseNotes, MenuTitle.HelpCenter].includes(item.title as MenuTitle) ? (
            <MenuItem
              key={i}
              {...item}
              activeTitle={activeTitle}
              activeIcon={item.title === activeTitle}
              disabled={hasDisabledNavigation}
            />
          ) : null
        )}
        {!type && (
          <>
            <hr css={styles.hr} />
            <MenuItem
              key="help-center"
              target="_blank"
              title={MenuTitle.HelpCenter}
              href={isAdvertiserFromTikTok ? TIKTOK_HELP_LINK.helpCenter : helpCenterUrl}
              rightNode={<Icon icon="external-link" width={16} height={16} color="#6E7C89" />}
              css={{ marginTop: '8px', padding: '0 8px', height: '32px' }}
              size={22}
            />
          </>
        )}
      </div>

      {process.env.NODE_ENV === 'development' && (
        <MenuItem
          key="__asset-preview"
          keyPrefix=""
          rightNode="🏗️"
          title={MenuTitle.AssetPreview}
          activeIcon={MenuTitle.AssetPreview === activeTitle}
          to="/asset-preview"
          css={{ marginTop: '8px', padding: '0 8px', height: '32px' }}
          size={22}
        />
      )}
      <CrowdinButton />
      <hr css={styles.hr} />
      <UserPanel />
    </div>
  );
};

const styles = {
  hr: css({
    width: '100%',
    height: '1px',
    background: '#dee5ec'
  }),
  wrapper: css({
    width: THEME.container.menu.width,
    backgroundColor: THEME.background.colors.white,
    position: 'fixed',
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25)',
    zIndex: 11
  }),
  listMenu: (permissionLoading: boolean) =>
    css({
      flex: '1 1 auto',
      overflow: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      overflowY: 'scroll',
      padding: '16px 8px',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      ...(permissionLoading && { opacity: 0.6 })
    })
};
