import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { Button, Icon } from '@/shared/atoms';
import { AddTagModal, DeleteTagModal } from '@/shared/molecules';
import { TagType } from '../schemaTypes';
import { useManageTags, ManageTagsProps } from './hooks';

export interface TagsListProps extends ManageTagsProps {
  tagType?: TagType;
  className?: string;
  creating?: boolean;
  deleting?: boolean;
}

const LIMIT = 200;

export const TagsList = ({
  tags,
  onSubmit,
  creating,
  deleting,
  className,
  onCreateTag,
  onDeleteTag,
  tagType = TagType.HASHTAG
}: TagsListProps) => {
  const { t } = useTranslation();
  const {
    addModalState,
    deletingTag,
    setDeletingTag,
    handleSelectTag,
    handleDeleteTag,
    handleCreateTag,
    resetDeletingTag,
    checkActivatingTag
  } = useManageTags({ tags, onCreateTag, onDeleteTag, onSubmit });

  const disabledAddButton = tags.length > LIMIT;
  const isKeywordTag = tagType === TagType.KEYWORD;

  return (
    <>
      <div css={styles.wrapper} className={className}>
        <div css={styles.itemWrapper}>
          <Button
            title="+"
            variant="white"
            onClick={addModalState.open}
            disabled={disabledAddButton}
            css={[styles.item(false), { padding: 0 }]}
            tooltip={{
              help: disabledAddButton ? t('Can’t add tags more than', { count: LIMIT }) : t('Tooltip.Add Hashtag')
            }}
          />
        </div>

        {tags.map((tag) => {
          const active = checkActivatingTag(tag);

          return (
            <div key={tag.id} css={styles.itemWrapper}>
              <Button
                css={styles.item(active)}
                variant={active ? 'black' : 'white'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectTag(tag);
                }}
              >
                <p css={styles.itemName(active)}>{tag.label}</p>

                <div
                  css={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletingTag(tag);
                  }}
                >
                  <Icon size={8} icon="close" color="inherit" />
                </div>
              </Button>
            </div>
          );
        })}
      </div>

      <AddTagModal
        loading={creating}
        onAdd={handleCreateTag}
        open={addModalState.status}
        onClose={addModalState.close}
        title={isKeywordTag ? t('Dialog.Add Keyword') : t('Dialog.Add Hashtag')}
        description={
          isKeywordTag
            ? t('Dialog.Enter the name of the keyword you wish to add')
            : t('Dialog.Enter the name of the hashtag you wish to add')
        }
      />

      <DeleteTagModal
        loading={deleting}
        open={!!deletingTag?.id}
        tag={deletingTag?.label}
        onClose={resetDeletingTag}
        onDelete={handleDeleteTag}
        title={isKeywordTag ? t('Dialog.Delete Keyword') : t('Dialog.Delete Hashtag')}
        description={isKeywordTag ? t('Dialog.DeleteKeywordWarning') : t('Dialog.DeleteComparableHashtagWarning')}
      />
    </>
  );
};
const styles = {
  wrapper: css({
    margin: '-4px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  }),
  itemWrapper: css({
    padding: '4px',
    boxSizing: 'border-box'
  }),
  item: (active?: boolean) =>
    css({
      minWidth: '120px',
      padding: '0 12px 0 16px',
      color: active ? THEME.text.colors.white : THEME.text.colors.black.lv1
    }),
  itemName: (active?: boolean) =>
    css({
      flex: 1,
      fontSize: '12px',
      lineHeight: '100%',
      letterSpacing: '0.5px',
      fontWeight: active ? 600 : 400
    }),
  icon: css({
    flex: 'none',
    width: '12px',
    height: '12px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center'
  })
};
