import { useFormContext } from 'react-hook-form';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useGetSocialMediaRedirectUrl } from '@/shared/hooks';
import { SupportedSocialMediaType } from '@/shared/types';
import { manageLocalStorage } from '@/router';
import { AnalyticsFormValues } from '../schemaTypes';
import { setAnalyticsAccountFormState } from '../utils';

export const useAnalyticsConnectSocialAccount = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch } = useFormContext<AnalyticsFormValues>();
  const { getSocialMediaRedirectUrl, loading: connecting } = useGetSocialMediaRedirectUrl({
    redirectType: 'ANALYTICS_SIGNIN',
    query: 'AnalyticsSocialAuthRedirectUrl'
  });
  const { getSocialMediaRedirectUrl: getReconnectSocialMediaRedirectUrl, loading: reconnecting } =
    useGetSocialMediaRedirectUrl({
      redirectType: 'ANALYTICS_CONNECT',
      query: 'AnalyticsSocialAuthRedirectUrl'
    });

  const values = watch();
  const brandAccountId = String(values.id || '');

  const handleConnect = async (socialMedia: keyof typeof SupportedSocialMediaType) => {
    try {
      const redirectUrl = await getSocialMediaRedirectUrl(socialMedia);

      if (redirectUrl) {
        manageLocalStorage(
          'set',
          'analyticsAccountFormState_json',
          setAnalyticsAccountFormState('stringify', {
            params: {
              to: brandAccountId ? '/analytics/$brandAccountId' : '/analytics/add',
              params: brandAccountId ? { brandAccountId } : undefined,
              formState: values
            }
          })
        );

        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error('error:', error);
    }
  };

  const handleReconnect = async (socialMedia: keyof typeof SupportedSocialMediaType) => {
    // we can reconnect SNS on edit page only
    if (!brandAccountId) return;

    try {
      const redirectUrl = await getReconnectSocialMediaRedirectUrl(socialMedia);

      if (redirectUrl) {
        manageLocalStorage(
          'set',
          'analyticsAccountFormState_json',
          setAnalyticsAccountFormState('stringify', {
            params: {
              to: '/analytics/$brandAccountId',
              params: { brandAccountId },
              formState: values
            }
          })
        );

        window.location.href = redirectUrl;
      }
    } catch (error) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return { handleConnect, handleReconnect, loading: connecting || reconnecting };
};
