import { createFileRoute, redirect } from '@tanstack/react-router';
import { checkSignedIn } from '../utils';
export const Route = createFileRoute('/')({
  beforeLoad: ({
    context
  }) => {
    if (checkSignedIn()) {
      throw redirect({
        to: context.defaultRoute || '/report'
      });
    } else {
      throw redirect({
        to: '/sign-in'
      });
    }
  }
});