import { YoutubeAnalyticsOverviewPayload } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { DAILY_POST_PARAM_KEY } from '@/shared/constants';
import { useSelectItemIds } from '@/shared/hooks';
import { YoutubePostInDateDetailsModal } from '@/shared/molecules';
import { NoteAggregateLastThirtyDays } from '@/shared/organisms';
import {
  AnalyticsOverviewChart,
  AnalyticsOverviewLegend,
  AnalyticsOverviewWrapper,
  AnalyticsOverviewEngagement
} from '../../../shared';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import {
  useAnalyticsYoutubeDashboardOverviewData,
  useAnalyticsYoutubeDashboardOverviewPosts,
  useAnalyticsYoutubeDashboardOverviewLegend
} from './hooks';
import { AnalyticsYoutubeDashboardGraphKeys as Keys } from './types';
import { allOverviewEngagements } from './utils';

interface TemplateProps {
  data: YoutubeAnalyticsOverviewPayload;
  accountId: AnalyticsSelectedAccountInfo['id'];
  isInfluencerProfile?: boolean;
}

const Template = ({ data, accountId, isInfluencerProfile }: TemplateProps) => {
  const { availableLegendOptions } = useAnalyticsYoutubeDashboardOverviewLegend();
  const { selectItem: setSelectedLegendKey, selectedItemIds: selectedLegendKeys } = useSelectItemIds<Keys>({
    defaultSelectedIds: ['subscriber', 'post']
  });
  const { chartData, categories, overviewEngagements, availableOverviewEngagements } =
    useAnalyticsYoutubeDashboardOverviewData({
      data,
      selectedLegendKeys,
      availableEngagementKeys: allOverviewEngagements.map((item) => item.key)
    });
  const { dailyPosts, customChartOptions } = useAnalyticsYoutubeDashboardOverviewPosts({
    chartData,
    accountId,
    isInfluencerProfile
  });

  const hasNoData = !overviewEngagements.some((item) => !!item.total);

  return (
    <>
      <AnalyticsOverviewWrapper
        help=""
        dataNotAvailable={hasNoData}
        headerChildren={!hasNoData && isInfluencerProfile ? <NoteAggregateLastThirtyDays /> : null}
      >
        <AnalyticsOverviewEngagement engagement={availableOverviewEngagements} />
        <AnalyticsOverviewLegend
          options={availableLegendOptions}
          selectedOptions={selectedLegendKeys}
          onSelectOption={({ value }) => setSelectedLegendKey(value)}
        />
        <AnalyticsOverviewChart
          data={chartData}
          categories={categories}
          customTooltip={{ itemsPerRow: 3 }}
          customOptions={customChartOptions}
        />
      </AnalyticsOverviewWrapper>

      <YoutubePostInDateDetailsModal data={dailyPosts} paramKey={DAILY_POST_PARAM_KEY} />
    </>
  );
};

export interface AnalyticsYoutubeDashboardOverviewTemplateProps
  extends Pick<TemplateProps, 'accountId' | 'isInfluencerProfile'> {
  loading?: boolean;
  data?: YoutubeAnalyticsOverviewPayload | null;
}
export const AnalyticsYoutubeDashboardOverviewTemplate = ({
  data,
  loading,
  ...restProps
}: AnalyticsYoutubeDashboardOverviewTemplateProps) => (
  <RenderDataWithFallback loading={loading} skipDelayed>
    {data ? <Template {...restProps} data={data} /> : null}
  </RenderDataWithFallback>
);
