import { useContext } from 'react';
import { AdminStore } from '../AdminStoreProvider';

export const useAuthData = () => {
  const { state, dispatch } = useContext(AdminStore);

  return {
    auth: state,
    defaultRoute: state.defaultRoute,
    permissions: state.permissions,
    dispatch
  };
};
