import { ReactNode } from 'react';
import { ConstantHasEstimateMessage } from '@/graphql';
import { AWAIT_ESTIMATED_DATA_TEXT, HAS_NO_ESTIMATED_TEXT } from '@/shared/constants';
import { getAuthHeaders } from '@/router';

declare const REST_API_URL: string;

export const getPostInsightDataByOCR = async (postId: number, imageUrl: string) => {
  const headers = getAuthHeaders();
  headers.set('Content-Type', 'application/json');
  headers.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
  const url = `${REST_API_URL}api/engagement/post/${postId}/new/insight_data/`;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({ image_url: imageUrl })
  });

  if (!response || !response.ok) {
    const errorMessage = response.statusText || 'something wrong';

    return { ok: false, error: errorMessage, data: null };
  }

  const data = await response.json();

  return { ok: true, error: '', data };
};

interface GetEstimatedWrapperTextProps {
  type: 'age' | 'gender';
  wrapperText?: ReactNode;
  hasEstimatedDemographics?: boolean;
}
export const getEstimatedWrapperText = ({
  type,
  wrapperText,
  hasEstimatedDemographics
}: GetEstimatedWrapperTextProps) => {
  const messagesObj = (() => {
    const isGenderChart = type === 'gender';

    return {
      [HAS_NO_ESTIMATED_TEXT]: 'Age estimation data is not supported for this influencer country',
      [AWAIT_ESTIMATED_DATA_TEXT]: 'Please click below to run a script to analyze age & gender prediction models ↓',
      [ConstantHasEstimateMessage.YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY]: isGenderChart
        ? 'Gender estimation data is not supported for this influencer country'
        : 'Age estimation data is not supported for this influencer country',
      [ConstantHasEstimateMessage.YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K]:
        'We cannot provide estimation models because the follower is less than 10K',
      [ConstantHasEstimateMessage.YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR]:
        'We cannot provide estimation models because the last post was made over 1 year ago',
      [ConstantHasEstimateMessage.YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS]: isGenderChart
        ? 'Please wait for 1-2 days till you can see gender estimation data after you added an account to AnyTag'
        : 'Please wait for 1-2 days till you can see age estimation data after you added an account to AnyTag'
    };
  })();

  return hasEstimatedDemographics &&
    wrapperText &&
    typeof wrapperText === 'string' &&
    Object.keys(messagesObj).includes(wrapperText)
    ? messagesObj[wrapperText as keyof typeof messagesObj]
    : wrapperText;
};
