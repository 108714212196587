import { AppName, useTiktokBusinessAdAccountsLazyQuery, useTokenAuthMutation } from '@/graphql';
import { setToken } from '@/router';
import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useUpdateDocumentTitle } from '@/shared/hooks';
import { SignInFormValues, TikTokEnterPasswordForm } from '@/shared/organisms';
import { FormAction } from '@/shared/types';

export const TikTokPasswordEnter = () => {
  const { callTokenAuth } = useTokenAuthMutation();
  const { t, navigate, enqueueSnackbar } = useQueryHelper();
  const { getTiktokBusinessAdAccountsQuery } = useTiktokBusinessAdAccountsLazyQuery();
  useUpdateDocumentTitle({
    title: 'appHeader.Continue onboarding',
    appHeader: 'appHeader.Continue onboarding'
  });

  const handleSubmit: FormAction<SignInFormValues>['onSubmit'] = async (payload) => {
    try {
      const result = await callTokenAuth({
        variables: { input: { email: payload.email, password: payload.password, appName: AppName.ANYTAG } }
      });

      if (result?.data?.tokenAuth?.token) {
        setToken(result.data.tokenAuth?.token);
        const { data } = await getTiktokBusinessAdAccountsQuery();

        if (data?.tiktokBusinessAdAccounts?.length) {
          navigate({ to: '/tiktok/ad-campaign-report' });
        } else {
          navigate({ to: '/tiktok-onboarding/ad-account' });
        }
      } else {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t(error?.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return <TikTokEnterPasswordForm onSubmit={handleSubmit} />;
};
