import { TableData } from '@/shared/types';
import { TiktokUserTagListPostsItemPayload } from '@/graphql';
import { TiktokTagPostDetailsModal } from '@/shared/molecules';
import { TagsTable } from '../../shared';
import { ProfilePostsCount } from '../../../ProfilePostsList';
import { useTiktokTagsAnalyticsPostsListTable } from './hooks';

export type TiktokTagsAnalyticsPostsListProps = TableData<TiktokUserTagListPostsItemPayload>;

export const TiktokTagsAnalyticsPostsList = ({
  loading,
  data = [],
  totalRecords
}: TiktokTagsAnalyticsPostsListProps) => {
  const { rows, columns } = useTiktokTagsAnalyticsPostsListTable({ data });

  return (
    <>
      <ProfilePostsCount count={totalRecords} css={{ boxShadow: 'none' }} />
      <TagsTable loading={loading} hasBorder={!!totalRecords} data={{ rows, columns }} />
      <TiktokTagPostDetailsModal posts={data.map(({ postId }) => ({ id: postId }))} />
    </>
  );
};
