import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Popover, PopoverProps } from '@/shared/atoms';
import { Option } from '@/shared/types';
import { manageLocalStorage, LocalStorageKey } from '@/router';
import { TableColumnSelectorGroup } from '../TableColumnSelectorGroup';

export interface ColumnCustomiserProps<T extends string> {
  className?: string;
  position?: PopoverProps['position'];
  savedValuesKey?: LocalStorageKey; // If Not put, the selection will be clear on sort/refresh
  setSelectedColumns: (col: T[]) => void;
  options: Option<T>[];
  defaultOptions: T[];
  initialValues: T[]; // Cannot selectable options
  buttonProps?: ButtonProps;
}

export const ColumnCustomiser = <T extends string>({
  options,
  className,
  buttonProps,
  initialValues,
  defaultOptions,
  savedValuesKey,
  setSelectedColumns,
  position = 'bottom'
}: ColumnCustomiserProps<T>) => {
  const { t } = useTranslation();
  const [checkboxValues, setCheckboxValues] = useState<T[]>(defaultOptions);

  // note they are always identical to table columns so we should use HeaderColumn prefix
  const checkboxOptions = options.reduce<Option<T>[]>(
    (results, option) =>
      option.value && initialValues.includes(option.value)
        ? results
        : [...results, { label: option.label, value: option.value }],
    []
  );

  useEffect(() => {
    if (savedValuesKey) {
      const savedColumnsState = manageLocalStorage('get', savedValuesKey);
      if (savedColumnsState) {
        const savedCheckboxes = savedColumnsState.split(', ') as T[];
        setSelectedColumns(savedCheckboxes);
        setCheckboxValues(savedCheckboxes);
      }
    }
    return () => {
      if (savedValuesKey === 'adReportTableColumns') {
        manageLocalStorage('remove', savedValuesKey);
      }
    };
  }, []);

  const handleSubmit = () => {
    setSelectedColumns(checkboxValues);
    if (savedValuesKey) {
      manageLocalStorage('set', savedValuesKey, checkboxValues.join(', '));
    }
  };

  return (
    <Popover
      position={position}
      target={({ togglePopover }) => (
        <Button
          variant="white"
          css={{ padding: 0 }}
          className={className}
          onClick={togglePopover}
          prefixIcon={{ icon: 'icon-filter', size: 18, color: '#3b4146' }}
          tooltip={{
            help: <span css={{ fontSize: '12px' }}>{t('Button.Customise report table')}</span>,
            position: 'top'
          }}
          {...buttonProps}
        />
      )}
    >
      {({ togglePopover }) => (
        <TableColumnSelectorGroup<T>
          name="columnSelector"
          value={checkboxValues}
          options={checkboxOptions}
          handleSubmit={handleSubmit}
          setCheckboxValues={setCheckboxValues}
          closeModal={togglePopover}
          css={{ width: '300px' }}
          canOnlyAddMore
          initialValues={initialValues}
          defaultFields={defaultOptions}
        />
      )}
    </Popover>
  );
};
