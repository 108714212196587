import { useTiktokUserHashtagsQuery } from '@/graphql';
import { RenderDataWithFallback } from '@/shared/atoms';
import { useUpdateDocumentTitle } from '@/shared/hooks';
import { useTiktokTagsAnalyticsFilter } from '@/shared/organisms';
import { Template } from './Template';

export const TiktokHashtagsAnalytics = () => {
  useUpdateDocumentTitle({
    title: 'appHeader.Tiktok Hashtag Analytics',
    appHeader: 'appHeader.Tiktok Hashtag Analytics'
  });
  const { filter, setFilter } = useTiktokTagsAnalyticsFilter();
  const { data, loading, called } = useTiktokUserHashtagsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ tiktokUserHashtags }) => {
      const isValidHashtag = tiktokUserHashtags?.hashtags?.findIndex((hashtag) => hashtag.id === filter.tagId) !== -1;

      if (!filter.tagId || !isValidHashtag) {
        setFilter({ tagId: tiktokUserHashtags?.hashtags?.at(0)?.id || '' }, { replace: true });
      }
    }
  });

  return (
    <RenderDataWithFallback loading={loading && !called} hasNoData={!filter.date}>
      <Template hashtags={data?.tiktokUserHashtags.hashtags || []} />
    </RenderDataWithFallback>
  );
};
