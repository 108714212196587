import { useTranslation } from 'react-i18next';
import { InstagramFeedPost, InstagramFeedPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { SelectColumnsAction } from '@/shared/types';
import { formatIntNumber, formatPercent } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramFeedPostSortField, keyof InstagramFeedPost>;
interface Props extends SelectColumnsAction<keyof InstagramFeedPost> {
  data: readonly InstagramFeedPost[];
  isComparePage?: boolean;
}

export const useAnalyticsInstagramPostsListTable = ({ data, selectedColumns }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ key, hidden, styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles },
    hidden: hidden || (!!key && !selectedColumns.includes(key))
  });

  const columns: CustomColumnProps[] = [
    {
      title: <div css={{ display: 'flex', alignItems: 'center' }}>{t('HeaderColumn.Post')}</div>,
      sticky: { active: true, hasBorderRight: true }
    },
    getMetricColumnProps({
      key: 'views',
      title: t('HeaderColumn.View'),
      help: t<string>('Tooltip.ViewHelp'),
      sortField: InstagramFeedPostSortField.VIEW
    }),
    getMetricColumnProps({
      key: 'viewsByFollowers',
      title: t('HeaderColumn.Views by Followers'),
      help: t<string>('Tooltip.viewsByFollowersHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_BY_FOLLOWERS
    }),
    getMetricColumnProps({
      key: 'viewsByNonFollowers',
      title: t('HeaderColumn.Views by Non-Followers'),
      help: t<string>('Tooltip.viewsByNonFollowersHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_BY_NON_FOLLOWERS
    }),
    getMetricColumnProps({
      key: 'viewsFromHome',
      title: t('HeaderColumn.Views from Home'),
      help: t<string>('Tooltip.viewsFromHomeHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_FROM_HOME
    }),
    getMetricColumnProps({
      key: 'viewsFromProfile',
      title: t('HeaderColumn.Views from Profile'),
      help: t<string>('Tooltip.viewsFromProfileHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_FROM_PROFILE
    }),
    getMetricColumnProps({
      key: 'viewsFromDiscovery',
      title: t('HeaderColumn.Views from Discovery'),
      help: t<string>('Tooltip.viewsFromDiscoveryHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_FROM_DISCOVERY
    }),
    getMetricColumnProps({
      key: 'viewsFromOthers',
      title: t('HeaderColumn.Views from Others'),
      help: t<string>('Tooltip.viewsFromOthersHelp'),
      sortField: InstagramFeedPostSortField.VIEWS_FROM_OTHERS
    }),
    getMetricColumnProps({
      key: 'reach',
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramFeedPostSortField.REACH
    }),
    getMetricColumnProps({
      key: 'nonFollowerReach',
      title: t('HeaderColumn.Non Follower Reach'),
      help: t<string>('Tooltip.nonFollowerReachHelp'),
      sortField: InstagramFeedPostSortField.NON_FOLLOWER_REACH
    }),
    getMetricColumnProps({
      key: 'interaction',
      title: t('HeaderColumn.Interaction'),
      help: t<string>('Tooltip.interactionHelp'),
      sortField: InstagramFeedPostSortField.INTERACTION
    }),
    getMetricColumnProps({
      key: 'engagement',
      title: t('HeaderColumn.Engagement'),
      help: t<string>('Tooltip.EngagementHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT
    }),
    getMetricColumnProps({
      key: 'engagementRateByFollowers',
      title: t('HeaderColumn.Engagement Rate by Followers'),
      help: t<string>('Tooltip.engagementRateByFollowersHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT_RATE_BY_FOLLOWERS
    }),
    getMetricColumnProps({
      key: 'engagementRateByViews',
      title: t('HeaderColumn.Engagement Rate by Views'),
      help: t<string>('Tooltip.EngagementRateHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT_RATE
    }),
    getMetricColumnProps({
      key: 'likes',
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramFeedPostSortField.LIKE
    }),
    getMetricColumnProps({
      key: 'comments',
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramFeedPostSortField.COMMENT
    }),
    getMetricColumnProps({
      key: 'share',
      title: t('HeaderColumn.share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: InstagramFeedPostSortField.SHARE
    }),
    getMetricColumnProps({
      key: 'saved',
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: InstagramFeedPostSortField.SAVED
    }),
    getMetricColumnProps({
      key: 'accountsEngaged',
      title: t('HeaderColumn.Accounts Engaged'),
      help: t<string>('Tooltip.accountsEngagedHelp'),
      sortField: InstagramFeedPostSortField.ACCOUNTS_ENGAGED
    }),
    getMetricColumnProps({
      key: 'profileActivity',
      title: t('HeaderColumn.Profile Activity'),
      help: t<string>('Tooltip.profileActivityHelp'),
      sortField: InstagramFeedPostSortField.PROFILE_ACTIVITY
    }),
    getMetricColumnProps({
      key: 'profileVisits',
      title: t('HeaderColumn.Profile Visits'),
      help: t<string>('Tooltip.profileVisitsHelp'),
      sortField: InstagramFeedPostSortField.PROFILE_VISITS
    }),
    getMetricColumnProps({
      key: 'externalLinkTaps',
      title: t('HeaderColumn.External Link Taps'),
      help: t<string>('Tooltip.externalLinkTapsHelp'),
      sortField: InstagramFeedPostSortField.EXTERNAL_LINK_TAPS
    }),
    getMetricColumnProps({
      key: 'follow',
      help: t<string>('Tooltip.FollowHelp'),
      title: t('HeaderColumn.Follow'),
      sortField: InstagramFeedPostSortField.FOLLOW
    }),
    getMetricColumnProps({
      key: 'productPageView',
      title: t('HeaderColumn.Product Page View'),
      help: t<string>('Tooltip.productPageViewHelp'),
      sortField: InstagramFeedPostSortField.PRODUCT_PAGE_VIEW
    }),
    getMetricColumnProps({
      key: 'productButtonClick',
      title: t('HeaderColumn.Product Button Click'),
      help: t<string>('Tooltip.productButtonClickHelp'),
      sortField: InstagramFeedPostSortField.PRODUCT_BUTTON_CLICK
    }),
    getMetricColumnProps({
      key: 'postDate',
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramFeedPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      accountsEngaged,
      comments,
      content,
      engagement,
      engagementRateByFollowers,
      engagementRateByViews,
      externalLinkTaps,
      follow,
      id,
      interaction,
      likes,
      nonFollowerReach,
      postDate,
      productButtonClick,
      productPageView,
      profileActivity,
      profileVisits,
      reach,
      saved,
      share,
      thumbNail,
      views,
      viewsByFollowers,
      viewsByNonFollowers,
      viewsFromDiscovery,
      viewsFromHome,
      viewsFromOthers,
      viewsFromProfile
    }) => [
      <TableCell.Post
        title={content || ''}
        thumbnail={thumbNail || ''}
        css={{ maxWidth: 'unset' }}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatPercent(viewsByFollowers, false)} unit="%" />,
      <TableCell.Number value={formatPercent(viewsByNonFollowers, false)} unit="%" />,
      <TableCell.Number value={formatIntNumber(viewsFromHome)} />,
      <TableCell.Number value={formatIntNumber(viewsFromProfile)} />,
      <TableCell.Number value={formatIntNumber(viewsFromDiscovery)} />,
      <TableCell.Number value={formatIntNumber(viewsFromOthers)} />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatIntNumber(nonFollowerReach || 0)} />,
      <TableCell.Number value={formatIntNumber(interaction || 0)} />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatPercent(engagementRateByFollowers, false)} unit="%" />,
      <TableCell.Number value={formatPercent(engagementRateByViews, false)} unit="%" />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(share || 0)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Number value={formatIntNumber(accountsEngaged || 0)} />,
      <TableCell.Number value={formatIntNumber(profileActivity || 0)} />,
      <TableCell.Number value={formatIntNumber(profileVisits || 0)} />,
      <TableCell.Number value={formatIntNumber(externalLinkTaps || 0)} />,
      <TableCell.Number value={formatIntNumber(follow || 0)} />,
      <TableCell.Number value={formatIntNumber(productPageView || 0)} />,
      <TableCell.Number value={formatIntNumber(productButtonClick || 0)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
