import { DownloadFileType } from '@/shared/types';
import { getAccountManagementApiUrl } from '@/shared/utils';
import { DownloadButton, DownloadButtonProps } from '@/shared/atoms';
import { ExportTagsModalProps, ExportTagsModal } from '@/shared/molecules';
import { useToggleState, useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { useExportInstagramHashtagPostsToGoogleSpreadsheetMutation } from '@/graphql';

interface Props {
  hashtagId: string;
}

export const useExportAnalyticsInstagramHashtagsFile = ({ hashtagId }: Props) => {
  const exportModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { loading, callExportInstagramHashtagPostsToGoogleSpreadsheet } =
    useExportInstagramHashtagPostsToGoogleSpreadsheetMutation();

  const handleExportFile: ExportTagsModalProps['onSubmit'] = async ({ type, endDate, startDate }) => {
    const isSupportedType = [DownloadFileType.CSV, DownloadFileType.EXCEL, DownloadFileType.SPREADSHEET];

    if (!hashtagId || !isSupportedType) return;

    if (type === DownloadFileType.SPREADSHEET) {
      await callExportInstagramHashtagPostsToGoogleSpreadsheet({
        variables: {
          input: {
            hashtagId: +hashtagId,
            endDate: endDate.toString(),
            startDate: startDate.toString()
          }
        }
      });
      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } else {
      await handleDownloadFile({
        method: 'GET',
        params: { endDate, startDate },
        filenamePrefix: `hashtags-analytics-${new Date().toUTCString()}`,
        url: getAccountManagementApiUrl(
          `api/analytics/instagram/hashtag/${type === DownloadFileType.EXCEL ? 'xlsx' : 'csv'}/${hashtagId}`
        )
      });
    }

    exportModal.close();
  };

  return {
    ExportButton: (props: DownloadButtonProps) => (
      <DownloadButton onClick={exportModal.open} title={t('Button.HashTag Export')} {...props} />
    ),
    ExportModal: (
      <ExportTagsModal
        open={exportModal.status}
        onClose={exportModal.close}
        onSubmit={handleExportFile}
        downloading={downloading || loading}
      />
    )
  };
};
