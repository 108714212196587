import { LinkRouterProps, Link } from '@/router';
import { Button, ButtonProps } from './Button';

type LinkPropsModified = Omit<LinkRouterProps, 'title'>;
export interface ButtonLinkProps extends Omit<ButtonProps, 'onClick'>, LinkPropsModified {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  rel?: string;
}

export const ButtonLink = ({ to, disabled, onClick, params, search, as = Link, ...props }: ButtonLinkProps) => (
  <Button<LinkPropsModified>
    {...props}
    disabled={disabled}
    to={disabled ? '' : to}
    params={params}
    search={search}
    as={as}
    onClick={
      disabled
        ? (e) => {
            e.preventDefault();
          }
        : onClick
    }
  />
);
