import {
  HttpLink,
  Operation,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ApolloClientProvider
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import axios from 'axios';
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';
import { ReactNode } from 'react';
import { GRAPHQL_SERVER_VERSION_URL, GRAPHQL_URL } from '@/shared/constants';
import { getToken, manageLocalStorage } from '@/router';

const __DEV__ = process.env.NODE_ENV !== 'production';

const operationInfo = (operation: Operation) => {
  const operationDefinition = operation.query.definitions.find((def) => 'operation' in def) as
    | OperationDefinitionNode
    | undefined;
  const fragmentDefinition = operation.query.definitions.filter(
    (def) => def.kind === 'FragmentDefinition'
  ) as FragmentDefinitionNode[];

  return {
    type: operationDefinition ? operationDefinition.operation : '',
    name: operation.operationName,
    fragments: fragmentDefinition.map((def) => def.name.value).join(', '),
    variables: operation.variables
  };
};

const sentryLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    category: 'graphql',
    data: operationInfo(operation),
    level: 'debug'
  });

  return forward ? forward(operation) : null;
});
const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${GRAPHQL_URL}?${operation.operationName}`
  }));

  return forward ? forward(operation) : null;
});
// Authentication
const httpLink = new HttpLink({ uri: GRAPHQL_URL });
const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token || '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };
});

// Error handling
const showError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (__DEV__) {
        Sentry.captureException(new Error(message));
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        influencerProfileV2: { merge: true },
        engagementCampaignReport: { keyArgs: false, merge: true },
        marketplaceCampaignReport: { keyArgs: false, merge: true }
      }
    }
  }
});

const serverApiVersionKey = await axios(GRAPHQL_SERVER_VERSION_URL, {
  method: 'GET'
})
  .then((res) => res.data)
  .catch((err) => {
    console.error('version error', JSON.stringify(err));
    return '';
  });
const localStorageApiVersionKey = manageLocalStorage('get', 'apiVersionKey');

const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage)
});

if (serverApiVersionKey === localStorageApiVersionKey) {
  await persistor.restore();
} else {
  // If the hash keys don't match, purge the storage, write a new one, and update the hash key
  await persistor.purge();
  persistor.remove(); // this will remove the key-value pair associated with apollo cache, by default it is "apollo-cache-persist"
  manageLocalStorage('set', 'apiVersionKey', serverApiVersionKey);
}

export const client = new ApolloClient({
  cache,
  connectToDevTools: __DEV__,
  name: 'anytag-client',
  link: ApolloLink.from([namedLink, authLink, sentryLink, showError, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      // https://github.com/apollographql/apollo-client/issues/6833
      // in short 'cache-and-network' may cause duplicate call to some APIs
      nextFetchPolicy: (lastFetchPolicy) => {
        if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
          return 'cache-first';
        }

        return lastFetchPolicy;
      }
    },
    mutate: { errorPolicy: 'none' },
    query: { fetchPolicy: 'network-only', errorPolicy: 'all' }
  }
});

interface ApolloProviderProps {
  children: ReactNode;
}

export const ApolloProvider = ({ children }: ApolloProviderProps) => (
  <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
);
