import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { Modal, ModalContent, ModalFooterActions, ModalProps, ModalTitle } from '@/shared/atoms';

export interface DeleteTagModalProps extends ModalProps {
  tag?: string;
  loading?: boolean;
  onDelete: () => void;
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export const DeleteTagModal = ({
  tag,
  title,
  loading,
  onClose,
  onDelete,
  description,
  ...restProps
}: DeleteTagModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} css={{ width: THEME.modal.size.lv1 }} {...restProps}>
      <ModalContent>
        <ModalTitle>
          <>
            <span>{title ?? t('Dialog.Delete Hashtag')}</span>
            {tag ? (
              <>
                &nbsp;<span>{tag}</span>
              </>
            ) : null}
          </>
        </ModalTitle>

        <p css={{ fontSize: '14px', marginTop: '16px' }}>{description ?? t('Dialog.DeleteComparableHashtagWarning')}</p>
      </ModalContent>

      <ModalFooterActions
        cancelButtonProps={{ onClick: onClose }}
        submitButtonProps={{ variant: 'red', onClick: onDelete, title: t('Button.Delete') }}
      />
    </Modal>
  );
};
