import { THEME } from '@/shared/constants';
import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useTiktokTagsAnalyticsFilter } from './hooks';

export interface TiktokTagsAnalyticsFilterProps extends TemplateProps {
  className?: string;
}

export const TiktokTagsAnalyticsFilter = ({ className, ...templateProps }: TiktokTagsAnalyticsFilterProps) => {
  const { filter, setFilterForm } = useTiktokTagsAnalyticsFilter();

  return (
    <FilterWrapper
      css={{
        borderRadius: 0,
        overflow: 'auto',
        boxShadow: 'none',
        maxHeight: '40vh',
        borderTop: THEME.border.base
      }}
      className={className}
    >
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...templateProps} />} />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export { useTiktokTagsAnalyticsFilter };
