import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { THEME } from '@/shared/constants';
import { FormStyle } from '@/shared/atoms';
import { useExportAnalyticsInstagramHashtagsFile } from '@/shared/organisms';
import { TextSearchField, BasicFilterWrapper, SingleDatePickerField } from '@/shared/molecules';
import { TagsList, TagsListProps, TagsAnalyticsFilterFormKeys, TagsAnalyticsFilterFormValues } from '../../shared';
import { useCreateDeleteInstagramHashtag } from './hooks';

export interface TemplateProps {
  hashtags: TagsListProps['tags'];
  onSubmit?: TagsListProps['onSubmit'];
}

export const Template = ({ hashtags, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<TagsAnalyticsFilterFormValues>();
  const [dateValue, tagIdValue] = watch(['date', 'tagId']);
  const { creating, deleting, handleCreateHashtag, handleDeleteHashtag } = useCreateDeleteInstagramHashtag();
  const { ExportButton, ExportModal } = useExportAnalyticsInstagramHashtagsFile({ hashtagId: String(tagIdValue) });

  return (
    <>
      <div css={{ padding: '16px 16px 4px', boxSizing: 'border-box', borderBottom: THEME.border.base }}>
        <TagsList
          tags={hashtags}
          onSubmit={onSubmit}
          creating={creating}
          deleting={deleting}
          onCreateTag={handleCreateHashtag}
          onDeleteTag={handleDeleteHashtag}
        />

        <BasicFilterWrapper css={{ padding: 0, marginTop: '8px' }}>
          <TextSearchField<TagsAnalyticsFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('hashtagsAnalyticsKeywordPlaceholder')}
          />

          <SingleDatePickerField<TagsAnalyticsFilterFormKeys>
            name="date"
            onSubmit={onSubmit}
            disabledDays={{ after: new Date() }}
            css={{ flex: 'none', width: '184px' }}
            required
            clearable={false}
          />

          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <ExportButton disabled={!dateValue || !tagIdValue} />
          </FormStyle.FieldWrapper>
        </BasicFilterWrapper>
      </div>

      {ExportModal}
    </>
  );
};
