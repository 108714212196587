import { LinkRouterProps } from '@/router';
import { ParamType, ParamsType, RedirectType, SocialMediaProviderType } from '@/shared/types';

export const getFilenameFromUrl = (url: string) => url.substring(url.lastIndexOf('/') + 1);
export const getFileTypeFromUrl = (url: string) => url.substring(url.lastIndexOf('.') + 1).toLowerCase();
export const getParamString = <T>(s: ParamType, init: T) => (s || init) as T;
export const getParamStrings = <T>(s: ParamType, init: T[]) => (!s ? init : s.split(',')) as T[];
export const getParamNumber = <T>(input: ParamType, init: T) => (!input || isNaN(Number(input)) ? init : Number(input));
export const getParamNumbers = <T>(input: ParamType, init: T) =>
  input
    ?.split(',')
    ?.map(Number)
    ?.filter((n) => !isNaN(n)) || init;

/**
 * Convert Object to ParamsType
 * @param object
 * @returns ParamsType
 */
export const convertObjToParamsType = (object: Record<string, any>) =>
  Object.keys(object).reduce<ParamsType<string>>((obj, key) => {
    const value = (() => {
      const propertyType = Array.isArray(object[key]) ? 'array' : object[key] === null ? 'null' : typeof object[key];
      // Support types: array, number, string, null, undefined
      switch (propertyType) {
        case 'string':
          return object[key] || '';
        case 'array':
          return object[key].join(',');
        case 'number':
          return String(object[key] ?? '');
        case 'null':
        case 'undefined':
          return '';
        default:
          throw new Error(`Fn: convertObjToParamsType() does not support type ${propertyType}`);
      }
    })();

    return { ...obj, [key]: value };
  }, {});

export const getValuableParams = (object: Record<string, any>) => {
  const params = object;

  return Object.keys(params).reduce((obj, key) => (params[key] ? { ...obj, [key]: params[key] } : obj), {});
};

export const validateUrl = (url?: string | null): string | false => {
  if (!url) {
    return false;
  }

  try {
    new URL(url);

    return encodeURI(decodeURI(url));
  } catch {
    return false;
  }
};
export const getCallbackUrl = (provider: SocialMediaProviderType, redirectType: keyof typeof RedirectType) => {
  const path = ((): (typeof redirectLinkProps)[number] => {
    switch (redirectType) {
      case RedirectType.ANALYTICS_SIGNIN:
        return '/redirect/analytics-signin/$provider';
      case RedirectType.ANALYTICS_CONNECT:
        return '/redirect/analytics-connect/$provider';
      case RedirectType.ANALYTICS_LIST_CONNECT:
        return '/redirect/analytics-list-connect/$provider';
      case RedirectType.TALENT_SIGNIN:
        return '/redirect/talent-signin/$provider';
      case RedirectType.RECONNECT_IG:
        return '/redirect/talent-analytics-connect/$provider';
      default:
        throw new Error('redirectType mismatch');
    }
  })();

  return `${window.location.origin}${path.replace('$provider', provider)}`;
};

export const redirectLinkProps = [
  '/redirect/analytics-signin/$provider',
  '/redirect/analytics-connect/$provider',
  '/redirect/analytics-list-connect/$provider',
  '/redirect/talent-signin/$provider',
  '/redirect/talent-analytics-connect/$provider'
] as const satisfies ReadonlyArray<LinkRouterProps['to']>;
