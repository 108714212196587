import { useFormContext } from 'react-hook-form';
import { AddTagModalProps } from '@/shared/molecules';
import { useQueryHelper, useSelectItem, useToggleState } from '@/shared/hooks';
import { TagAnalyticsType, TagsAnalyticsFilterFormValues } from '../../schemaTypes';

export interface ManageTagsProps {
  onSubmit?: () => void;
  tags: readonly TagAnalyticsType[];
  onDeleteTag: (tag: TagAnalyticsType) => Promise<void>;
  onCreateTag: (label: TagAnalyticsType['label']) => Promise<void>;
}

export const useManageTags = ({ tags, onCreateTag, onDeleteTag, onSubmit }: ManageTagsProps) => {
  const addModalState = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch, setValue } = useFormContext<TagsAnalyticsFilterFormValues>();
  const {
    selectedItem: deletingTag,
    setSelectedItem: setDeletingTag,
    resetSelectedItem: resetDeletingTag
  } = useSelectItem<TagAnalyticsType>();

  const tagIdValue = watch('tagId');

  const checkActivatingTag = (tag: TagAnalyticsType) => tag.id === tagIdValue;

  const handleCreateTag: AddTagModalProps['onAdd'] = async (tag) => {
    try {
      await onCreateTag(tag);

      addModalState.close();
    } catch (error) {
      enqueueSnackbar(t('failedToCreate', { name: tag }), { variant: 'error' });
    }
  };

  const handleDeleteTag = async () => {
    if (!deletingTag) return;

    try {
      await onDeleteTag(deletingTag);
      resetDeletingTag();

      if (checkActivatingTag(deletingTag)) {
        setValue('tagId', tags.at(0)?.id || '');
        onSubmit?.();
      }
    } catch (error) {
      enqueueSnackbar(t('failedToDelete', { name: deletingTag.label }), { variant: 'error' });
    }
  };

  const handleSelectTag = (tag: TagAnalyticsType) => {
    setValue('tagId', checkActivatingTag(tag) ? '' : tag.id);
    onSubmit?.();
  };

  return {
    deletingTag,
    addModalState,
    setDeletingTag,
    handleDeleteTag,
    handleCreateTag,
    handleSelectTag,
    resetDeletingTag,
    checkActivatingTag
  };
};
