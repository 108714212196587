import { Outlet, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { PermissionRoute } from '@/app-components';
import { adminStaffRoles } from '@/shared/constants';
import { InstagramHashtagsAnalyticsSearchSchema, TiktokTagsAnalyticsSearchSchema } from '@/shared/organisms';
import { InstagramHashtagsAnalytics, TiktokKeywordAnalytics, TiktokHashtagsAnalytics } from '@/pages/HashtagsAnalytics';
import { PartialRouter } from '../../types';

export const HashtagAnalyticsRouter = {
  '/_private-routes/hashtag-analytics': [() => <PermissionRoute permissions={adminStaffRoles} />, {}],
  '/_private-routes/hashtag-analytics/': [
    () => <Outlet />,
    {
      beforeLoad: () => {
        throw redirect({
          to: '/hashtag-analytics/instagram'
        });
      }
    }
  ],
  '/_private-routes/hashtag-analytics/instagram': [
    InstagramHashtagsAnalytics,
    { validateSearch: zodValidator(InstagramHashtagsAnalyticsSearchSchema) }
  ],
  '/_private-routes/hashtag-analytics/tiktok/': [
    () => <Outlet />,
    {
      beforeLoad: () => {
        throw redirect({
          to: '/hashtag-analytics/tiktok/hashtag'
        });
      }
    }
  ],
  '/_private-routes/hashtag-analytics/tiktok/keyword': [
    TiktokKeywordAnalytics,
    { validateSearch: zodValidator(TiktokTagsAnalyticsSearchSchema) }
  ],
  '/_private-routes/hashtag-analytics/tiktok/hashtag': [
    TiktokHashtagsAnalytics,
    { validateSearch: zodValidator(TiktokTagsAnalyticsSearchSchema) }
  ]
} as const satisfies PartialRouter;
