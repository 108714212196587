import { SerializedStyles, css } from '@emotion/react';
import axios, { AxiosError } from 'axios';
import { Accept } from 'react-dropzone';
import React, { useState, ReactNode, forwardRef, useImperativeHandle } from 'react';
import { VIDEO_UPLOAD_LIMIT, DEFAULT_UPLOAD_LIMIT } from '@/shared/constants';
import { useQueryHelper } from '@/shared/hooks';
import { getToken } from '@/router';
import { DropArea } from './DropArea';
import { Preview } from './Preview';
import { UploadedFile } from './types';

export interface FileDropProps {
  notes?: string[];
  value?: string[];
  initialFileUrls?: UploadedFile[];
  title?: string;
  multiple?: boolean;
  accept?: Accept;
  description?: React.ReactNode | string;
  error?: boolean;
  disabled?: boolean;
  customLabel?: ReactNode;
  isHidden?: boolean;
  className?: string;
  showPreview?: boolean;
  previewWidth?: string;
  previewCss?: SerializedStyles;
  dropAreaCss?: SerializedStyles;
  uploadOnDrop?: boolean;
  uploadFileName?: string;
  dropAreaButtonLabel?: string | null;
  dropAreaDescription?: string | null;
  uploadURL?: string;
  isGCSUpload?: boolean;
  generateSignedUrl?: (filename: string) => Promise<{ fileName: string; signedUrl: string } | null>;
  onFileChange?: (values: string[]) => void;
  onFileUploaded?: () => void;
  onUploadStatusChange?: (isUploading: boolean) => void;
  onDropCallback?: (values: File[]) => void;
}

export const FileDrop = forwardRef((props: FileDropProps, ref) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  useImperativeHandle(ref, () => ({
    requestUpload: () => {
      requestUpload();
    },
    newFile
  }));
  const {
    initialFileUrls = [],
    value = [],
    description,
    title,
    isGCSUpload = false,
    uploadFileName,
    uploadURL,
    uploadOnDrop,
    multiple = false,
    customLabel,
    className,
    dropAreaButtonLabel,
    dropAreaDescription,
    previewCss,
    dropAreaCss,
    showPreview = false,
    generateSignedUrl,
    onFileChange = () => null,
    onFileUploaded,
    onUploadStatusChange,
    onDropCallback
  } = props;

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(initialFileUrls);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<{ progressUrl: string | null; progress: number }>({
    progressUrl: null,
    progress: 0
  });

  const uploadFile = async (url: string, accepted: File) => {
    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFiles, { url: URL.createObjectURL(accepted), preview: '' }];
      onFileChange([...value, accepted.name]);
    } else {
      newUploadedFiles = [{ url: URL.createObjectURL(accepted), preview: '' }];
      onFileChange([accepted.name]);
    }

    setUploadedFiles(newUploadedFiles);

    if (onUploadStatusChange) {
      onUploadStatusChange(true);
    }

    try {
      const formData = new FormData();
      formData.append(uploadFileName || '', accepted);
      const { data } = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          authorization: getToken() || '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        onUploadProgress: (p) => {
          setUploadProgress({ progress: (p.loaded / (p.total || 1)) * 100, progressUrl: accepted.name });
        }
      });

      if (data.status === 'ERROR') {
        enqueueSnackbar(t(data?.message || 'fileUploadFail'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      }
      setUploadProgress({ progress: 100, progressUrl: null });
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        enqueueSnackbar(t(error?.response?.data?.message || 'fileUploadFail'), { variant: 'error' });
      } else {
        enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
      }
      setUploadedFiles(uploadedFiles);
    }
  };

  const uploadFileToGCS = async ({ signedUrl }: { fileName: string; signedUrl: string }, accepted: File) => {
    const videoUrlName = signedUrl.split('?').at(0) || '';
    const preview = URL.createObjectURL(accepted);

    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFiles, { url: videoUrlName, preview }];
      onFileChange([...value, videoUrlName]);
    } else {
      newUploadedFiles = [{ url: videoUrlName, preview }];
      onFileChange([videoUrlName]);
    }

    setUploadedFiles(newUploadedFiles);

    if (onUploadStatusChange) {
      onUploadStatusChange(true);
    }

    try {
      await axios(signedUrl, {
        method: 'PUT',
        data: accepted,
        onUploadProgress: (p) => {
          setUploadProgress({ progress: (p.loaded / (p.total || 1)) * 100, progressUrl: videoUrlName ?? null });
        }
      });

      setUploadProgress({ progress: 100, progressUrl: null });
      enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      setUploadedFiles(uploadedFiles);
    }

    if (onUploadStatusChange) {
      onUploadStatusChange(false);
    }
  };

  const onDrop = async (accepted: File[]) => {
    // eslint-disable-next-line no-unused-expressions
    onDropCallback?.(accepted);
    const acceptedFile = (accepted && accepted.length ? accepted.at(0) : null) || null;
    const isVideoFile = acceptedFile && ['video/mp4', 'video/quicktime'].includes(acceptedFile.type);
    const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

    if (acceptedFile && acceptedFile.size > sizeLimit) {
      enqueueSnackbar(t('General.UploadSizeError'), { variant: 'error' });

      return;
    }

    setNewFile(acceptedFile);

    if (uploadOnDrop) {
      await requestUpload();
    }
  };

  const requestUpload = async () => {
    if (!newFile) {
      return;
    }

    if (isGCSUpload && generateSignedUrl) {
      const generatedSignedUrl = await generateSignedUrl(newFile.name);
      if (generatedSignedUrl) {
        await uploadFileToGCS(generatedSignedUrl, newFile);
      }
    }
    if (!isGCSUpload && uploadURL) {
      await uploadFile(uploadURL, newFile);
    }
  };

  const deleteUploadedFile = (index: number) => () => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
    const newMaterialsValue = value.filter((materialUrl) => materialUrl !== uploadedFiles[index]?.url);

    setUploadedFiles(newUploadedFiles);
    onFileChange(newMaterialsValue);

    return;
  };

  return (
    <div css={{ minWidth: '500px' }}>
      <h2 css={styles.title}>{t(title || '')}</h2>
      <div css={styles.description}>{description}</div>
      <div className={className}>
        {!props.isHidden && (
          <div css={dropAreaCss}>
            {!!customLabel && customLabel}
            <DropArea
              notes={props.notes || []}
              multiple={false}
              accept={props.accept}
              onDrop={onDrop}
              disabled={!!props.disabled}
              buttonLabel={dropAreaButtonLabel}
              description={dropAreaDescription}
            />
          </div>
        )}
        {showPreview && (
          <Preview
            uploadedFiles={uploadedFiles}
            deleteUploadedFile={deleteUploadedFile}
            previewWidth={props.previewWidth}
            disabled={props.disabled}
            uploadProgress={uploadProgress}
            previewCss={previewCss}
          />
        )}
      </div>
    </div>
  );
});
FileDrop.displayName = 'FileDragDropComponent';

const styles = {
  title: css({
    fontSize: '20px',
    lineHeight: '24px',
    fontStyle: 'normal',
    marginBottom: '24px',
    fontWeight: 'normal'
  }),
  description: css({
    fontSize: '13px',
    lineHeight: '18px',
    marginBottom: '8px',
    fontStyle: 'normal',
    fontWeight: 'normal'
  })
};
