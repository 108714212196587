import { useLocation } from '@tanstack/react-router';
import { useSyncExternalStore } from 'react';
import { LinkRouterProps } from '@/router';

export interface RouterLocation extends Pick<ReturnType<typeof useLocation>, 'search' | 'href' | 'state'> {
  pathname: LinkRouterProps['to'];
}

export const useLocationHistory = () => {
  const currentLocation = useLocation();

  const lastLoc = useSyncExternalStore(subscribe, () => sessionStorage.getItem('location'));
  let lastLocation = null;

  try {
    lastLocation = lastLoc ? (JSON.parse(lastLoc) as RouterLocation) : null;
  } catch (e) {
    console.warn('Error parsing location from sessionStorage:', e);
  }

  return {
    lastLocation,
    currentLocation
  };
};

function subscribe() {
  return () => null;
}
