import { useEffect } from 'react';
import { useParams } from '@tanstack/react-router';
import {
  AnalyticsRedirectType,
  useSocialAuthConnectableFacebookPageAnalyticsMutation,
  useSocialAuthConnectableTwitterAccountAnalyticsV2Mutation,
  useSocialAuthConnectableInstagramAccountsAnalyticsMutation,
  useSocialAuthConnectableYouTubeChannelsAnalyticsV2Mutation
} from '@/graphql';
import { Loading } from '@/shared/atoms';
import { useQueryHelper } from '@/shared/hooks';
import { AnalyticsConnectableAccountsType, getAnalyticsAccountFormState } from '@/shared/organisms';
import { getCallbackUrl } from '@/shared/utils';
import { manageLocalStorage } from '@/router';
import { RedirectProviderParamsSchemaType } from './schemaTypes';

export const RedirectAnalyticsSignin = () => {
  const { t, enqueueSnackbar, navigate } = useQueryHelper();
  const { provider } = useParams({
    from: '/_private-routes/redirect/analytics-signin/$provider'
  }) satisfies RedirectProviderParamsSchemaType;
  const response = window.location.search.slice(1);
  const { callSocialAuthConnectableFacebookPageAnalytics } = useSocialAuthConnectableFacebookPageAnalyticsMutation();
  const { callSocialAuthConnectableInstagramAccountsAnalytics } =
    useSocialAuthConnectableInstagramAccountsAnalyticsMutation();
  const { callSocialAuthConnectableYouTubeChannelsAnalyticsV2 } =
    useSocialAuthConnectableYouTubeChannelsAnalyticsV2Mutation();
  const { callSocialAuthConnectableTwitterAccountAnalyticsV2 } =
    useSocialAuthConnectableTwitterAccountAnalyticsV2Mutation();

  useEffect(() => {
    fetchConnectableAccounts();
  }, []);

  const connectableAccounts: AnalyticsConnectableAccountsType = {} as AnalyticsConnectableAccountsType;
  const stringifiedData = manageLocalStorage('get', 'analyticsAccountFormState_json');
  const { to, params } = getAnalyticsAccountFormState('parse', { stringifiedData });
  const variables = {
    input: { response, callbackUrl: getCallbackUrl(provider, AnalyticsRedirectType.ANALYTICS_SIGNIN) }
  };

  const goToAnalyticsPage = (state: { connectableAccounts: AnalyticsConnectableAccountsType }) => {
    if (to) {
      navigate({
        to,
        params,
        search: {
          state
        }
      });
    } else {
      enqueueSnackbar(t('Missing redirect back route'), { variant: 'error' });
    }
  };

  const fetchConnectableAccounts = async () => {
    try {
      switch (provider) {
        case 'instagram':
          {
            const { data } = await callSocialAuthConnectableInstagramAccountsAnalytics({ variables });
            connectableAccounts.instagram = data?.socialAuthConnectableInstagramAccountsAnalytics?.accounts;
          }
          break;
        case 'youtube':
          {
            const { data } = await callSocialAuthConnectableYouTubeChannelsAnalyticsV2({ variables });
            connectableAccounts.youtube = data?.socialAuthConnectableYouTubeChannelsAnalyticsV2;
          }
          break;
        case 'twitter':
          {
            const { data } = await callSocialAuthConnectableTwitterAccountAnalyticsV2({ variables });
            const connectableTwAccount = data?.socialAuthConnectableTwitterAccountAnalyticsV2 || undefined;

            if (connectableTwAccount) {
              if (connectableTwAccount.isUsed) {
                enqueueSnackbar(t('This account already exists'), { variant: 'error' });
              } else {
                connectableAccounts.twitter = connectableTwAccount;
              }
            }
          }
          break;
        case 'facebook':
          {
            const { data } = await callSocialAuthConnectableFacebookPageAnalytics({ variables });
            connectableAccounts.facebookPage = data?.socialAuthConnectableFacebookPageAnalytics || undefined;
          }
          break;
        default:
          throw new Error('Unsupported provider');
      }
    } catch (error) {
      enqueueSnackbar(t('Unable to get your connected accounts information'), { variant: 'error' });
    } finally {
      goToAnalyticsPage({ connectableAccounts });
    }
  };

  return <Loading />;
};
