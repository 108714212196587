import { useTranslation } from 'react-i18next';
import { InstagramCompareFeedPost, InstagramFeedPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatIntNumber, formatPercent } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramFeedPostSortField>;

interface Props {
  data: readonly InstagramCompareFeedPost[];
}

export const useAnalyticsInstagramComparePostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Post'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      help: t<string>('Tooltip.EngagementHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement Rate by Followers'), // TODO: Renat will change it later
      help: t<string>('Tooltip.EngagementRateHelp'),
      sortField: InstagramFeedPostSortField.ENGAGEMENT_RATE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramFeedPostSortField.LIKE
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramFeedPostSortField.COMMENT
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramFeedPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ id, likes, content, comments, postDate, thumbNail, engagement, engagementRate }) => [
      <TableCell.Post
        title={content || ''}
        thumbnail={thumbNail || ''}
        css={{ maxWidth: 'unset' }}
        onClick={() => setPostDetailsParam(id)}
      />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatPercent(engagementRate, false, 2)} unit="%" />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
