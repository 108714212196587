import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { usePostDetailsParam } from '@/shared/hooks';
import { Anchor, MediaPreview, MediaPreviewProps } from '@/shared/atoms';

export interface PostCellProps {
  postId: number;
  content?: string;
  thumbnail?: string;
  counter?: MediaPreviewProps['counter'];
}

export const PostCell = ({ postId, content, thumbnail, counter }: PostCellProps) => {
  const { setPostDetailsParam } = usePostDetailsParam();

  return (
    <div onClick={() => setPostDetailsParam(postId)} css={styles.postWrapper}>
      <MediaPreview src={thumbnail} objectFit="contain" css={styles.postPreview} counter={counter} />
      <Anchor lines={3} label={content || '-'} />
    </div>
  );
};

const styles = {
  postWrapper: css({
    gap: '16px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center'
  }),
  postPreview: css({
    width: '56px',
    height: '56px',
    borderRadius: '5px',
    backgroundColor: THEME.background.colors.gray.lv1
  })
};
