import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportTiktokHashtagPostsToGoogleSpreadsheet.graphql';
import { Mutation, MutationexportTiktokHashtagPostsToGoogleSpreadsheetArgs } from '../../__generated__/globalTypes';

export const useExportTiktokHashtagPostsToGoogleSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationexportTiktokHashtagPostsToGoogleSpreadsheetArgs>
) => {
  const [callExportTiktokHashtagPostsToGoogleSpreadsheet, result] = useMutation<
    Mutation,
    MutationexportTiktokHashtagPostsToGoogleSpreadsheetArgs
  >(MUTATION, options);

  return { callExportTiktokHashtagPostsToGoogleSpreadsheet, ...result };
};

export type ExportTiktokHashtagPostsToGoogleSpreadsheetMutationFunction =
  Mutation['exportTiktokHashtagPostsToGoogleSpreadsheet'];
