import { css } from '@emotion/react';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { THEME } from '@/shared/constants';
import { SelectedRangeOption } from '@/shared/types';
import { DayPickerRangeProps } from './DayPickerRange';
import { getSelectedRange } from './helpers';
import { Period, DateRange } from './types';

interface CustomRangeProps {
  state: DateRange;
  setState: (range: DateRange) => void;
  initialPeriod?: Period;
  rangeList: DayPickerRangeProps['rangeList'];
  clearTitle: DayPickerRangeProps['clearTitle'];
}
export const useCustomRange = ({ state, setState, initialPeriod, rangeList, clearTitle }: CustomRangeProps) => {
  const today = new Date();
  const lastDayOfPrevMonth = subDays(startOfMonth(today), 1);
  const { t } = useTranslation();

  const handleClickToday = () => {
    const range = {
      from: today,
      to: today,
      hoverTo: today,
      hoverFrom: today
    };
    setState(range);
  };

  const handleClickYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const range = {
      from: date,
      hoverFrom: date,
      to: date,
      hoverTo: date
    };
    setState(range);
  };

  const handleClickThisWeek = () => {
    const fistDayOdWeek = startOfWeek(today);
    const range = {
      from: fistDayOdWeek,
      hoverFrom: fistDayOdWeek,
      to: today,
      hoverTo: today
    };
    setState(range);
  };

  const handleClickLastSevenDays = () => {
    const sevenDaysAgo = subDays(today, 6);
    const range = {
      from: sevenDaysAgo,
      hoverFrom: sevenDaysAgo,
      to: today,
      hoverTo: today
    };
    setState(range);
  };

  const handleClickLastWeek = () => {
    const oneWeekAgo = subWeeks(today, 1);
    const startLastWeek = startOfWeek(oneWeekAgo);
    const endLastWeek = endOfWeek(oneWeekAgo);
    const range = {
      from: startLastWeek,
      hoverFrom: startLastWeek,
      to: endLastWeek,
      hoverTo: endLastWeek
    };
    setState(range);
  };

  const handleClickThisMonth = () => {
    const range = {
      from: startOfMonth(today),
      hoverTo: endOfMonth(today),
      to: endOfMonth(today),
      hoverFrom: startOfMonth(today)
    };
    setState(range);
  };
  const handleClickLastMonth = () => {
    const range = {
      from: startOfMonth(lastDayOfPrevMonth),
      hoverTo: lastDayOfPrevMonth,
      to: lastDayOfPrevMonth,
      hoverFrom: startOfMonth(lastDayOfPrevMonth)
    };
    setState(range);
  };

  const handleClickOneMonthAgo = () => {
    const range = {
      from: subDays(today, 29),
      hoverTo: today,
      to: today,
      hoverFrom: subDays(today, 29)
    };
    setState(range);
  };

  const handleClickAllTime = () => {
    const initialFrom = initialPeriod ? new Date(initialPeriod.startDate) : today;
    const initialTo = initialPeriod ? new Date(initialPeriod.endDate) : today;

    const range = {
      from: initialFrom,
      hoverTo: initialTo,
      to: initialTo,
      hoverFrom: initialFrom
    };
    setState(range);
  };
  const handleClickClearRange = () => {
    const range = {
      from: undefined,
      hoverTo: undefined,
      to: undefined,
      hoverFrom: undefined
    };
    setState(range);
  };

  const highlightedRange = getSelectedRange(state.hoverFrom, state.hoverTo, initialPeriod);

  return rangeList?.length ? (
    <div css={styles.leftNavbar}>
      {/* Today */}
      {rangeList.includes(SelectedRangeOption.TODAY) && (
        <button onClick={handleClickToday} css={styles.rangeButton(highlightedRange === SelectedRangeOption.TODAY)}>
          {t(SelectedRangeOption.TODAY)}
        </button>
      )}
      {/* Yesterday */}
      {rangeList.includes(SelectedRangeOption.YESTERDAY) && (
        <button
          onClick={handleClickYesterday}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.YESTERDAY)}
        >
          {t(SelectedRangeOption.YESTERDAY)}
        </button>
      )}
      {/* This Week */}
      {rangeList.includes(SelectedRangeOption.THIS_WEEK) && (
        <button
          onClick={handleClickThisWeek}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.THIS_WEEK)}
        >
          {t(SelectedRangeOption.THIS_WEEK)}
        </button>
      )}
      {/* Last 7 days */}
      {rangeList.includes(SelectedRangeOption.LAST_SEVEN_DAYS) && (
        <button
          onClick={handleClickLastSevenDays}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.LAST_SEVEN_DAYS)}
        >
          {t(SelectedRangeOption.LAST_SEVEN_DAYS)}
        </button>
      )}
      {/* Last week */}
      {rangeList.includes(SelectedRangeOption.LAST_WEEK) && (
        <button
          onClick={handleClickLastWeek}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.LAST_WEEK)}
        >
          {t(SelectedRangeOption.LAST_WEEK)}
        </button>
      )}
      {/* Last 30 days */}
      {rangeList.includes(SelectedRangeOption.TODAY_TO_MONTH_AGO) && (
        <button
          onClick={handleClickOneMonthAgo}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.TODAY_TO_MONTH_AGO)}
        >
          {t(SelectedRangeOption.TODAY_TO_MONTH_AGO)}
        </button>
      )}
      {/* This month */}
      {rangeList.includes(SelectedRangeOption.THIS_MONTH) && (
        <button
          onClick={handleClickThisMonth}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.THIS_MONTH)}
        >
          {t(SelectedRangeOption.THIS_MONTH)}
        </button>
      )}
      {/* Last Month */}
      {rangeList.includes(SelectedRangeOption.LAST_MONTH) && (
        <button
          onClick={handleClickLastMonth}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.LAST_MONTH)}
        >
          {t(SelectedRangeOption.LAST_MONTH)}
        </button>
      )}
      {/* All Time */}
      {rangeList.includes(SelectedRangeOption.ALL_TIME) && (
        <button
          onClick={handleClickAllTime}
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.ALL_TIME)}
        >
          {t(SelectedRangeOption.ALL_TIME)}
        </button>
      )}
      {/* Custom */}
      {rangeList.includes(SelectedRangeOption.CUSTOM) && (
        <button css={styles.rangeButton(highlightedRange === SelectedRangeOption.CUSTOM)}>
          {t(SelectedRangeOption.CUSTOM)}
        </button>
      )}
      {/* Clear */}
      {rangeList.includes(SelectedRangeOption.CLEAR) && (
        <button
          css={styles.rangeButton(highlightedRange === SelectedRangeOption.CLEAR)}
          onClick={handleClickClearRange}
        >
          {t(clearTitle || SelectedRangeOption.CLEAR)}
        </button>
      )}
    </div>
  ) : null;
};

const styles = {
  leftNavbar: css({
    display: 'flex',
    flexDirection: 'column',
    borderRight: THEME.border.base,
    backgroundColor: THEME.background.colors.white
  }),
  rangeButton: (isSelected: boolean) =>
    css({
      border: 'none',
      padding: '10px',
      textAlign: 'left',
      cursor: 'pointer',
      color: isSelected ? THEME.text.colors.white : 'unset',
      backgroundColor: isSelected ? THEME.datePicker.colors.active.background : 'unset',
      '&:hover': {
        backgroundColor: isSelected
          ? THEME.datePicker.colors.active.background
          : THEME.datePicker.colors.hover.background
      }
    })
};
