import { ReactElement } from 'react';
import { Navigate, Outlet } from '@tanstack/react-router';
import { useAuthData, useUserRoles } from '@/auth';
import { UserRoles } from '@/graphql';
import { useMatchPaths } from '@/shared/hooks';
import { LinkRouterProps } from '@/router';

const TT_CAMPAIGN_FOR_ADMINS_ROUTES = [
  '/tiktok/campaigns',
  '/tiktok/campaigns/$id',
  '/tiktok/campaigns/$id/posts',
  '/tiktok/campaigns/$id/report',
  '/tiktok/campaigns/$id/influencers',
  '/tiktok/campaigns/$id/posts/$postId'
] as const satisfies LinkRouterProps['to'][];

export interface PermissionRouteProps {
  element?: ReactElement;
  permissions?: UserRoles[];
}

export const TikTokPermissionRoute = (_: PermissionRouteProps) => {
  const { isAdvertiserFromTikTok, role } = useUserRoles();
  const { matchPaths } = useMatchPaths();
  const { defaultRoute } = useAuthData();

  if ((!role || !isAdvertiserFromTikTok) && !matchPaths(TT_CAMPAIGN_FOR_ADMINS_ROUTES)) {
    return <Navigate to={defaultRoute} />;
  }

  return <Outlet />;
};
