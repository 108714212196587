import { useChatDemo } from '@/shared/hooks';
import { useUserRoles, useLoggedInUserPermissions } from '@/auth';
import { MenuTitle, MenuType } from '@/router';
import { loadingMenuItems, getAllowMenuItemsFromPermissions } from '../utils';

export const useMenuConfigs = () => {
  const { isChatDemoAccount } = useChatDemo();
  const { isAdvertiser, isAdminStaff } = useUserRoles();
  const { permissions, permissionLoading } = useLoggedInUserPermissions();

  let menuItems: MenuType[] = [];

  if (permissionLoading) {
    menuItems = loadingMenuItems;
  } else {
    menuItems = getAllowMenuItemsFromPermissions({
      isAdvertiser,
      permissions,
      hideOptions: [
        ...(!isAdvertiser ? [MenuTitle.Settings] : [MenuTitle.Influencers, MenuTitle.Posts]),
        ...(!isAdminStaff ? [MenuTitle.AutoManaged, MenuTitle.TikTokCampaigns] : [])
      ]
    });

    // TODO DEMO: remove later
    if (isChatDemoAccount) {
      menuItems.unshift({ title: MenuTitle.ChatDemo, to: '/chat-demo' });
    }
  }

  return { menuItems, permissionLoading };
};
