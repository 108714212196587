import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from '@tanstack/react-router';
import { useAuthData } from '@/auth';
import { checkSignedIn } from '@/router';

export const useGaTracker = () => {
  const { auth } = useAuthData();
  const { pathname } = useLocation();
  const isSignedIn = checkSignedIn();

  const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.set({ user_properties: { role: auth.role, email: auth.email } });
    ReactGA.send({ hitType: 'pageview', page });
  };

  useEffect(() => {
    if (isSignedIn) {
      trackPage(pathname);
    }
  }, [pathname, isSignedIn]);
};
