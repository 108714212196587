import { useTranslation } from 'react-i18next';
import { InstagramDashboardOverview, InstagramInteractionOverviewSummaryData } from '@/graphql';
import { AnalyticsOverviewChartProps } from '../../../../shared';
import {
  AnalyticsInstagramDashboardGraphKeys as Keys,
  AnalyticsInstagramDashboardOverviewEngagementType
} from '../types';
import { allOverviewEngagements, legendColors } from '../utils';

interface Props {
  selectedLegendKeys: Keys[];
  isInfluencerProfile?: boolean;
  availableEngagementKeys: Keys[];
  data: InstagramDashboardOverview;
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  reachCount: number[];
  likesCount: number[];
  savedCount: number[];
  commentsCount: number[];
  followersCount: number[];
  engagementCount: number[];
  impressionsCount: number[];
  profileViewsCount: number[];
  websiteClicksCount: number[];
  engagementRateCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  reachCount: [],
  likesCount: [],
  savedCount: [],
  commentsCount: [],
  followersCount: [],
  engagementCount: [],
  impressionsCount: [],
  profileViewsCount: [],
  websiteClicksCount: [],
  engagementRateCount: []
};

const getEngagementMetrics = ({ total, growth, percentage }: InstagramInteractionOverviewSummaryData) => ({
  total: total || 0,
  growth: growth || 0,
  percentage: percentage || 0
});

export const useAnalyticsInstagramDashboardOverviewData = ({
  data,
  selectedLegendKeys,
  isInfluencerProfile,
  availableEngagementKeys
}: Props) => {
  const { t } = useTranslation();

  const {
    posts,
    reach,
    likes,
    saved,
    comments,
    followers,
    engagement,
    impressions,
    profileViews,
    websiteClicks,
    engagementRate
  } = data;
  const overviewEngagements = allOverviewEngagements.map<AnalyticsInstagramDashboardOverviewEngagementType>((item) => ({
    ...item,
    ...getEngagementMetrics(data[item.key]),
    title: t<string>(item.title)
  }));
  const availableOverviewEngagements = overviewEngagements.filter((eng) => availableEngagementKeys.includes(eng.key));

  const {
    categories,
    postsCount,
    reachCount,
    likesCount,
    savedCount,
    commentsCount,
    followersCount,
    engagementCount,
    impressionsCount,
    profileViewsCount,
    websiteClicksCount,
    engagementRateCount
  } = (followers.history || []).reduce<ChartValuesType>((acc, curr) => {
    const date = curr.date || '';

    return {
      categories: [...acc.categories, date],
      followersCount: [...acc.followersCount, Number(curr.count)],
      reachCount: [...acc.reachCount, reach.history?.find((item) => item.date === date)?.count || 0],
      likesCount: [...acc.likesCount, likes.history?.find((item) => item.date === date)?.count || 0],
      postsCount: [...acc.postsCount, posts.history?.find((item) => item.date === date)?.count || 0],
      savedCount: [...acc.savedCount, saved.history?.find((item) => item.date === date)?.count || 0],
      commentsCount: [...acc.commentsCount, comments.history?.find((item) => item.date === date)?.count || 0],
      engagementCount: [...acc.engagementCount, engagement.history?.find((item) => item.date === date)?.count || 0],
      impressionsCount: [...acc.impressionsCount, impressions.history?.find((item) => item.date === date)?.count || 0],
      profileViewsCount: [
        ...acc.profileViewsCount,
        profileViews.history?.find((item) => item.date === date)?.count || 0
      ],
      websiteClicksCount: [
        ...acc.websiteClicksCount,
        websiteClicks.history?.find((item) => item.date === date)?.count || 0
      ],
      engagementRateCount: [
        ...acc.engagementRateCount,
        engagementRate.history?.find((item) => item.date === date)?.count || 0
      ]
    };
  }, defaultChartValues);

  const chartData: AnalyticsOverviewChartProps<Keys>['data'] = {
    followers: {
      zIndex: 1,
      type: 'line',
      showIncrement: true,
      data: followersCount,
      name: t<string>('Follower'),
      color: legendColors.followers,
      visible: selectedLegendKeys.includes('followers'),
      marker: {
        lineWidth: 2,
        symbol: 'circle',
        fillColor: '#fff',
        lineColor: undefined // inherit from chart
      }
    },
    posts: {
      yAxis: 1,
      type: 'column',
      data: postsCount,
      name: t<string>('Post'),
      color: legendColors.posts,
      visible: selectedLegendKeys.includes('posts')
    },
    likes: {
      yAxis: 2,
      type: 'column',
      data: likesCount,
      name: t<string>('Like'),
      color: legendColors.likes,
      visible: selectedLegendKeys.includes('likes')
    },
    reach: {
      yAxis: 3,
      type: 'column',
      data: reachCount,
      name: t<string>('Reach'),
      color: legendColors.reach,
      visible: selectedLegendKeys.includes('reach')
    },
    comments: {
      yAxis: 4,
      type: 'column',
      data: commentsCount,
      name: t<string>('Comment'),
      color: legendColors.comments,
      visible: selectedLegendKeys.includes('comments')
    },
    saved: {
      yAxis: 5,
      type: 'column',
      data: savedCount,
      name: t<string>('Save'),
      color: legendColors.saved,
      visible: selectedLegendKeys.includes('saved')
    },
    impressions: {
      yAxis: 6,
      type: 'column',
      data: impressionsCount,
      name: t<string>('Impression'),
      color: legendColors.impressions,
      visible: selectedLegendKeys.includes('impressions')
    },
    engagement: {
      yAxis: 7,
      type: 'column',
      data: engagementCount,
      name: t<string>('Engagement'),
      color: legendColors.engagement,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagement')
    },
    engagementRate: {
      yAxis: 8,
      type: 'column',
      formatType: 'percentage',
      data: engagementRateCount,
      name: t<string>('Engagement Rate'),
      color: legendColors.engagementRate,
      visible: !isInfluencerProfile && selectedLegendKeys.includes('engagementRate')
    },
    profileViews: {
      yAxis: 9,
      type: 'column',
      data: profileViewsCount,
      color: legendColors.profileViews,
      name: t<string>('Profile views'),
      visible: selectedLegendKeys.includes('profileViews')
    },
    websiteClicks: {
      yAxis: 10,
      type: 'column',
      data: websiteClicksCount,
      name: t<string>('Website Click'),
      color: legendColors.websiteClicks,
      visible: selectedLegendKeys.includes('websiteClicks')
    }
  };

  return {
    chartData,
    categories,
    overviewEngagements,
    availableOverviewEngagements
  };
};
