import {
  namedOperations,
  useCreateTiktokUserHashtagMutation,
  useCreateTiktokUserKeywordMutation,
  useDeleteTiktokUserHashtagMutation,
  useDeleteTiktokUserKeywordMutation
} from '@/graphql';
import { TagType, TagsListProps } from '../../../shared';

interface Props {
  tagType: TagType;
}

export const useCreateDeleteTiktokTag = ({ tagType }: Props) => {
  const { callCreateTiktokUserHashtag, loading: creatingHashtag } = useCreateTiktokUserHashtagMutation({
    refetchQueries: [namedOperations.Query.TiktokUserHashtags]
  });
  const { callDeleteTiktokUserHashtag, loading: deletingHashtag } = useDeleteTiktokUserHashtagMutation({
    refetchQueries: [namedOperations.Query.TiktokUserHashtags]
  });
  const { callCreateTiktokUserKeyword, loading: creatingKeyword } = useCreateTiktokUserKeywordMutation({
    refetchQueries: [namedOperations.Query.TiktokUserKeywords]
  });
  const { callDeleteTiktokUserKeyword, loading: deletingKeyword } = useDeleteTiktokUserKeywordMutation({
    refetchQueries: [namedOperations.Query.TiktokUserKeywords]
  });

  const isKeywordTag = tagType === TagType.KEYWORD;

  const handleCreateTag: TagsListProps['onCreateTag'] = async (label) => {
    if (isKeywordTag) {
      await callCreateTiktokUserKeyword({ variables: { input: { keyword: label } } });
    } else {
      await callCreateTiktokUserHashtag({ variables: { input: { hashtag: label } } });
    }
  };

  const handleDeleteTag: TagsListProps['onDeleteTag'] = async (tag) => {
    if (isKeywordTag) {
      await callDeleteTiktokUserKeyword({ variables: { input: { keywordId: tag.id } } });
    } else {
      await callDeleteTiktokUserHashtag({ variables: { input: { hashtagId: tag.id } } });
    }
  };

  return {
    handleCreateTag,
    handleDeleteTag,
    creating: isKeywordTag ? creatingKeyword : creatingHashtag,
    deleting: isKeywordTag ? deletingKeyword : deletingHashtag
  };
};
