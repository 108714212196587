import * as Sentry from '@sentry/browser';
import { ReactElement, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRouter } from '@tanstack/react-router';
import { useAuthData } from '@/auth';
import { UserRoles, useVerifyTokenMutation } from '@/graphql';
import { InitialLoading } from '@/shared/molecules';
import {
  AnytagTokenPropsType,
  DecodedTokenShape,
  RouterContext,
  checkSignedIn,
  StateType,
  SUPPORTED_USER_ROLES,
  removeToken,
  getToken
} from '@/router';

export interface VerifyTokenProps {
  children: ReactElement;
}

const defaultUserDataFromToken: AnytagTokenPropsType = {
  ok: false,
  userId: null,
  role: null,
  name: null,
  hash: null,
  email: null,
  currencyId: null,
  countryId: null
};

/**
 * @description this HOC will do token verification while loaded it's children
 * since we can get most of user related data by decoding JWT - we can render it's children and do the checking in the background
 */
export const VerifyTokenAsync = ({ children }: VerifyTokenProps) => {
  const tokenFromStorage = getToken();
  const router = useRouter();
  const isSignedIn = checkSignedIn();
  const { auth, dispatch } = useAuthData();
  const { callVerifyToken } = useVerifyTokenMutation();

  const dispatchAuthUser = (payload: AnytagTokenPropsType, status: StateType['status']) => {
    router.invalidate();
    router.update({
      context: {
        ...(router.options.context as RouterContext),
        auth: {
          ...(router.options.context as { auth: StateType }).auth,
          ...payload,
          status,
          countryId: payload.countryId || '',
          company: payload.company as DecodedTokenShape['company']
        }
      }
    });
    dispatch({
      type: 'AUTH_USER',
      payload: {
        ...payload,
        status,
        countryId: payload.countryId || '',
        company: payload.company as DecodedTokenShape['company']
      }
    });
    Sentry.setUser({
      id: String(payload.userId),
      role: payload.role
    });
    ReactGA.set({ userId: payload.userId });
    ReactGA.set({
      user_properties: {
        role: payload.role,
        email: payload.email
      }
    });
  };

  const isUserRoleSupported = (role: UserRoles | null) => role && SUPPORTED_USER_ROLES.includes(role);

  // verify token and get userId and role
  const updateAuthDataInBackground = async () => {
    if (!tokenFromStorage) {
      dispatchAuthUser(defaultUserDataFromToken, 'idle');

      return;
    }

    const { data } = await callVerifyToken({ variables: { input: { token: tokenFromStorage } } });

    if (data?.verifyToken) {
      const { verifyToken } = data;

      if (!!verifyToken.userId && verifyToken.role && isUserRoleSupported(verifyToken.role)) {
        dispatchAuthUser(verifyToken, 'success');
      } else {
        removeToken();
        dispatchAuthUser(defaultUserDataFromToken, 'error');
      }
    }
  };

  useEffect(() => {
    updateAuthDataInBackground();
  }, [tokenFromStorage]);

  // TODO DEMO: revert return statement
  // We need to check some special logged in email to show and navigate to Chat Demo
  return (isSignedIn && !auth.email) || ['loading'].includes(auth.status) ? <InitialLoading /> : children;
};
