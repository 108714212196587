import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { useAllInstagramReelPostsQuery } from '@/graphql';
import { getOffset, getValuableVariables, convertOrderToORDER } from '@/shared/utils';
import { ProfilePostsCount, ProfilePostsFilter, AnalyticsInstagramReelPostsList } from '@/shared/organisms';
import { AnalyticsIgReelPostsListSearchSchemaType, useInitialAnalyticsPosts } from '../hooks';

export const AnalyticsInstagramReelPosts = () => {
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({ socialMedia: 'instagram' });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<AnalyticsIgReelPostsListSearchSchemaType>();
  const { data, previousData, loading } = useAllInstagramReelPostsQuery({
    skip: !brandAccountId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      offset: getOffset(page, limit),
      accountId: Number(mainSocialAccountId),
      orderBy: { field: sort.field, order: convertOrderToORDER(sort.order) },
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.allInstagramReelPostsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />
      <ProfilePostsCount count={totalRecords} />
      <AnalyticsInstagramReelPostsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.allInstagramReelPosts || []}
      />
    </ListWithPagination>
  );
};
