import { getOffset, getValuableVariables } from '@/shared/utils';
import { TiktokUserKeyword, useTiktokUserKeywordPostListQuery } from '@/graphql';
import {
  TagType,
  TagAnalyticsType,
  TiktokTagsAnalyticsFilter,
  TiktokTagsAnalyticsPostsList,
  useTiktokTagsAnalyticsFilter
} from '@/shared/organisms';
import { TiktokTagsAnalyticsTemplate } from '../shared';

interface TemplateProps {
  keywords: readonly TiktokUserKeyword[];
}

export const Template = ({ keywords }: TemplateProps) => {
  const { page, limit, sort, filter } = useTiktokTagsAnalyticsFilter();
  const { data, loading, previousData } = useTiktokUserKeywordPostListQuery({
    skip: !filter.tagId,
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      limit,
      orderBy: sort,
      date: filter.date,
      keyword: filter.keyword,
      tagId: Number(filter.tagId),
      offset: getOffset(page, limit)
    })
  });

  const result = loading ? previousData : data;
  const keywordData = result?.tiktokUserKeywordPostList;

  return (
    <TiktokTagsAnalyticsTemplate>
      <TiktokTagsAnalyticsFilter
        tagType={TagType.KEYWORD}
        tags={keywords.map<TagAnalyticsType>(({ id, keyword }) => ({ id, label: keyword }))}
      />

      <TiktokTagsAnalyticsPostsList data={keywordData?.posts} loading={loading} totalRecords={keywordData?.count} />
    </TiktokTagsAnalyticsTemplate>
  );
};
