import { useParams } from '@tanstack/react-router';
import { useUpdateDocumentTitle, useQueryHelper } from '@/shared/hooks';
import { LinkRouterProps } from '@/router';
import { useAnalyticsIgComparePostsFilter } from '../AnalyticsInstagramComparePosts/hooks';

interface Props {
  analyticsProfileUrl?: (typeof AnalyticsProfileUrl)[number];
  socialMedia: 'instagram' | 'youtube' | 'twitter' | 'facebook';
  document?: (name?: string) => Parameters<typeof useUpdateDocumentTitle>[0];
}

const AnalyticsProfileUrl = [
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare',
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard',
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction'
] as const satisfies Array<LinkRouterProps['to']>;

export const useInitialAnalyticsPosts = ({
  document,
  analyticsProfileUrl = '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard'
}: Props) => {
  const { navigate } = useQueryHelper();
  const params = useParams({
    from: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
  });
  const { filter } = useAnalyticsIgComparePostsFilter();

  // We just get some common fields, others should be got from place where it used
  const name = filter.brandAccountName;
  const brandAccountId = Number(params.brandAccountId || 0);
  const socialAccountId = Number(params.socialMediaAccountId || 0);

  useUpdateDocumentTitle({
    href: analyticsProfileUrl,
    search: { filter },
    alwaysBackToParent: true,
    interpolation: { title: name || '' },
    title: name ? 'appHeader.PostListWithAccountName' : 'documentTitle.Post List',
    appHeader: name ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
    ...(document ? document(name) : {})
  });

  if (!brandAccountId || !socialAccountId) {
    navigate({ to: analyticsProfileUrl, params });
  }

  return { goBackUrl: analyticsProfileUrl, brandAccountId, mainSocialAccountId: socialAccountId, params };
};
