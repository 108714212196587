import { UNEXPECTED_ERROR } from '@/shared/constants';
import { useQueryHelper, useToggleState, useGetSocialMediaRedirectUrl } from '@/shared/hooks';
import { SupportedSocialMediaType } from '@/shared/types';
import { manageLocalStorage } from '@/router';
import { TalentInfluencerScrappingAccountInfo, TalentInfluencerFormValues } from '../../../schemaTypes';
import { ReconnectAccountType } from '../types';

interface Props {
  influencerId?: number;
  values: TalentInfluencerFormValues;
}

export const useTalentInfluencerConnectSocialAccount = ({ influencerId, values }: Props) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const connectState = useToggleState();
  const reconnectState = useToggleState();
  const { getSocialMediaRedirectUrl } = useGetSocialMediaRedirectUrl({
    redirectType: 'TALENT_SIGNIN',
    query: 'SocialAuthRedirectUrlForInfluencer'
  });
  const { getSocialMediaRedirectUrl: getReconnectSocialMediaRedirectUrl } = useGetSocialMediaRedirectUrl({
    redirectType: 'RECONNECT_IG',
    query: 'TalentAgencyReconnectAnalyticsSocialAuthRedirectUrl'
  });

  const handleConnect = async (
    socialMedia: keyof typeof SupportedSocialMediaType,
    info?: TalentInfluencerScrappingAccountInfo
  ) => {
    try {
      connectState.open();
      const redirectUrl = await getSocialMediaRedirectUrl(
        socialMedia,
        socialMedia === 'INSTAGRAM' ? 'FACEBOOK' : socialMedia
      );

      if (redirectUrl) {
        window.location.href = redirectUrl;
        if (influencerId) {
          manageLocalStorage('set', 'talentInfluencerId', String(influencerId));
        }
        manageLocalStorage('set', 'scrappingAccountInfo', JSON.stringify(info));
        manageLocalStorage('set', 'talentAgencyUserFormValues', JSON.stringify(values));
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      connectState.close();
    }
  };

  const handleReconnect = async (socialMedia: keyof typeof SupportedSocialMediaType, data: ReconnectAccountType) => {
    if (!data) return;

    try {
      reconnectState.open();
      const redirectUrl = await getReconnectSocialMediaRedirectUrl(socialMedia);

      if (redirectUrl) {
        window.location.href = redirectUrl;
        manageLocalStorage('set', 'reconnectStatus', JSON.stringify(data));
      }
    } catch (error) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    } finally {
      reconnectState.close();
    }
  };

  return {
    handleConnect,
    handleReconnect,
    connecting: connectState.status,
    reconnecting: reconnectState.status
  };
};
