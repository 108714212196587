import { intervalToDuration } from 'date-fns';
import { TFunction } from 'i18next';
import { ORDER, Order } from '@/graphql';

/**
 * @description Calculate Float number (ref: https://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html)
 * 0.14 * 100 -> 14.000000000000002
 * floatify(0.14 * 100) -> 14
 */
export const floatify = (num: number, digits = 9) => parseFloat(num.toFixed(digits));
export const formatNumber = (n: number | null | undefined, digits = 2) =>
  n === null || n === undefined
    ? '-'
    : n.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
export const formatIntNumber = (n: number | null | undefined) => formatNumber(n, 0);
export const formatPercent = (n: number | null | undefined, unit = true, digits = 2) => {
  const num =
    n === null || n === undefined
      ? '-'
      : `${Number(n * 100)
          .toFixed(digits)
          .toLocaleString()}`;
  if (unit && n !== null && n !== undefined) {
    // "+" helps to get rid of ".00"
    return `${+num}%`;
  }

  return num;
};
export const intlNumberFormat = (num: number | null | undefined) =>
  num === null || num === undefined ? '-' : new Intl.NumberFormat('en-US').format(num);
type BigIntFormatterOptions = {
  k?: boolean;
  m?: boolean;
  b?: boolean;
};
export const bigIntFormatter = (
  value: number | null | undefined,
  toFixed: number | undefined = 2,
  { k, m, b }: BigIntFormatterOptions | undefined = { k: false, m: true, b: false }
): string => {
  if (value === null || value === undefined) {
    return '-';
  }

  const absValue = Math.abs(value);
  if (b && absValue >= NUMBERS_FOR_ROUNDING.billion) {
    return `${(value / NUMBERS_FOR_ROUNDING.billion).toFixed(2)}b`;
  } else if (m && absValue >= NUMBERS_FOR_ROUNDING.million) {
    return `${(value / NUMBERS_FOR_ROUNDING.million).toFixed(2)}m`;
  } else if (k && absValue >= NUMBERS_FOR_ROUNDING.ten_thousand) {
    return `${(value / NUMBERS_FOR_ROUNDING.thousand).toFixed(2)}k`;
  }

  return intlNumberFormat(+value.toFixed(toFixed));
};
export const formatPrice = (
  n: number | null | undefined,
  currency?: string | null,
  digits?: number,
  options?: BigIntFormatterOptions
) => {
  const toFixed = digits ?? (currency && ['JPY', '円'].includes(currency) ? 0 : 2);

  return bigIntFormatter(n, toFixed, options);
};
export const capitalizeText = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
export const rateToApiFormat = (rate: number | string | null | undefined) => {
  if (typeof rate === 'number') {
    return rate / 100;
  } else if (typeof rate === 'string') {
    return Number(rate) / 100;
  } else {
    return null;
  }
};
export const rateToFormFormat = (rate: number | string | null | undefined) => (rate ? Number(rate) * 100 : '');
export const formatHashTag = (tag: string) => {
  const regex = /[ !"#$%&'()*+\-.,/:;<=>?@[\\\]^`{|}~]/g;

  return tag ? tag.replace(regex, '') : tag;
};
export const formatHashTags = (tags: readonly string[]) => tags.map((tag) => formatHashTag(tag));
// This work different with toFixed()
// https://www.codingem.com/javascript-how-to-limit-decimal-places/#:~:text=To%20limit%20decimal%20places%20in%20JavaScript%2C%20use%20the%20toFixed(),Converts%20it%20into%20a%20string.
export const limitDecimalDigits = (value?: string | number, digits = 10) =>
  value && Number(value)
    ? String(Math.round((Number(value) + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits))
    : '';
export const formatDecimalNumber = (value?: string | number) =>
  value && Number(value)
    ? String(value)
        .replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, '') // Remove lead zero: 01 -> 1 but keep 0 -> 0
        .replace(/(?<!(\.\d*|^.{0}))(?=(\d{3})+(?!\d))/g, ',') // Format 123456.123456 -> 123,456.123456
    : '';
export const numberFormatter = (value: any): string => value.toLocaleString();
export const tickFormatter = (value: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= NUMBERS_FOR_ROUNDING.billion) {
    return `${(value / NUMBERS_FOR_ROUNDING.billion).toFixed(2)}b`;
  } else if (absValue >= NUMBERS_FOR_ROUNDING.million) {
    return `${(value / NUMBERS_FOR_ROUNDING.million).toFixed(2)}m`;
  } else if (absValue >= NUMBERS_FOR_ROUNDING.ten_thousand) {
    return `${(value / NUMBERS_FOR_ROUNDING.thousand).toFixed()}k`;
  }

  return value.toString();
};
// TODO: remove later. We decided to use Order for sorting, but some APIs still use ORDER during refactoring
// We will remove after all APIs support Order type
export const convertOrderToORDER = (order?: Order | null): ORDER | null => {
  switch (order) {
    case Order.ASC:
      return ORDER.ASC;
    case Order.DESC:
      return ORDER.DESC;
    default:
      return null;
  }
};
export const getGrowthArrow = (value?: number | null) => {
  if ([0, undefined, null].includes(value)) {
    return '';
  }

  return Number(value) > 0 ? '\u2191' : '\u2193';
};
export const getGrowthPrefix = (value?: number | null) => {
  if ([0, undefined, null].includes(value)) {
    return '';
  }

  return Number(value) > 0 ? '+' : '';
};

export const NUMBERS_FOR_ROUNDING = {
  thousand: 1000,
  ten_thousand: 10000,
  million: 1000000,
  billion: 1000000000
};

const zeroPad = (num: number) => String(num).padStart(2, '0');
export const formatDurationToDaysHoursMinutesSeconds = (duration: number | null | undefined, t: TFunction) => {
  if (!duration) return '00:00:00';

  const durationObj = intervalToDuration({ start: 0, end: duration });
  let result = '';

  if (durationObj.days) {
    result += `${durationObj.days} ${durationObj.days === 1 ? t('day') : t('days')}, `;
  }

  return (
    result +
    `${zeroPad(durationObj.hours || 0)}:${zeroPad(durationObj.minutes || 0)}:${zeroPad(durationObj.seconds || 0)}`
  );
};
