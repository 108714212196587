/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NotFoundImport } from './routes/not-found'
import { Route as PublicRoutesImport } from './routes/_public-routes'
import { Route as PrivateRoutesImport } from './routes/_private-routes'
import { Route as IndexImport } from './routes/index'
import { Route as PublicRoutesTiktokOnboardingImport } from './routes/_public-routes/tiktok-onboarding'
import { Route as PublicRoutesTermsCombinedImport } from './routes/_public-routes/terms-combined'
import { Route as PublicRoutesTermsImport } from './routes/_public-routes/terms'
import { Route as PublicRoutesSignInImport } from './routes/_public-routes/sign-in'
import { Route as PublicRoutesRedirecttoImport } from './routes/_public-routes/redirect_to'
import { Route as PublicRoutesPrivacyImport } from './routes/_public-routes/privacy'
import { Route as PrivateRoutesYoutubeCmsImport } from './routes/_private-routes/youtube-cms'
import { Route as PrivateRoutesUserSettingsImport } from './routes/_private-routes/user-settings'
import { Route as PrivateRoutesUserImport } from './routes/_private-routes/user'
import { Route as PrivateRoutesTrendingImport } from './routes/_private-routes/trending'
import { Route as PrivateRoutesTiktokImport } from './routes/_private-routes/tiktok'
import { Route as PrivateRoutesTalentAgencyImport } from './routes/_private-routes/talent-agency'
import { Route as PrivateRoutesSettingsImport } from './routes/_private-routes/settings'
import { Route as PrivateRoutesReportImport } from './routes/_private-routes/report'
import { Route as PrivateRoutesPostsInsightImport } from './routes/_private-routes/posts-insight'
import { Route as PrivateRoutesPostsImport } from './routes/_private-routes/posts'
import { Route as PrivateRoutesPaymentsImport } from './routes/_private-routes/payments'
import { Route as PrivateRoutesPartnerImport } from './routes/_private-routes/partner'
import { Route as PrivateRoutesPackageImport } from './routes/_private-routes/package'
import { Route as PrivateRoutesInvoicesImport } from './routes/_private-routes/invoices'
import { Route as PrivateRoutesInfluencersImport } from './routes/_private-routes/influencers'
import { Route as PrivateRoutesInfluencerImport } from './routes/_private-routes/influencer'
import { Route as PrivateRoutesHashtagAnalyticsImport } from './routes/_private-routes/hashtag-analytics'
import { Route as PrivateRoutesChatDemoImport } from './routes/_private-routes/chat-demo'
import { Route as PrivateRoutesCampaignPostsDiscoveryImport } from './routes/_private-routes/campaign-posts-discovery'
import { Route as PrivateRoutesAutoManagedImport } from './routes/_private-routes/auto-managed'
import { Route as PrivateRoutesAssetPreviewImport } from './routes/_private-routes/asset-preview'
import { Route as PrivateRoutesAnalyticsImport } from './routes/_private-routes/analytics'
import { Route as PrivateRoutesAgencyImport } from './routes/_private-routes/agency'
import { Route as PrivateRoutesAdvertiserImport } from './routes/_private-routes/advertiser'
import { Route as PublicRoutesTiktokOnboardingIndexImport } from './routes/_public-routes/tiktok-onboarding/index'
import { Route as PublicRoutesSignUpIndexImport } from './routes/_public-routes/sign-up/index'
import { Route as PrivateRoutesYoutubeCmsIndexImport } from './routes/_private-routes/youtube-cms/index'
import { Route as PrivateRoutesUserIndexImport } from './routes/_private-routes/user/index'
import { Route as PrivateRoutesTrendingIndexImport } from './routes/_private-routes/trending/index'
import { Route as PrivateRoutesTalentAgencyIndexImport } from './routes/_private-routes/talent-agency/index'
import { Route as PrivateRoutesPostsInsightIndexImport } from './routes/_private-routes/posts-insight/index'
import { Route as PrivateRoutesPartnerIndexImport } from './routes/_private-routes/partner/index'
import { Route as PrivateRoutesPackageIndexImport } from './routes/_private-routes/package/index'
import { Route as PrivateRoutesMarketplaceIndexImport } from './routes/_private-routes/marketplace/index'
import { Route as PrivateRoutesInfluencersIndexImport } from './routes/_private-routes/influencers/index'
import { Route as PrivateRoutesInfluencerIndexImport } from './routes/_private-routes/influencer/index'
import { Route as PrivateRoutesHashtagAnalyticsIndexImport } from './routes/_private-routes/hashtag-analytics/index'
import { Route as PrivateRoutesEngagementIndexImport } from './routes/_private-routes/engagement/index'
import { Route as PrivateRoutesChatDemoIndexImport } from './routes/_private-routes/chat-demo/index'
import { Route as PrivateRoutesAutoManagedIndexImport } from './routes/_private-routes/auto-managed/index'
import { Route as PrivateRoutesAnalyticsIndexImport } from './routes/_private-routes/analytics/index'
import { Route as PrivateRoutesAgencyIndexImport } from './routes/_private-routes/agency/index'
import { Route as PrivateRoutesAdvertiserIndexImport } from './routes/_private-routes/advertiser/index'
import { Route as PublicRoutesTiktokOnboardingSuccessImport } from './routes/_public-routes/tiktok-onboarding/success'
import { Route as PublicRoutesTiktokOnboardingSelectPlanImport } from './routes/_public-routes/tiktok-onboarding/select-plan'
import { Route as PublicRoutesTiktokOnboardingPasswordEnterImport } from './routes/_public-routes/tiktok-onboarding/password-enter'
import { Route as PublicRoutesTiktokOnboardingAdAccountImport } from './routes/_public-routes/tiktok-onboarding/ad-account'
import { Route as PublicRoutesSignUpSuccessImport } from './routes/_public-routes/sign-up/success'
import { Route as PublicRoutesMkpReferralImport } from './routes/_public-routes/mkp.referral'
import { Route as PublicRoutesEggReferralImport } from './routes/_public-routes/egg.referral'
import { Route as PublicRoutesPasswordPasswordforgotImport } from './routes/_public-routes/_password/password_forgot'
import { Route as PrivateRoutesYoutubeCmsConfirmedRevenueImport } from './routes/_private-routes/youtube-cms/confirmed-revenue'
import { Route as PrivateRoutesYoutubeCmsCmsChannelIdImport } from './routes/_private-routes/youtube-cms/$cmsChannelId'
import { Route as PrivateRoutesUserLinkedImport } from './routes/_private-routes/user/linked'
import { Route as PrivateRoutesUserAddImport } from './routes/_private-routes/user/add'
import { Route as PrivateRoutesUserIdImport } from './routes/_private-routes/user/$id'
import { Route as PrivateRoutesTrendingPostsImport } from './routes/_private-routes/trending/posts'
import { Route as PrivateRoutesTrendingInfluencersImport } from './routes/_private-routes/trending/influencers'
import { Route as PrivateRoutesTiktokSubscriptionBillingImport } from './routes/_private-routes/tiktok/subscription-billing'
import { Route as PrivateRoutesTiktokFailedPaymentImport } from './routes/_private-routes/tiktok/failed-payment'
import { Route as PrivateRoutesTiktokCampaignsImport } from './routes/_private-routes/tiktok/campaigns'
import { Route as PrivateRoutesTiktokBudgetAllocationImport } from './routes/_private-routes/tiktok/budget-allocation'
import { Route as PrivateRoutesTiktokAdvertiserDetailsImport } from './routes/_private-routes/tiktok/advertiser-details'
import { Route as PrivateRoutesTiktokAdCampaignReportImport } from './routes/_private-routes/tiktok/ad-campaign-report'
import { Route as PrivateRoutesTiktokAdAccountsImport } from './routes/_private-routes/tiktok/ad-accounts'
import { Route as PrivateRoutesTalentAgencyAddImport } from './routes/_private-routes/talent-agency/add'
import { Route as PrivateRoutesTalentAgencyIdImport } from './routes/_private-routes/talent-agency/$id'
import { Route as PrivateRoutesPostsInsightIdImport } from './routes/_private-routes/posts-insight/$id'
import { Route as PrivateRoutesPartnerIdImport } from './routes/_private-routes/partner/$id'
import { Route as PrivateRoutesPackageAddImport } from './routes/_private-routes/package/add'
import { Route as PrivateRoutesPackageIdImport } from './routes/_private-routes/package/$id'
import { Route as PrivateRoutesMarketplaceReorderImport } from './routes/_private-routes/marketplace/reorder'
import { Route as PrivateRoutesMarketplaceAddImport } from './routes/_private-routes/marketplace/add'
import { Route as PrivateRoutesMarketplaceIdImport } from './routes/_private-routes/marketplace/$id'
import { Route as PrivateRoutesInfluencersSearchImport } from './routes/_private-routes/influencers/search'
import { Route as PrivateRoutesInfluencersProposalImport } from './routes/_private-routes/influencers/proposal'
import { Route as PrivateRoutesInfluencerAddImport } from './routes/_private-routes/influencer/add'
import { Route as PrivateRoutesInfluencerIdImport } from './routes/_private-routes/influencer/$id'
import { Route as PrivateRoutesHashtagAnalyticsInstagramImport } from './routes/_private-routes/hashtag-analytics/instagram'
import { Route as PrivateRoutesEngagementAddImport } from './routes/_private-routes/engagement/add'
import { Route as PrivateRoutesEngagementIdImport } from './routes/_private-routes/engagement/$id'
import { Route as PrivateRoutesChatDemoChatImport } from './routes/_private-routes/chat-demo/chat'
import { Route as PrivateRoutesAutoManagedIdImport } from './routes/_private-routes/auto-managed/$id'
import { Route as PrivateRoutesAnalyticsAddImport } from './routes/_private-routes/analytics/add'
import { Route as PrivateRoutesAgencyAddImport } from './routes/_private-routes/agency/add'
import { Route as PrivateRoutesAgencyIdImport } from './routes/_private-routes/agency/$id'
import { Route as PrivateRoutesAdvertiserAddImport } from './routes/_private-routes/advertiser/add'
import { Route as PrivateRoutesAdvertiserIdImport } from './routes/_private-routes/advertiser/$id'
import { Route as PrivateRoutesTiktokCampaignsIndexImport } from './routes/_private-routes/tiktok/campaigns/index'
import { Route as PrivateRoutesTiktokAdCampaignReportIndexImport } from './routes/_private-routes/tiktok/ad-campaign-report/index'
import { Route as PrivateRoutesTalentAgencyIdIndexImport } from './routes/_private-routes/talent-agency/$id/index'
import { Route as PrivateRoutesPartnerIdIndexImport } from './routes/_private-routes/partner/$id/index'
import { Route as PrivateRoutesPackageIdIndexImport } from './routes/_private-routes/package/$id/index'
import { Route as PrivateRoutesMarketplaceAddIndexImport } from './routes/_private-routes/marketplace/add/index'
import { Route as PrivateRoutesMarketplaceIdIndexImport } from './routes/_private-routes/marketplace/$id/index'
import { Route as PrivateRoutesInfluencersSearchIndexImport } from './routes/_private-routes/influencers/search/index'
import { Route as PrivateRoutesInfluencerIdIndexImport } from './routes/_private-routes/influencer/$id/index'
import { Route as PrivateRoutesHashtagAnalyticsTiktokIndexImport } from './routes/_private-routes/hashtag-analytics/tiktok/index'
import { Route as PrivateRoutesEngagementIdIndexImport } from './routes/_private-routes/engagement/$id/index'
import { Route as PrivateRoutesAutoManagedIdIndexImport } from './routes/_private-routes/auto-managed/$id/index'
import { Route as PrivateRoutesAnalyticsBrandAccountIdIndexImport } from './routes/_private-routes/analytics/$brandAccountId/index'
import { Route as PrivateRoutesAgencyIdIndexImport } from './routes/_private-routes/agency/$id/index'
import { Route as PrivateRoutesAdvertiserIdIndexImport } from './routes/_private-routes/advertiser/$id/index'
import { Route as PublicRoutesTiktokOnboardingPasswordRegisterTokenImport } from './routes/_public-routes/tiktok-onboarding/password-register.$token'
import { Route as PublicRoutesPasswordPasswordresetTokenImport } from './routes/_public-routes/_password/password_reset.$token'
import { Route as PublicRoutesPasswordPasswordregisterTokenImport } from './routes/_public-routes/_password/password_register.$token'
import { Route as PrivateRoutesTiktokCampaignsReviewInfluencersImport } from './routes/_private-routes/tiktok/campaigns/review-influencers'
import { Route as PrivateRoutesTiktokCampaignsAddImport } from './routes/_private-routes/tiktok/campaigns/add'
import { Route as PrivateRoutesTiktokCampaignsIdImport } from './routes/_private-routes/tiktok/campaigns/$id'
import { Route as PrivateRoutesTalentAgencyIdUsersImport } from './routes/_private-routes/talent-agency/$id/users'
import { Route as PrivateRoutesTalentAgencyIdBusinessImport } from './routes/_private-routes/talent-agency/$id/business'
import { Route as PrivateRoutesRedirectTalentSigninProviderImport } from './routes/_private-routes/redirect/talent-signin.$provider'
import { Route as PrivateRoutesRedirectTalentAnalyticsConnectProviderImport } from './routes/_private-routes/redirect/talent-analytics-connect.$provider'
import { Route as PrivateRoutesRedirectHiddenYoutubeListConnectImport } from './routes/_private-routes/redirect/hidden.youtube-list-connect'
import { Route as PrivateRoutesRedirectConnectTiktokBusinessImport } from './routes/_private-routes/redirect/connect.tiktok-business'
import { Route as PrivateRoutesRedirectAnalyticsSigninProviderImport } from './routes/_private-routes/redirect/analytics-signin.$provider'
import { Route as PrivateRoutesRedirectAnalyticsListConnectProviderImport } from './routes/_private-routes/redirect/analytics-list-connect.$provider'
import { Route as PrivateRoutesRedirectAnalyticsConnectProviderImport } from './routes/_private-routes/redirect/analytics-connect.$provider'
import { Route as PrivateRoutesPostsInsightIdPostIdImport } from './routes/_private-routes/posts-insight/$id.$postId'
import { Route as PrivateRoutesPartnerIdUsersImport } from './routes/_private-routes/partner/$id/users'
import { Route as PrivateRoutesPartnerIdBusinessImport } from './routes/_private-routes/partner/$id/business'
import { Route as PrivateRoutesPackageIdContentImport } from './routes/_private-routes/package/$id/content'
import { Route as PrivateRoutesMarketplaceAddUrlTrackingImport } from './routes/_private-routes/marketplace/add/url-tracking'
import { Route as PrivateRoutesMarketplaceAddSocialMediaTrackingImport } from './routes/_private-routes/marketplace/add/social-media-tracking'
import { Route as PrivateRoutesMarketplaceAddReferralCodeTrackingImport } from './routes/_private-routes/marketplace/add/referral-code-tracking'
import { Route as PrivateRoutesMarketplaceIdTrackingReferralCodesImport } from './routes/_private-routes/marketplace/$id/tracking-referral-codes'
import { Route as PrivateRoutesMarketplaceIdTrackingImport } from './routes/_private-routes/marketplace/$id/tracking'
import { Route as PrivateRoutesMarketplaceIdReportAffiliateImport } from './routes/_private-routes/marketplace/$id/report-affiliate'
import { Route as PrivateRoutesMarketplaceIdReportImport } from './routes/_private-routes/marketplace/$id/report'
import { Route as PrivateRoutesMarketplaceIdPostsImport } from './routes/_private-routes/marketplace/$id/posts'
import { Route as PrivateRoutesMarketplaceIdJoinedInfluencersImport } from './routes/_private-routes/marketplace/$id/joined-influencers'
import { Route as PrivateRoutesMarketplaceIdInfluencerImport } from './routes/_private-routes/marketplace/$id/influencer'
import { Route as PrivateRoutesMarketplaceIdCommissionsImport } from './routes/_private-routes/marketplace/$id/commissions'
import { Route as PrivateRoutesMarketplaceIdApplicantImport } from './routes/_private-routes/marketplace/$id/applicant'
import { Route as PrivateRoutesInfluencersSearchYoutubeImport } from './routes/_private-routes/influencers/search/youtube'
import { Route as PrivateRoutesInfluencersSearchXhsImport } from './routes/_private-routes/influencers/search/xhs'
import { Route as PrivateRoutesInfluencersSearchTwitterImport } from './routes/_private-routes/influencers/search/twitter'
import { Route as PrivateRoutesInfluencersSearchTiktokImport } from './routes/_private-routes/influencers/search/tiktok'
import { Route as PrivateRoutesInfluencersSearchThreadsImport } from './routes/_private-routes/influencers/search/threads'
import { Route as PrivateRoutesInfluencersSearchInstagramImport } from './routes/_private-routes/influencers/search/instagram'
import { Route as PrivateRoutesInfluencersSearchFacebookpageImport } from './routes/_private-routes/influencers/search/facebook_page'
import { Route as PrivateRoutesInfluencersSearchFacebookImport } from './routes/_private-routes/influencers/search/facebook'
import { Route as PrivateRoutesInfluencersSearchDouyinImport } from './routes/_private-routes/influencers/search/douyin'
import { Route as PrivateRoutesInfluencerIdProfileImport } from './routes/_private-routes/influencer/$id/profile'
import { Route as PrivateRoutesHashtagAnalyticsTiktokKeywordImport } from './routes/_private-routes/hashtag-analytics/tiktok/keyword'
import { Route as PrivateRoutesHashtagAnalyticsTiktokHashtagImport } from './routes/_private-routes/hashtag-analytics/tiktok/hashtag'
import { Route as PrivateRoutesEngagementIdTrackingReferralCodesImport } from './routes/_private-routes/engagement/$id/tracking-referral-codes'
import { Route as PrivateRoutesEngagementIdTrackingImport } from './routes/_private-routes/engagement/$id/tracking'
import { Route as PrivateRoutesEngagementIdReportImport } from './routes/_private-routes/engagement/$id/report'
import { Route as PrivateRoutesEngagementIdProposalImport } from './routes/_private-routes/engagement/$id/proposal'
import { Route as PrivateRoutesEngagementIdPostsImport } from './routes/_private-routes/engagement/$id/posts'
import { Route as PrivateRoutesEngagementIdInfluencerImport } from './routes/_private-routes/engagement/$id/influencer'
import { Route as PrivateRoutesAutoManagedIdReportImport } from './routes/_private-routes/auto-managed/$id/report'
import { Route as PrivateRoutesAutoManagedIdJoinedInfluencersImport } from './routes/_private-routes/auto-managed/$id/joined-influencers'
import { Route as PrivateRoutesAutoManagedIdCommissionsImport } from './routes/_private-routes/auto-managed/$id/commissions'
import { Route as PrivateRoutesAgencyIdUsersImport } from './routes/_private-routes/agency/$id/users'
import { Route as PrivateRoutesAgencyIdBusinessImport } from './routes/_private-routes/agency/$id/business'
import { Route as PrivateRoutesAdvertiserIdUsersImport } from './routes/_private-routes/advertiser/$id/users'
import { Route as PrivateRoutesAdvertiserIdBudgetAllocationImport } from './routes/_private-routes/advertiser/$id/budget-allocation'
import { Route as PrivateRoutesTiktokCampaignsIdIndexImport } from './routes/_private-routes/tiktok/campaigns/$id/index'
import { Route as PrivateRoutesTiktokAdCampaignReportIdIndexImport } from './routes/_private-routes/tiktok/ad-campaign-report/$id/index'
import { Route as PrivateRoutesPackageIdContentIndexImport } from './routes/_private-routes/package/$id/content/index'
import { Route as PrivateRoutesMarketplaceAddECommerceIndexImport } from './routes/_private-routes/marketplace/add/e-commerce/index'
import { Route as PrivateRoutesMarketplaceIdPostsIndexImport } from './routes/_private-routes/marketplace/$id/posts/index'
import { Route as PrivateRoutesInfluencerIdProfileIndexImport } from './routes/_private-routes/influencer/$id/profile/index'
import { Route as PrivateRoutesEngagementIdPostsIndexImport } from './routes/_private-routes/engagement/$id/posts/index'
import { Route as PrivateRoutesEngagementIdInfluencerIndexImport } from './routes/_private-routes/engagement/$id/influencer/index'
import { Route as PrivateRoutesTiktokCampaignsIdReportImport } from './routes/_private-routes/tiktok/campaigns/$id/report'
import { Route as PrivateRoutesTiktokCampaignsIdPostsImport } from './routes/_private-routes/tiktok/campaigns/$id/posts'
import { Route as PrivateRoutesTiktokCampaignsIdInfluencersImport } from './routes/_private-routes/tiktok/campaigns/$id/influencers'
import { Route as PrivateRoutesTiktokAdCampaignReportIdGroupIdImport } from './routes/_private-routes/tiktok/ad-campaign-report/$id/$groupId'
import { Route as PrivateRoutesPackageIdContentSearchImport } from './routes/_private-routes/package/$id/content/search'
import { Route as PrivateRoutesPackageIdContentListImport } from './routes/_private-routes/package/$id/content/list'
import { Route as PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdImport } from './routes/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId'
import { Route as PrivateRoutesMarketplaceIdPostsPostIdImport } from './routes/_private-routes/marketplace/$id/posts/$postId'
import { Route as PrivateRoutesInfluencerIdProfileCompareImport } from './routes/_private-routes/influencer/$id/profile/compare'
import { Route as PrivateRoutesInfluencerIdProfileCampaignsImport } from './routes/_private-routes/influencer/$id/profile/campaigns'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdShortsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/shorts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post'
import { Route as PrivateRoutesEngagementIdPostsAddImport } from './routes/_private-routes/engagement/$id/posts/add'
import { Route as PrivateRoutesEngagementIdPostsPostIdImport } from './routes/_private-routes/engagement/$id/posts/$postId'
import { Route as PrivateRoutesEngagementIdInfluencerYoutubeImport } from './routes/_private-routes/engagement/$id/influencer/youtube'
import { Route as PrivateRoutesEngagementIdInfluencerXhsImport } from './routes/_private-routes/engagement/$id/influencer/xhs'
import { Route as PrivateRoutesEngagementIdInfluencerTwitterImport } from './routes/_private-routes/engagement/$id/influencer/twitter'
import { Route as PrivateRoutesEngagementIdInfluencerTiktokImport } from './routes/_private-routes/engagement/$id/influencer/tiktok'
import { Route as PrivateRoutesEngagementIdInfluencerThreadsImport } from './routes/_private-routes/engagement/$id/influencer/threads'
import { Route as PrivateRoutesEngagementIdInfluencerPostsImport } from './routes/_private-routes/engagement/$id/influencer/posts'
import { Route as PrivateRoutesEngagementIdInfluencerPackagesImport } from './routes/_private-routes/engagement/$id/influencer/packages'
import { Route as PrivateRoutesEngagementIdInfluencerInstagramImport } from './routes/_private-routes/engagement/$id/influencer/instagram'
import { Route as PrivateRoutesEngagementIdInfluencerFacebookpageImport } from './routes/_private-routes/engagement/$id/influencer/facebook_page'
import { Route as PrivateRoutesEngagementIdInfluencerFacebookImport } from './routes/_private-routes/engagement/$id/influencer/facebook'
import { Route as PrivateRoutesEngagementIdInfluencerDouyinImport } from './routes/_private-routes/engagement/$id/influencer/douyin'
import { Route as PrivateRoutesEngagementIdInfluencerCampaignsImport } from './routes/_private-routes/engagement/$id/influencer/campaigns'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
import { Route as PrivateRoutesTiktokCampaignsIdPostsIndexImport } from './routes/_private-routes/tiktok/campaigns/$id/posts/index'
import { Route as PrivateRoutesPackageIdContentSearchIndexImport } from './routes/_private-routes/package/$id/content/search/index'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/index'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/index'
import { Route as PrivateRoutesTiktokCampaignsIdPostsPostIdImport } from './routes/_private-routes/tiktok/campaigns/$id/posts/$postId'
import { Route as PrivateRoutesPackageIdContentSearchYoutubeImport } from './routes/_private-routes/package/$id/content/search/youtube'
import { Route as PrivateRoutesPackageIdContentSearchXhsImport } from './routes/_private-routes/package/$id/content/search/xhs'
import { Route as PrivateRoutesPackageIdContentSearchTwitterImport } from './routes/_private-routes/package/$id/content/search/twitter'
import { Route as PrivateRoutesPackageIdContentSearchTiktokImport } from './routes/_private-routes/package/$id/content/search/tiktok'
import { Route as PrivateRoutesPackageIdContentSearchThreadsImport } from './routes/_private-routes/package/$id/content/search/threads'
import { Route as PrivateRoutesPackageIdContentSearchInstagramImport } from './routes/_private-routes/package/$id/content/search/instagram'
import { Route as PrivateRoutesPackageIdContentSearchFacebookpageImport } from './routes/_private-routes/package/$id/content/search/facebook_page'
import { Route as PrivateRoutesPackageIdContentSearchFacebookImport } from './routes/_private-routes/package/$id/content/search/facebook'
import { Route as PrivateRoutesPackageIdContentSearchDouyinImport } from './routes/_private-routes/package/$id/content/search/douyin'
import { Route as PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdImport } from './routes/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/index'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/index'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/index'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
import { Route as PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostImport } from './routes/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'

// Create/Update Routes

const NotFoundRoute = NotFoundImport.update({
  id: '/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any)

const PublicRoutesRoute = PublicRoutesImport.update({
  id: '/_public-routes',
  getParentRoute: () => rootRoute,
} as any)

const PrivateRoutesRoute = PrivateRoutesImport.update({
  id: '/_private-routes',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PublicRoutesTiktokOnboardingRoute =
  PublicRoutesTiktokOnboardingImport.update({
    id: '/tiktok-onboarding',
    path: '/tiktok-onboarding',
    getParentRoute: () => PublicRoutesRoute,
  } as any)

const PublicRoutesTermsCombinedRoute = PublicRoutesTermsCombinedImport.update({
  id: '/terms-combined',
  path: '/terms-combined',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesTermsRoute = PublicRoutesTermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesSignInRoute = PublicRoutesSignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesRedirecttoRoute = PublicRoutesRedirecttoImport.update({
  id: '/redirect_to',
  path: '/redirect_to',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesPrivacyRoute = PublicRoutesPrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PrivateRoutesYoutubeCmsRoute = PrivateRoutesYoutubeCmsImport.update({
  id: '/youtube-cms',
  path: '/youtube-cms',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesUserSettingsRoute = PrivateRoutesUserSettingsImport.update({
  id: '/user-settings',
  path: '/user-settings',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesUserRoute = PrivateRoutesUserImport.update({
  id: '/user',
  path: '/user',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesTrendingRoute = PrivateRoutesTrendingImport.update({
  id: '/trending',
  path: '/trending',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesTiktokRoute = PrivateRoutesTiktokImport.update({
  id: '/tiktok',
  path: '/tiktok',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesTalentAgencyRoute = PrivateRoutesTalentAgencyImport.update({
  id: '/talent-agency',
  path: '/talent-agency',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesSettingsRoute = PrivateRoutesSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesReportRoute = PrivateRoutesReportImport.update({
  id: '/report',
  path: '/report',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesPostsInsightRoute = PrivateRoutesPostsInsightImport.update({
  id: '/posts-insight',
  path: '/posts-insight',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesPostsRoute = PrivateRoutesPostsImport.update({
  id: '/posts',
  path: '/posts',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesPaymentsRoute = PrivateRoutesPaymentsImport.update({
  id: '/payments',
  path: '/payments',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesPartnerRoute = PrivateRoutesPartnerImport.update({
  id: '/partner',
  path: '/partner',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesPackageRoute = PrivateRoutesPackageImport.update({
  id: '/package',
  path: '/package',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesInvoicesRoute = PrivateRoutesInvoicesImport.update({
  id: '/invoices',
  path: '/invoices',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesInfluencersRoute = PrivateRoutesInfluencersImport.update({
  id: '/influencers',
  path: '/influencers',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesInfluencerRoute = PrivateRoutesInfluencerImport.update({
  id: '/influencer',
  path: '/influencer',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesHashtagAnalyticsRoute =
  PrivateRoutesHashtagAnalyticsImport.update({
    id: '/hashtag-analytics',
    path: '/hashtag-analytics',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesChatDemoRoute = PrivateRoutesChatDemoImport.update({
  id: '/chat-demo',
  path: '/chat-demo',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesCampaignPostsDiscoveryRoute =
  PrivateRoutesCampaignPostsDiscoveryImport.update({
    id: '/campaign-posts-discovery',
    path: '/campaign-posts-discovery',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesAutoManagedRoute = PrivateRoutesAutoManagedImport.update({
  id: '/auto-managed',
  path: '/auto-managed',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesAssetPreviewRoute = PrivateRoutesAssetPreviewImport.update({
  id: '/asset-preview',
  path: '/asset-preview',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesAnalyticsRoute = PrivateRoutesAnalyticsImport.update({
  id: '/analytics',
  path: '/analytics',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesAgencyRoute = PrivateRoutesAgencyImport.update({
  id: '/agency',
  path: '/agency',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesAdvertiserRoute = PrivateRoutesAdvertiserImport.update({
  id: '/advertiser',
  path: '/advertiser',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PublicRoutesTiktokOnboardingIndexRoute =
  PublicRoutesTiktokOnboardingIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesSignUpIndexRoute = PublicRoutesSignUpIndexImport.update({
  id: '/sign-up/',
  path: '/sign-up/',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PrivateRoutesYoutubeCmsIndexRoute =
  PrivateRoutesYoutubeCmsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesYoutubeCmsRoute,
  } as any)

const PrivateRoutesUserIndexRoute = PrivateRoutesUserIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoutesUserRoute,
} as any)

const PrivateRoutesTrendingIndexRoute = PrivateRoutesTrendingIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTrendingRoute,
  } as any,
)

const PrivateRoutesTalentAgencyIndexRoute =
  PrivateRoutesTalentAgencyIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTalentAgencyRoute,
  } as any)

const PrivateRoutesPostsInsightIndexRoute =
  PrivateRoutesPostsInsightIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesPostsInsightRoute,
  } as any)

const PrivateRoutesPartnerIndexRoute = PrivateRoutesPartnerIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoutesPartnerRoute,
} as any)

const PrivateRoutesPackageIndexRoute = PrivateRoutesPackageIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoutesPackageRoute,
} as any)

const PrivateRoutesMarketplaceIndexRoute =
  PrivateRoutesMarketplaceIndexImport.update({
    id: '/marketplace/',
    path: '/marketplace/',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesInfluencersIndexRoute =
  PrivateRoutesInfluencersIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesInfluencersRoute,
  } as any)

const PrivateRoutesInfluencerIndexRoute =
  PrivateRoutesInfluencerIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesInfluencerRoute,
  } as any)

const PrivateRoutesHashtagAnalyticsIndexRoute =
  PrivateRoutesHashtagAnalyticsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesHashtagAnalyticsRoute,
  } as any)

const PrivateRoutesEngagementIndexRoute =
  PrivateRoutesEngagementIndexImport.update({
    id: '/engagement/',
    path: '/engagement/',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesChatDemoIndexRoute = PrivateRoutesChatDemoIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesChatDemoRoute,
  } as any,
)

const PrivateRoutesAutoManagedIndexRoute =
  PrivateRoutesAutoManagedIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAutoManagedRoute,
  } as any)

const PrivateRoutesAnalyticsIndexRoute =
  PrivateRoutesAnalyticsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAnalyticsRoute,
  } as any)

const PrivateRoutesAgencyIndexRoute = PrivateRoutesAgencyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoutesAgencyRoute,
} as any)

const PrivateRoutesAdvertiserIndexRoute =
  PrivateRoutesAdvertiserIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAdvertiserRoute,
  } as any)

const PublicRoutesTiktokOnboardingSuccessRoute =
  PublicRoutesTiktokOnboardingSuccessImport.update({
    id: '/success',
    path: '/success',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesTiktokOnboardingSelectPlanRoute =
  PublicRoutesTiktokOnboardingSelectPlanImport.update({
    id: '/select-plan',
    path: '/select-plan',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesTiktokOnboardingPasswordEnterRoute =
  PublicRoutesTiktokOnboardingPasswordEnterImport.update({
    id: '/password-enter',
    path: '/password-enter',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesTiktokOnboardingAdAccountRoute =
  PublicRoutesTiktokOnboardingAdAccountImport.update({
    id: '/ad-account',
    path: '/ad-account',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesSignUpSuccessRoute = PublicRoutesSignUpSuccessImport.update({
  id: '/sign-up/success',
  path: '/sign-up/success',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesMkpReferralRoute = PublicRoutesMkpReferralImport.update({
  id: '/mkp/referral',
  path: '/mkp/referral',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesEggReferralRoute = PublicRoutesEggReferralImport.update({
  id: '/egg/referral',
  path: '/egg/referral',
  getParentRoute: () => PublicRoutesRoute,
} as any)

const PublicRoutesPasswordPasswordforgotRoute =
  PublicRoutesPasswordPasswordforgotImport.update({
    id: '/_password/password_forgot',
    path: '/password_forgot',
    getParentRoute: () => PublicRoutesRoute,
  } as any)

const PrivateRoutesYoutubeCmsConfirmedRevenueRoute =
  PrivateRoutesYoutubeCmsConfirmedRevenueImport.update({
    id: '/confirmed-revenue',
    path: '/confirmed-revenue',
    getParentRoute: () => PrivateRoutesYoutubeCmsRoute,
  } as any)

const PrivateRoutesYoutubeCmsCmsChannelIdRoute =
  PrivateRoutesYoutubeCmsCmsChannelIdImport.update({
    id: '/$cmsChannelId',
    path: '/$cmsChannelId',
    getParentRoute: () => PrivateRoutesYoutubeCmsRoute,
  } as any)

const PrivateRoutesUserLinkedRoute = PrivateRoutesUserLinkedImport.update({
  id: '/linked',
  path: '/linked',
  getParentRoute: () => PrivateRoutesUserRoute,
} as any)

const PrivateRoutesUserAddRoute = PrivateRoutesUserAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => PrivateRoutesUserRoute,
} as any)

const PrivateRoutesUserIdRoute = PrivateRoutesUserIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesUserRoute,
} as any)

const PrivateRoutesTrendingPostsRoute = PrivateRoutesTrendingPostsImport.update(
  {
    id: '/posts',
    path: '/posts',
    getParentRoute: () => PrivateRoutesTrendingRoute,
  } as any,
)

const PrivateRoutesTrendingInfluencersRoute =
  PrivateRoutesTrendingInfluencersImport.update({
    id: '/influencers',
    path: '/influencers',
    getParentRoute: () => PrivateRoutesTrendingRoute,
  } as any)

const PrivateRoutesTiktokSubscriptionBillingRoute =
  PrivateRoutesTiktokSubscriptionBillingImport.update({
    id: '/subscription-billing',
    path: '/subscription-billing',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokFailedPaymentRoute =
  PrivateRoutesTiktokFailedPaymentImport.update({
    id: '/failed-payment',
    path: '/failed-payment',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokCampaignsRoute =
  PrivateRoutesTiktokCampaignsImport.update({
    id: '/campaigns',
    path: '/campaigns',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokBudgetAllocationRoute =
  PrivateRoutesTiktokBudgetAllocationImport.update({
    id: '/budget-allocation',
    path: '/budget-allocation',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokAdvertiserDetailsRoute =
  PrivateRoutesTiktokAdvertiserDetailsImport.update({
    id: '/advertiser-details',
    path: '/advertiser-details',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokAdCampaignReportRoute =
  PrivateRoutesTiktokAdCampaignReportImport.update({
    id: '/ad-campaign-report',
    path: '/ad-campaign-report',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTiktokAdAccountsRoute =
  PrivateRoutesTiktokAdAccountsImport.update({
    id: '/ad-accounts',
    path: '/ad-accounts',
    getParentRoute: () => PrivateRoutesTiktokRoute,
  } as any)

const PrivateRoutesTalentAgencyAddRoute =
  PrivateRoutesTalentAgencyAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => PrivateRoutesTalentAgencyRoute,
  } as any)

const PrivateRoutesTalentAgencyIdRoute =
  PrivateRoutesTalentAgencyIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => PrivateRoutesTalentAgencyRoute,
  } as any)

const PrivateRoutesPostsInsightIdRoute =
  PrivateRoutesPostsInsightIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => PrivateRoutesPostsInsightRoute,
  } as any)

const PrivateRoutesPartnerIdRoute = PrivateRoutesPartnerIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesPartnerRoute,
} as any)

const PrivateRoutesPackageAddRoute = PrivateRoutesPackageAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => PrivateRoutesPackageRoute,
} as any)

const PrivateRoutesPackageIdRoute = PrivateRoutesPackageIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesPackageRoute,
} as any)

const PrivateRoutesMarketplaceReorderRoute =
  PrivateRoutesMarketplaceReorderImport.update({
    id: '/marketplace/reorder',
    path: '/marketplace/reorder',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesMarketplaceAddRoute =
  PrivateRoutesMarketplaceAddImport.update({
    id: '/marketplace/add',
    path: '/marketplace/add',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesMarketplaceIdRoute = PrivateRoutesMarketplaceIdImport.update(
  {
    id: '/marketplace/$id',
    path: '/marketplace/$id',
    getParentRoute: () => PrivateRoutesRoute,
  } as any,
)

const PrivateRoutesInfluencersSearchRoute =
  PrivateRoutesInfluencersSearchImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => PrivateRoutesInfluencersRoute,
  } as any)

const PrivateRoutesInfluencersProposalRoute =
  PrivateRoutesInfluencersProposalImport.update({
    id: '/proposal',
    path: '/proposal',
    getParentRoute: () => PrivateRoutesInfluencersRoute,
  } as any)

const PrivateRoutesInfluencerAddRoute = PrivateRoutesInfluencerAddImport.update(
  {
    id: '/add',
    path: '/add',
    getParentRoute: () => PrivateRoutesInfluencerRoute,
  } as any,
)

const PrivateRoutesInfluencerIdRoute = PrivateRoutesInfluencerIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesInfluencerRoute,
} as any)

const PrivateRoutesHashtagAnalyticsInstagramRoute =
  PrivateRoutesHashtagAnalyticsInstagramImport.update({
    id: '/instagram',
    path: '/instagram',
    getParentRoute: () => PrivateRoutesHashtagAnalyticsRoute,
  } as any)

const PrivateRoutesEngagementAddRoute = PrivateRoutesEngagementAddImport.update(
  {
    id: '/engagement/add',
    path: '/engagement/add',
    getParentRoute: () => PrivateRoutesRoute,
  } as any,
)

const PrivateRoutesEngagementIdRoute = PrivateRoutesEngagementIdImport.update({
  id: '/engagement/$id',
  path: '/engagement/$id',
  getParentRoute: () => PrivateRoutesRoute,
} as any)

const PrivateRoutesChatDemoChatRoute = PrivateRoutesChatDemoChatImport.update({
  id: '/chat',
  path: '/chat',
  getParentRoute: () => PrivateRoutesChatDemoRoute,
} as any)

const PrivateRoutesAutoManagedIdRoute = PrivateRoutesAutoManagedIdImport.update(
  {
    id: '/$id',
    path: '/$id',
    getParentRoute: () => PrivateRoutesAutoManagedRoute,
  } as any,
)

const PrivateRoutesAnalyticsAddRoute = PrivateRoutesAnalyticsAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => PrivateRoutesAnalyticsRoute,
} as any)

const PrivateRoutesAgencyAddRoute = PrivateRoutesAgencyAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => PrivateRoutesAgencyRoute,
} as any)

const PrivateRoutesAgencyIdRoute = PrivateRoutesAgencyIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesAgencyRoute,
} as any)

const PrivateRoutesAdvertiserAddRoute = PrivateRoutesAdvertiserAddImport.update(
  {
    id: '/add',
    path: '/add',
    getParentRoute: () => PrivateRoutesAdvertiserRoute,
  } as any,
)

const PrivateRoutesAdvertiserIdRoute = PrivateRoutesAdvertiserIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => PrivateRoutesAdvertiserRoute,
} as any)

const PrivateRoutesTiktokCampaignsIndexRoute =
  PrivateRoutesTiktokCampaignsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTiktokCampaignsRoute,
  } as any)

const PrivateRoutesTiktokAdCampaignReportIndexRoute =
  PrivateRoutesTiktokAdCampaignReportIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTiktokAdCampaignReportRoute,
  } as any)

const PrivateRoutesTalentAgencyIdIndexRoute =
  PrivateRoutesTalentAgencyIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTalentAgencyIdRoute,
  } as any)

const PrivateRoutesPartnerIdIndexRoute =
  PrivateRoutesPartnerIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesPartnerIdRoute,
  } as any)

const PrivateRoutesPackageIdIndexRoute =
  PrivateRoutesPackageIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesPackageIdRoute,
  } as any)

const PrivateRoutesMarketplaceAddIndexRoute =
  PrivateRoutesMarketplaceAddIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceIdIndexRoute =
  PrivateRoutesMarketplaceIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesInfluencersSearchIndexRoute =
  PrivateRoutesInfluencersSearchIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencerIdIndexRoute =
  PrivateRoutesInfluencerIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesHashtagAnalyticsTiktokIndexRoute =
  PrivateRoutesHashtagAnalyticsTiktokIndexImport.update({
    id: '/tiktok/',
    path: '/tiktok/',
    getParentRoute: () => PrivateRoutesHashtagAnalyticsRoute,
  } as any)

const PrivateRoutesEngagementIdIndexRoute =
  PrivateRoutesEngagementIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesAutoManagedIdIndexRoute =
  PrivateRoutesAutoManagedIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAutoManagedIdRoute,
  } as any)

const PrivateRoutesAnalyticsBrandAccountIdIndexRoute =
  PrivateRoutesAnalyticsBrandAccountIdIndexImport.update({
    id: '/$brandAccountId/',
    path: '/$brandAccountId/',
    getParentRoute: () => PrivateRoutesAnalyticsRoute,
  } as any)

const PrivateRoutesAgencyIdIndexRoute = PrivateRoutesAgencyIdIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAgencyIdRoute,
  } as any,
)

const PrivateRoutesAdvertiserIdIndexRoute =
  PrivateRoutesAdvertiserIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesAdvertiserIdRoute,
  } as any)

const PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute =
  PublicRoutesTiktokOnboardingPasswordRegisterTokenImport.update({
    id: '/password-register/$token',
    path: '/password-register/$token',
    getParentRoute: () => PublicRoutesTiktokOnboardingRoute,
  } as any)

const PublicRoutesPasswordPasswordresetTokenRoute =
  PublicRoutesPasswordPasswordresetTokenImport.update({
    id: '/_password/password_reset/$token',
    path: '/password_reset/$token',
    getParentRoute: () => PublicRoutesRoute,
  } as any)

const PublicRoutesPasswordPasswordregisterTokenRoute =
  PublicRoutesPasswordPasswordregisterTokenImport.update({
    id: '/_password/password_register/$token',
    path: '/password_register/$token',
    getParentRoute: () => PublicRoutesRoute,
  } as any)

const PrivateRoutesTiktokCampaignsReviewInfluencersRoute =
  PrivateRoutesTiktokCampaignsReviewInfluencersImport.update({
    id: '/review-influencers',
    path: '/review-influencers',
    getParentRoute: () => PrivateRoutesTiktokCampaignsRoute,
  } as any)

const PrivateRoutesTiktokCampaignsAddRoute =
  PrivateRoutesTiktokCampaignsAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => PrivateRoutesTiktokCampaignsRoute,
  } as any)

const PrivateRoutesTiktokCampaignsIdRoute =
  PrivateRoutesTiktokCampaignsIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => PrivateRoutesTiktokCampaignsRoute,
  } as any)

const PrivateRoutesTalentAgencyIdUsersRoute =
  PrivateRoutesTalentAgencyIdUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => PrivateRoutesTalentAgencyIdRoute,
  } as any)

const PrivateRoutesTalentAgencyIdBusinessRoute =
  PrivateRoutesTalentAgencyIdBusinessImport.update({
    id: '/business',
    path: '/business',
    getParentRoute: () => PrivateRoutesTalentAgencyIdRoute,
  } as any)

const PrivateRoutesRedirectTalentSigninProviderRoute =
  PrivateRoutesRedirectTalentSigninProviderImport.update({
    id: '/redirect/talent-signin/$provider',
    path: '/redirect/talent-signin/$provider',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute =
  PrivateRoutesRedirectTalentAnalyticsConnectProviderImport.update({
    id: '/redirect/talent-analytics-connect/$provider',
    path: '/redirect/talent-analytics-connect/$provider',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectHiddenYoutubeListConnectRoute =
  PrivateRoutesRedirectHiddenYoutubeListConnectImport.update({
    id: '/redirect/hidden/youtube-list-connect',
    path: '/redirect/hidden/youtube-list-connect',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectConnectTiktokBusinessRoute =
  PrivateRoutesRedirectConnectTiktokBusinessImport.update({
    id: '/redirect/connect/tiktok-business',
    path: '/redirect/connect/tiktok-business',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectAnalyticsSigninProviderRoute =
  PrivateRoutesRedirectAnalyticsSigninProviderImport.update({
    id: '/redirect/analytics-signin/$provider',
    path: '/redirect/analytics-signin/$provider',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectAnalyticsListConnectProviderRoute =
  PrivateRoutesRedirectAnalyticsListConnectProviderImport.update({
    id: '/redirect/analytics-list-connect/$provider',
    path: '/redirect/analytics-list-connect/$provider',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesRedirectAnalyticsConnectProviderRoute =
  PrivateRoutesRedirectAnalyticsConnectProviderImport.update({
    id: '/redirect/analytics-connect/$provider',
    path: '/redirect/analytics-connect/$provider',
    getParentRoute: () => PrivateRoutesRoute,
  } as any)

const PrivateRoutesPostsInsightIdPostIdRoute =
  PrivateRoutesPostsInsightIdPostIdImport.update({
    id: '/$postId',
    path: '/$postId',
    getParentRoute: () => PrivateRoutesPostsInsightIdRoute,
  } as any)

const PrivateRoutesPartnerIdUsersRoute =
  PrivateRoutesPartnerIdUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => PrivateRoutesPartnerIdRoute,
  } as any)

const PrivateRoutesPartnerIdBusinessRoute =
  PrivateRoutesPartnerIdBusinessImport.update({
    id: '/business',
    path: '/business',
    getParentRoute: () => PrivateRoutesPartnerIdRoute,
  } as any)

const PrivateRoutesPackageIdContentRoute =
  PrivateRoutesPackageIdContentImport.update({
    id: '/content',
    path: '/content',
    getParentRoute: () => PrivateRoutesPackageIdRoute,
  } as any)

const PrivateRoutesMarketplaceAddUrlTrackingRoute =
  PrivateRoutesMarketplaceAddUrlTrackingImport.update({
    id: '/url-tracking',
    path: '/url-tracking',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceAddSocialMediaTrackingRoute =
  PrivateRoutesMarketplaceAddSocialMediaTrackingImport.update({
    id: '/social-media-tracking',
    path: '/social-media-tracking',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceAddReferralCodeTrackingRoute =
  PrivateRoutesMarketplaceAddReferralCodeTrackingImport.update({
    id: '/referral-code-tracking',
    path: '/referral-code-tracking',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceIdTrackingReferralCodesRoute =
  PrivateRoutesMarketplaceIdTrackingReferralCodesImport.update({
    id: '/tracking-referral-codes',
    path: '/tracking-referral-codes',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdTrackingRoute =
  PrivateRoutesMarketplaceIdTrackingImport.update({
    id: '/tracking',
    path: '/tracking',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdReportAffiliateRoute =
  PrivateRoutesMarketplaceIdReportAffiliateImport.update({
    id: '/report-affiliate',
    path: '/report-affiliate',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdReportRoute =
  PrivateRoutesMarketplaceIdReportImport.update({
    id: '/report',
    path: '/report',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdPostsRoute =
  PrivateRoutesMarketplaceIdPostsImport.update({
    id: '/posts',
    path: '/posts',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdJoinedInfluencersRoute =
  PrivateRoutesMarketplaceIdJoinedInfluencersImport.update({
    id: '/joined-influencers',
    path: '/joined-influencers',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdInfluencerRoute =
  PrivateRoutesMarketplaceIdInfluencerImport.update({
    id: '/influencer',
    path: '/influencer',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdCommissionsRoute =
  PrivateRoutesMarketplaceIdCommissionsImport.update({
    id: '/commissions',
    path: '/commissions',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesMarketplaceIdApplicantRoute =
  PrivateRoutesMarketplaceIdApplicantImport.update({
    id: '/applicant',
    path: '/applicant',
    getParentRoute: () => PrivateRoutesMarketplaceIdRoute,
  } as any)

const PrivateRoutesInfluencersSearchYoutubeRoute =
  PrivateRoutesInfluencersSearchYoutubeImport.update({
    id: '/youtube',
    path: '/youtube',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchXhsRoute =
  PrivateRoutesInfluencersSearchXhsImport.update({
    id: '/xhs',
    path: '/xhs',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchTwitterRoute =
  PrivateRoutesInfluencersSearchTwitterImport.update({
    id: '/twitter',
    path: '/twitter',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchTiktokRoute =
  PrivateRoutesInfluencersSearchTiktokImport.update({
    id: '/tiktok',
    path: '/tiktok',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchThreadsRoute =
  PrivateRoutesInfluencersSearchThreadsImport.update({
    id: '/threads',
    path: '/threads',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchInstagramRoute =
  PrivateRoutesInfluencersSearchInstagramImport.update({
    id: '/instagram',
    path: '/instagram',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchFacebookpageRoute =
  PrivateRoutesInfluencersSearchFacebookpageImport.update({
    id: '/facebook_page',
    path: '/facebook_page',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchFacebookRoute =
  PrivateRoutesInfluencersSearchFacebookImport.update({
    id: '/facebook',
    path: '/facebook',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencersSearchDouyinRoute =
  PrivateRoutesInfluencersSearchDouyinImport.update({
    id: '/douyin',
    path: '/douyin',
    getParentRoute: () => PrivateRoutesInfluencersSearchRoute,
  } as any)

const PrivateRoutesInfluencerIdProfileRoute =
  PrivateRoutesInfluencerIdProfileImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesHashtagAnalyticsTiktokKeywordRoute =
  PrivateRoutesHashtagAnalyticsTiktokKeywordImport.update({
    id: '/tiktok/keyword',
    path: '/tiktok/keyword',
    getParentRoute: () => PrivateRoutesHashtagAnalyticsRoute,
  } as any)

const PrivateRoutesHashtagAnalyticsTiktokHashtagRoute =
  PrivateRoutesHashtagAnalyticsTiktokHashtagImport.update({
    id: '/tiktok/hashtag',
    path: '/tiktok/hashtag',
    getParentRoute: () => PrivateRoutesHashtagAnalyticsRoute,
  } as any)

const PrivateRoutesEngagementIdTrackingReferralCodesRoute =
  PrivateRoutesEngagementIdTrackingReferralCodesImport.update({
    id: '/tracking-referral-codes',
    path: '/tracking-referral-codes',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesEngagementIdTrackingRoute =
  PrivateRoutesEngagementIdTrackingImport.update({
    id: '/tracking',
    path: '/tracking',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesEngagementIdReportRoute =
  PrivateRoutesEngagementIdReportImport.update({
    id: '/report',
    path: '/report',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesEngagementIdProposalRoute =
  PrivateRoutesEngagementIdProposalImport.update({
    id: '/proposal',
    path: '/proposal',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesEngagementIdPostsRoute =
  PrivateRoutesEngagementIdPostsImport.update({
    id: '/posts',
    path: '/posts',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerRoute =
  PrivateRoutesEngagementIdInfluencerImport.update({
    id: '/influencer',
    path: '/influencer',
    getParentRoute: () => PrivateRoutesEngagementIdRoute,
  } as any)

const PrivateRoutesAutoManagedIdReportRoute =
  PrivateRoutesAutoManagedIdReportImport.update({
    id: '/report',
    path: '/report',
    getParentRoute: () => PrivateRoutesAutoManagedIdRoute,
  } as any)

const PrivateRoutesAutoManagedIdJoinedInfluencersRoute =
  PrivateRoutesAutoManagedIdJoinedInfluencersImport.update({
    id: '/joined-influencers',
    path: '/joined-influencers',
    getParentRoute: () => PrivateRoutesAutoManagedIdRoute,
  } as any)

const PrivateRoutesAutoManagedIdCommissionsRoute =
  PrivateRoutesAutoManagedIdCommissionsImport.update({
    id: '/commissions',
    path: '/commissions',
    getParentRoute: () => PrivateRoutesAutoManagedIdRoute,
  } as any)

const PrivateRoutesAgencyIdUsersRoute = PrivateRoutesAgencyIdUsersImport.update(
  {
    id: '/users',
    path: '/users',
    getParentRoute: () => PrivateRoutesAgencyIdRoute,
  } as any,
)

const PrivateRoutesAgencyIdBusinessRoute =
  PrivateRoutesAgencyIdBusinessImport.update({
    id: '/business',
    path: '/business',
    getParentRoute: () => PrivateRoutesAgencyIdRoute,
  } as any)

const PrivateRoutesAdvertiserIdUsersRoute =
  PrivateRoutesAdvertiserIdUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => PrivateRoutesAdvertiserIdRoute,
  } as any)

const PrivateRoutesAdvertiserIdBudgetAllocationRoute =
  PrivateRoutesAdvertiserIdBudgetAllocationImport.update({
    id: '/budget-allocation',
    path: '/budget-allocation',
    getParentRoute: () => PrivateRoutesAdvertiserIdRoute,
  } as any)

const PrivateRoutesTiktokCampaignsIdIndexRoute =
  PrivateRoutesTiktokCampaignsIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdRoute,
  } as any)

const PrivateRoutesTiktokAdCampaignReportIdIndexRoute =
  PrivateRoutesTiktokAdCampaignReportIdIndexImport.update({
    id: '/$id/',
    path: '/$id/',
    getParentRoute: () => PrivateRoutesTiktokAdCampaignReportRoute,
  } as any)

const PrivateRoutesPackageIdContentIndexRoute =
  PrivateRoutesPackageIdContentIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesPackageIdContentRoute,
  } as any)

const PrivateRoutesMarketplaceAddECommerceIndexRoute =
  PrivateRoutesMarketplaceAddECommerceIndexImport.update({
    id: '/e-commerce/',
    path: '/e-commerce/',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceIdPostsIndexRoute =
  PrivateRoutesMarketplaceIdPostsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesMarketplaceIdPostsRoute,
  } as any)

const PrivateRoutesInfluencerIdProfileIndexRoute =
  PrivateRoutesInfluencerIdProfileIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesInfluencerIdProfileRoute,
  } as any)

const PrivateRoutesEngagementIdPostsIndexRoute =
  PrivateRoutesEngagementIdPostsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesEngagementIdPostsRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerIndexRoute =
  PrivateRoutesEngagementIdInfluencerIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesTiktokCampaignsIdReportRoute =
  PrivateRoutesTiktokCampaignsIdReportImport.update({
    id: '/report',
    path: '/report',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdRoute,
  } as any)

const PrivateRoutesTiktokCampaignsIdPostsRoute =
  PrivateRoutesTiktokCampaignsIdPostsImport.update({
    id: '/posts',
    path: '/posts',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdRoute,
  } as any)

const PrivateRoutesTiktokCampaignsIdInfluencersRoute =
  PrivateRoutesTiktokCampaignsIdInfluencersImport.update({
    id: '/influencers',
    path: '/influencers',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdRoute,
  } as any)

const PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute =
  PrivateRoutesTiktokAdCampaignReportIdGroupIdImport.update({
    id: '/$id/$groupId',
    path: '/$id/$groupId',
    getParentRoute: () => PrivateRoutesTiktokAdCampaignReportRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchRoute =
  PrivateRoutesPackageIdContentSearchImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => PrivateRoutesPackageIdContentRoute,
  } as any)

const PrivateRoutesPackageIdContentListRoute =
  PrivateRoutesPackageIdContentListImport.update({
    id: '/list',
    path: '/list',
    getParentRoute: () => PrivateRoutesPackageIdContentRoute,
  } as any)

const PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute =
  PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdImport.update({
    id: '/e-commerce/$autoMangedCampaignId',
    path: '/e-commerce/$autoMangedCampaignId',
    getParentRoute: () => PrivateRoutesMarketplaceAddRoute,
  } as any)

const PrivateRoutesMarketplaceIdPostsPostIdRoute =
  PrivateRoutesMarketplaceIdPostsPostIdImport.update({
    id: '/$postId',
    path: '/$postId',
    getParentRoute: () => PrivateRoutesMarketplaceIdPostsRoute,
  } as any)

const PrivateRoutesInfluencerIdProfileCompareRoute =
  PrivateRoutesInfluencerIdProfileCompareImport.update({
    id: '/compare',
    path: '/compare',
    getParentRoute: () => PrivateRoutesInfluencerIdProfileRoute,
  } as any)

const PrivateRoutesInfluencerIdProfileCampaignsRoute =
  PrivateRoutesInfluencerIdProfileCampaignsImport.update({
    id: '/campaigns',
    path: '/campaigns',
    getParentRoute: () => PrivateRoutesInfluencerIdProfileRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingImport.update({
    id: '/$socialMediaAccountId/youtube_tags_ranking',
    path: '/$socialMediaAccountId/youtube_tags_ranking',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosImport.update(
    {
      id: '/$socialMediaAccountId/youtube_compare_feed_videos',
      path: '/$socialMediaAccountId/youtube_compare_feed_videos',
      getParentRoute: () => PrivateRoutesInfluencerIdRoute,
    } as any,
  )

const PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsImport.update({
    id: '/$socialMediaAccountId/xhs_video_posts',
    path: '/$socialMediaAccountId/xhs_video_posts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsImport.update({
    id: '/$socialMediaAccountId/xhs_picture_posts',
    path: '/$socialMediaAccountId/xhs_picture_posts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsImport.update({
    id: '/$socialMediaAccountId/twitter_feed_posts',
    path: '/$socialMediaAccountId/twitter_feed_posts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsImport.update(
    {
      id: '/$socialMediaAccountId/sponsored_youtube_feed_posts',
      path: '/$socialMediaAccountId/sponsored_youtube_feed_posts',
      getParentRoute: () => PrivateRoutesInfluencerIdRoute,
    } as any,
  )

const PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsImport.update({
    id: '/$socialMediaAccountId/sponsored_feed_posts',
    path: '/$socialMediaAccountId/sponsored_feed_posts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdShortsImport.update({
    id: '/$socialMediaAccountId/shorts',
    path: '/$socialMediaAccountId/shorts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsImport.update({
    id: '/$socialMediaAccountId/scraping_feed_posts',
    path: '/$socialMediaAccountId/scraping_feed_posts',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostImport.update({
    id: '/$socialMediaAccountId/instagram_story_post',
    path: '/$socialMediaAccountId/instagram_story_post',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostImport.update({
    id: '/$socialMediaAccountId/instagram_reel_post',
    path: '/$socialMediaAccountId/instagram_reel_post',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostImport.update({
    id: '/$socialMediaAccountId/instagram_feed_post',
    path: '/$socialMediaAccountId/instagram_feed_post',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosImport.update({
    id: '/$socialMediaAccountId/feed_videos',
    path: '/$socialMediaAccountId/feed_videos',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostImport.update({
    id: '/$socialMediaAccountId/facebook_feed_post',
    path: '/$socialMediaAccountId/facebook_feed_post',
    getParentRoute: () => PrivateRoutesInfluencerIdRoute,
  } as any)

const PrivateRoutesEngagementIdPostsAddRoute =
  PrivateRoutesEngagementIdPostsAddImport.update({
    id: '/add',
    path: '/add',
    getParentRoute: () => PrivateRoutesEngagementIdPostsRoute,
  } as any)

const PrivateRoutesEngagementIdPostsPostIdRoute =
  PrivateRoutesEngagementIdPostsPostIdImport.update({
    id: '/$postId',
    path: '/$postId',
    getParentRoute: () => PrivateRoutesEngagementIdPostsRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerYoutubeRoute =
  PrivateRoutesEngagementIdInfluencerYoutubeImport.update({
    id: '/youtube',
    path: '/youtube',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerXhsRoute =
  PrivateRoutesEngagementIdInfluencerXhsImport.update({
    id: '/xhs',
    path: '/xhs',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerTwitterRoute =
  PrivateRoutesEngagementIdInfluencerTwitterImport.update({
    id: '/twitter',
    path: '/twitter',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerTiktokRoute =
  PrivateRoutesEngagementIdInfluencerTiktokImport.update({
    id: '/tiktok',
    path: '/tiktok',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerThreadsRoute =
  PrivateRoutesEngagementIdInfluencerThreadsImport.update({
    id: '/threads',
    path: '/threads',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerPostsRoute =
  PrivateRoutesEngagementIdInfluencerPostsImport.update({
    id: '/posts',
    path: '/posts',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerPackagesRoute =
  PrivateRoutesEngagementIdInfluencerPackagesImport.update({
    id: '/packages',
    path: '/packages',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerInstagramRoute =
  PrivateRoutesEngagementIdInfluencerInstagramImport.update({
    id: '/instagram',
    path: '/instagram',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerFacebookpageRoute =
  PrivateRoutesEngagementIdInfluencerFacebookpageImport.update({
    id: '/facebook_page',
    path: '/facebook_page',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerFacebookRoute =
  PrivateRoutesEngagementIdInfluencerFacebookImport.update({
    id: '/facebook',
    path: '/facebook',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerDouyinRoute =
  PrivateRoutesEngagementIdInfluencerDouyinImport.update({
    id: '/douyin',
    path: '/douyin',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesEngagementIdInfluencerCampaignsRoute =
  PrivateRoutesEngagementIdInfluencerCampaignsImport.update({
    id: '/campaigns',
    path: '/campaigns',
    getParentRoute: () => PrivateRoutesEngagementIdInfluencerRoute,
  } as any)

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport.update(
    {
      id: '/$brandAccountId/$socialMedia/$socialMediaAccountId',
      path: '/$brandAccountId/$socialMedia/$socialMediaAccountId',
      getParentRoute: () => PrivateRoutesAnalyticsRoute,
    } as any,
  )

const PrivateRoutesTiktokCampaignsIdPostsIndexRoute =
  PrivateRoutesTiktokCampaignsIdPostsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdPostsRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchIndexRoute =
  PrivateRoutesPackageIdContentSearchIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesTiktokCampaignsIdPostsPostIdRoute =
  PrivateRoutesTiktokCampaignsIdPostsPostIdImport.update({
    id: '/$postId',
    path: '/$postId',
    getParentRoute: () => PrivateRoutesTiktokCampaignsIdPostsRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchYoutubeRoute =
  PrivateRoutesPackageIdContentSearchYoutubeImport.update({
    id: '/youtube',
    path: '/youtube',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchXhsRoute =
  PrivateRoutesPackageIdContentSearchXhsImport.update({
    id: '/xhs',
    path: '/xhs',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchTwitterRoute =
  PrivateRoutesPackageIdContentSearchTwitterImport.update({
    id: '/twitter',
    path: '/twitter',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchTiktokRoute =
  PrivateRoutesPackageIdContentSearchTiktokImport.update({
    id: '/tiktok',
    path: '/tiktok',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchThreadsRoute =
  PrivateRoutesPackageIdContentSearchThreadsImport.update({
    id: '/threads',
    path: '/threads',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchInstagramRoute =
  PrivateRoutesPackageIdContentSearchInstagramImport.update({
    id: '/instagram',
    path: '/instagram',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchFacebookpageRoute =
  PrivateRoutesPackageIdContentSearchFacebookpageImport.update({
    id: '/facebook_page',
    path: '/facebook_page',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchFacebookRoute =
  PrivateRoutesPackageIdContentSearchFacebookImport.update({
    id: '/facebook',
    path: '/facebook',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesPackageIdContentSearchDouyinRoute =
  PrivateRoutesPackageIdContentSearchDouyinImport.update({
    id: '/douyin',
    path: '/douyin',
    getParentRoute: () => PrivateRoutesPackageIdContentSearchRoute,
  } as any)

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute =
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdImport.update(
    {
      id: '/$compareId',
      path: '/$compareId',
      getParentRoute: () =>
        PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcImport.update(
    {
      id: '/ugc',
      path: '/ugc',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendImport.update(
    {
      id: '/trend',
      path: '/trend',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagImport.update(
    {
      id: '/hashtag',
      path: '/hashtag',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexImport.update(
    {
      id: '/interaction/',
      path: '/interaction/',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexImport.update(
    {
      id: '/dashboard/',
      path: '/dashboard/',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexImport.update(
    {
      id: '/compare/',
      path: '/compare/',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsImport.update(
    {
      id: '/interaction/tagged_posts',
      path: '/interaction/tagged_posts',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsImport.update(
    {
      id: '/interaction/mentioned_posts',
      path: '/interaction/mentioned_posts',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsImport.update(
    {
      id: '/interaction/brand-ambassadors',
      path: '/interaction/brand-ambassadors',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsImport.update(
    {
      id: '/dashboard/youtube_comments',
      path: '/dashboard/youtube_comments',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsImport.update(
    {
      id: '/dashboard/twitter_feed_posts',
      path: '/dashboard/twitter_feed_posts',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistImport.update(
    {
      id: '/dashboard/tag_ranking_list',
      path: '/dashboard/tag_ranking_list',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsImport.update(
    {
      id: '/dashboard/shorts',
      path: '/dashboard/shorts',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostImport.update(
    {
      id: '/dashboard/instagram_story_post',
      path: '/dashboard/instagram_story_post',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostImport.update(
    {
      id: '/dashboard/instagram_reel_post',
      path: '/dashboard/instagram_reel_post',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostImport.update(
    {
      id: '/dashboard/instagram_feed_post',
      path: '/dashboard/instagram_feed_post',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsImport.update(
    {
      id: '/dashboard/instagram_comments',
      path: '/dashboard/instagram_comments',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosImport.update(
    {
      id: '/dashboard/feed_videos',
      path: '/dashboard/feed_videos',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostImport.update(
    {
      id: '/dashboard/facebook_feed_post',
      path: '/dashboard/facebook_feed_post',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosImport.update(
    {
      id: '/compare/youtube_compare_feed_videos',
      path: '/compare/youtube_compare_feed_videos',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostImport.update(
    {
      id: '/compare/instagram_compare_feed_post',
      path: '/compare/instagram_compare_feed_post',
      getParentRoute: () =>
        PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_private-routes': {
      id: '/_private-routes'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PrivateRoutesImport
      parentRoute: typeof rootRoute
    }
    '/_public-routes': {
      id: '/_public-routes'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicRoutesImport
      parentRoute: typeof rootRoute
    }
    '/not-found': {
      id: '/not-found'
      path: '/not-found'
      fullPath: '/not-found'
      preLoaderRoute: typeof NotFoundImport
      parentRoute: typeof rootRoute
    }
    '/_private-routes/advertiser': {
      id: '/_private-routes/advertiser'
      path: '/advertiser'
      fullPath: '/advertiser'
      preLoaderRoute: typeof PrivateRoutesAdvertiserImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/agency': {
      id: '/_private-routes/agency'
      path: '/agency'
      fullPath: '/agency'
      preLoaderRoute: typeof PrivateRoutesAgencyImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/analytics': {
      id: '/_private-routes/analytics'
      path: '/analytics'
      fullPath: '/analytics'
      preLoaderRoute: typeof PrivateRoutesAnalyticsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/asset-preview': {
      id: '/_private-routes/asset-preview'
      path: '/asset-preview'
      fullPath: '/asset-preview'
      preLoaderRoute: typeof PrivateRoutesAssetPreviewImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/auto-managed': {
      id: '/_private-routes/auto-managed'
      path: '/auto-managed'
      fullPath: '/auto-managed'
      preLoaderRoute: typeof PrivateRoutesAutoManagedImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/campaign-posts-discovery': {
      id: '/_private-routes/campaign-posts-discovery'
      path: '/campaign-posts-discovery'
      fullPath: '/campaign-posts-discovery'
      preLoaderRoute: typeof PrivateRoutesCampaignPostsDiscoveryImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/chat-demo': {
      id: '/_private-routes/chat-demo'
      path: '/chat-demo'
      fullPath: '/chat-demo'
      preLoaderRoute: typeof PrivateRoutesChatDemoImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/hashtag-analytics': {
      id: '/_private-routes/hashtag-analytics'
      path: '/hashtag-analytics'
      fullPath: '/hashtag-analytics'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/influencer': {
      id: '/_private-routes/influencer'
      path: '/influencer'
      fullPath: '/influencer'
      preLoaderRoute: typeof PrivateRoutesInfluencerImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/influencers': {
      id: '/_private-routes/influencers'
      path: '/influencers'
      fullPath: '/influencers'
      preLoaderRoute: typeof PrivateRoutesInfluencersImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/invoices': {
      id: '/_private-routes/invoices'
      path: '/invoices'
      fullPath: '/invoices'
      preLoaderRoute: typeof PrivateRoutesInvoicesImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/package': {
      id: '/_private-routes/package'
      path: '/package'
      fullPath: '/package'
      preLoaderRoute: typeof PrivateRoutesPackageImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/partner': {
      id: '/_private-routes/partner'
      path: '/partner'
      fullPath: '/partner'
      preLoaderRoute: typeof PrivateRoutesPartnerImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/payments': {
      id: '/_private-routes/payments'
      path: '/payments'
      fullPath: '/payments'
      preLoaderRoute: typeof PrivateRoutesPaymentsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/posts': {
      id: '/_private-routes/posts'
      path: '/posts'
      fullPath: '/posts'
      preLoaderRoute: typeof PrivateRoutesPostsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/posts-insight': {
      id: '/_private-routes/posts-insight'
      path: '/posts-insight'
      fullPath: '/posts-insight'
      preLoaderRoute: typeof PrivateRoutesPostsInsightImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/report': {
      id: '/_private-routes/report'
      path: '/report'
      fullPath: '/report'
      preLoaderRoute: typeof PrivateRoutesReportImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/settings': {
      id: '/_private-routes/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof PrivateRoutesSettingsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/talent-agency': {
      id: '/_private-routes/talent-agency'
      path: '/talent-agency'
      fullPath: '/talent-agency'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/tiktok': {
      id: '/_private-routes/tiktok'
      path: '/tiktok'
      fullPath: '/tiktok'
      preLoaderRoute: typeof PrivateRoutesTiktokImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/trending': {
      id: '/_private-routes/trending'
      path: '/trending'
      fullPath: '/trending'
      preLoaderRoute: typeof PrivateRoutesTrendingImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/user': {
      id: '/_private-routes/user'
      path: '/user'
      fullPath: '/user'
      preLoaderRoute: typeof PrivateRoutesUserImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/user-settings': {
      id: '/_private-routes/user-settings'
      path: '/user-settings'
      fullPath: '/user-settings'
      preLoaderRoute: typeof PrivateRoutesUserSettingsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/youtube-cms': {
      id: '/_private-routes/youtube-cms'
      path: '/youtube-cms'
      fullPath: '/youtube-cms'
      preLoaderRoute: typeof PrivateRoutesYoutubeCmsImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_public-routes/privacy': {
      id: '/_public-routes/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PublicRoutesPrivacyImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/redirect_to': {
      id: '/_public-routes/redirect_to'
      path: '/redirect_to'
      fullPath: '/redirect_to'
      preLoaderRoute: typeof PublicRoutesRedirecttoImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/sign-in': {
      id: '/_public-routes/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof PublicRoutesSignInImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/terms': {
      id: '/_public-routes/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof PublicRoutesTermsImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/terms-combined': {
      id: '/_public-routes/terms-combined'
      path: '/terms-combined'
      fullPath: '/terms-combined'
      preLoaderRoute: typeof PublicRoutesTermsCombinedImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/tiktok-onboarding': {
      id: '/_public-routes/tiktok-onboarding'
      path: '/tiktok-onboarding'
      fullPath: '/tiktok-onboarding'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_private-routes/advertiser/$id': {
      id: '/_private-routes/advertiser/$id'
      path: '/$id'
      fullPath: '/advertiser/$id'
      preLoaderRoute: typeof PrivateRoutesAdvertiserIdImport
      parentRoute: typeof PrivateRoutesAdvertiserImport
    }
    '/_private-routes/advertiser/add': {
      id: '/_private-routes/advertiser/add'
      path: '/add'
      fullPath: '/advertiser/add'
      preLoaderRoute: typeof PrivateRoutesAdvertiserAddImport
      parentRoute: typeof PrivateRoutesAdvertiserImport
    }
    '/_private-routes/agency/$id': {
      id: '/_private-routes/agency/$id'
      path: '/$id'
      fullPath: '/agency/$id'
      preLoaderRoute: typeof PrivateRoutesAgencyIdImport
      parentRoute: typeof PrivateRoutesAgencyImport
    }
    '/_private-routes/agency/add': {
      id: '/_private-routes/agency/add'
      path: '/add'
      fullPath: '/agency/add'
      preLoaderRoute: typeof PrivateRoutesAgencyAddImport
      parentRoute: typeof PrivateRoutesAgencyImport
    }
    '/_private-routes/analytics/add': {
      id: '/_private-routes/analytics/add'
      path: '/add'
      fullPath: '/analytics/add'
      preLoaderRoute: typeof PrivateRoutesAnalyticsAddImport
      parentRoute: typeof PrivateRoutesAnalyticsImport
    }
    '/_private-routes/auto-managed/$id': {
      id: '/_private-routes/auto-managed/$id'
      path: '/$id'
      fullPath: '/auto-managed/$id'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIdImport
      parentRoute: typeof PrivateRoutesAutoManagedImport
    }
    '/_private-routes/chat-demo/chat': {
      id: '/_private-routes/chat-demo/chat'
      path: '/chat'
      fullPath: '/chat-demo/chat'
      preLoaderRoute: typeof PrivateRoutesChatDemoChatImport
      parentRoute: typeof PrivateRoutesChatDemoImport
    }
    '/_private-routes/engagement/$id': {
      id: '/_private-routes/engagement/$id'
      path: '/engagement/$id'
      fullPath: '/engagement/$id'
      preLoaderRoute: typeof PrivateRoutesEngagementIdImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/engagement/add': {
      id: '/_private-routes/engagement/add'
      path: '/engagement/add'
      fullPath: '/engagement/add'
      preLoaderRoute: typeof PrivateRoutesEngagementAddImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/hashtag-analytics/instagram': {
      id: '/_private-routes/hashtag-analytics/instagram'
      path: '/instagram'
      fullPath: '/hashtag-analytics/instagram'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsInstagramImport
      parentRoute: typeof PrivateRoutesHashtagAnalyticsImport
    }
    '/_private-routes/influencer/$id': {
      id: '/_private-routes/influencer/$id'
      path: '/$id'
      fullPath: '/influencer/$id'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdImport
      parentRoute: typeof PrivateRoutesInfluencerImport
    }
    '/_private-routes/influencer/add': {
      id: '/_private-routes/influencer/add'
      path: '/add'
      fullPath: '/influencer/add'
      preLoaderRoute: typeof PrivateRoutesInfluencerAddImport
      parentRoute: typeof PrivateRoutesInfluencerImport
    }
    '/_private-routes/influencers/proposal': {
      id: '/_private-routes/influencers/proposal'
      path: '/proposal'
      fullPath: '/influencers/proposal'
      preLoaderRoute: typeof PrivateRoutesInfluencersProposalImport
      parentRoute: typeof PrivateRoutesInfluencersImport
    }
    '/_private-routes/influencers/search': {
      id: '/_private-routes/influencers/search'
      path: '/search'
      fullPath: '/influencers/search'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchImport
      parentRoute: typeof PrivateRoutesInfluencersImport
    }
    '/_private-routes/marketplace/$id': {
      id: '/_private-routes/marketplace/$id'
      path: '/marketplace/$id'
      fullPath: '/marketplace/$id'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/marketplace/add': {
      id: '/_private-routes/marketplace/add'
      path: '/marketplace/add'
      fullPath: '/marketplace/add'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/marketplace/reorder': {
      id: '/_private-routes/marketplace/reorder'
      path: '/marketplace/reorder'
      fullPath: '/marketplace/reorder'
      preLoaderRoute: typeof PrivateRoutesMarketplaceReorderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/package/$id': {
      id: '/_private-routes/package/$id'
      path: '/$id'
      fullPath: '/package/$id'
      preLoaderRoute: typeof PrivateRoutesPackageIdImport
      parentRoute: typeof PrivateRoutesPackageImport
    }
    '/_private-routes/package/add': {
      id: '/_private-routes/package/add'
      path: '/add'
      fullPath: '/package/add'
      preLoaderRoute: typeof PrivateRoutesPackageAddImport
      parentRoute: typeof PrivateRoutesPackageImport
    }
    '/_private-routes/partner/$id': {
      id: '/_private-routes/partner/$id'
      path: '/$id'
      fullPath: '/partner/$id'
      preLoaderRoute: typeof PrivateRoutesPartnerIdImport
      parentRoute: typeof PrivateRoutesPartnerImport
    }
    '/_private-routes/posts-insight/$id': {
      id: '/_private-routes/posts-insight/$id'
      path: '/$id'
      fullPath: '/posts-insight/$id'
      preLoaderRoute: typeof PrivateRoutesPostsInsightIdImport
      parentRoute: typeof PrivateRoutesPostsInsightImport
    }
    '/_private-routes/talent-agency/$id': {
      id: '/_private-routes/talent-agency/$id'
      path: '/$id'
      fullPath: '/talent-agency/$id'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyIdImport
      parentRoute: typeof PrivateRoutesTalentAgencyImport
    }
    '/_private-routes/talent-agency/add': {
      id: '/_private-routes/talent-agency/add'
      path: '/add'
      fullPath: '/talent-agency/add'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyAddImport
      parentRoute: typeof PrivateRoutesTalentAgencyImport
    }
    '/_private-routes/tiktok/ad-accounts': {
      id: '/_private-routes/tiktok/ad-accounts'
      path: '/ad-accounts'
      fullPath: '/tiktok/ad-accounts'
      preLoaderRoute: typeof PrivateRoutesTiktokAdAccountsImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/ad-campaign-report': {
      id: '/_private-routes/tiktok/ad-campaign-report'
      path: '/ad-campaign-report'
      fullPath: '/tiktok/ad-campaign-report'
      preLoaderRoute: typeof PrivateRoutesTiktokAdCampaignReportImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/advertiser-details': {
      id: '/_private-routes/tiktok/advertiser-details'
      path: '/advertiser-details'
      fullPath: '/tiktok/advertiser-details'
      preLoaderRoute: typeof PrivateRoutesTiktokAdvertiserDetailsImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/budget-allocation': {
      id: '/_private-routes/tiktok/budget-allocation'
      path: '/budget-allocation'
      fullPath: '/tiktok/budget-allocation'
      preLoaderRoute: typeof PrivateRoutesTiktokBudgetAllocationImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/campaigns': {
      id: '/_private-routes/tiktok/campaigns'
      path: '/campaigns'
      fullPath: '/tiktok/campaigns'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/failed-payment': {
      id: '/_private-routes/tiktok/failed-payment'
      path: '/failed-payment'
      fullPath: '/tiktok/failed-payment'
      preLoaderRoute: typeof PrivateRoutesTiktokFailedPaymentImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/tiktok/subscription-billing': {
      id: '/_private-routes/tiktok/subscription-billing'
      path: '/subscription-billing'
      fullPath: '/tiktok/subscription-billing'
      preLoaderRoute: typeof PrivateRoutesTiktokSubscriptionBillingImport
      parentRoute: typeof PrivateRoutesTiktokImport
    }
    '/_private-routes/trending/influencers': {
      id: '/_private-routes/trending/influencers'
      path: '/influencers'
      fullPath: '/trending/influencers'
      preLoaderRoute: typeof PrivateRoutesTrendingInfluencersImport
      parentRoute: typeof PrivateRoutesTrendingImport
    }
    '/_private-routes/trending/posts': {
      id: '/_private-routes/trending/posts'
      path: '/posts'
      fullPath: '/trending/posts'
      preLoaderRoute: typeof PrivateRoutesTrendingPostsImport
      parentRoute: typeof PrivateRoutesTrendingImport
    }
    '/_private-routes/user/$id': {
      id: '/_private-routes/user/$id'
      path: '/$id'
      fullPath: '/user/$id'
      preLoaderRoute: typeof PrivateRoutesUserIdImport
      parentRoute: typeof PrivateRoutesUserImport
    }
    '/_private-routes/user/add': {
      id: '/_private-routes/user/add'
      path: '/add'
      fullPath: '/user/add'
      preLoaderRoute: typeof PrivateRoutesUserAddImport
      parentRoute: typeof PrivateRoutesUserImport
    }
    '/_private-routes/user/linked': {
      id: '/_private-routes/user/linked'
      path: '/linked'
      fullPath: '/user/linked'
      preLoaderRoute: typeof PrivateRoutesUserLinkedImport
      parentRoute: typeof PrivateRoutesUserImport
    }
    '/_private-routes/youtube-cms/$cmsChannelId': {
      id: '/_private-routes/youtube-cms/$cmsChannelId'
      path: '/$cmsChannelId'
      fullPath: '/youtube-cms/$cmsChannelId'
      preLoaderRoute: typeof PrivateRoutesYoutubeCmsCmsChannelIdImport
      parentRoute: typeof PrivateRoutesYoutubeCmsImport
    }
    '/_private-routes/youtube-cms/confirmed-revenue': {
      id: '/_private-routes/youtube-cms/confirmed-revenue'
      path: '/confirmed-revenue'
      fullPath: '/youtube-cms/confirmed-revenue'
      preLoaderRoute: typeof PrivateRoutesYoutubeCmsConfirmedRevenueImport
      parentRoute: typeof PrivateRoutesYoutubeCmsImport
    }
    '/_public-routes/_password/password_forgot': {
      id: '/_public-routes/_password/password_forgot'
      path: '/password_forgot'
      fullPath: '/password_forgot'
      preLoaderRoute: typeof PublicRoutesPasswordPasswordforgotImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/egg/referral': {
      id: '/_public-routes/egg/referral'
      path: '/egg/referral'
      fullPath: '/egg/referral'
      preLoaderRoute: typeof PublicRoutesEggReferralImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/mkp/referral': {
      id: '/_public-routes/mkp/referral'
      path: '/mkp/referral'
      fullPath: '/mkp/referral'
      preLoaderRoute: typeof PublicRoutesMkpReferralImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/sign-up/success': {
      id: '/_public-routes/sign-up/success'
      path: '/sign-up/success'
      fullPath: '/sign-up/success'
      preLoaderRoute: typeof PublicRoutesSignUpSuccessImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/tiktok-onboarding/ad-account': {
      id: '/_public-routes/tiktok-onboarding/ad-account'
      path: '/ad-account'
      fullPath: '/tiktok-onboarding/ad-account'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingAdAccountImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_public-routes/tiktok-onboarding/password-enter': {
      id: '/_public-routes/tiktok-onboarding/password-enter'
      path: '/password-enter'
      fullPath: '/tiktok-onboarding/password-enter'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingPasswordEnterImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_public-routes/tiktok-onboarding/select-plan': {
      id: '/_public-routes/tiktok-onboarding/select-plan'
      path: '/select-plan'
      fullPath: '/tiktok-onboarding/select-plan'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingSelectPlanImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_public-routes/tiktok-onboarding/success': {
      id: '/_public-routes/tiktok-onboarding/success'
      path: '/success'
      fullPath: '/tiktok-onboarding/success'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingSuccessImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_private-routes/advertiser/': {
      id: '/_private-routes/advertiser/'
      path: '/'
      fullPath: '/advertiser/'
      preLoaderRoute: typeof PrivateRoutesAdvertiserIndexImport
      parentRoute: typeof PrivateRoutesAdvertiserImport
    }
    '/_private-routes/agency/': {
      id: '/_private-routes/agency/'
      path: '/'
      fullPath: '/agency/'
      preLoaderRoute: typeof PrivateRoutesAgencyIndexImport
      parentRoute: typeof PrivateRoutesAgencyImport
    }
    '/_private-routes/analytics/': {
      id: '/_private-routes/analytics/'
      path: '/'
      fullPath: '/analytics/'
      preLoaderRoute: typeof PrivateRoutesAnalyticsIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsImport
    }
    '/_private-routes/auto-managed/': {
      id: '/_private-routes/auto-managed/'
      path: '/'
      fullPath: '/auto-managed/'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIndexImport
      parentRoute: typeof PrivateRoutesAutoManagedImport
    }
    '/_private-routes/chat-demo/': {
      id: '/_private-routes/chat-demo/'
      path: '/'
      fullPath: '/chat-demo/'
      preLoaderRoute: typeof PrivateRoutesChatDemoIndexImport
      parentRoute: typeof PrivateRoutesChatDemoImport
    }
    '/_private-routes/engagement/': {
      id: '/_private-routes/engagement/'
      path: '/engagement'
      fullPath: '/engagement'
      preLoaderRoute: typeof PrivateRoutesEngagementIndexImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/hashtag-analytics/': {
      id: '/_private-routes/hashtag-analytics/'
      path: '/'
      fullPath: '/hashtag-analytics/'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsIndexImport
      parentRoute: typeof PrivateRoutesHashtagAnalyticsImport
    }
    '/_private-routes/influencer/': {
      id: '/_private-routes/influencer/'
      path: '/'
      fullPath: '/influencer/'
      preLoaderRoute: typeof PrivateRoutesInfluencerIndexImport
      parentRoute: typeof PrivateRoutesInfluencerImport
    }
    '/_private-routes/influencers/': {
      id: '/_private-routes/influencers/'
      path: '/'
      fullPath: '/influencers/'
      preLoaderRoute: typeof PrivateRoutesInfluencersIndexImport
      parentRoute: typeof PrivateRoutesInfluencersImport
    }
    '/_private-routes/marketplace/': {
      id: '/_private-routes/marketplace/'
      path: '/marketplace'
      fullPath: '/marketplace'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIndexImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/package/': {
      id: '/_private-routes/package/'
      path: '/'
      fullPath: '/package/'
      preLoaderRoute: typeof PrivateRoutesPackageIndexImport
      parentRoute: typeof PrivateRoutesPackageImport
    }
    '/_private-routes/partner/': {
      id: '/_private-routes/partner/'
      path: '/'
      fullPath: '/partner/'
      preLoaderRoute: typeof PrivateRoutesPartnerIndexImport
      parentRoute: typeof PrivateRoutesPartnerImport
    }
    '/_private-routes/posts-insight/': {
      id: '/_private-routes/posts-insight/'
      path: '/'
      fullPath: '/posts-insight/'
      preLoaderRoute: typeof PrivateRoutesPostsInsightIndexImport
      parentRoute: typeof PrivateRoutesPostsInsightImport
    }
    '/_private-routes/talent-agency/': {
      id: '/_private-routes/talent-agency/'
      path: '/'
      fullPath: '/talent-agency/'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyIndexImport
      parentRoute: typeof PrivateRoutesTalentAgencyImport
    }
    '/_private-routes/trending/': {
      id: '/_private-routes/trending/'
      path: '/'
      fullPath: '/trending/'
      preLoaderRoute: typeof PrivateRoutesTrendingIndexImport
      parentRoute: typeof PrivateRoutesTrendingImport
    }
    '/_private-routes/user/': {
      id: '/_private-routes/user/'
      path: '/'
      fullPath: '/user/'
      preLoaderRoute: typeof PrivateRoutesUserIndexImport
      parentRoute: typeof PrivateRoutesUserImport
    }
    '/_private-routes/youtube-cms/': {
      id: '/_private-routes/youtube-cms/'
      path: '/'
      fullPath: '/youtube-cms/'
      preLoaderRoute: typeof PrivateRoutesYoutubeCmsIndexImport
      parentRoute: typeof PrivateRoutesYoutubeCmsImport
    }
    '/_public-routes/sign-up/': {
      id: '/_public-routes/sign-up/'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof PublicRoutesSignUpIndexImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/tiktok-onboarding/': {
      id: '/_public-routes/tiktok-onboarding/'
      path: '/'
      fullPath: '/tiktok-onboarding/'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingIndexImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_private-routes/advertiser/$id/budget-allocation': {
      id: '/_private-routes/advertiser/$id/budget-allocation'
      path: '/budget-allocation'
      fullPath: '/advertiser/$id/budget-allocation'
      preLoaderRoute: typeof PrivateRoutesAdvertiserIdBudgetAllocationImport
      parentRoute: typeof PrivateRoutesAdvertiserIdImport
    }
    '/_private-routes/advertiser/$id/users': {
      id: '/_private-routes/advertiser/$id/users'
      path: '/users'
      fullPath: '/advertiser/$id/users'
      preLoaderRoute: typeof PrivateRoutesAdvertiserIdUsersImport
      parentRoute: typeof PrivateRoutesAdvertiserIdImport
    }
    '/_private-routes/agency/$id/business': {
      id: '/_private-routes/agency/$id/business'
      path: '/business'
      fullPath: '/agency/$id/business'
      preLoaderRoute: typeof PrivateRoutesAgencyIdBusinessImport
      parentRoute: typeof PrivateRoutesAgencyIdImport
    }
    '/_private-routes/agency/$id/users': {
      id: '/_private-routes/agency/$id/users'
      path: '/users'
      fullPath: '/agency/$id/users'
      preLoaderRoute: typeof PrivateRoutesAgencyIdUsersImport
      parentRoute: typeof PrivateRoutesAgencyIdImport
    }
    '/_private-routes/auto-managed/$id/commissions': {
      id: '/_private-routes/auto-managed/$id/commissions'
      path: '/commissions'
      fullPath: '/auto-managed/$id/commissions'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIdCommissionsImport
      parentRoute: typeof PrivateRoutesAutoManagedIdImport
    }
    '/_private-routes/auto-managed/$id/joined-influencers': {
      id: '/_private-routes/auto-managed/$id/joined-influencers'
      path: '/joined-influencers'
      fullPath: '/auto-managed/$id/joined-influencers'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIdJoinedInfluencersImport
      parentRoute: typeof PrivateRoutesAutoManagedIdImport
    }
    '/_private-routes/auto-managed/$id/report': {
      id: '/_private-routes/auto-managed/$id/report'
      path: '/report'
      fullPath: '/auto-managed/$id/report'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIdReportImport
      parentRoute: typeof PrivateRoutesAutoManagedIdImport
    }
    '/_private-routes/engagement/$id/influencer': {
      id: '/_private-routes/engagement/$id/influencer'
      path: '/influencer'
      fullPath: '/engagement/$id/influencer'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/engagement/$id/posts': {
      id: '/_private-routes/engagement/$id/posts'
      path: '/posts'
      fullPath: '/engagement/$id/posts'
      preLoaderRoute: typeof PrivateRoutesEngagementIdPostsImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/engagement/$id/proposal': {
      id: '/_private-routes/engagement/$id/proposal'
      path: '/proposal'
      fullPath: '/engagement/$id/proposal'
      preLoaderRoute: typeof PrivateRoutesEngagementIdProposalImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/engagement/$id/report': {
      id: '/_private-routes/engagement/$id/report'
      path: '/report'
      fullPath: '/engagement/$id/report'
      preLoaderRoute: typeof PrivateRoutesEngagementIdReportImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/engagement/$id/tracking': {
      id: '/_private-routes/engagement/$id/tracking'
      path: '/tracking'
      fullPath: '/engagement/$id/tracking'
      preLoaderRoute: typeof PrivateRoutesEngagementIdTrackingImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/engagement/$id/tracking-referral-codes': {
      id: '/_private-routes/engagement/$id/tracking-referral-codes'
      path: '/tracking-referral-codes'
      fullPath: '/engagement/$id/tracking-referral-codes'
      preLoaderRoute: typeof PrivateRoutesEngagementIdTrackingReferralCodesImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/hashtag-analytics/tiktok/hashtag': {
      id: '/_private-routes/hashtag-analytics/tiktok/hashtag'
      path: '/tiktok/hashtag'
      fullPath: '/hashtag-analytics/tiktok/hashtag'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsTiktokHashtagImport
      parentRoute: typeof PrivateRoutesHashtagAnalyticsImport
    }
    '/_private-routes/hashtag-analytics/tiktok/keyword': {
      id: '/_private-routes/hashtag-analytics/tiktok/keyword'
      path: '/tiktok/keyword'
      fullPath: '/hashtag-analytics/tiktok/keyword'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsTiktokKeywordImport
      parentRoute: typeof PrivateRoutesHashtagAnalyticsImport
    }
    '/_private-routes/influencer/$id/profile': {
      id: '/_private-routes/influencer/$id/profile'
      path: '/profile'
      fullPath: '/influencer/$id/profile'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdProfileImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencers/search/douyin': {
      id: '/_private-routes/influencers/search/douyin'
      path: '/douyin'
      fullPath: '/influencers/search/douyin'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchDouyinImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/facebook': {
      id: '/_private-routes/influencers/search/facebook'
      path: '/facebook'
      fullPath: '/influencers/search/facebook'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchFacebookImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/facebook_page': {
      id: '/_private-routes/influencers/search/facebook_page'
      path: '/facebook_page'
      fullPath: '/influencers/search/facebook_page'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchFacebookpageImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/instagram': {
      id: '/_private-routes/influencers/search/instagram'
      path: '/instagram'
      fullPath: '/influencers/search/instagram'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchInstagramImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/threads': {
      id: '/_private-routes/influencers/search/threads'
      path: '/threads'
      fullPath: '/influencers/search/threads'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchThreadsImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/tiktok': {
      id: '/_private-routes/influencers/search/tiktok'
      path: '/tiktok'
      fullPath: '/influencers/search/tiktok'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchTiktokImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/twitter': {
      id: '/_private-routes/influencers/search/twitter'
      path: '/twitter'
      fullPath: '/influencers/search/twitter'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchTwitterImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/xhs': {
      id: '/_private-routes/influencers/search/xhs'
      path: '/xhs'
      fullPath: '/influencers/search/xhs'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchXhsImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/influencers/search/youtube': {
      id: '/_private-routes/influencers/search/youtube'
      path: '/youtube'
      fullPath: '/influencers/search/youtube'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchYoutubeImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/marketplace/$id/applicant': {
      id: '/_private-routes/marketplace/$id/applicant'
      path: '/applicant'
      fullPath: '/marketplace/$id/applicant'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdApplicantImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/commissions': {
      id: '/_private-routes/marketplace/$id/commissions'
      path: '/commissions'
      fullPath: '/marketplace/$id/commissions'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdCommissionsImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/influencer': {
      id: '/_private-routes/marketplace/$id/influencer'
      path: '/influencer'
      fullPath: '/marketplace/$id/influencer'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdInfluencerImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/joined-influencers': {
      id: '/_private-routes/marketplace/$id/joined-influencers'
      path: '/joined-influencers'
      fullPath: '/marketplace/$id/joined-influencers'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdJoinedInfluencersImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/posts': {
      id: '/_private-routes/marketplace/$id/posts'
      path: '/posts'
      fullPath: '/marketplace/$id/posts'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdPostsImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/report': {
      id: '/_private-routes/marketplace/$id/report'
      path: '/report'
      fullPath: '/marketplace/$id/report'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdReportImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/report-affiliate': {
      id: '/_private-routes/marketplace/$id/report-affiliate'
      path: '/report-affiliate'
      fullPath: '/marketplace/$id/report-affiliate'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdReportAffiliateImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/tracking': {
      id: '/_private-routes/marketplace/$id/tracking'
      path: '/tracking'
      fullPath: '/marketplace/$id/tracking'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdTrackingImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/$id/tracking-referral-codes': {
      id: '/_private-routes/marketplace/$id/tracking-referral-codes'
      path: '/tracking-referral-codes'
      fullPath: '/marketplace/$id/tracking-referral-codes'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdTrackingReferralCodesImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/add/referral-code-tracking': {
      id: '/_private-routes/marketplace/add/referral-code-tracking'
      path: '/referral-code-tracking'
      fullPath: '/marketplace/add/referral-code-tracking'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddReferralCodeTrackingImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/marketplace/add/social-media-tracking': {
      id: '/_private-routes/marketplace/add/social-media-tracking'
      path: '/social-media-tracking'
      fullPath: '/marketplace/add/social-media-tracking'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddSocialMediaTrackingImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/marketplace/add/url-tracking': {
      id: '/_private-routes/marketplace/add/url-tracking'
      path: '/url-tracking'
      fullPath: '/marketplace/add/url-tracking'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddUrlTrackingImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/package/$id/content': {
      id: '/_private-routes/package/$id/content'
      path: '/content'
      fullPath: '/package/$id/content'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentImport
      parentRoute: typeof PrivateRoutesPackageIdImport
    }
    '/_private-routes/partner/$id/business': {
      id: '/_private-routes/partner/$id/business'
      path: '/business'
      fullPath: '/partner/$id/business'
      preLoaderRoute: typeof PrivateRoutesPartnerIdBusinessImport
      parentRoute: typeof PrivateRoutesPartnerIdImport
    }
    '/_private-routes/partner/$id/users': {
      id: '/_private-routes/partner/$id/users'
      path: '/users'
      fullPath: '/partner/$id/users'
      preLoaderRoute: typeof PrivateRoutesPartnerIdUsersImport
      parentRoute: typeof PrivateRoutesPartnerIdImport
    }
    '/_private-routes/posts-insight/$id/$postId': {
      id: '/_private-routes/posts-insight/$id/$postId'
      path: '/$postId'
      fullPath: '/posts-insight/$id/$postId'
      preLoaderRoute: typeof PrivateRoutesPostsInsightIdPostIdImport
      parentRoute: typeof PrivateRoutesPostsInsightIdImport
    }
    '/_private-routes/redirect/analytics-connect/$provider': {
      id: '/_private-routes/redirect/analytics-connect/$provider'
      path: '/redirect/analytics-connect/$provider'
      fullPath: '/redirect/analytics-connect/$provider'
      preLoaderRoute: typeof PrivateRoutesRedirectAnalyticsConnectProviderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/analytics-list-connect/$provider': {
      id: '/_private-routes/redirect/analytics-list-connect/$provider'
      path: '/redirect/analytics-list-connect/$provider'
      fullPath: '/redirect/analytics-list-connect/$provider'
      preLoaderRoute: typeof PrivateRoutesRedirectAnalyticsListConnectProviderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/analytics-signin/$provider': {
      id: '/_private-routes/redirect/analytics-signin/$provider'
      path: '/redirect/analytics-signin/$provider'
      fullPath: '/redirect/analytics-signin/$provider'
      preLoaderRoute: typeof PrivateRoutesRedirectAnalyticsSigninProviderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/connect/tiktok-business': {
      id: '/_private-routes/redirect/connect/tiktok-business'
      path: '/redirect/connect/tiktok-business'
      fullPath: '/redirect/connect/tiktok-business'
      preLoaderRoute: typeof PrivateRoutesRedirectConnectTiktokBusinessImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/hidden/youtube-list-connect': {
      id: '/_private-routes/redirect/hidden/youtube-list-connect'
      path: '/redirect/hidden/youtube-list-connect'
      fullPath: '/redirect/hidden/youtube-list-connect'
      preLoaderRoute: typeof PrivateRoutesRedirectHiddenYoutubeListConnectImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/talent-analytics-connect/$provider': {
      id: '/_private-routes/redirect/talent-analytics-connect/$provider'
      path: '/redirect/talent-analytics-connect/$provider'
      fullPath: '/redirect/talent-analytics-connect/$provider'
      preLoaderRoute: typeof PrivateRoutesRedirectTalentAnalyticsConnectProviderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/redirect/talent-signin/$provider': {
      id: '/_private-routes/redirect/talent-signin/$provider'
      path: '/redirect/talent-signin/$provider'
      fullPath: '/redirect/talent-signin/$provider'
      preLoaderRoute: typeof PrivateRoutesRedirectTalentSigninProviderImport
      parentRoute: typeof PrivateRoutesImport
    }
    '/_private-routes/talent-agency/$id/business': {
      id: '/_private-routes/talent-agency/$id/business'
      path: '/business'
      fullPath: '/talent-agency/$id/business'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyIdBusinessImport
      parentRoute: typeof PrivateRoutesTalentAgencyIdImport
    }
    '/_private-routes/talent-agency/$id/users': {
      id: '/_private-routes/talent-agency/$id/users'
      path: '/users'
      fullPath: '/talent-agency/$id/users'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyIdUsersImport
      parentRoute: typeof PrivateRoutesTalentAgencyIdImport
    }
    '/_private-routes/tiktok/campaigns/$id': {
      id: '/_private-routes/tiktok/campaigns/$id'
      path: '/$id'
      fullPath: '/tiktok/campaigns/$id'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsImport
    }
    '/_private-routes/tiktok/campaigns/add': {
      id: '/_private-routes/tiktok/campaigns/add'
      path: '/add'
      fullPath: '/tiktok/campaigns/add'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsAddImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsImport
    }
    '/_private-routes/tiktok/campaigns/review-influencers': {
      id: '/_private-routes/tiktok/campaigns/review-influencers'
      path: '/review-influencers'
      fullPath: '/tiktok/campaigns/review-influencers'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsReviewInfluencersImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsImport
    }
    '/_public-routes/_password/password_register/$token': {
      id: '/_public-routes/_password/password_register/$token'
      path: '/password_register/$token'
      fullPath: '/password_register/$token'
      preLoaderRoute: typeof PublicRoutesPasswordPasswordregisterTokenImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/_password/password_reset/$token': {
      id: '/_public-routes/_password/password_reset/$token'
      path: '/password_reset/$token'
      fullPath: '/password_reset/$token'
      preLoaderRoute: typeof PublicRoutesPasswordPasswordresetTokenImport
      parentRoute: typeof PublicRoutesImport
    }
    '/_public-routes/tiktok-onboarding/password-register/$token': {
      id: '/_public-routes/tiktok-onboarding/password-register/$token'
      path: '/password-register/$token'
      fullPath: '/tiktok-onboarding/password-register/$token'
      preLoaderRoute: typeof PublicRoutesTiktokOnboardingPasswordRegisterTokenImport
      parentRoute: typeof PublicRoutesTiktokOnboardingImport
    }
    '/_private-routes/advertiser/$id/': {
      id: '/_private-routes/advertiser/$id/'
      path: '/'
      fullPath: '/advertiser/$id/'
      preLoaderRoute: typeof PrivateRoutesAdvertiserIdIndexImport
      parentRoute: typeof PrivateRoutesAdvertiserIdImport
    }
    '/_private-routes/agency/$id/': {
      id: '/_private-routes/agency/$id/'
      path: '/'
      fullPath: '/agency/$id/'
      preLoaderRoute: typeof PrivateRoutesAgencyIdIndexImport
      parentRoute: typeof PrivateRoutesAgencyIdImport
    }
    '/_private-routes/analytics/$brandAccountId/': {
      id: '/_private-routes/analytics/$brandAccountId/'
      path: '/$brandAccountId'
      fullPath: '/analytics/$brandAccountId'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsImport
    }
    '/_private-routes/auto-managed/$id/': {
      id: '/_private-routes/auto-managed/$id/'
      path: '/'
      fullPath: '/auto-managed/$id/'
      preLoaderRoute: typeof PrivateRoutesAutoManagedIdIndexImport
      parentRoute: typeof PrivateRoutesAutoManagedIdImport
    }
    '/_private-routes/engagement/$id/': {
      id: '/_private-routes/engagement/$id/'
      path: '/'
      fullPath: '/engagement/$id/'
      preLoaderRoute: typeof PrivateRoutesEngagementIdIndexImport
      parentRoute: typeof PrivateRoutesEngagementIdImport
    }
    '/_private-routes/hashtag-analytics/tiktok/': {
      id: '/_private-routes/hashtag-analytics/tiktok/'
      path: '/tiktok'
      fullPath: '/hashtag-analytics/tiktok'
      preLoaderRoute: typeof PrivateRoutesHashtagAnalyticsTiktokIndexImport
      parentRoute: typeof PrivateRoutesHashtagAnalyticsImport
    }
    '/_private-routes/influencer/$id/': {
      id: '/_private-routes/influencer/$id/'
      path: '/'
      fullPath: '/influencer/$id/'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdIndexImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencers/search/': {
      id: '/_private-routes/influencers/search/'
      path: '/'
      fullPath: '/influencers/search/'
      preLoaderRoute: typeof PrivateRoutesInfluencersSearchIndexImport
      parentRoute: typeof PrivateRoutesInfluencersSearchImport
    }
    '/_private-routes/marketplace/$id/': {
      id: '/_private-routes/marketplace/$id/'
      path: '/'
      fullPath: '/marketplace/$id/'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdIndexImport
      parentRoute: typeof PrivateRoutesMarketplaceIdImport
    }
    '/_private-routes/marketplace/add/': {
      id: '/_private-routes/marketplace/add/'
      path: '/'
      fullPath: '/marketplace/add/'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddIndexImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/package/$id/': {
      id: '/_private-routes/package/$id/'
      path: '/'
      fullPath: '/package/$id/'
      preLoaderRoute: typeof PrivateRoutesPackageIdIndexImport
      parentRoute: typeof PrivateRoutesPackageIdImport
    }
    '/_private-routes/partner/$id/': {
      id: '/_private-routes/partner/$id/'
      path: '/'
      fullPath: '/partner/$id/'
      preLoaderRoute: typeof PrivateRoutesPartnerIdIndexImport
      parentRoute: typeof PrivateRoutesPartnerIdImport
    }
    '/_private-routes/talent-agency/$id/': {
      id: '/_private-routes/talent-agency/$id/'
      path: '/'
      fullPath: '/talent-agency/$id/'
      preLoaderRoute: typeof PrivateRoutesTalentAgencyIdIndexImport
      parentRoute: typeof PrivateRoutesTalentAgencyIdImport
    }
    '/_private-routes/tiktok/ad-campaign-report/': {
      id: '/_private-routes/tiktok/ad-campaign-report/'
      path: '/'
      fullPath: '/tiktok/ad-campaign-report/'
      preLoaderRoute: typeof PrivateRoutesTiktokAdCampaignReportIndexImport
      parentRoute: typeof PrivateRoutesTiktokAdCampaignReportImport
    }
    '/_private-routes/tiktok/campaigns/': {
      id: '/_private-routes/tiktok/campaigns/'
      path: '/'
      fullPath: '/tiktok/campaigns/'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIndexImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
      path: '/$brandAccountId/$socialMedia/$socialMediaAccountId'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
      parentRoute: typeof PrivateRoutesAnalyticsImport
    }
    '/_private-routes/engagement/$id/influencer/campaigns': {
      id: '/_private-routes/engagement/$id/influencer/campaigns'
      path: '/campaigns'
      fullPath: '/engagement/$id/influencer/campaigns'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerCampaignsImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/douyin': {
      id: '/_private-routes/engagement/$id/influencer/douyin'
      path: '/douyin'
      fullPath: '/engagement/$id/influencer/douyin'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerDouyinImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/facebook': {
      id: '/_private-routes/engagement/$id/influencer/facebook'
      path: '/facebook'
      fullPath: '/engagement/$id/influencer/facebook'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerFacebookImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/facebook_page': {
      id: '/_private-routes/engagement/$id/influencer/facebook_page'
      path: '/facebook_page'
      fullPath: '/engagement/$id/influencer/facebook_page'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerFacebookpageImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/instagram': {
      id: '/_private-routes/engagement/$id/influencer/instagram'
      path: '/instagram'
      fullPath: '/engagement/$id/influencer/instagram'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerInstagramImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/packages': {
      id: '/_private-routes/engagement/$id/influencer/packages'
      path: '/packages'
      fullPath: '/engagement/$id/influencer/packages'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerPackagesImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/posts': {
      id: '/_private-routes/engagement/$id/influencer/posts'
      path: '/posts'
      fullPath: '/engagement/$id/influencer/posts'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerPostsImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/threads': {
      id: '/_private-routes/engagement/$id/influencer/threads'
      path: '/threads'
      fullPath: '/engagement/$id/influencer/threads'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerThreadsImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/tiktok': {
      id: '/_private-routes/engagement/$id/influencer/tiktok'
      path: '/tiktok'
      fullPath: '/engagement/$id/influencer/tiktok'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerTiktokImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/twitter': {
      id: '/_private-routes/engagement/$id/influencer/twitter'
      path: '/twitter'
      fullPath: '/engagement/$id/influencer/twitter'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerTwitterImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/xhs': {
      id: '/_private-routes/engagement/$id/influencer/xhs'
      path: '/xhs'
      fullPath: '/engagement/$id/influencer/xhs'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerXhsImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/influencer/youtube': {
      id: '/_private-routes/engagement/$id/influencer/youtube'
      path: '/youtube'
      fullPath: '/engagement/$id/influencer/youtube'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerYoutubeImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/posts/$postId': {
      id: '/_private-routes/engagement/$id/posts/$postId'
      path: '/$postId'
      fullPath: '/engagement/$id/posts/$postId'
      preLoaderRoute: typeof PrivateRoutesEngagementIdPostsPostIdImport
      parentRoute: typeof PrivateRoutesEngagementIdPostsImport
    }
    '/_private-routes/engagement/$id/posts/add': {
      id: '/_private-routes/engagement/$id/posts/add'
      path: '/add'
      fullPath: '/engagement/$id/posts/add'
      preLoaderRoute: typeof PrivateRoutesEngagementIdPostsAddImport
      parentRoute: typeof PrivateRoutesEngagementIdPostsImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post'
      path: '/$socialMediaAccountId/facebook_feed_post'
      fullPath: '/influencer/$id/$socialMediaAccountId/facebook_feed_post'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos'
      path: '/$socialMediaAccountId/feed_videos'
      fullPath: '/influencer/$id/$socialMediaAccountId/feed_videos'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post'
      path: '/$socialMediaAccountId/instagram_feed_post'
      fullPath: '/influencer/$id/$socialMediaAccountId/instagram_feed_post'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post'
      path: '/$socialMediaAccountId/instagram_reel_post'
      fullPath: '/influencer/$id/$socialMediaAccountId/instagram_reel_post'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post'
      path: '/$socialMediaAccountId/instagram_story_post'
      fullPath: '/influencer/$id/$socialMediaAccountId/instagram_story_post'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
      path: '/$socialMediaAccountId/scraping_feed_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/shorts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/shorts'
      path: '/$socialMediaAccountId/shorts'
      fullPath: '/influencer/$id/$socialMediaAccountId/shorts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdShortsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
      path: '/$socialMediaAccountId/sponsored_feed_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
      path: '/$socialMediaAccountId/sponsored_youtube_feed_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
      path: '/$socialMediaAccountId/twitter_feed_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
      path: '/$socialMediaAccountId/xhs_picture_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts'
      path: '/$socialMediaAccountId/xhs_video_posts'
      fullPath: '/influencer/$id/$socialMediaAccountId/xhs_video_posts'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
      path: '/$socialMediaAccountId/youtube_compare_feed_videos'
      fullPath: '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
      path: '/$socialMediaAccountId/youtube_tags_ranking'
      fullPath: '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingImport
      parentRoute: typeof PrivateRoutesInfluencerIdImport
    }
    '/_private-routes/influencer/$id/profile/campaigns': {
      id: '/_private-routes/influencer/$id/profile/campaigns'
      path: '/campaigns'
      fullPath: '/influencer/$id/profile/campaigns'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdProfileCampaignsImport
      parentRoute: typeof PrivateRoutesInfluencerIdProfileImport
    }
    '/_private-routes/influencer/$id/profile/compare': {
      id: '/_private-routes/influencer/$id/profile/compare'
      path: '/compare'
      fullPath: '/influencer/$id/profile/compare'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdProfileCompareImport
      parentRoute: typeof PrivateRoutesInfluencerIdProfileImport
    }
    '/_private-routes/marketplace/$id/posts/$postId': {
      id: '/_private-routes/marketplace/$id/posts/$postId'
      path: '/$postId'
      fullPath: '/marketplace/$id/posts/$postId'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdPostsPostIdImport
      parentRoute: typeof PrivateRoutesMarketplaceIdPostsImport
    }
    '/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId': {
      id: '/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId'
      path: '/e-commerce/$autoMangedCampaignId'
      fullPath: '/marketplace/add/e-commerce/$autoMangedCampaignId'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/package/$id/content/list': {
      id: '/_private-routes/package/$id/content/list'
      path: '/list'
      fullPath: '/package/$id/content/list'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentListImport
      parentRoute: typeof PrivateRoutesPackageIdContentImport
    }
    '/_private-routes/package/$id/content/search': {
      id: '/_private-routes/package/$id/content/search'
      path: '/search'
      fullPath: '/package/$id/content/search'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchImport
      parentRoute: typeof PrivateRoutesPackageIdContentImport
    }
    '/_private-routes/tiktok/ad-campaign-report/$id/$groupId': {
      id: '/_private-routes/tiktok/ad-campaign-report/$id/$groupId'
      path: '/$id/$groupId'
      fullPath: '/tiktok/ad-campaign-report/$id/$groupId'
      preLoaderRoute: typeof PrivateRoutesTiktokAdCampaignReportIdGroupIdImport
      parentRoute: typeof PrivateRoutesTiktokAdCampaignReportImport
    }
    '/_private-routes/tiktok/campaigns/$id/influencers': {
      id: '/_private-routes/tiktok/campaigns/$id/influencers'
      path: '/influencers'
      fullPath: '/tiktok/campaigns/$id/influencers'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdInfluencersImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdImport
    }
    '/_private-routes/tiktok/campaigns/$id/posts': {
      id: '/_private-routes/tiktok/campaigns/$id/posts'
      path: '/posts'
      fullPath: '/tiktok/campaigns/$id/posts'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdPostsImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdImport
    }
    '/_private-routes/tiktok/campaigns/$id/report': {
      id: '/_private-routes/tiktok/campaigns/$id/report'
      path: '/report'
      fullPath: '/tiktok/campaigns/$id/report'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdReportImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdImport
    }
    '/_private-routes/engagement/$id/influencer/': {
      id: '/_private-routes/engagement/$id/influencer/'
      path: '/'
      fullPath: '/engagement/$id/influencer/'
      preLoaderRoute: typeof PrivateRoutesEngagementIdInfluencerIndexImport
      parentRoute: typeof PrivateRoutesEngagementIdInfluencerImport
    }
    '/_private-routes/engagement/$id/posts/': {
      id: '/_private-routes/engagement/$id/posts/'
      path: '/'
      fullPath: '/engagement/$id/posts/'
      preLoaderRoute: typeof PrivateRoutesEngagementIdPostsIndexImport
      parentRoute: typeof PrivateRoutesEngagementIdPostsImport
    }
    '/_private-routes/influencer/$id/profile/': {
      id: '/_private-routes/influencer/$id/profile/'
      path: '/'
      fullPath: '/influencer/$id/profile/'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdProfileIndexImport
      parentRoute: typeof PrivateRoutesInfluencerIdProfileImport
    }
    '/_private-routes/marketplace/$id/posts/': {
      id: '/_private-routes/marketplace/$id/posts/'
      path: '/'
      fullPath: '/marketplace/$id/posts/'
      preLoaderRoute: typeof PrivateRoutesMarketplaceIdPostsIndexImport
      parentRoute: typeof PrivateRoutesMarketplaceIdPostsImport
    }
    '/_private-routes/marketplace/add/e-commerce/': {
      id: '/_private-routes/marketplace/add/e-commerce/'
      path: '/e-commerce'
      fullPath: '/marketplace/add/e-commerce'
      preLoaderRoute: typeof PrivateRoutesMarketplaceAddECommerceIndexImport
      parentRoute: typeof PrivateRoutesMarketplaceAddImport
    }
    '/_private-routes/package/$id/content/': {
      id: '/_private-routes/package/$id/content/'
      path: '/'
      fullPath: '/package/$id/content/'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentIndexImport
      parentRoute: typeof PrivateRoutesPackageIdContentImport
    }
    '/_private-routes/tiktok/ad-campaign-report/$id/': {
      id: '/_private-routes/tiktok/ad-campaign-report/$id/'
      path: '/$id'
      fullPath: '/tiktok/ad-campaign-report/$id'
      preLoaderRoute: typeof PrivateRoutesTiktokAdCampaignReportIdIndexImport
      parentRoute: typeof PrivateRoutesTiktokAdCampaignReportImport
    }
    '/_private-routes/tiktok/campaigns/$id/': {
      id: '/_private-routes/tiktok/campaigns/$id/'
      path: '/'
      fullPath: '/tiktok/campaigns/$id/'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdIndexImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
      path: '/hashtag'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
      path: '/trend'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
      path: '/ugc'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
      path: '/$compareId'
      fullPath: '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdImport
      parentRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosImport
    }
    '/_private-routes/package/$id/content/search/douyin': {
      id: '/_private-routes/package/$id/content/search/douyin'
      path: '/douyin'
      fullPath: '/package/$id/content/search/douyin'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchDouyinImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/facebook': {
      id: '/_private-routes/package/$id/content/search/facebook'
      path: '/facebook'
      fullPath: '/package/$id/content/search/facebook'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchFacebookImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/facebook_page': {
      id: '/_private-routes/package/$id/content/search/facebook_page'
      path: '/facebook_page'
      fullPath: '/package/$id/content/search/facebook_page'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchFacebookpageImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/instagram': {
      id: '/_private-routes/package/$id/content/search/instagram'
      path: '/instagram'
      fullPath: '/package/$id/content/search/instagram'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchInstagramImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/threads': {
      id: '/_private-routes/package/$id/content/search/threads'
      path: '/threads'
      fullPath: '/package/$id/content/search/threads'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchThreadsImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/tiktok': {
      id: '/_private-routes/package/$id/content/search/tiktok'
      path: '/tiktok'
      fullPath: '/package/$id/content/search/tiktok'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchTiktokImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/twitter': {
      id: '/_private-routes/package/$id/content/search/twitter'
      path: '/twitter'
      fullPath: '/package/$id/content/search/twitter'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchTwitterImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/xhs': {
      id: '/_private-routes/package/$id/content/search/xhs'
      path: '/xhs'
      fullPath: '/package/$id/content/search/xhs'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchXhsImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/package/$id/content/search/youtube': {
      id: '/_private-routes/package/$id/content/search/youtube'
      path: '/youtube'
      fullPath: '/package/$id/content/search/youtube'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchYoutubeImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/tiktok/campaigns/$id/posts/$postId': {
      id: '/_private-routes/tiktok/campaigns/$id/posts/$postId'
      path: '/$postId'
      fullPath: '/tiktok/campaigns/$id/posts/$postId'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdPostsPostIdImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdPostsImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/'
      path: '/'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/': {
      id: '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/'
      path: '/'
      fullPath: '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/'
      preLoaderRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexImport
      parentRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosImport
    }
    '/_private-routes/package/$id/content/search/': {
      id: '/_private-routes/package/$id/content/search/'
      path: '/'
      fullPath: '/package/$id/content/search/'
      preLoaderRoute: typeof PrivateRoutesPackageIdContentSearchIndexImport
      parentRoute: typeof PrivateRoutesPackageIdContentSearchImport
    }
    '/_private-routes/tiktok/campaigns/$id/posts/': {
      id: '/_private-routes/tiktok/campaigns/$id/posts/'
      path: '/'
      fullPath: '/tiktok/campaigns/$id/posts/'
      preLoaderRoute: typeof PrivateRoutesTiktokCampaignsIdPostsIndexImport
      parentRoute: typeof PrivateRoutesTiktokCampaignsIdPostsImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'
      path: '/compare/instagram_compare_feed_post'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
      path: '/compare/youtube_compare_feed_videos'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
      path: '/dashboard/facebook_feed_post'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
      path: '/dashboard/feed_videos'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
      path: '/dashboard/instagram_comments'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
      path: '/dashboard/instagram_feed_post'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
      path: '/dashboard/instagram_reel_post'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
      path: '/dashboard/instagram_story_post'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
      path: '/dashboard/shorts'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
      path: '/dashboard/tag_ranking_list'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
      path: '/dashboard/twitter_feed_posts'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
      path: '/dashboard/youtube_comments'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
      path: '/interaction/brand-ambassadors'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
      path: '/interaction/mentioned_posts'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
      path: '/interaction/tagged_posts'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/'
      path: '/compare'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/'
      path: '/dashboard'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
    '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/': {
      id: '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/'
      path: '/interaction'
      fullPath: '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction'
      preLoaderRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexImport
      parentRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdImport
    }
  }
}

// Create and export the route tree

interface PrivateRoutesAdvertiserIdRouteChildren {
  PrivateRoutesAdvertiserIdBudgetAllocationRoute: typeof PrivateRoutesAdvertiserIdBudgetAllocationRoute
  PrivateRoutesAdvertiserIdUsersRoute: typeof PrivateRoutesAdvertiserIdUsersRoute
  PrivateRoutesAdvertiserIdIndexRoute: typeof PrivateRoutesAdvertiserIdIndexRoute
}

const PrivateRoutesAdvertiserIdRouteChildren: PrivateRoutesAdvertiserIdRouteChildren =
  {
    PrivateRoutesAdvertiserIdBudgetAllocationRoute:
      PrivateRoutesAdvertiserIdBudgetAllocationRoute,
    PrivateRoutesAdvertiserIdUsersRoute: PrivateRoutesAdvertiserIdUsersRoute,
    PrivateRoutesAdvertiserIdIndexRoute: PrivateRoutesAdvertiserIdIndexRoute,
  }

const PrivateRoutesAdvertiserIdRouteWithChildren =
  PrivateRoutesAdvertiserIdRoute._addFileChildren(
    PrivateRoutesAdvertiserIdRouteChildren,
  )

interface PrivateRoutesAdvertiserRouteChildren {
  PrivateRoutesAdvertiserIdRoute: typeof PrivateRoutesAdvertiserIdRouteWithChildren
  PrivateRoutesAdvertiserAddRoute: typeof PrivateRoutesAdvertiserAddRoute
  PrivateRoutesAdvertiserIndexRoute: typeof PrivateRoutesAdvertiserIndexRoute
}

const PrivateRoutesAdvertiserRouteChildren: PrivateRoutesAdvertiserRouteChildren =
  {
    PrivateRoutesAdvertiserIdRoute: PrivateRoutesAdvertiserIdRouteWithChildren,
    PrivateRoutesAdvertiserAddRoute: PrivateRoutesAdvertiserAddRoute,
    PrivateRoutesAdvertiserIndexRoute: PrivateRoutesAdvertiserIndexRoute,
  }

const PrivateRoutesAdvertiserRouteWithChildren =
  PrivateRoutesAdvertiserRoute._addFileChildren(
    PrivateRoutesAdvertiserRouteChildren,
  )

interface PrivateRoutesAgencyIdRouteChildren {
  PrivateRoutesAgencyIdBusinessRoute: typeof PrivateRoutesAgencyIdBusinessRoute
  PrivateRoutesAgencyIdUsersRoute: typeof PrivateRoutesAgencyIdUsersRoute
  PrivateRoutesAgencyIdIndexRoute: typeof PrivateRoutesAgencyIdIndexRoute
}

const PrivateRoutesAgencyIdRouteChildren: PrivateRoutesAgencyIdRouteChildren = {
  PrivateRoutesAgencyIdBusinessRoute: PrivateRoutesAgencyIdBusinessRoute,
  PrivateRoutesAgencyIdUsersRoute: PrivateRoutesAgencyIdUsersRoute,
  PrivateRoutesAgencyIdIndexRoute: PrivateRoutesAgencyIdIndexRoute,
}

const PrivateRoutesAgencyIdRouteWithChildren =
  PrivateRoutesAgencyIdRoute._addFileChildren(
    PrivateRoutesAgencyIdRouteChildren,
  )

interface PrivateRoutesAgencyRouteChildren {
  PrivateRoutesAgencyIdRoute: typeof PrivateRoutesAgencyIdRouteWithChildren
  PrivateRoutesAgencyAddRoute: typeof PrivateRoutesAgencyAddRoute
  PrivateRoutesAgencyIndexRoute: typeof PrivateRoutesAgencyIndexRoute
}

const PrivateRoutesAgencyRouteChildren: PrivateRoutesAgencyRouteChildren = {
  PrivateRoutesAgencyIdRoute: PrivateRoutesAgencyIdRouteWithChildren,
  PrivateRoutesAgencyAddRoute: PrivateRoutesAgencyAddRoute,
  PrivateRoutesAgencyIndexRoute: PrivateRoutesAgencyIndexRoute,
}

const PrivateRoutesAgencyRouteWithChildren =
  PrivateRoutesAgencyRoute._addFileChildren(PrivateRoutesAgencyRouteChildren)

interface PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteChildren {
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute
}

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteChildren: PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteChildren =
  {
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute,
  }

const PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteWithChildren =
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute._addFileChildren(
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteChildren,
  )

interface PrivateRoutesAnalyticsRouteChildren {
  PrivateRoutesAnalyticsAddRoute: typeof PrivateRoutesAnalyticsAddRoute
  PrivateRoutesAnalyticsIndexRoute: typeof PrivateRoutesAnalyticsIndexRoute
  PrivateRoutesAnalyticsBrandAccountIdIndexRoute: typeof PrivateRoutesAnalyticsBrandAccountIdIndexRoute
  PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute: typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteWithChildren
}

const PrivateRoutesAnalyticsRouteChildren: PrivateRoutesAnalyticsRouteChildren =
  {
    PrivateRoutesAnalyticsAddRoute: PrivateRoutesAnalyticsAddRoute,
    PrivateRoutesAnalyticsIndexRoute: PrivateRoutesAnalyticsIndexRoute,
    PrivateRoutesAnalyticsBrandAccountIdIndexRoute:
      PrivateRoutesAnalyticsBrandAccountIdIndexRoute,
    PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRoute:
      PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteWithChildren,
  }

const PrivateRoutesAnalyticsRouteWithChildren =
  PrivateRoutesAnalyticsRoute._addFileChildren(
    PrivateRoutesAnalyticsRouteChildren,
  )

interface PrivateRoutesAutoManagedIdRouteChildren {
  PrivateRoutesAutoManagedIdCommissionsRoute: typeof PrivateRoutesAutoManagedIdCommissionsRoute
  PrivateRoutesAutoManagedIdJoinedInfluencersRoute: typeof PrivateRoutesAutoManagedIdJoinedInfluencersRoute
  PrivateRoutesAutoManagedIdReportRoute: typeof PrivateRoutesAutoManagedIdReportRoute
  PrivateRoutesAutoManagedIdIndexRoute: typeof PrivateRoutesAutoManagedIdIndexRoute
}

const PrivateRoutesAutoManagedIdRouteChildren: PrivateRoutesAutoManagedIdRouteChildren =
  {
    PrivateRoutesAutoManagedIdCommissionsRoute:
      PrivateRoutesAutoManagedIdCommissionsRoute,
    PrivateRoutesAutoManagedIdJoinedInfluencersRoute:
      PrivateRoutesAutoManagedIdJoinedInfluencersRoute,
    PrivateRoutesAutoManagedIdReportRoute:
      PrivateRoutesAutoManagedIdReportRoute,
    PrivateRoutesAutoManagedIdIndexRoute: PrivateRoutesAutoManagedIdIndexRoute,
  }

const PrivateRoutesAutoManagedIdRouteWithChildren =
  PrivateRoutesAutoManagedIdRoute._addFileChildren(
    PrivateRoutesAutoManagedIdRouteChildren,
  )

interface PrivateRoutesAutoManagedRouteChildren {
  PrivateRoutesAutoManagedIdRoute: typeof PrivateRoutesAutoManagedIdRouteWithChildren
  PrivateRoutesAutoManagedIndexRoute: typeof PrivateRoutesAutoManagedIndexRoute
}

const PrivateRoutesAutoManagedRouteChildren: PrivateRoutesAutoManagedRouteChildren =
  {
    PrivateRoutesAutoManagedIdRoute:
      PrivateRoutesAutoManagedIdRouteWithChildren,
    PrivateRoutesAutoManagedIndexRoute: PrivateRoutesAutoManagedIndexRoute,
  }

const PrivateRoutesAutoManagedRouteWithChildren =
  PrivateRoutesAutoManagedRoute._addFileChildren(
    PrivateRoutesAutoManagedRouteChildren,
  )

interface PrivateRoutesChatDemoRouteChildren {
  PrivateRoutesChatDemoChatRoute: typeof PrivateRoutesChatDemoChatRoute
  PrivateRoutesChatDemoIndexRoute: typeof PrivateRoutesChatDemoIndexRoute
}

const PrivateRoutesChatDemoRouteChildren: PrivateRoutesChatDemoRouteChildren = {
  PrivateRoutesChatDemoChatRoute: PrivateRoutesChatDemoChatRoute,
  PrivateRoutesChatDemoIndexRoute: PrivateRoutesChatDemoIndexRoute,
}

const PrivateRoutesChatDemoRouteWithChildren =
  PrivateRoutesChatDemoRoute._addFileChildren(
    PrivateRoutesChatDemoRouteChildren,
  )

interface PrivateRoutesHashtagAnalyticsRouteChildren {
  PrivateRoutesHashtagAnalyticsInstagramRoute: typeof PrivateRoutesHashtagAnalyticsInstagramRoute
  PrivateRoutesHashtagAnalyticsIndexRoute: typeof PrivateRoutesHashtagAnalyticsIndexRoute
  PrivateRoutesHashtagAnalyticsTiktokHashtagRoute: typeof PrivateRoutesHashtagAnalyticsTiktokHashtagRoute
  PrivateRoutesHashtagAnalyticsTiktokKeywordRoute: typeof PrivateRoutesHashtagAnalyticsTiktokKeywordRoute
  PrivateRoutesHashtagAnalyticsTiktokIndexRoute: typeof PrivateRoutesHashtagAnalyticsTiktokIndexRoute
}

const PrivateRoutesHashtagAnalyticsRouteChildren: PrivateRoutesHashtagAnalyticsRouteChildren =
  {
    PrivateRoutesHashtagAnalyticsInstagramRoute:
      PrivateRoutesHashtagAnalyticsInstagramRoute,
    PrivateRoutesHashtagAnalyticsIndexRoute:
      PrivateRoutesHashtagAnalyticsIndexRoute,
    PrivateRoutesHashtagAnalyticsTiktokHashtagRoute:
      PrivateRoutesHashtagAnalyticsTiktokHashtagRoute,
    PrivateRoutesHashtagAnalyticsTiktokKeywordRoute:
      PrivateRoutesHashtagAnalyticsTiktokKeywordRoute,
    PrivateRoutesHashtagAnalyticsTiktokIndexRoute:
      PrivateRoutesHashtagAnalyticsTiktokIndexRoute,
  }

const PrivateRoutesHashtagAnalyticsRouteWithChildren =
  PrivateRoutesHashtagAnalyticsRoute._addFileChildren(
    PrivateRoutesHashtagAnalyticsRouteChildren,
  )

interface PrivateRoutesInfluencerIdProfileRouteChildren {
  PrivateRoutesInfluencerIdProfileCampaignsRoute: typeof PrivateRoutesInfluencerIdProfileCampaignsRoute
  PrivateRoutesInfluencerIdProfileCompareRoute: typeof PrivateRoutesInfluencerIdProfileCompareRoute
  PrivateRoutesInfluencerIdProfileIndexRoute: typeof PrivateRoutesInfluencerIdProfileIndexRoute
}

const PrivateRoutesInfluencerIdProfileRouteChildren: PrivateRoutesInfluencerIdProfileRouteChildren =
  {
    PrivateRoutesInfluencerIdProfileCampaignsRoute:
      PrivateRoutesInfluencerIdProfileCampaignsRoute,
    PrivateRoutesInfluencerIdProfileCompareRoute:
      PrivateRoutesInfluencerIdProfileCompareRoute,
    PrivateRoutesInfluencerIdProfileIndexRoute:
      PrivateRoutesInfluencerIdProfileIndexRoute,
  }

const PrivateRoutesInfluencerIdProfileRouteWithChildren =
  PrivateRoutesInfluencerIdProfileRoute._addFileChildren(
    PrivateRoutesInfluencerIdProfileRouteChildren,
  )

interface PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteChildren {
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute
}

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteChildren: PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteChildren =
  {
    PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute,
  }

const PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteWithChildren =
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute._addFileChildren(
    PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteChildren,
  )

interface PrivateRoutesInfluencerIdRouteChildren {
  PrivateRoutesInfluencerIdProfileRoute: typeof PrivateRoutesInfluencerIdProfileRouteWithChildren
  PrivateRoutesInfluencerIdIndexRoute: typeof PrivateRoutesInfluencerIdIndexRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteWithChildren
  PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute: typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute
}

const PrivateRoutesInfluencerIdRouteChildren: PrivateRoutesInfluencerIdRouteChildren =
  {
    PrivateRoutesInfluencerIdProfileRoute:
      PrivateRoutesInfluencerIdProfileRouteWithChildren,
    PrivateRoutesInfluencerIdIndexRoute: PrivateRoutesInfluencerIdIndexRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute,
    PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteWithChildren,
    PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute:
      PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute,
  }

const PrivateRoutesInfluencerIdRouteWithChildren =
  PrivateRoutesInfluencerIdRoute._addFileChildren(
    PrivateRoutesInfluencerIdRouteChildren,
  )

interface PrivateRoutesInfluencerRouteChildren {
  PrivateRoutesInfluencerIdRoute: typeof PrivateRoutesInfluencerIdRouteWithChildren
  PrivateRoutesInfluencerAddRoute: typeof PrivateRoutesInfluencerAddRoute
  PrivateRoutesInfluencerIndexRoute: typeof PrivateRoutesInfluencerIndexRoute
}

const PrivateRoutesInfluencerRouteChildren: PrivateRoutesInfluencerRouteChildren =
  {
    PrivateRoutesInfluencerIdRoute: PrivateRoutesInfluencerIdRouteWithChildren,
    PrivateRoutesInfluencerAddRoute: PrivateRoutesInfluencerAddRoute,
    PrivateRoutesInfluencerIndexRoute: PrivateRoutesInfluencerIndexRoute,
  }

const PrivateRoutesInfluencerRouteWithChildren =
  PrivateRoutesInfluencerRoute._addFileChildren(
    PrivateRoutesInfluencerRouteChildren,
  )

interface PrivateRoutesInfluencersSearchRouteChildren {
  PrivateRoutesInfluencersSearchDouyinRoute: typeof PrivateRoutesInfluencersSearchDouyinRoute
  PrivateRoutesInfluencersSearchFacebookRoute: typeof PrivateRoutesInfluencersSearchFacebookRoute
  PrivateRoutesInfluencersSearchFacebookpageRoute: typeof PrivateRoutesInfluencersSearchFacebookpageRoute
  PrivateRoutesInfluencersSearchInstagramRoute: typeof PrivateRoutesInfluencersSearchInstagramRoute
  PrivateRoutesInfluencersSearchThreadsRoute: typeof PrivateRoutesInfluencersSearchThreadsRoute
  PrivateRoutesInfluencersSearchTiktokRoute: typeof PrivateRoutesInfluencersSearchTiktokRoute
  PrivateRoutesInfluencersSearchTwitterRoute: typeof PrivateRoutesInfluencersSearchTwitterRoute
  PrivateRoutesInfluencersSearchXhsRoute: typeof PrivateRoutesInfluencersSearchXhsRoute
  PrivateRoutesInfluencersSearchYoutubeRoute: typeof PrivateRoutesInfluencersSearchYoutubeRoute
  PrivateRoutesInfluencersSearchIndexRoute: typeof PrivateRoutesInfluencersSearchIndexRoute
}

const PrivateRoutesInfluencersSearchRouteChildren: PrivateRoutesInfluencersSearchRouteChildren =
  {
    PrivateRoutesInfluencersSearchDouyinRoute:
      PrivateRoutesInfluencersSearchDouyinRoute,
    PrivateRoutesInfluencersSearchFacebookRoute:
      PrivateRoutesInfluencersSearchFacebookRoute,
    PrivateRoutesInfluencersSearchFacebookpageRoute:
      PrivateRoutesInfluencersSearchFacebookpageRoute,
    PrivateRoutesInfluencersSearchInstagramRoute:
      PrivateRoutesInfluencersSearchInstagramRoute,
    PrivateRoutesInfluencersSearchThreadsRoute:
      PrivateRoutesInfluencersSearchThreadsRoute,
    PrivateRoutesInfluencersSearchTiktokRoute:
      PrivateRoutesInfluencersSearchTiktokRoute,
    PrivateRoutesInfluencersSearchTwitterRoute:
      PrivateRoutesInfluencersSearchTwitterRoute,
    PrivateRoutesInfluencersSearchXhsRoute:
      PrivateRoutesInfluencersSearchXhsRoute,
    PrivateRoutesInfluencersSearchYoutubeRoute:
      PrivateRoutesInfluencersSearchYoutubeRoute,
    PrivateRoutesInfluencersSearchIndexRoute:
      PrivateRoutesInfluencersSearchIndexRoute,
  }

const PrivateRoutesInfluencersSearchRouteWithChildren =
  PrivateRoutesInfluencersSearchRoute._addFileChildren(
    PrivateRoutesInfluencersSearchRouteChildren,
  )

interface PrivateRoutesInfluencersRouteChildren {
  PrivateRoutesInfluencersProposalRoute: typeof PrivateRoutesInfluencersProposalRoute
  PrivateRoutesInfluencersSearchRoute: typeof PrivateRoutesInfluencersSearchRouteWithChildren
  PrivateRoutesInfluencersIndexRoute: typeof PrivateRoutesInfluencersIndexRoute
}

const PrivateRoutesInfluencersRouteChildren: PrivateRoutesInfluencersRouteChildren =
  {
    PrivateRoutesInfluencersProposalRoute:
      PrivateRoutesInfluencersProposalRoute,
    PrivateRoutesInfluencersSearchRoute:
      PrivateRoutesInfluencersSearchRouteWithChildren,
    PrivateRoutesInfluencersIndexRoute: PrivateRoutesInfluencersIndexRoute,
  }

const PrivateRoutesInfluencersRouteWithChildren =
  PrivateRoutesInfluencersRoute._addFileChildren(
    PrivateRoutesInfluencersRouteChildren,
  )

interface PrivateRoutesPackageIdContentSearchRouteChildren {
  PrivateRoutesPackageIdContentSearchDouyinRoute: typeof PrivateRoutesPackageIdContentSearchDouyinRoute
  PrivateRoutesPackageIdContentSearchFacebookRoute: typeof PrivateRoutesPackageIdContentSearchFacebookRoute
  PrivateRoutesPackageIdContentSearchFacebookpageRoute: typeof PrivateRoutesPackageIdContentSearchFacebookpageRoute
  PrivateRoutesPackageIdContentSearchInstagramRoute: typeof PrivateRoutesPackageIdContentSearchInstagramRoute
  PrivateRoutesPackageIdContentSearchThreadsRoute: typeof PrivateRoutesPackageIdContentSearchThreadsRoute
  PrivateRoutesPackageIdContentSearchTiktokRoute: typeof PrivateRoutesPackageIdContentSearchTiktokRoute
  PrivateRoutesPackageIdContentSearchTwitterRoute: typeof PrivateRoutesPackageIdContentSearchTwitterRoute
  PrivateRoutesPackageIdContentSearchXhsRoute: typeof PrivateRoutesPackageIdContentSearchXhsRoute
  PrivateRoutesPackageIdContentSearchYoutubeRoute: typeof PrivateRoutesPackageIdContentSearchYoutubeRoute
  PrivateRoutesPackageIdContentSearchIndexRoute: typeof PrivateRoutesPackageIdContentSearchIndexRoute
}

const PrivateRoutesPackageIdContentSearchRouteChildren: PrivateRoutesPackageIdContentSearchRouteChildren =
  {
    PrivateRoutesPackageIdContentSearchDouyinRoute:
      PrivateRoutesPackageIdContentSearchDouyinRoute,
    PrivateRoutesPackageIdContentSearchFacebookRoute:
      PrivateRoutesPackageIdContentSearchFacebookRoute,
    PrivateRoutesPackageIdContentSearchFacebookpageRoute:
      PrivateRoutesPackageIdContentSearchFacebookpageRoute,
    PrivateRoutesPackageIdContentSearchInstagramRoute:
      PrivateRoutesPackageIdContentSearchInstagramRoute,
    PrivateRoutesPackageIdContentSearchThreadsRoute:
      PrivateRoutesPackageIdContentSearchThreadsRoute,
    PrivateRoutesPackageIdContentSearchTiktokRoute:
      PrivateRoutesPackageIdContentSearchTiktokRoute,
    PrivateRoutesPackageIdContentSearchTwitterRoute:
      PrivateRoutesPackageIdContentSearchTwitterRoute,
    PrivateRoutesPackageIdContentSearchXhsRoute:
      PrivateRoutesPackageIdContentSearchXhsRoute,
    PrivateRoutesPackageIdContentSearchYoutubeRoute:
      PrivateRoutesPackageIdContentSearchYoutubeRoute,
    PrivateRoutesPackageIdContentSearchIndexRoute:
      PrivateRoutesPackageIdContentSearchIndexRoute,
  }

const PrivateRoutesPackageIdContentSearchRouteWithChildren =
  PrivateRoutesPackageIdContentSearchRoute._addFileChildren(
    PrivateRoutesPackageIdContentSearchRouteChildren,
  )

interface PrivateRoutesPackageIdContentRouteChildren {
  PrivateRoutesPackageIdContentListRoute: typeof PrivateRoutesPackageIdContentListRoute
  PrivateRoutesPackageIdContentSearchRoute: typeof PrivateRoutesPackageIdContentSearchRouteWithChildren
  PrivateRoutesPackageIdContentIndexRoute: typeof PrivateRoutesPackageIdContentIndexRoute
}

const PrivateRoutesPackageIdContentRouteChildren: PrivateRoutesPackageIdContentRouteChildren =
  {
    PrivateRoutesPackageIdContentListRoute:
      PrivateRoutesPackageIdContentListRoute,
    PrivateRoutesPackageIdContentSearchRoute:
      PrivateRoutesPackageIdContentSearchRouteWithChildren,
    PrivateRoutesPackageIdContentIndexRoute:
      PrivateRoutesPackageIdContentIndexRoute,
  }

const PrivateRoutesPackageIdContentRouteWithChildren =
  PrivateRoutesPackageIdContentRoute._addFileChildren(
    PrivateRoutesPackageIdContentRouteChildren,
  )

interface PrivateRoutesPackageIdRouteChildren {
  PrivateRoutesPackageIdContentRoute: typeof PrivateRoutesPackageIdContentRouteWithChildren
  PrivateRoutesPackageIdIndexRoute: typeof PrivateRoutesPackageIdIndexRoute
}

const PrivateRoutesPackageIdRouteChildren: PrivateRoutesPackageIdRouteChildren =
  {
    PrivateRoutesPackageIdContentRoute:
      PrivateRoutesPackageIdContentRouteWithChildren,
    PrivateRoutesPackageIdIndexRoute: PrivateRoutesPackageIdIndexRoute,
  }

const PrivateRoutesPackageIdRouteWithChildren =
  PrivateRoutesPackageIdRoute._addFileChildren(
    PrivateRoutesPackageIdRouteChildren,
  )

interface PrivateRoutesPackageRouteChildren {
  PrivateRoutesPackageIdRoute: typeof PrivateRoutesPackageIdRouteWithChildren
  PrivateRoutesPackageAddRoute: typeof PrivateRoutesPackageAddRoute
  PrivateRoutesPackageIndexRoute: typeof PrivateRoutesPackageIndexRoute
}

const PrivateRoutesPackageRouteChildren: PrivateRoutesPackageRouteChildren = {
  PrivateRoutesPackageIdRoute: PrivateRoutesPackageIdRouteWithChildren,
  PrivateRoutesPackageAddRoute: PrivateRoutesPackageAddRoute,
  PrivateRoutesPackageIndexRoute: PrivateRoutesPackageIndexRoute,
}

const PrivateRoutesPackageRouteWithChildren =
  PrivateRoutesPackageRoute._addFileChildren(PrivateRoutesPackageRouteChildren)

interface PrivateRoutesPartnerIdRouteChildren {
  PrivateRoutesPartnerIdBusinessRoute: typeof PrivateRoutesPartnerIdBusinessRoute
  PrivateRoutesPartnerIdUsersRoute: typeof PrivateRoutesPartnerIdUsersRoute
  PrivateRoutesPartnerIdIndexRoute: typeof PrivateRoutesPartnerIdIndexRoute
}

const PrivateRoutesPartnerIdRouteChildren: PrivateRoutesPartnerIdRouteChildren =
  {
    PrivateRoutesPartnerIdBusinessRoute: PrivateRoutesPartnerIdBusinessRoute,
    PrivateRoutesPartnerIdUsersRoute: PrivateRoutesPartnerIdUsersRoute,
    PrivateRoutesPartnerIdIndexRoute: PrivateRoutesPartnerIdIndexRoute,
  }

const PrivateRoutesPartnerIdRouteWithChildren =
  PrivateRoutesPartnerIdRoute._addFileChildren(
    PrivateRoutesPartnerIdRouteChildren,
  )

interface PrivateRoutesPartnerRouteChildren {
  PrivateRoutesPartnerIdRoute: typeof PrivateRoutesPartnerIdRouteWithChildren
  PrivateRoutesPartnerIndexRoute: typeof PrivateRoutesPartnerIndexRoute
}

const PrivateRoutesPartnerRouteChildren: PrivateRoutesPartnerRouteChildren = {
  PrivateRoutesPartnerIdRoute: PrivateRoutesPartnerIdRouteWithChildren,
  PrivateRoutesPartnerIndexRoute: PrivateRoutesPartnerIndexRoute,
}

const PrivateRoutesPartnerRouteWithChildren =
  PrivateRoutesPartnerRoute._addFileChildren(PrivateRoutesPartnerRouteChildren)

interface PrivateRoutesPostsInsightIdRouteChildren {
  PrivateRoutesPostsInsightIdPostIdRoute: typeof PrivateRoutesPostsInsightIdPostIdRoute
}

const PrivateRoutesPostsInsightIdRouteChildren: PrivateRoutesPostsInsightIdRouteChildren =
  {
    PrivateRoutesPostsInsightIdPostIdRoute:
      PrivateRoutesPostsInsightIdPostIdRoute,
  }

const PrivateRoutesPostsInsightIdRouteWithChildren =
  PrivateRoutesPostsInsightIdRoute._addFileChildren(
    PrivateRoutesPostsInsightIdRouteChildren,
  )

interface PrivateRoutesPostsInsightRouteChildren {
  PrivateRoutesPostsInsightIdRoute: typeof PrivateRoutesPostsInsightIdRouteWithChildren
  PrivateRoutesPostsInsightIndexRoute: typeof PrivateRoutesPostsInsightIndexRoute
}

const PrivateRoutesPostsInsightRouteChildren: PrivateRoutesPostsInsightRouteChildren =
  {
    PrivateRoutesPostsInsightIdRoute:
      PrivateRoutesPostsInsightIdRouteWithChildren,
    PrivateRoutesPostsInsightIndexRoute: PrivateRoutesPostsInsightIndexRoute,
  }

const PrivateRoutesPostsInsightRouteWithChildren =
  PrivateRoutesPostsInsightRoute._addFileChildren(
    PrivateRoutesPostsInsightRouteChildren,
  )

interface PrivateRoutesTalentAgencyIdRouteChildren {
  PrivateRoutesTalentAgencyIdBusinessRoute: typeof PrivateRoutesTalentAgencyIdBusinessRoute
  PrivateRoutesTalentAgencyIdUsersRoute: typeof PrivateRoutesTalentAgencyIdUsersRoute
  PrivateRoutesTalentAgencyIdIndexRoute: typeof PrivateRoutesTalentAgencyIdIndexRoute
}

const PrivateRoutesTalentAgencyIdRouteChildren: PrivateRoutesTalentAgencyIdRouteChildren =
  {
    PrivateRoutesTalentAgencyIdBusinessRoute:
      PrivateRoutesTalentAgencyIdBusinessRoute,
    PrivateRoutesTalentAgencyIdUsersRoute:
      PrivateRoutesTalentAgencyIdUsersRoute,
    PrivateRoutesTalentAgencyIdIndexRoute:
      PrivateRoutesTalentAgencyIdIndexRoute,
  }

const PrivateRoutesTalentAgencyIdRouteWithChildren =
  PrivateRoutesTalentAgencyIdRoute._addFileChildren(
    PrivateRoutesTalentAgencyIdRouteChildren,
  )

interface PrivateRoutesTalentAgencyRouteChildren {
  PrivateRoutesTalentAgencyIdRoute: typeof PrivateRoutesTalentAgencyIdRouteWithChildren
  PrivateRoutesTalentAgencyAddRoute: typeof PrivateRoutesTalentAgencyAddRoute
  PrivateRoutesTalentAgencyIndexRoute: typeof PrivateRoutesTalentAgencyIndexRoute
}

const PrivateRoutesTalentAgencyRouteChildren: PrivateRoutesTalentAgencyRouteChildren =
  {
    PrivateRoutesTalentAgencyIdRoute:
      PrivateRoutesTalentAgencyIdRouteWithChildren,
    PrivateRoutesTalentAgencyAddRoute: PrivateRoutesTalentAgencyAddRoute,
    PrivateRoutesTalentAgencyIndexRoute: PrivateRoutesTalentAgencyIndexRoute,
  }

const PrivateRoutesTalentAgencyRouteWithChildren =
  PrivateRoutesTalentAgencyRoute._addFileChildren(
    PrivateRoutesTalentAgencyRouteChildren,
  )

interface PrivateRoutesTiktokAdCampaignReportRouteChildren {
  PrivateRoutesTiktokAdCampaignReportIndexRoute: typeof PrivateRoutesTiktokAdCampaignReportIndexRoute
  PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute: typeof PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute
  PrivateRoutesTiktokAdCampaignReportIdIndexRoute: typeof PrivateRoutesTiktokAdCampaignReportIdIndexRoute
}

const PrivateRoutesTiktokAdCampaignReportRouteChildren: PrivateRoutesTiktokAdCampaignReportRouteChildren =
  {
    PrivateRoutesTiktokAdCampaignReportIndexRoute:
      PrivateRoutesTiktokAdCampaignReportIndexRoute,
    PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute:
      PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute,
    PrivateRoutesTiktokAdCampaignReportIdIndexRoute:
      PrivateRoutesTiktokAdCampaignReportIdIndexRoute,
  }

const PrivateRoutesTiktokAdCampaignReportRouteWithChildren =
  PrivateRoutesTiktokAdCampaignReportRoute._addFileChildren(
    PrivateRoutesTiktokAdCampaignReportRouteChildren,
  )

interface PrivateRoutesTiktokCampaignsIdPostsRouteChildren {
  PrivateRoutesTiktokCampaignsIdPostsPostIdRoute: typeof PrivateRoutesTiktokCampaignsIdPostsPostIdRoute
  PrivateRoutesTiktokCampaignsIdPostsIndexRoute: typeof PrivateRoutesTiktokCampaignsIdPostsIndexRoute
}

const PrivateRoutesTiktokCampaignsIdPostsRouteChildren: PrivateRoutesTiktokCampaignsIdPostsRouteChildren =
  {
    PrivateRoutesTiktokCampaignsIdPostsPostIdRoute:
      PrivateRoutesTiktokCampaignsIdPostsPostIdRoute,
    PrivateRoutesTiktokCampaignsIdPostsIndexRoute:
      PrivateRoutesTiktokCampaignsIdPostsIndexRoute,
  }

const PrivateRoutesTiktokCampaignsIdPostsRouteWithChildren =
  PrivateRoutesTiktokCampaignsIdPostsRoute._addFileChildren(
    PrivateRoutesTiktokCampaignsIdPostsRouteChildren,
  )

interface PrivateRoutesTiktokCampaignsIdRouteChildren {
  PrivateRoutesTiktokCampaignsIdInfluencersRoute: typeof PrivateRoutesTiktokCampaignsIdInfluencersRoute
  PrivateRoutesTiktokCampaignsIdPostsRoute: typeof PrivateRoutesTiktokCampaignsIdPostsRouteWithChildren
  PrivateRoutesTiktokCampaignsIdReportRoute: typeof PrivateRoutesTiktokCampaignsIdReportRoute
  PrivateRoutesTiktokCampaignsIdIndexRoute: typeof PrivateRoutesTiktokCampaignsIdIndexRoute
}

const PrivateRoutesTiktokCampaignsIdRouteChildren: PrivateRoutesTiktokCampaignsIdRouteChildren =
  {
    PrivateRoutesTiktokCampaignsIdInfluencersRoute:
      PrivateRoutesTiktokCampaignsIdInfluencersRoute,
    PrivateRoutesTiktokCampaignsIdPostsRoute:
      PrivateRoutesTiktokCampaignsIdPostsRouteWithChildren,
    PrivateRoutesTiktokCampaignsIdReportRoute:
      PrivateRoutesTiktokCampaignsIdReportRoute,
    PrivateRoutesTiktokCampaignsIdIndexRoute:
      PrivateRoutesTiktokCampaignsIdIndexRoute,
  }

const PrivateRoutesTiktokCampaignsIdRouteWithChildren =
  PrivateRoutesTiktokCampaignsIdRoute._addFileChildren(
    PrivateRoutesTiktokCampaignsIdRouteChildren,
  )

interface PrivateRoutesTiktokCampaignsRouteChildren {
  PrivateRoutesTiktokCampaignsIdRoute: typeof PrivateRoutesTiktokCampaignsIdRouteWithChildren
  PrivateRoutesTiktokCampaignsAddRoute: typeof PrivateRoutesTiktokCampaignsAddRoute
  PrivateRoutesTiktokCampaignsReviewInfluencersRoute: typeof PrivateRoutesTiktokCampaignsReviewInfluencersRoute
  PrivateRoutesTiktokCampaignsIndexRoute: typeof PrivateRoutesTiktokCampaignsIndexRoute
}

const PrivateRoutesTiktokCampaignsRouteChildren: PrivateRoutesTiktokCampaignsRouteChildren =
  {
    PrivateRoutesTiktokCampaignsIdRoute:
      PrivateRoutesTiktokCampaignsIdRouteWithChildren,
    PrivateRoutesTiktokCampaignsAddRoute: PrivateRoutesTiktokCampaignsAddRoute,
    PrivateRoutesTiktokCampaignsReviewInfluencersRoute:
      PrivateRoutesTiktokCampaignsReviewInfluencersRoute,
    PrivateRoutesTiktokCampaignsIndexRoute:
      PrivateRoutesTiktokCampaignsIndexRoute,
  }

const PrivateRoutesTiktokCampaignsRouteWithChildren =
  PrivateRoutesTiktokCampaignsRoute._addFileChildren(
    PrivateRoutesTiktokCampaignsRouteChildren,
  )

interface PrivateRoutesTiktokRouteChildren {
  PrivateRoutesTiktokAdAccountsRoute: typeof PrivateRoutesTiktokAdAccountsRoute
  PrivateRoutesTiktokAdCampaignReportRoute: typeof PrivateRoutesTiktokAdCampaignReportRouteWithChildren
  PrivateRoutesTiktokAdvertiserDetailsRoute: typeof PrivateRoutesTiktokAdvertiserDetailsRoute
  PrivateRoutesTiktokBudgetAllocationRoute: typeof PrivateRoutesTiktokBudgetAllocationRoute
  PrivateRoutesTiktokCampaignsRoute: typeof PrivateRoutesTiktokCampaignsRouteWithChildren
  PrivateRoutesTiktokFailedPaymentRoute: typeof PrivateRoutesTiktokFailedPaymentRoute
  PrivateRoutesTiktokSubscriptionBillingRoute: typeof PrivateRoutesTiktokSubscriptionBillingRoute
}

const PrivateRoutesTiktokRouteChildren: PrivateRoutesTiktokRouteChildren = {
  PrivateRoutesTiktokAdAccountsRoute: PrivateRoutesTiktokAdAccountsRoute,
  PrivateRoutesTiktokAdCampaignReportRoute:
    PrivateRoutesTiktokAdCampaignReportRouteWithChildren,
  PrivateRoutesTiktokAdvertiserDetailsRoute:
    PrivateRoutesTiktokAdvertiserDetailsRoute,
  PrivateRoutesTiktokBudgetAllocationRoute:
    PrivateRoutesTiktokBudgetAllocationRoute,
  PrivateRoutesTiktokCampaignsRoute:
    PrivateRoutesTiktokCampaignsRouteWithChildren,
  PrivateRoutesTiktokFailedPaymentRoute: PrivateRoutesTiktokFailedPaymentRoute,
  PrivateRoutesTiktokSubscriptionBillingRoute:
    PrivateRoutesTiktokSubscriptionBillingRoute,
}

const PrivateRoutesTiktokRouteWithChildren =
  PrivateRoutesTiktokRoute._addFileChildren(PrivateRoutesTiktokRouteChildren)

interface PrivateRoutesTrendingRouteChildren {
  PrivateRoutesTrendingInfluencersRoute: typeof PrivateRoutesTrendingInfluencersRoute
  PrivateRoutesTrendingPostsRoute: typeof PrivateRoutesTrendingPostsRoute
  PrivateRoutesTrendingIndexRoute: typeof PrivateRoutesTrendingIndexRoute
}

const PrivateRoutesTrendingRouteChildren: PrivateRoutesTrendingRouteChildren = {
  PrivateRoutesTrendingInfluencersRoute: PrivateRoutesTrendingInfluencersRoute,
  PrivateRoutesTrendingPostsRoute: PrivateRoutesTrendingPostsRoute,
  PrivateRoutesTrendingIndexRoute: PrivateRoutesTrendingIndexRoute,
}

const PrivateRoutesTrendingRouteWithChildren =
  PrivateRoutesTrendingRoute._addFileChildren(
    PrivateRoutesTrendingRouteChildren,
  )

interface PrivateRoutesUserRouteChildren {
  PrivateRoutesUserIdRoute: typeof PrivateRoutesUserIdRoute
  PrivateRoutesUserAddRoute: typeof PrivateRoutesUserAddRoute
  PrivateRoutesUserLinkedRoute: typeof PrivateRoutesUserLinkedRoute
  PrivateRoutesUserIndexRoute: typeof PrivateRoutesUserIndexRoute
}

const PrivateRoutesUserRouteChildren: PrivateRoutesUserRouteChildren = {
  PrivateRoutesUserIdRoute: PrivateRoutesUserIdRoute,
  PrivateRoutesUserAddRoute: PrivateRoutesUserAddRoute,
  PrivateRoutesUserLinkedRoute: PrivateRoutesUserLinkedRoute,
  PrivateRoutesUserIndexRoute: PrivateRoutesUserIndexRoute,
}

const PrivateRoutesUserRouteWithChildren =
  PrivateRoutesUserRoute._addFileChildren(PrivateRoutesUserRouteChildren)

interface PrivateRoutesYoutubeCmsRouteChildren {
  PrivateRoutesYoutubeCmsCmsChannelIdRoute: typeof PrivateRoutesYoutubeCmsCmsChannelIdRoute
  PrivateRoutesYoutubeCmsConfirmedRevenueRoute: typeof PrivateRoutesYoutubeCmsConfirmedRevenueRoute
  PrivateRoutesYoutubeCmsIndexRoute: typeof PrivateRoutesYoutubeCmsIndexRoute
}

const PrivateRoutesYoutubeCmsRouteChildren: PrivateRoutesYoutubeCmsRouteChildren =
  {
    PrivateRoutesYoutubeCmsCmsChannelIdRoute:
      PrivateRoutesYoutubeCmsCmsChannelIdRoute,
    PrivateRoutesYoutubeCmsConfirmedRevenueRoute:
      PrivateRoutesYoutubeCmsConfirmedRevenueRoute,
    PrivateRoutesYoutubeCmsIndexRoute: PrivateRoutesYoutubeCmsIndexRoute,
  }

const PrivateRoutesYoutubeCmsRouteWithChildren =
  PrivateRoutesYoutubeCmsRoute._addFileChildren(
    PrivateRoutesYoutubeCmsRouteChildren,
  )

interface PrivateRoutesEngagementIdInfluencerRouteChildren {
  PrivateRoutesEngagementIdInfluencerCampaignsRoute: typeof PrivateRoutesEngagementIdInfluencerCampaignsRoute
  PrivateRoutesEngagementIdInfluencerDouyinRoute: typeof PrivateRoutesEngagementIdInfluencerDouyinRoute
  PrivateRoutesEngagementIdInfluencerFacebookRoute: typeof PrivateRoutesEngagementIdInfluencerFacebookRoute
  PrivateRoutesEngagementIdInfluencerFacebookpageRoute: typeof PrivateRoutesEngagementIdInfluencerFacebookpageRoute
  PrivateRoutesEngagementIdInfluencerInstagramRoute: typeof PrivateRoutesEngagementIdInfluencerInstagramRoute
  PrivateRoutesEngagementIdInfluencerPackagesRoute: typeof PrivateRoutesEngagementIdInfluencerPackagesRoute
  PrivateRoutesEngagementIdInfluencerPostsRoute: typeof PrivateRoutesEngagementIdInfluencerPostsRoute
  PrivateRoutesEngagementIdInfluencerThreadsRoute: typeof PrivateRoutesEngagementIdInfluencerThreadsRoute
  PrivateRoutesEngagementIdInfluencerTiktokRoute: typeof PrivateRoutesEngagementIdInfluencerTiktokRoute
  PrivateRoutesEngagementIdInfluencerTwitterRoute: typeof PrivateRoutesEngagementIdInfluencerTwitterRoute
  PrivateRoutesEngagementIdInfluencerXhsRoute: typeof PrivateRoutesEngagementIdInfluencerXhsRoute
  PrivateRoutesEngagementIdInfluencerYoutubeRoute: typeof PrivateRoutesEngagementIdInfluencerYoutubeRoute
  PrivateRoutesEngagementIdInfluencerIndexRoute: typeof PrivateRoutesEngagementIdInfluencerIndexRoute
}

const PrivateRoutesEngagementIdInfluencerRouteChildren: PrivateRoutesEngagementIdInfluencerRouteChildren =
  {
    PrivateRoutesEngagementIdInfluencerCampaignsRoute:
      PrivateRoutesEngagementIdInfluencerCampaignsRoute,
    PrivateRoutesEngagementIdInfluencerDouyinRoute:
      PrivateRoutesEngagementIdInfluencerDouyinRoute,
    PrivateRoutesEngagementIdInfluencerFacebookRoute:
      PrivateRoutesEngagementIdInfluencerFacebookRoute,
    PrivateRoutesEngagementIdInfluencerFacebookpageRoute:
      PrivateRoutesEngagementIdInfluencerFacebookpageRoute,
    PrivateRoutesEngagementIdInfluencerInstagramRoute:
      PrivateRoutesEngagementIdInfluencerInstagramRoute,
    PrivateRoutesEngagementIdInfluencerPackagesRoute:
      PrivateRoutesEngagementIdInfluencerPackagesRoute,
    PrivateRoutesEngagementIdInfluencerPostsRoute:
      PrivateRoutesEngagementIdInfluencerPostsRoute,
    PrivateRoutesEngagementIdInfluencerThreadsRoute:
      PrivateRoutesEngagementIdInfluencerThreadsRoute,
    PrivateRoutesEngagementIdInfluencerTiktokRoute:
      PrivateRoutesEngagementIdInfluencerTiktokRoute,
    PrivateRoutesEngagementIdInfluencerTwitterRoute:
      PrivateRoutesEngagementIdInfluencerTwitterRoute,
    PrivateRoutesEngagementIdInfluencerXhsRoute:
      PrivateRoutesEngagementIdInfluencerXhsRoute,
    PrivateRoutesEngagementIdInfluencerYoutubeRoute:
      PrivateRoutesEngagementIdInfluencerYoutubeRoute,
    PrivateRoutesEngagementIdInfluencerIndexRoute:
      PrivateRoutesEngagementIdInfluencerIndexRoute,
  }

const PrivateRoutesEngagementIdInfluencerRouteWithChildren =
  PrivateRoutesEngagementIdInfluencerRoute._addFileChildren(
    PrivateRoutesEngagementIdInfluencerRouteChildren,
  )

interface PrivateRoutesEngagementIdPostsRouteChildren {
  PrivateRoutesEngagementIdPostsPostIdRoute: typeof PrivateRoutesEngagementIdPostsPostIdRoute
  PrivateRoutesEngagementIdPostsAddRoute: typeof PrivateRoutesEngagementIdPostsAddRoute
  PrivateRoutesEngagementIdPostsIndexRoute: typeof PrivateRoutesEngagementIdPostsIndexRoute
}

const PrivateRoutesEngagementIdPostsRouteChildren: PrivateRoutesEngagementIdPostsRouteChildren =
  {
    PrivateRoutesEngagementIdPostsPostIdRoute:
      PrivateRoutesEngagementIdPostsPostIdRoute,
    PrivateRoutesEngagementIdPostsAddRoute:
      PrivateRoutesEngagementIdPostsAddRoute,
    PrivateRoutesEngagementIdPostsIndexRoute:
      PrivateRoutesEngagementIdPostsIndexRoute,
  }

const PrivateRoutesEngagementIdPostsRouteWithChildren =
  PrivateRoutesEngagementIdPostsRoute._addFileChildren(
    PrivateRoutesEngagementIdPostsRouteChildren,
  )

interface PrivateRoutesEngagementIdRouteChildren {
  PrivateRoutesEngagementIdInfluencerRoute: typeof PrivateRoutesEngagementIdInfluencerRouteWithChildren
  PrivateRoutesEngagementIdPostsRoute: typeof PrivateRoutesEngagementIdPostsRouteWithChildren
  PrivateRoutesEngagementIdProposalRoute: typeof PrivateRoutesEngagementIdProposalRoute
  PrivateRoutesEngagementIdReportRoute: typeof PrivateRoutesEngagementIdReportRoute
  PrivateRoutesEngagementIdTrackingRoute: typeof PrivateRoutesEngagementIdTrackingRoute
  PrivateRoutesEngagementIdTrackingReferralCodesRoute: typeof PrivateRoutesEngagementIdTrackingReferralCodesRoute
  PrivateRoutesEngagementIdIndexRoute: typeof PrivateRoutesEngagementIdIndexRoute
}

const PrivateRoutesEngagementIdRouteChildren: PrivateRoutesEngagementIdRouteChildren =
  {
    PrivateRoutesEngagementIdInfluencerRoute:
      PrivateRoutesEngagementIdInfluencerRouteWithChildren,
    PrivateRoutesEngagementIdPostsRoute:
      PrivateRoutesEngagementIdPostsRouteWithChildren,
    PrivateRoutesEngagementIdProposalRoute:
      PrivateRoutesEngagementIdProposalRoute,
    PrivateRoutesEngagementIdReportRoute: PrivateRoutesEngagementIdReportRoute,
    PrivateRoutesEngagementIdTrackingRoute:
      PrivateRoutesEngagementIdTrackingRoute,
    PrivateRoutesEngagementIdTrackingReferralCodesRoute:
      PrivateRoutesEngagementIdTrackingReferralCodesRoute,
    PrivateRoutesEngagementIdIndexRoute: PrivateRoutesEngagementIdIndexRoute,
  }

const PrivateRoutesEngagementIdRouteWithChildren =
  PrivateRoutesEngagementIdRoute._addFileChildren(
    PrivateRoutesEngagementIdRouteChildren,
  )

interface PrivateRoutesMarketplaceIdPostsRouteChildren {
  PrivateRoutesMarketplaceIdPostsPostIdRoute: typeof PrivateRoutesMarketplaceIdPostsPostIdRoute
  PrivateRoutesMarketplaceIdPostsIndexRoute: typeof PrivateRoutesMarketplaceIdPostsIndexRoute
}

const PrivateRoutesMarketplaceIdPostsRouteChildren: PrivateRoutesMarketplaceIdPostsRouteChildren =
  {
    PrivateRoutesMarketplaceIdPostsPostIdRoute:
      PrivateRoutesMarketplaceIdPostsPostIdRoute,
    PrivateRoutesMarketplaceIdPostsIndexRoute:
      PrivateRoutesMarketplaceIdPostsIndexRoute,
  }

const PrivateRoutesMarketplaceIdPostsRouteWithChildren =
  PrivateRoutesMarketplaceIdPostsRoute._addFileChildren(
    PrivateRoutesMarketplaceIdPostsRouteChildren,
  )

interface PrivateRoutesMarketplaceIdRouteChildren {
  PrivateRoutesMarketplaceIdApplicantRoute: typeof PrivateRoutesMarketplaceIdApplicantRoute
  PrivateRoutesMarketplaceIdCommissionsRoute: typeof PrivateRoutesMarketplaceIdCommissionsRoute
  PrivateRoutesMarketplaceIdInfluencerRoute: typeof PrivateRoutesMarketplaceIdInfluencerRoute
  PrivateRoutesMarketplaceIdJoinedInfluencersRoute: typeof PrivateRoutesMarketplaceIdJoinedInfluencersRoute
  PrivateRoutesMarketplaceIdPostsRoute: typeof PrivateRoutesMarketplaceIdPostsRouteWithChildren
  PrivateRoutesMarketplaceIdReportRoute: typeof PrivateRoutesMarketplaceIdReportRoute
  PrivateRoutesMarketplaceIdReportAffiliateRoute: typeof PrivateRoutesMarketplaceIdReportAffiliateRoute
  PrivateRoutesMarketplaceIdTrackingRoute: typeof PrivateRoutesMarketplaceIdTrackingRoute
  PrivateRoutesMarketplaceIdTrackingReferralCodesRoute: typeof PrivateRoutesMarketplaceIdTrackingReferralCodesRoute
  PrivateRoutesMarketplaceIdIndexRoute: typeof PrivateRoutesMarketplaceIdIndexRoute
}

const PrivateRoutesMarketplaceIdRouteChildren: PrivateRoutesMarketplaceIdRouteChildren =
  {
    PrivateRoutesMarketplaceIdApplicantRoute:
      PrivateRoutesMarketplaceIdApplicantRoute,
    PrivateRoutesMarketplaceIdCommissionsRoute:
      PrivateRoutesMarketplaceIdCommissionsRoute,
    PrivateRoutesMarketplaceIdInfluencerRoute:
      PrivateRoutesMarketplaceIdInfluencerRoute,
    PrivateRoutesMarketplaceIdJoinedInfluencersRoute:
      PrivateRoutesMarketplaceIdJoinedInfluencersRoute,
    PrivateRoutesMarketplaceIdPostsRoute:
      PrivateRoutesMarketplaceIdPostsRouteWithChildren,
    PrivateRoutesMarketplaceIdReportRoute:
      PrivateRoutesMarketplaceIdReportRoute,
    PrivateRoutesMarketplaceIdReportAffiliateRoute:
      PrivateRoutesMarketplaceIdReportAffiliateRoute,
    PrivateRoutesMarketplaceIdTrackingRoute:
      PrivateRoutesMarketplaceIdTrackingRoute,
    PrivateRoutesMarketplaceIdTrackingReferralCodesRoute:
      PrivateRoutesMarketplaceIdTrackingReferralCodesRoute,
    PrivateRoutesMarketplaceIdIndexRoute: PrivateRoutesMarketplaceIdIndexRoute,
  }

const PrivateRoutesMarketplaceIdRouteWithChildren =
  PrivateRoutesMarketplaceIdRoute._addFileChildren(
    PrivateRoutesMarketplaceIdRouteChildren,
  )

interface PrivateRoutesMarketplaceAddRouteChildren {
  PrivateRoutesMarketplaceAddReferralCodeTrackingRoute: typeof PrivateRoutesMarketplaceAddReferralCodeTrackingRoute
  PrivateRoutesMarketplaceAddSocialMediaTrackingRoute: typeof PrivateRoutesMarketplaceAddSocialMediaTrackingRoute
  PrivateRoutesMarketplaceAddUrlTrackingRoute: typeof PrivateRoutesMarketplaceAddUrlTrackingRoute
  PrivateRoutesMarketplaceAddIndexRoute: typeof PrivateRoutesMarketplaceAddIndexRoute
  PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute: typeof PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute
  PrivateRoutesMarketplaceAddECommerceIndexRoute: typeof PrivateRoutesMarketplaceAddECommerceIndexRoute
}

const PrivateRoutesMarketplaceAddRouteChildren: PrivateRoutesMarketplaceAddRouteChildren =
  {
    PrivateRoutesMarketplaceAddReferralCodeTrackingRoute:
      PrivateRoutesMarketplaceAddReferralCodeTrackingRoute,
    PrivateRoutesMarketplaceAddSocialMediaTrackingRoute:
      PrivateRoutesMarketplaceAddSocialMediaTrackingRoute,
    PrivateRoutesMarketplaceAddUrlTrackingRoute:
      PrivateRoutesMarketplaceAddUrlTrackingRoute,
    PrivateRoutesMarketplaceAddIndexRoute:
      PrivateRoutesMarketplaceAddIndexRoute,
    PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute:
      PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute,
    PrivateRoutesMarketplaceAddECommerceIndexRoute:
      PrivateRoutesMarketplaceAddECommerceIndexRoute,
  }

const PrivateRoutesMarketplaceAddRouteWithChildren =
  PrivateRoutesMarketplaceAddRoute._addFileChildren(
    PrivateRoutesMarketplaceAddRouteChildren,
  )

interface PrivateRoutesRouteChildren {
  PrivateRoutesAdvertiserRoute: typeof PrivateRoutesAdvertiserRouteWithChildren
  PrivateRoutesAgencyRoute: typeof PrivateRoutesAgencyRouteWithChildren
  PrivateRoutesAnalyticsRoute: typeof PrivateRoutesAnalyticsRouteWithChildren
  PrivateRoutesAssetPreviewRoute: typeof PrivateRoutesAssetPreviewRoute
  PrivateRoutesAutoManagedRoute: typeof PrivateRoutesAutoManagedRouteWithChildren
  PrivateRoutesCampaignPostsDiscoveryRoute: typeof PrivateRoutesCampaignPostsDiscoveryRoute
  PrivateRoutesChatDemoRoute: typeof PrivateRoutesChatDemoRouteWithChildren
  PrivateRoutesHashtagAnalyticsRoute: typeof PrivateRoutesHashtagAnalyticsRouteWithChildren
  PrivateRoutesInfluencerRoute: typeof PrivateRoutesInfluencerRouteWithChildren
  PrivateRoutesInfluencersRoute: typeof PrivateRoutesInfluencersRouteWithChildren
  PrivateRoutesInvoicesRoute: typeof PrivateRoutesInvoicesRoute
  PrivateRoutesPackageRoute: typeof PrivateRoutesPackageRouteWithChildren
  PrivateRoutesPartnerRoute: typeof PrivateRoutesPartnerRouteWithChildren
  PrivateRoutesPaymentsRoute: typeof PrivateRoutesPaymentsRoute
  PrivateRoutesPostsRoute: typeof PrivateRoutesPostsRoute
  PrivateRoutesPostsInsightRoute: typeof PrivateRoutesPostsInsightRouteWithChildren
  PrivateRoutesReportRoute: typeof PrivateRoutesReportRoute
  PrivateRoutesSettingsRoute: typeof PrivateRoutesSettingsRoute
  PrivateRoutesTalentAgencyRoute: typeof PrivateRoutesTalentAgencyRouteWithChildren
  PrivateRoutesTiktokRoute: typeof PrivateRoutesTiktokRouteWithChildren
  PrivateRoutesTrendingRoute: typeof PrivateRoutesTrendingRouteWithChildren
  PrivateRoutesUserRoute: typeof PrivateRoutesUserRouteWithChildren
  PrivateRoutesUserSettingsRoute: typeof PrivateRoutesUserSettingsRoute
  PrivateRoutesYoutubeCmsRoute: typeof PrivateRoutesYoutubeCmsRouteWithChildren
  PrivateRoutesEngagementIdRoute: typeof PrivateRoutesEngagementIdRouteWithChildren
  PrivateRoutesEngagementAddRoute: typeof PrivateRoutesEngagementAddRoute
  PrivateRoutesMarketplaceIdRoute: typeof PrivateRoutesMarketplaceIdRouteWithChildren
  PrivateRoutesMarketplaceAddRoute: typeof PrivateRoutesMarketplaceAddRouteWithChildren
  PrivateRoutesMarketplaceReorderRoute: typeof PrivateRoutesMarketplaceReorderRoute
  PrivateRoutesEngagementIndexRoute: typeof PrivateRoutesEngagementIndexRoute
  PrivateRoutesMarketplaceIndexRoute: typeof PrivateRoutesMarketplaceIndexRoute
  PrivateRoutesRedirectAnalyticsConnectProviderRoute: typeof PrivateRoutesRedirectAnalyticsConnectProviderRoute
  PrivateRoutesRedirectAnalyticsListConnectProviderRoute: typeof PrivateRoutesRedirectAnalyticsListConnectProviderRoute
  PrivateRoutesRedirectAnalyticsSigninProviderRoute: typeof PrivateRoutesRedirectAnalyticsSigninProviderRoute
  PrivateRoutesRedirectConnectTiktokBusinessRoute: typeof PrivateRoutesRedirectConnectTiktokBusinessRoute
  PrivateRoutesRedirectHiddenYoutubeListConnectRoute: typeof PrivateRoutesRedirectHiddenYoutubeListConnectRoute
  PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute: typeof PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute
  PrivateRoutesRedirectTalentSigninProviderRoute: typeof PrivateRoutesRedirectTalentSigninProviderRoute
}

const PrivateRoutesRouteChildren: PrivateRoutesRouteChildren = {
  PrivateRoutesAdvertiserRoute: PrivateRoutesAdvertiserRouteWithChildren,
  PrivateRoutesAgencyRoute: PrivateRoutesAgencyRouteWithChildren,
  PrivateRoutesAnalyticsRoute: PrivateRoutesAnalyticsRouteWithChildren,
  PrivateRoutesAssetPreviewRoute: PrivateRoutesAssetPreviewRoute,
  PrivateRoutesAutoManagedRoute: PrivateRoutesAutoManagedRouteWithChildren,
  PrivateRoutesCampaignPostsDiscoveryRoute:
    PrivateRoutesCampaignPostsDiscoveryRoute,
  PrivateRoutesChatDemoRoute: PrivateRoutesChatDemoRouteWithChildren,
  PrivateRoutesHashtagAnalyticsRoute:
    PrivateRoutesHashtagAnalyticsRouteWithChildren,
  PrivateRoutesInfluencerRoute: PrivateRoutesInfluencerRouteWithChildren,
  PrivateRoutesInfluencersRoute: PrivateRoutesInfluencersRouteWithChildren,
  PrivateRoutesInvoicesRoute: PrivateRoutesInvoicesRoute,
  PrivateRoutesPackageRoute: PrivateRoutesPackageRouteWithChildren,
  PrivateRoutesPartnerRoute: PrivateRoutesPartnerRouteWithChildren,
  PrivateRoutesPaymentsRoute: PrivateRoutesPaymentsRoute,
  PrivateRoutesPostsRoute: PrivateRoutesPostsRoute,
  PrivateRoutesPostsInsightRoute: PrivateRoutesPostsInsightRouteWithChildren,
  PrivateRoutesReportRoute: PrivateRoutesReportRoute,
  PrivateRoutesSettingsRoute: PrivateRoutesSettingsRoute,
  PrivateRoutesTalentAgencyRoute: PrivateRoutesTalentAgencyRouteWithChildren,
  PrivateRoutesTiktokRoute: PrivateRoutesTiktokRouteWithChildren,
  PrivateRoutesTrendingRoute: PrivateRoutesTrendingRouteWithChildren,
  PrivateRoutesUserRoute: PrivateRoutesUserRouteWithChildren,
  PrivateRoutesUserSettingsRoute: PrivateRoutesUserSettingsRoute,
  PrivateRoutesYoutubeCmsRoute: PrivateRoutesYoutubeCmsRouteWithChildren,
  PrivateRoutesEngagementIdRoute: PrivateRoutesEngagementIdRouteWithChildren,
  PrivateRoutesEngagementAddRoute: PrivateRoutesEngagementAddRoute,
  PrivateRoutesMarketplaceIdRoute: PrivateRoutesMarketplaceIdRouteWithChildren,
  PrivateRoutesMarketplaceAddRoute:
    PrivateRoutesMarketplaceAddRouteWithChildren,
  PrivateRoutesMarketplaceReorderRoute: PrivateRoutesMarketplaceReorderRoute,
  PrivateRoutesEngagementIndexRoute: PrivateRoutesEngagementIndexRoute,
  PrivateRoutesMarketplaceIndexRoute: PrivateRoutesMarketplaceIndexRoute,
  PrivateRoutesRedirectAnalyticsConnectProviderRoute:
    PrivateRoutesRedirectAnalyticsConnectProviderRoute,
  PrivateRoutesRedirectAnalyticsListConnectProviderRoute:
    PrivateRoutesRedirectAnalyticsListConnectProviderRoute,
  PrivateRoutesRedirectAnalyticsSigninProviderRoute:
    PrivateRoutesRedirectAnalyticsSigninProviderRoute,
  PrivateRoutesRedirectConnectTiktokBusinessRoute:
    PrivateRoutesRedirectConnectTiktokBusinessRoute,
  PrivateRoutesRedirectHiddenYoutubeListConnectRoute:
    PrivateRoutesRedirectHiddenYoutubeListConnectRoute,
  PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute:
    PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute,
  PrivateRoutesRedirectTalentSigninProviderRoute:
    PrivateRoutesRedirectTalentSigninProviderRoute,
}

const PrivateRoutesRouteWithChildren = PrivateRoutesRoute._addFileChildren(
  PrivateRoutesRouteChildren,
)

interface PublicRoutesTiktokOnboardingRouteChildren {
  PublicRoutesTiktokOnboardingAdAccountRoute: typeof PublicRoutesTiktokOnboardingAdAccountRoute
  PublicRoutesTiktokOnboardingPasswordEnterRoute: typeof PublicRoutesTiktokOnboardingPasswordEnterRoute
  PublicRoutesTiktokOnboardingSelectPlanRoute: typeof PublicRoutesTiktokOnboardingSelectPlanRoute
  PublicRoutesTiktokOnboardingSuccessRoute: typeof PublicRoutesTiktokOnboardingSuccessRoute
  PublicRoutesTiktokOnboardingIndexRoute: typeof PublicRoutesTiktokOnboardingIndexRoute
  PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute: typeof PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute
}

const PublicRoutesTiktokOnboardingRouteChildren: PublicRoutesTiktokOnboardingRouteChildren =
  {
    PublicRoutesTiktokOnboardingAdAccountRoute:
      PublicRoutesTiktokOnboardingAdAccountRoute,
    PublicRoutesTiktokOnboardingPasswordEnterRoute:
      PublicRoutesTiktokOnboardingPasswordEnterRoute,
    PublicRoutesTiktokOnboardingSelectPlanRoute:
      PublicRoutesTiktokOnboardingSelectPlanRoute,
    PublicRoutesTiktokOnboardingSuccessRoute:
      PublicRoutesTiktokOnboardingSuccessRoute,
    PublicRoutesTiktokOnboardingIndexRoute:
      PublicRoutesTiktokOnboardingIndexRoute,
    PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute:
      PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute,
  }

const PublicRoutesTiktokOnboardingRouteWithChildren =
  PublicRoutesTiktokOnboardingRoute._addFileChildren(
    PublicRoutesTiktokOnboardingRouteChildren,
  )

interface PublicRoutesRouteChildren {
  PublicRoutesPrivacyRoute: typeof PublicRoutesPrivacyRoute
  PublicRoutesRedirecttoRoute: typeof PublicRoutesRedirecttoRoute
  PublicRoutesSignInRoute: typeof PublicRoutesSignInRoute
  PublicRoutesTermsRoute: typeof PublicRoutesTermsRoute
  PublicRoutesTermsCombinedRoute: typeof PublicRoutesTermsCombinedRoute
  PublicRoutesTiktokOnboardingRoute: typeof PublicRoutesTiktokOnboardingRouteWithChildren
  PublicRoutesPasswordPasswordforgotRoute: typeof PublicRoutesPasswordPasswordforgotRoute
  PublicRoutesEggReferralRoute: typeof PublicRoutesEggReferralRoute
  PublicRoutesMkpReferralRoute: typeof PublicRoutesMkpReferralRoute
  PublicRoutesSignUpSuccessRoute: typeof PublicRoutesSignUpSuccessRoute
  PublicRoutesSignUpIndexRoute: typeof PublicRoutesSignUpIndexRoute
  PublicRoutesPasswordPasswordregisterTokenRoute: typeof PublicRoutesPasswordPasswordregisterTokenRoute
  PublicRoutesPasswordPasswordresetTokenRoute: typeof PublicRoutesPasswordPasswordresetTokenRoute
}

const PublicRoutesRouteChildren: PublicRoutesRouteChildren = {
  PublicRoutesPrivacyRoute: PublicRoutesPrivacyRoute,
  PublicRoutesRedirecttoRoute: PublicRoutesRedirecttoRoute,
  PublicRoutesSignInRoute: PublicRoutesSignInRoute,
  PublicRoutesTermsRoute: PublicRoutesTermsRoute,
  PublicRoutesTermsCombinedRoute: PublicRoutesTermsCombinedRoute,
  PublicRoutesTiktokOnboardingRoute:
    PublicRoutesTiktokOnboardingRouteWithChildren,
  PublicRoutesPasswordPasswordforgotRoute:
    PublicRoutesPasswordPasswordforgotRoute,
  PublicRoutesEggReferralRoute: PublicRoutesEggReferralRoute,
  PublicRoutesMkpReferralRoute: PublicRoutesMkpReferralRoute,
  PublicRoutesSignUpSuccessRoute: PublicRoutesSignUpSuccessRoute,
  PublicRoutesSignUpIndexRoute: PublicRoutesSignUpIndexRoute,
  PublicRoutesPasswordPasswordregisterTokenRoute:
    PublicRoutesPasswordPasswordregisterTokenRoute,
  PublicRoutesPasswordPasswordresetTokenRoute:
    PublicRoutesPasswordPasswordresetTokenRoute,
}

const PublicRoutesRouteWithChildren = PublicRoutesRoute._addFileChildren(
  PublicRoutesRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof PublicRoutesRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/advertiser': typeof PrivateRoutesAdvertiserRouteWithChildren
  '/agency': typeof PrivateRoutesAgencyRouteWithChildren
  '/analytics': typeof PrivateRoutesAnalyticsRouteWithChildren
  '/asset-preview': typeof PrivateRoutesAssetPreviewRoute
  '/auto-managed': typeof PrivateRoutesAutoManagedRouteWithChildren
  '/campaign-posts-discovery': typeof PrivateRoutesCampaignPostsDiscoveryRoute
  '/chat-demo': typeof PrivateRoutesChatDemoRouteWithChildren
  '/hashtag-analytics': typeof PrivateRoutesHashtagAnalyticsRouteWithChildren
  '/influencer': typeof PrivateRoutesInfluencerRouteWithChildren
  '/influencers': typeof PrivateRoutesInfluencersRouteWithChildren
  '/invoices': typeof PrivateRoutesInvoicesRoute
  '/package': typeof PrivateRoutesPackageRouteWithChildren
  '/partner': typeof PrivateRoutesPartnerRouteWithChildren
  '/payments': typeof PrivateRoutesPaymentsRoute
  '/posts': typeof PrivateRoutesPostsRoute
  '/posts-insight': typeof PrivateRoutesPostsInsightRouteWithChildren
  '/report': typeof PrivateRoutesReportRoute
  '/settings': typeof PrivateRoutesSettingsRoute
  '/talent-agency': typeof PrivateRoutesTalentAgencyRouteWithChildren
  '/tiktok': typeof PrivateRoutesTiktokRouteWithChildren
  '/trending': typeof PrivateRoutesTrendingRouteWithChildren
  '/user': typeof PrivateRoutesUserRouteWithChildren
  '/user-settings': typeof PrivateRoutesUserSettingsRoute
  '/youtube-cms': typeof PrivateRoutesYoutubeCmsRouteWithChildren
  '/privacy': typeof PublicRoutesPrivacyRoute
  '/redirect_to': typeof PublicRoutesRedirecttoRoute
  '/sign-in': typeof PublicRoutesSignInRoute
  '/terms': typeof PublicRoutesTermsRoute
  '/terms-combined': typeof PublicRoutesTermsCombinedRoute
  '/tiktok-onboarding': typeof PublicRoutesTiktokOnboardingRouteWithChildren
  '/advertiser/$id': typeof PrivateRoutesAdvertiserIdRouteWithChildren
  '/advertiser/add': typeof PrivateRoutesAdvertiserAddRoute
  '/agency/$id': typeof PrivateRoutesAgencyIdRouteWithChildren
  '/agency/add': typeof PrivateRoutesAgencyAddRoute
  '/analytics/add': typeof PrivateRoutesAnalyticsAddRoute
  '/auto-managed/$id': typeof PrivateRoutesAutoManagedIdRouteWithChildren
  '/chat-demo/chat': typeof PrivateRoutesChatDemoChatRoute
  '/engagement/$id': typeof PrivateRoutesEngagementIdRouteWithChildren
  '/engagement/add': typeof PrivateRoutesEngagementAddRoute
  '/hashtag-analytics/instagram': typeof PrivateRoutesHashtagAnalyticsInstagramRoute
  '/influencer/$id': typeof PrivateRoutesInfluencerIdRouteWithChildren
  '/influencer/add': typeof PrivateRoutesInfluencerAddRoute
  '/influencers/proposal': typeof PrivateRoutesInfluencersProposalRoute
  '/influencers/search': typeof PrivateRoutesInfluencersSearchRouteWithChildren
  '/marketplace/$id': typeof PrivateRoutesMarketplaceIdRouteWithChildren
  '/marketplace/add': typeof PrivateRoutesMarketplaceAddRouteWithChildren
  '/marketplace/reorder': typeof PrivateRoutesMarketplaceReorderRoute
  '/package/$id': typeof PrivateRoutesPackageIdRouteWithChildren
  '/package/add': typeof PrivateRoutesPackageAddRoute
  '/partner/$id': typeof PrivateRoutesPartnerIdRouteWithChildren
  '/posts-insight/$id': typeof PrivateRoutesPostsInsightIdRouteWithChildren
  '/talent-agency/$id': typeof PrivateRoutesTalentAgencyIdRouteWithChildren
  '/talent-agency/add': typeof PrivateRoutesTalentAgencyAddRoute
  '/tiktok/ad-accounts': typeof PrivateRoutesTiktokAdAccountsRoute
  '/tiktok/ad-campaign-report': typeof PrivateRoutesTiktokAdCampaignReportRouteWithChildren
  '/tiktok/advertiser-details': typeof PrivateRoutesTiktokAdvertiserDetailsRoute
  '/tiktok/budget-allocation': typeof PrivateRoutesTiktokBudgetAllocationRoute
  '/tiktok/campaigns': typeof PrivateRoutesTiktokCampaignsRouteWithChildren
  '/tiktok/failed-payment': typeof PrivateRoutesTiktokFailedPaymentRoute
  '/tiktok/subscription-billing': typeof PrivateRoutesTiktokSubscriptionBillingRoute
  '/trending/influencers': typeof PrivateRoutesTrendingInfluencersRoute
  '/trending/posts': typeof PrivateRoutesTrendingPostsRoute
  '/user/$id': typeof PrivateRoutesUserIdRoute
  '/user/add': typeof PrivateRoutesUserAddRoute
  '/user/linked': typeof PrivateRoutesUserLinkedRoute
  '/youtube-cms/$cmsChannelId': typeof PrivateRoutesYoutubeCmsCmsChannelIdRoute
  '/youtube-cms/confirmed-revenue': typeof PrivateRoutesYoutubeCmsConfirmedRevenueRoute
  '/password_forgot': typeof PublicRoutesPasswordPasswordforgotRoute
  '/egg/referral': typeof PublicRoutesEggReferralRoute
  '/mkp/referral': typeof PublicRoutesMkpReferralRoute
  '/sign-up/success': typeof PublicRoutesSignUpSuccessRoute
  '/tiktok-onboarding/ad-account': typeof PublicRoutesTiktokOnboardingAdAccountRoute
  '/tiktok-onboarding/password-enter': typeof PublicRoutesTiktokOnboardingPasswordEnterRoute
  '/tiktok-onboarding/select-plan': typeof PublicRoutesTiktokOnboardingSelectPlanRoute
  '/tiktok-onboarding/success': typeof PublicRoutesTiktokOnboardingSuccessRoute
  '/advertiser/': typeof PrivateRoutesAdvertiserIndexRoute
  '/agency/': typeof PrivateRoutesAgencyIndexRoute
  '/analytics/': typeof PrivateRoutesAnalyticsIndexRoute
  '/auto-managed/': typeof PrivateRoutesAutoManagedIndexRoute
  '/chat-demo/': typeof PrivateRoutesChatDemoIndexRoute
  '/engagement': typeof PrivateRoutesEngagementIndexRoute
  '/hashtag-analytics/': typeof PrivateRoutesHashtagAnalyticsIndexRoute
  '/influencer/': typeof PrivateRoutesInfluencerIndexRoute
  '/influencers/': typeof PrivateRoutesInfluencersIndexRoute
  '/marketplace': typeof PrivateRoutesMarketplaceIndexRoute
  '/package/': typeof PrivateRoutesPackageIndexRoute
  '/partner/': typeof PrivateRoutesPartnerIndexRoute
  '/posts-insight/': typeof PrivateRoutesPostsInsightIndexRoute
  '/talent-agency/': typeof PrivateRoutesTalentAgencyIndexRoute
  '/trending/': typeof PrivateRoutesTrendingIndexRoute
  '/user/': typeof PrivateRoutesUserIndexRoute
  '/youtube-cms/': typeof PrivateRoutesYoutubeCmsIndexRoute
  '/sign-up': typeof PublicRoutesSignUpIndexRoute
  '/tiktok-onboarding/': typeof PublicRoutesTiktokOnboardingIndexRoute
  '/advertiser/$id/budget-allocation': typeof PrivateRoutesAdvertiserIdBudgetAllocationRoute
  '/advertiser/$id/users': typeof PrivateRoutesAdvertiserIdUsersRoute
  '/agency/$id/business': typeof PrivateRoutesAgencyIdBusinessRoute
  '/agency/$id/users': typeof PrivateRoutesAgencyIdUsersRoute
  '/auto-managed/$id/commissions': typeof PrivateRoutesAutoManagedIdCommissionsRoute
  '/auto-managed/$id/joined-influencers': typeof PrivateRoutesAutoManagedIdJoinedInfluencersRoute
  '/auto-managed/$id/report': typeof PrivateRoutesAutoManagedIdReportRoute
  '/engagement/$id/influencer': typeof PrivateRoutesEngagementIdInfluencerRouteWithChildren
  '/engagement/$id/posts': typeof PrivateRoutesEngagementIdPostsRouteWithChildren
  '/engagement/$id/proposal': typeof PrivateRoutesEngagementIdProposalRoute
  '/engagement/$id/report': typeof PrivateRoutesEngagementIdReportRoute
  '/engagement/$id/tracking': typeof PrivateRoutesEngagementIdTrackingRoute
  '/engagement/$id/tracking-referral-codes': typeof PrivateRoutesEngagementIdTrackingReferralCodesRoute
  '/hashtag-analytics/tiktok/hashtag': typeof PrivateRoutesHashtagAnalyticsTiktokHashtagRoute
  '/hashtag-analytics/tiktok/keyword': typeof PrivateRoutesHashtagAnalyticsTiktokKeywordRoute
  '/influencer/$id/profile': typeof PrivateRoutesInfluencerIdProfileRouteWithChildren
  '/influencers/search/douyin': typeof PrivateRoutesInfluencersSearchDouyinRoute
  '/influencers/search/facebook': typeof PrivateRoutesInfluencersSearchFacebookRoute
  '/influencers/search/facebook_page': typeof PrivateRoutesInfluencersSearchFacebookpageRoute
  '/influencers/search/instagram': typeof PrivateRoutesInfluencersSearchInstagramRoute
  '/influencers/search/threads': typeof PrivateRoutesInfluencersSearchThreadsRoute
  '/influencers/search/tiktok': typeof PrivateRoutesInfluencersSearchTiktokRoute
  '/influencers/search/twitter': typeof PrivateRoutesInfluencersSearchTwitterRoute
  '/influencers/search/xhs': typeof PrivateRoutesInfluencersSearchXhsRoute
  '/influencers/search/youtube': typeof PrivateRoutesInfluencersSearchYoutubeRoute
  '/marketplace/$id/applicant': typeof PrivateRoutesMarketplaceIdApplicantRoute
  '/marketplace/$id/commissions': typeof PrivateRoutesMarketplaceIdCommissionsRoute
  '/marketplace/$id/influencer': typeof PrivateRoutesMarketplaceIdInfluencerRoute
  '/marketplace/$id/joined-influencers': typeof PrivateRoutesMarketplaceIdJoinedInfluencersRoute
  '/marketplace/$id/posts': typeof PrivateRoutesMarketplaceIdPostsRouteWithChildren
  '/marketplace/$id/report': typeof PrivateRoutesMarketplaceIdReportRoute
  '/marketplace/$id/report-affiliate': typeof PrivateRoutesMarketplaceIdReportAffiliateRoute
  '/marketplace/$id/tracking': typeof PrivateRoutesMarketplaceIdTrackingRoute
  '/marketplace/$id/tracking-referral-codes': typeof PrivateRoutesMarketplaceIdTrackingReferralCodesRoute
  '/marketplace/add/referral-code-tracking': typeof PrivateRoutesMarketplaceAddReferralCodeTrackingRoute
  '/marketplace/add/social-media-tracking': typeof PrivateRoutesMarketplaceAddSocialMediaTrackingRoute
  '/marketplace/add/url-tracking': typeof PrivateRoutesMarketplaceAddUrlTrackingRoute
  '/package/$id/content': typeof PrivateRoutesPackageIdContentRouteWithChildren
  '/partner/$id/business': typeof PrivateRoutesPartnerIdBusinessRoute
  '/partner/$id/users': typeof PrivateRoutesPartnerIdUsersRoute
  '/posts-insight/$id/$postId': typeof PrivateRoutesPostsInsightIdPostIdRoute
  '/redirect/analytics-connect/$provider': typeof PrivateRoutesRedirectAnalyticsConnectProviderRoute
  '/redirect/analytics-list-connect/$provider': typeof PrivateRoutesRedirectAnalyticsListConnectProviderRoute
  '/redirect/analytics-signin/$provider': typeof PrivateRoutesRedirectAnalyticsSigninProviderRoute
  '/redirect/connect/tiktok-business': typeof PrivateRoutesRedirectConnectTiktokBusinessRoute
  '/redirect/hidden/youtube-list-connect': typeof PrivateRoutesRedirectHiddenYoutubeListConnectRoute
  '/redirect/talent-analytics-connect/$provider': typeof PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute
  '/redirect/talent-signin/$provider': typeof PrivateRoutesRedirectTalentSigninProviderRoute
  '/talent-agency/$id/business': typeof PrivateRoutesTalentAgencyIdBusinessRoute
  '/talent-agency/$id/users': typeof PrivateRoutesTalentAgencyIdUsersRoute
  '/tiktok/campaigns/$id': typeof PrivateRoutesTiktokCampaignsIdRouteWithChildren
  '/tiktok/campaigns/add': typeof PrivateRoutesTiktokCampaignsAddRoute
  '/tiktok/campaigns/review-influencers': typeof PrivateRoutesTiktokCampaignsReviewInfluencersRoute
  '/password_register/$token': typeof PublicRoutesPasswordPasswordregisterTokenRoute
  '/password_reset/$token': typeof PublicRoutesPasswordPasswordresetTokenRoute
  '/tiktok-onboarding/password-register/$token': typeof PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute
  '/advertiser/$id/': typeof PrivateRoutesAdvertiserIdIndexRoute
  '/agency/$id/': typeof PrivateRoutesAgencyIdIndexRoute
  '/analytics/$brandAccountId': typeof PrivateRoutesAnalyticsBrandAccountIdIndexRoute
  '/auto-managed/$id/': typeof PrivateRoutesAutoManagedIdIndexRoute
  '/engagement/$id/': typeof PrivateRoutesEngagementIdIndexRoute
  '/hashtag-analytics/tiktok': typeof PrivateRoutesHashtagAnalyticsTiktokIndexRoute
  '/influencer/$id/': typeof PrivateRoutesInfluencerIdIndexRoute
  '/influencers/search/': typeof PrivateRoutesInfluencersSearchIndexRoute
  '/marketplace/$id/': typeof PrivateRoutesMarketplaceIdIndexRoute
  '/marketplace/add/': typeof PrivateRoutesMarketplaceAddIndexRoute
  '/package/$id/': typeof PrivateRoutesPackageIdIndexRoute
  '/partner/$id/': typeof PrivateRoutesPartnerIdIndexRoute
  '/talent-agency/$id/': typeof PrivateRoutesTalentAgencyIdIndexRoute
  '/tiktok/ad-campaign-report/': typeof PrivateRoutesTiktokAdCampaignReportIndexRoute
  '/tiktok/campaigns/': typeof PrivateRoutesTiktokCampaignsIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteWithChildren
  '/engagement/$id/influencer/campaigns': typeof PrivateRoutesEngagementIdInfluencerCampaignsRoute
  '/engagement/$id/influencer/douyin': typeof PrivateRoutesEngagementIdInfluencerDouyinRoute
  '/engagement/$id/influencer/facebook': typeof PrivateRoutesEngagementIdInfluencerFacebookRoute
  '/engagement/$id/influencer/facebook_page': typeof PrivateRoutesEngagementIdInfluencerFacebookpageRoute
  '/engagement/$id/influencer/instagram': typeof PrivateRoutesEngagementIdInfluencerInstagramRoute
  '/engagement/$id/influencer/packages': typeof PrivateRoutesEngagementIdInfluencerPackagesRoute
  '/engagement/$id/influencer/posts': typeof PrivateRoutesEngagementIdInfluencerPostsRoute
  '/engagement/$id/influencer/threads': typeof PrivateRoutesEngagementIdInfluencerThreadsRoute
  '/engagement/$id/influencer/tiktok': typeof PrivateRoutesEngagementIdInfluencerTiktokRoute
  '/engagement/$id/influencer/twitter': typeof PrivateRoutesEngagementIdInfluencerTwitterRoute
  '/engagement/$id/influencer/xhs': typeof PrivateRoutesEngagementIdInfluencerXhsRoute
  '/engagement/$id/influencer/youtube': typeof PrivateRoutesEngagementIdInfluencerYoutubeRoute
  '/engagement/$id/posts/$postId': typeof PrivateRoutesEngagementIdPostsPostIdRoute
  '/engagement/$id/posts/add': typeof PrivateRoutesEngagementIdPostsAddRoute
  '/influencer/$id/$socialMediaAccountId/facebook_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute
  '/influencer/$id/$socialMediaAccountId/feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute
  '/influencer/$id/$socialMediaAccountId/instagram_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute
  '/influencer/$id/$socialMediaAccountId/instagram_reel_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute
  '/influencer/$id/$socialMediaAccountId/instagram_story_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute
  '/influencer/$id/$socialMediaAccountId/scraping_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/shorts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute
  '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/twitter_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/xhs_picture_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute
  '/influencer/$id/$socialMediaAccountId/xhs_video_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute
  '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteWithChildren
  '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute
  '/influencer/$id/profile/campaigns': typeof PrivateRoutesInfluencerIdProfileCampaignsRoute
  '/influencer/$id/profile/compare': typeof PrivateRoutesInfluencerIdProfileCompareRoute
  '/marketplace/$id/posts/$postId': typeof PrivateRoutesMarketplaceIdPostsPostIdRoute
  '/marketplace/add/e-commerce/$autoMangedCampaignId': typeof PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute
  '/package/$id/content/list': typeof PrivateRoutesPackageIdContentListRoute
  '/package/$id/content/search': typeof PrivateRoutesPackageIdContentSearchRouteWithChildren
  '/tiktok/ad-campaign-report/$id/$groupId': typeof PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute
  '/tiktok/campaigns/$id/influencers': typeof PrivateRoutesTiktokCampaignsIdInfluencersRoute
  '/tiktok/campaigns/$id/posts': typeof PrivateRoutesTiktokCampaignsIdPostsRouteWithChildren
  '/tiktok/campaigns/$id/report': typeof PrivateRoutesTiktokCampaignsIdReportRoute
  '/engagement/$id/influencer/': typeof PrivateRoutesEngagementIdInfluencerIndexRoute
  '/engagement/$id/posts/': typeof PrivateRoutesEngagementIdPostsIndexRoute
  '/influencer/$id/profile/': typeof PrivateRoutesInfluencerIdProfileIndexRoute
  '/marketplace/$id/posts/': typeof PrivateRoutesMarketplaceIdPostsIndexRoute
  '/marketplace/add/e-commerce': typeof PrivateRoutesMarketplaceAddECommerceIndexRoute
  '/package/$id/content/': typeof PrivateRoutesPackageIdContentIndexRoute
  '/tiktok/ad-campaign-report/$id': typeof PrivateRoutesTiktokAdCampaignReportIdIndexRoute
  '/tiktok/campaigns/$id/': typeof PrivateRoutesTiktokCampaignsIdIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute
  '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute
  '/package/$id/content/search/douyin': typeof PrivateRoutesPackageIdContentSearchDouyinRoute
  '/package/$id/content/search/facebook': typeof PrivateRoutesPackageIdContentSearchFacebookRoute
  '/package/$id/content/search/facebook_page': typeof PrivateRoutesPackageIdContentSearchFacebookpageRoute
  '/package/$id/content/search/instagram': typeof PrivateRoutesPackageIdContentSearchInstagramRoute
  '/package/$id/content/search/threads': typeof PrivateRoutesPackageIdContentSearchThreadsRoute
  '/package/$id/content/search/tiktok': typeof PrivateRoutesPackageIdContentSearchTiktokRoute
  '/package/$id/content/search/twitter': typeof PrivateRoutesPackageIdContentSearchTwitterRoute
  '/package/$id/content/search/xhs': typeof PrivateRoutesPackageIdContentSearchXhsRoute
  '/package/$id/content/search/youtube': typeof PrivateRoutesPackageIdContentSearchYoutubeRoute
  '/tiktok/campaigns/$id/posts/$postId': typeof PrivateRoutesTiktokCampaignsIdPostsPostIdRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute
  '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute
  '/package/$id/content/search/': typeof PrivateRoutesPackageIdContentSearchIndexRoute
  '/tiktok/campaigns/$id/posts/': typeof PrivateRoutesTiktokCampaignsIdPostsIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof PublicRoutesRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/asset-preview': typeof PrivateRoutesAssetPreviewRoute
  '/campaign-posts-discovery': typeof PrivateRoutesCampaignPostsDiscoveryRoute
  '/invoices': typeof PrivateRoutesInvoicesRoute
  '/payments': typeof PrivateRoutesPaymentsRoute
  '/posts': typeof PrivateRoutesPostsRoute
  '/report': typeof PrivateRoutesReportRoute
  '/settings': typeof PrivateRoutesSettingsRoute
  '/tiktok': typeof PrivateRoutesTiktokRouteWithChildren
  '/user-settings': typeof PrivateRoutesUserSettingsRoute
  '/privacy': typeof PublicRoutesPrivacyRoute
  '/redirect_to': typeof PublicRoutesRedirecttoRoute
  '/sign-in': typeof PublicRoutesSignInRoute
  '/terms': typeof PublicRoutesTermsRoute
  '/terms-combined': typeof PublicRoutesTermsCombinedRoute
  '/advertiser/add': typeof PrivateRoutesAdvertiserAddRoute
  '/agency/add': typeof PrivateRoutesAgencyAddRoute
  '/analytics/add': typeof PrivateRoutesAnalyticsAddRoute
  '/chat-demo/chat': typeof PrivateRoutesChatDemoChatRoute
  '/engagement/add': typeof PrivateRoutesEngagementAddRoute
  '/hashtag-analytics/instagram': typeof PrivateRoutesHashtagAnalyticsInstagramRoute
  '/influencer/add': typeof PrivateRoutesInfluencerAddRoute
  '/influencers/proposal': typeof PrivateRoutesInfluencersProposalRoute
  '/marketplace/reorder': typeof PrivateRoutesMarketplaceReorderRoute
  '/package/add': typeof PrivateRoutesPackageAddRoute
  '/posts-insight/$id': typeof PrivateRoutesPostsInsightIdRouteWithChildren
  '/talent-agency/add': typeof PrivateRoutesTalentAgencyAddRoute
  '/tiktok/ad-accounts': typeof PrivateRoutesTiktokAdAccountsRoute
  '/tiktok/advertiser-details': typeof PrivateRoutesTiktokAdvertiserDetailsRoute
  '/tiktok/budget-allocation': typeof PrivateRoutesTiktokBudgetAllocationRoute
  '/tiktok/failed-payment': typeof PrivateRoutesTiktokFailedPaymentRoute
  '/tiktok/subscription-billing': typeof PrivateRoutesTiktokSubscriptionBillingRoute
  '/trending/influencers': typeof PrivateRoutesTrendingInfluencersRoute
  '/trending/posts': typeof PrivateRoutesTrendingPostsRoute
  '/user/$id': typeof PrivateRoutesUserIdRoute
  '/user/add': typeof PrivateRoutesUserAddRoute
  '/user/linked': typeof PrivateRoutesUserLinkedRoute
  '/youtube-cms/$cmsChannelId': typeof PrivateRoutesYoutubeCmsCmsChannelIdRoute
  '/youtube-cms/confirmed-revenue': typeof PrivateRoutesYoutubeCmsConfirmedRevenueRoute
  '/password_forgot': typeof PublicRoutesPasswordPasswordforgotRoute
  '/egg/referral': typeof PublicRoutesEggReferralRoute
  '/mkp/referral': typeof PublicRoutesMkpReferralRoute
  '/sign-up/success': typeof PublicRoutesSignUpSuccessRoute
  '/tiktok-onboarding/ad-account': typeof PublicRoutesTiktokOnboardingAdAccountRoute
  '/tiktok-onboarding/password-enter': typeof PublicRoutesTiktokOnboardingPasswordEnterRoute
  '/tiktok-onboarding/select-plan': typeof PublicRoutesTiktokOnboardingSelectPlanRoute
  '/tiktok-onboarding/success': typeof PublicRoutesTiktokOnboardingSuccessRoute
  '/advertiser': typeof PrivateRoutesAdvertiserIndexRoute
  '/agency': typeof PrivateRoutesAgencyIndexRoute
  '/analytics': typeof PrivateRoutesAnalyticsIndexRoute
  '/auto-managed': typeof PrivateRoutesAutoManagedIndexRoute
  '/chat-demo': typeof PrivateRoutesChatDemoIndexRoute
  '/engagement': typeof PrivateRoutesEngagementIndexRoute
  '/hashtag-analytics': typeof PrivateRoutesHashtagAnalyticsIndexRoute
  '/influencer': typeof PrivateRoutesInfluencerIndexRoute
  '/influencers': typeof PrivateRoutesInfluencersIndexRoute
  '/marketplace': typeof PrivateRoutesMarketplaceIndexRoute
  '/package': typeof PrivateRoutesPackageIndexRoute
  '/partner': typeof PrivateRoutesPartnerIndexRoute
  '/posts-insight': typeof PrivateRoutesPostsInsightIndexRoute
  '/talent-agency': typeof PrivateRoutesTalentAgencyIndexRoute
  '/trending': typeof PrivateRoutesTrendingIndexRoute
  '/user': typeof PrivateRoutesUserIndexRoute
  '/youtube-cms': typeof PrivateRoutesYoutubeCmsIndexRoute
  '/sign-up': typeof PublicRoutesSignUpIndexRoute
  '/tiktok-onboarding': typeof PublicRoutesTiktokOnboardingIndexRoute
  '/advertiser/$id/budget-allocation': typeof PrivateRoutesAdvertiserIdBudgetAllocationRoute
  '/advertiser/$id/users': typeof PrivateRoutesAdvertiserIdUsersRoute
  '/agency/$id/business': typeof PrivateRoutesAgencyIdBusinessRoute
  '/agency/$id/users': typeof PrivateRoutesAgencyIdUsersRoute
  '/auto-managed/$id/commissions': typeof PrivateRoutesAutoManagedIdCommissionsRoute
  '/auto-managed/$id/joined-influencers': typeof PrivateRoutesAutoManagedIdJoinedInfluencersRoute
  '/auto-managed/$id/report': typeof PrivateRoutesAutoManagedIdReportRoute
  '/engagement/$id/proposal': typeof PrivateRoutesEngagementIdProposalRoute
  '/engagement/$id/report': typeof PrivateRoutesEngagementIdReportRoute
  '/engagement/$id/tracking': typeof PrivateRoutesEngagementIdTrackingRoute
  '/engagement/$id/tracking-referral-codes': typeof PrivateRoutesEngagementIdTrackingReferralCodesRoute
  '/hashtag-analytics/tiktok/hashtag': typeof PrivateRoutesHashtagAnalyticsTiktokHashtagRoute
  '/hashtag-analytics/tiktok/keyword': typeof PrivateRoutesHashtagAnalyticsTiktokKeywordRoute
  '/influencers/search/douyin': typeof PrivateRoutesInfluencersSearchDouyinRoute
  '/influencers/search/facebook': typeof PrivateRoutesInfluencersSearchFacebookRoute
  '/influencers/search/facebook_page': typeof PrivateRoutesInfluencersSearchFacebookpageRoute
  '/influencers/search/instagram': typeof PrivateRoutesInfluencersSearchInstagramRoute
  '/influencers/search/threads': typeof PrivateRoutesInfluencersSearchThreadsRoute
  '/influencers/search/tiktok': typeof PrivateRoutesInfluencersSearchTiktokRoute
  '/influencers/search/twitter': typeof PrivateRoutesInfluencersSearchTwitterRoute
  '/influencers/search/xhs': typeof PrivateRoutesInfluencersSearchXhsRoute
  '/influencers/search/youtube': typeof PrivateRoutesInfluencersSearchYoutubeRoute
  '/marketplace/$id/applicant': typeof PrivateRoutesMarketplaceIdApplicantRoute
  '/marketplace/$id/commissions': typeof PrivateRoutesMarketplaceIdCommissionsRoute
  '/marketplace/$id/influencer': typeof PrivateRoutesMarketplaceIdInfluencerRoute
  '/marketplace/$id/joined-influencers': typeof PrivateRoutesMarketplaceIdJoinedInfluencersRoute
  '/marketplace/$id/report': typeof PrivateRoutesMarketplaceIdReportRoute
  '/marketplace/$id/report-affiliate': typeof PrivateRoutesMarketplaceIdReportAffiliateRoute
  '/marketplace/$id/tracking': typeof PrivateRoutesMarketplaceIdTrackingRoute
  '/marketplace/$id/tracking-referral-codes': typeof PrivateRoutesMarketplaceIdTrackingReferralCodesRoute
  '/marketplace/add/referral-code-tracking': typeof PrivateRoutesMarketplaceAddReferralCodeTrackingRoute
  '/marketplace/add/social-media-tracking': typeof PrivateRoutesMarketplaceAddSocialMediaTrackingRoute
  '/marketplace/add/url-tracking': typeof PrivateRoutesMarketplaceAddUrlTrackingRoute
  '/partner/$id/business': typeof PrivateRoutesPartnerIdBusinessRoute
  '/partner/$id/users': typeof PrivateRoutesPartnerIdUsersRoute
  '/posts-insight/$id/$postId': typeof PrivateRoutesPostsInsightIdPostIdRoute
  '/redirect/analytics-connect/$provider': typeof PrivateRoutesRedirectAnalyticsConnectProviderRoute
  '/redirect/analytics-list-connect/$provider': typeof PrivateRoutesRedirectAnalyticsListConnectProviderRoute
  '/redirect/analytics-signin/$provider': typeof PrivateRoutesRedirectAnalyticsSigninProviderRoute
  '/redirect/connect/tiktok-business': typeof PrivateRoutesRedirectConnectTiktokBusinessRoute
  '/redirect/hidden/youtube-list-connect': typeof PrivateRoutesRedirectHiddenYoutubeListConnectRoute
  '/redirect/talent-analytics-connect/$provider': typeof PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute
  '/redirect/talent-signin/$provider': typeof PrivateRoutesRedirectTalentSigninProviderRoute
  '/talent-agency/$id/business': typeof PrivateRoutesTalentAgencyIdBusinessRoute
  '/talent-agency/$id/users': typeof PrivateRoutesTalentAgencyIdUsersRoute
  '/tiktok/campaigns/add': typeof PrivateRoutesTiktokCampaignsAddRoute
  '/tiktok/campaigns/review-influencers': typeof PrivateRoutesTiktokCampaignsReviewInfluencersRoute
  '/password_register/$token': typeof PublicRoutesPasswordPasswordregisterTokenRoute
  '/password_reset/$token': typeof PublicRoutesPasswordPasswordresetTokenRoute
  '/tiktok-onboarding/password-register/$token': typeof PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute
  '/advertiser/$id': typeof PrivateRoutesAdvertiserIdIndexRoute
  '/agency/$id': typeof PrivateRoutesAgencyIdIndexRoute
  '/analytics/$brandAccountId': typeof PrivateRoutesAnalyticsBrandAccountIdIndexRoute
  '/auto-managed/$id': typeof PrivateRoutesAutoManagedIdIndexRoute
  '/engagement/$id': typeof PrivateRoutesEngagementIdIndexRoute
  '/hashtag-analytics/tiktok': typeof PrivateRoutesHashtagAnalyticsTiktokIndexRoute
  '/influencer/$id': typeof PrivateRoutesInfluencerIdIndexRoute
  '/influencers/search': typeof PrivateRoutesInfluencersSearchIndexRoute
  '/marketplace/$id': typeof PrivateRoutesMarketplaceIdIndexRoute
  '/marketplace/add': typeof PrivateRoutesMarketplaceAddIndexRoute
  '/package/$id': typeof PrivateRoutesPackageIdIndexRoute
  '/partner/$id': typeof PrivateRoutesPartnerIdIndexRoute
  '/talent-agency/$id': typeof PrivateRoutesTalentAgencyIdIndexRoute
  '/tiktok/ad-campaign-report': typeof PrivateRoutesTiktokAdCampaignReportIndexRoute
  '/tiktok/campaigns': typeof PrivateRoutesTiktokCampaignsIndexRoute
  '/engagement/$id/influencer/campaigns': typeof PrivateRoutesEngagementIdInfluencerCampaignsRoute
  '/engagement/$id/influencer/douyin': typeof PrivateRoutesEngagementIdInfluencerDouyinRoute
  '/engagement/$id/influencer/facebook': typeof PrivateRoutesEngagementIdInfluencerFacebookRoute
  '/engagement/$id/influencer/facebook_page': typeof PrivateRoutesEngagementIdInfluencerFacebookpageRoute
  '/engagement/$id/influencer/instagram': typeof PrivateRoutesEngagementIdInfluencerInstagramRoute
  '/engagement/$id/influencer/packages': typeof PrivateRoutesEngagementIdInfluencerPackagesRoute
  '/engagement/$id/influencer/posts': typeof PrivateRoutesEngagementIdInfluencerPostsRoute
  '/engagement/$id/influencer/threads': typeof PrivateRoutesEngagementIdInfluencerThreadsRoute
  '/engagement/$id/influencer/tiktok': typeof PrivateRoutesEngagementIdInfluencerTiktokRoute
  '/engagement/$id/influencer/twitter': typeof PrivateRoutesEngagementIdInfluencerTwitterRoute
  '/engagement/$id/influencer/xhs': typeof PrivateRoutesEngagementIdInfluencerXhsRoute
  '/engagement/$id/influencer/youtube': typeof PrivateRoutesEngagementIdInfluencerYoutubeRoute
  '/engagement/$id/posts/$postId': typeof PrivateRoutesEngagementIdPostsPostIdRoute
  '/engagement/$id/posts/add': typeof PrivateRoutesEngagementIdPostsAddRoute
  '/influencer/$id/$socialMediaAccountId/facebook_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute
  '/influencer/$id/$socialMediaAccountId/feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute
  '/influencer/$id/$socialMediaAccountId/instagram_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute
  '/influencer/$id/$socialMediaAccountId/instagram_reel_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute
  '/influencer/$id/$socialMediaAccountId/instagram_story_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute
  '/influencer/$id/$socialMediaAccountId/scraping_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/shorts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute
  '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/twitter_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute
  '/influencer/$id/$socialMediaAccountId/xhs_picture_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute
  '/influencer/$id/$socialMediaAccountId/xhs_video_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute
  '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute
  '/influencer/$id/profile/campaigns': typeof PrivateRoutesInfluencerIdProfileCampaignsRoute
  '/influencer/$id/profile/compare': typeof PrivateRoutesInfluencerIdProfileCompareRoute
  '/marketplace/$id/posts/$postId': typeof PrivateRoutesMarketplaceIdPostsPostIdRoute
  '/marketplace/add/e-commerce/$autoMangedCampaignId': typeof PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute
  '/package/$id/content/list': typeof PrivateRoutesPackageIdContentListRoute
  '/tiktok/ad-campaign-report/$id/$groupId': typeof PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute
  '/tiktok/campaigns/$id/influencers': typeof PrivateRoutesTiktokCampaignsIdInfluencersRoute
  '/tiktok/campaigns/$id/report': typeof PrivateRoutesTiktokCampaignsIdReportRoute
  '/engagement/$id/influencer': typeof PrivateRoutesEngagementIdInfluencerIndexRoute
  '/engagement/$id/posts': typeof PrivateRoutesEngagementIdPostsIndexRoute
  '/influencer/$id/profile': typeof PrivateRoutesInfluencerIdProfileIndexRoute
  '/marketplace/$id/posts': typeof PrivateRoutesMarketplaceIdPostsIndexRoute
  '/marketplace/add/e-commerce': typeof PrivateRoutesMarketplaceAddECommerceIndexRoute
  '/package/$id/content': typeof PrivateRoutesPackageIdContentIndexRoute
  '/tiktok/ad-campaign-report/$id': typeof PrivateRoutesTiktokAdCampaignReportIdIndexRoute
  '/tiktok/campaigns/$id': typeof PrivateRoutesTiktokCampaignsIdIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute
  '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute
  '/package/$id/content/search/douyin': typeof PrivateRoutesPackageIdContentSearchDouyinRoute
  '/package/$id/content/search/facebook': typeof PrivateRoutesPackageIdContentSearchFacebookRoute
  '/package/$id/content/search/facebook_page': typeof PrivateRoutesPackageIdContentSearchFacebookpageRoute
  '/package/$id/content/search/instagram': typeof PrivateRoutesPackageIdContentSearchInstagramRoute
  '/package/$id/content/search/threads': typeof PrivateRoutesPackageIdContentSearchThreadsRoute
  '/package/$id/content/search/tiktok': typeof PrivateRoutesPackageIdContentSearchTiktokRoute
  '/package/$id/content/search/twitter': typeof PrivateRoutesPackageIdContentSearchTwitterRoute
  '/package/$id/content/search/xhs': typeof PrivateRoutesPackageIdContentSearchXhsRoute
  '/package/$id/content/search/youtube': typeof PrivateRoutesPackageIdContentSearchYoutubeRoute
  '/tiktok/campaigns/$id/posts/$postId': typeof PrivateRoutesTiktokCampaignsIdPostsPostIdRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute
  '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute
  '/package/$id/content/search': typeof PrivateRoutesPackageIdContentSearchIndexRoute
  '/tiktok/campaigns/$id/posts': typeof PrivateRoutesTiktokCampaignsIdPostsIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute
  '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_private-routes': typeof PrivateRoutesRouteWithChildren
  '/_public-routes': typeof PublicRoutesRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/_private-routes/advertiser': typeof PrivateRoutesAdvertiserRouteWithChildren
  '/_private-routes/agency': typeof PrivateRoutesAgencyRouteWithChildren
  '/_private-routes/analytics': typeof PrivateRoutesAnalyticsRouteWithChildren
  '/_private-routes/asset-preview': typeof PrivateRoutesAssetPreviewRoute
  '/_private-routes/auto-managed': typeof PrivateRoutesAutoManagedRouteWithChildren
  '/_private-routes/campaign-posts-discovery': typeof PrivateRoutesCampaignPostsDiscoveryRoute
  '/_private-routes/chat-demo': typeof PrivateRoutesChatDemoRouteWithChildren
  '/_private-routes/hashtag-analytics': typeof PrivateRoutesHashtagAnalyticsRouteWithChildren
  '/_private-routes/influencer': typeof PrivateRoutesInfluencerRouteWithChildren
  '/_private-routes/influencers': typeof PrivateRoutesInfluencersRouteWithChildren
  '/_private-routes/invoices': typeof PrivateRoutesInvoicesRoute
  '/_private-routes/package': typeof PrivateRoutesPackageRouteWithChildren
  '/_private-routes/partner': typeof PrivateRoutesPartnerRouteWithChildren
  '/_private-routes/payments': typeof PrivateRoutesPaymentsRoute
  '/_private-routes/posts': typeof PrivateRoutesPostsRoute
  '/_private-routes/posts-insight': typeof PrivateRoutesPostsInsightRouteWithChildren
  '/_private-routes/report': typeof PrivateRoutesReportRoute
  '/_private-routes/settings': typeof PrivateRoutesSettingsRoute
  '/_private-routes/talent-agency': typeof PrivateRoutesTalentAgencyRouteWithChildren
  '/_private-routes/tiktok': typeof PrivateRoutesTiktokRouteWithChildren
  '/_private-routes/trending': typeof PrivateRoutesTrendingRouteWithChildren
  '/_private-routes/user': typeof PrivateRoutesUserRouteWithChildren
  '/_private-routes/user-settings': typeof PrivateRoutesUserSettingsRoute
  '/_private-routes/youtube-cms': typeof PrivateRoutesYoutubeCmsRouteWithChildren
  '/_public-routes/privacy': typeof PublicRoutesPrivacyRoute
  '/_public-routes/redirect_to': typeof PublicRoutesRedirecttoRoute
  '/_public-routes/sign-in': typeof PublicRoutesSignInRoute
  '/_public-routes/terms': typeof PublicRoutesTermsRoute
  '/_public-routes/terms-combined': typeof PublicRoutesTermsCombinedRoute
  '/_public-routes/tiktok-onboarding': typeof PublicRoutesTiktokOnboardingRouteWithChildren
  '/_private-routes/advertiser/$id': typeof PrivateRoutesAdvertiserIdRouteWithChildren
  '/_private-routes/advertiser/add': typeof PrivateRoutesAdvertiserAddRoute
  '/_private-routes/agency/$id': typeof PrivateRoutesAgencyIdRouteWithChildren
  '/_private-routes/agency/add': typeof PrivateRoutesAgencyAddRoute
  '/_private-routes/analytics/add': typeof PrivateRoutesAnalyticsAddRoute
  '/_private-routes/auto-managed/$id': typeof PrivateRoutesAutoManagedIdRouteWithChildren
  '/_private-routes/chat-demo/chat': typeof PrivateRoutesChatDemoChatRoute
  '/_private-routes/engagement/$id': typeof PrivateRoutesEngagementIdRouteWithChildren
  '/_private-routes/engagement/add': typeof PrivateRoutesEngagementAddRoute
  '/_private-routes/hashtag-analytics/instagram': typeof PrivateRoutesHashtagAnalyticsInstagramRoute
  '/_private-routes/influencer/$id': typeof PrivateRoutesInfluencerIdRouteWithChildren
  '/_private-routes/influencer/add': typeof PrivateRoutesInfluencerAddRoute
  '/_private-routes/influencers/proposal': typeof PrivateRoutesInfluencersProposalRoute
  '/_private-routes/influencers/search': typeof PrivateRoutesInfluencersSearchRouteWithChildren
  '/_private-routes/marketplace/$id': typeof PrivateRoutesMarketplaceIdRouteWithChildren
  '/_private-routes/marketplace/add': typeof PrivateRoutesMarketplaceAddRouteWithChildren
  '/_private-routes/marketplace/reorder': typeof PrivateRoutesMarketplaceReorderRoute
  '/_private-routes/package/$id': typeof PrivateRoutesPackageIdRouteWithChildren
  '/_private-routes/package/add': typeof PrivateRoutesPackageAddRoute
  '/_private-routes/partner/$id': typeof PrivateRoutesPartnerIdRouteWithChildren
  '/_private-routes/posts-insight/$id': typeof PrivateRoutesPostsInsightIdRouteWithChildren
  '/_private-routes/talent-agency/$id': typeof PrivateRoutesTalentAgencyIdRouteWithChildren
  '/_private-routes/talent-agency/add': typeof PrivateRoutesTalentAgencyAddRoute
  '/_private-routes/tiktok/ad-accounts': typeof PrivateRoutesTiktokAdAccountsRoute
  '/_private-routes/tiktok/ad-campaign-report': typeof PrivateRoutesTiktokAdCampaignReportRouteWithChildren
  '/_private-routes/tiktok/advertiser-details': typeof PrivateRoutesTiktokAdvertiserDetailsRoute
  '/_private-routes/tiktok/budget-allocation': typeof PrivateRoutesTiktokBudgetAllocationRoute
  '/_private-routes/tiktok/campaigns': typeof PrivateRoutesTiktokCampaignsRouteWithChildren
  '/_private-routes/tiktok/failed-payment': typeof PrivateRoutesTiktokFailedPaymentRoute
  '/_private-routes/tiktok/subscription-billing': typeof PrivateRoutesTiktokSubscriptionBillingRoute
  '/_private-routes/trending/influencers': typeof PrivateRoutesTrendingInfluencersRoute
  '/_private-routes/trending/posts': typeof PrivateRoutesTrendingPostsRoute
  '/_private-routes/user/$id': typeof PrivateRoutesUserIdRoute
  '/_private-routes/user/add': typeof PrivateRoutesUserAddRoute
  '/_private-routes/user/linked': typeof PrivateRoutesUserLinkedRoute
  '/_private-routes/youtube-cms/$cmsChannelId': typeof PrivateRoutesYoutubeCmsCmsChannelIdRoute
  '/_private-routes/youtube-cms/confirmed-revenue': typeof PrivateRoutesYoutubeCmsConfirmedRevenueRoute
  '/_public-routes/_password/password_forgot': typeof PublicRoutesPasswordPasswordforgotRoute
  '/_public-routes/egg/referral': typeof PublicRoutesEggReferralRoute
  '/_public-routes/mkp/referral': typeof PublicRoutesMkpReferralRoute
  '/_public-routes/sign-up/success': typeof PublicRoutesSignUpSuccessRoute
  '/_public-routes/tiktok-onboarding/ad-account': typeof PublicRoutesTiktokOnboardingAdAccountRoute
  '/_public-routes/tiktok-onboarding/password-enter': typeof PublicRoutesTiktokOnboardingPasswordEnterRoute
  '/_public-routes/tiktok-onboarding/select-plan': typeof PublicRoutesTiktokOnboardingSelectPlanRoute
  '/_public-routes/tiktok-onboarding/success': typeof PublicRoutesTiktokOnboardingSuccessRoute
  '/_private-routes/advertiser/': typeof PrivateRoutesAdvertiserIndexRoute
  '/_private-routes/agency/': typeof PrivateRoutesAgencyIndexRoute
  '/_private-routes/analytics/': typeof PrivateRoutesAnalyticsIndexRoute
  '/_private-routes/auto-managed/': typeof PrivateRoutesAutoManagedIndexRoute
  '/_private-routes/chat-demo/': typeof PrivateRoutesChatDemoIndexRoute
  '/_private-routes/engagement/': typeof PrivateRoutesEngagementIndexRoute
  '/_private-routes/hashtag-analytics/': typeof PrivateRoutesHashtagAnalyticsIndexRoute
  '/_private-routes/influencer/': typeof PrivateRoutesInfluencerIndexRoute
  '/_private-routes/influencers/': typeof PrivateRoutesInfluencersIndexRoute
  '/_private-routes/marketplace/': typeof PrivateRoutesMarketplaceIndexRoute
  '/_private-routes/package/': typeof PrivateRoutesPackageIndexRoute
  '/_private-routes/partner/': typeof PrivateRoutesPartnerIndexRoute
  '/_private-routes/posts-insight/': typeof PrivateRoutesPostsInsightIndexRoute
  '/_private-routes/talent-agency/': typeof PrivateRoutesTalentAgencyIndexRoute
  '/_private-routes/trending/': typeof PrivateRoutesTrendingIndexRoute
  '/_private-routes/user/': typeof PrivateRoutesUserIndexRoute
  '/_private-routes/youtube-cms/': typeof PrivateRoutesYoutubeCmsIndexRoute
  '/_public-routes/sign-up/': typeof PublicRoutesSignUpIndexRoute
  '/_public-routes/tiktok-onboarding/': typeof PublicRoutesTiktokOnboardingIndexRoute
  '/_private-routes/advertiser/$id/budget-allocation': typeof PrivateRoutesAdvertiserIdBudgetAllocationRoute
  '/_private-routes/advertiser/$id/users': typeof PrivateRoutesAdvertiserIdUsersRoute
  '/_private-routes/agency/$id/business': typeof PrivateRoutesAgencyIdBusinessRoute
  '/_private-routes/agency/$id/users': typeof PrivateRoutesAgencyIdUsersRoute
  '/_private-routes/auto-managed/$id/commissions': typeof PrivateRoutesAutoManagedIdCommissionsRoute
  '/_private-routes/auto-managed/$id/joined-influencers': typeof PrivateRoutesAutoManagedIdJoinedInfluencersRoute
  '/_private-routes/auto-managed/$id/report': typeof PrivateRoutesAutoManagedIdReportRoute
  '/_private-routes/engagement/$id/influencer': typeof PrivateRoutesEngagementIdInfluencerRouteWithChildren
  '/_private-routes/engagement/$id/posts': typeof PrivateRoutesEngagementIdPostsRouteWithChildren
  '/_private-routes/engagement/$id/proposal': typeof PrivateRoutesEngagementIdProposalRoute
  '/_private-routes/engagement/$id/report': typeof PrivateRoutesEngagementIdReportRoute
  '/_private-routes/engagement/$id/tracking': typeof PrivateRoutesEngagementIdTrackingRoute
  '/_private-routes/engagement/$id/tracking-referral-codes': typeof PrivateRoutesEngagementIdTrackingReferralCodesRoute
  '/_private-routes/hashtag-analytics/tiktok/hashtag': typeof PrivateRoutesHashtagAnalyticsTiktokHashtagRoute
  '/_private-routes/hashtag-analytics/tiktok/keyword': typeof PrivateRoutesHashtagAnalyticsTiktokKeywordRoute
  '/_private-routes/influencer/$id/profile': typeof PrivateRoutesInfluencerIdProfileRouteWithChildren
  '/_private-routes/influencers/search/douyin': typeof PrivateRoutesInfluencersSearchDouyinRoute
  '/_private-routes/influencers/search/facebook': typeof PrivateRoutesInfluencersSearchFacebookRoute
  '/_private-routes/influencers/search/facebook_page': typeof PrivateRoutesInfluencersSearchFacebookpageRoute
  '/_private-routes/influencers/search/instagram': typeof PrivateRoutesInfluencersSearchInstagramRoute
  '/_private-routes/influencers/search/threads': typeof PrivateRoutesInfluencersSearchThreadsRoute
  '/_private-routes/influencers/search/tiktok': typeof PrivateRoutesInfluencersSearchTiktokRoute
  '/_private-routes/influencers/search/twitter': typeof PrivateRoutesInfluencersSearchTwitterRoute
  '/_private-routes/influencers/search/xhs': typeof PrivateRoutesInfluencersSearchXhsRoute
  '/_private-routes/influencers/search/youtube': typeof PrivateRoutesInfluencersSearchYoutubeRoute
  '/_private-routes/marketplace/$id/applicant': typeof PrivateRoutesMarketplaceIdApplicantRoute
  '/_private-routes/marketplace/$id/commissions': typeof PrivateRoutesMarketplaceIdCommissionsRoute
  '/_private-routes/marketplace/$id/influencer': typeof PrivateRoutesMarketplaceIdInfluencerRoute
  '/_private-routes/marketplace/$id/joined-influencers': typeof PrivateRoutesMarketplaceIdJoinedInfluencersRoute
  '/_private-routes/marketplace/$id/posts': typeof PrivateRoutesMarketplaceIdPostsRouteWithChildren
  '/_private-routes/marketplace/$id/report': typeof PrivateRoutesMarketplaceIdReportRoute
  '/_private-routes/marketplace/$id/report-affiliate': typeof PrivateRoutesMarketplaceIdReportAffiliateRoute
  '/_private-routes/marketplace/$id/tracking': typeof PrivateRoutesMarketplaceIdTrackingRoute
  '/_private-routes/marketplace/$id/tracking-referral-codes': typeof PrivateRoutesMarketplaceIdTrackingReferralCodesRoute
  '/_private-routes/marketplace/add/referral-code-tracking': typeof PrivateRoutesMarketplaceAddReferralCodeTrackingRoute
  '/_private-routes/marketplace/add/social-media-tracking': typeof PrivateRoutesMarketplaceAddSocialMediaTrackingRoute
  '/_private-routes/marketplace/add/url-tracking': typeof PrivateRoutesMarketplaceAddUrlTrackingRoute
  '/_private-routes/package/$id/content': typeof PrivateRoutesPackageIdContentRouteWithChildren
  '/_private-routes/partner/$id/business': typeof PrivateRoutesPartnerIdBusinessRoute
  '/_private-routes/partner/$id/users': typeof PrivateRoutesPartnerIdUsersRoute
  '/_private-routes/posts-insight/$id/$postId': typeof PrivateRoutesPostsInsightIdPostIdRoute
  '/_private-routes/redirect/analytics-connect/$provider': typeof PrivateRoutesRedirectAnalyticsConnectProviderRoute
  '/_private-routes/redirect/analytics-list-connect/$provider': typeof PrivateRoutesRedirectAnalyticsListConnectProviderRoute
  '/_private-routes/redirect/analytics-signin/$provider': typeof PrivateRoutesRedirectAnalyticsSigninProviderRoute
  '/_private-routes/redirect/connect/tiktok-business': typeof PrivateRoutesRedirectConnectTiktokBusinessRoute
  '/_private-routes/redirect/hidden/youtube-list-connect': typeof PrivateRoutesRedirectHiddenYoutubeListConnectRoute
  '/_private-routes/redirect/talent-analytics-connect/$provider': typeof PrivateRoutesRedirectTalentAnalyticsConnectProviderRoute
  '/_private-routes/redirect/talent-signin/$provider': typeof PrivateRoutesRedirectTalentSigninProviderRoute
  '/_private-routes/talent-agency/$id/business': typeof PrivateRoutesTalentAgencyIdBusinessRoute
  '/_private-routes/talent-agency/$id/users': typeof PrivateRoutesTalentAgencyIdUsersRoute
  '/_private-routes/tiktok/campaigns/$id': typeof PrivateRoutesTiktokCampaignsIdRouteWithChildren
  '/_private-routes/tiktok/campaigns/add': typeof PrivateRoutesTiktokCampaignsAddRoute
  '/_private-routes/tiktok/campaigns/review-influencers': typeof PrivateRoutesTiktokCampaignsReviewInfluencersRoute
  '/_public-routes/_password/password_register/$token': typeof PublicRoutesPasswordPasswordregisterTokenRoute
  '/_public-routes/_password/password_reset/$token': typeof PublicRoutesPasswordPasswordresetTokenRoute
  '/_public-routes/tiktok-onboarding/password-register/$token': typeof PublicRoutesTiktokOnboardingPasswordRegisterTokenRoute
  '/_private-routes/advertiser/$id/': typeof PrivateRoutesAdvertiserIdIndexRoute
  '/_private-routes/agency/$id/': typeof PrivateRoutesAgencyIdIndexRoute
  '/_private-routes/analytics/$brandAccountId/': typeof PrivateRoutesAnalyticsBrandAccountIdIndexRoute
  '/_private-routes/auto-managed/$id/': typeof PrivateRoutesAutoManagedIdIndexRoute
  '/_private-routes/engagement/$id/': typeof PrivateRoutesEngagementIdIndexRoute
  '/_private-routes/hashtag-analytics/tiktok/': typeof PrivateRoutesHashtagAnalyticsTiktokIndexRoute
  '/_private-routes/influencer/$id/': typeof PrivateRoutesInfluencerIdIndexRoute
  '/_private-routes/influencers/search/': typeof PrivateRoutesInfluencersSearchIndexRoute
  '/_private-routes/marketplace/$id/': typeof PrivateRoutesMarketplaceIdIndexRoute
  '/_private-routes/marketplace/add/': typeof PrivateRoutesMarketplaceAddIndexRoute
  '/_private-routes/package/$id/': typeof PrivateRoutesPackageIdIndexRoute
  '/_private-routes/partner/$id/': typeof PrivateRoutesPartnerIdIndexRoute
  '/_private-routes/talent-agency/$id/': typeof PrivateRoutesTalentAgencyIdIndexRoute
  '/_private-routes/tiktok/ad-campaign-report/': typeof PrivateRoutesTiktokAdCampaignReportIndexRoute
  '/_private-routes/tiktok/campaigns/': typeof PrivateRoutesTiktokCampaignsIndexRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdRouteWithChildren
  '/_private-routes/engagement/$id/influencer/campaigns': typeof PrivateRoutesEngagementIdInfluencerCampaignsRoute
  '/_private-routes/engagement/$id/influencer/douyin': typeof PrivateRoutesEngagementIdInfluencerDouyinRoute
  '/_private-routes/engagement/$id/influencer/facebook': typeof PrivateRoutesEngagementIdInfluencerFacebookRoute
  '/_private-routes/engagement/$id/influencer/facebook_page': typeof PrivateRoutesEngagementIdInfluencerFacebookpageRoute
  '/_private-routes/engagement/$id/influencer/instagram': typeof PrivateRoutesEngagementIdInfluencerInstagramRoute
  '/_private-routes/engagement/$id/influencer/packages': typeof PrivateRoutesEngagementIdInfluencerPackagesRoute
  '/_private-routes/engagement/$id/influencer/posts': typeof PrivateRoutesEngagementIdInfluencerPostsRoute
  '/_private-routes/engagement/$id/influencer/threads': typeof PrivateRoutesEngagementIdInfluencerThreadsRoute
  '/_private-routes/engagement/$id/influencer/tiktok': typeof PrivateRoutesEngagementIdInfluencerTiktokRoute
  '/_private-routes/engagement/$id/influencer/twitter': typeof PrivateRoutesEngagementIdInfluencerTwitterRoute
  '/_private-routes/engagement/$id/influencer/xhs': typeof PrivateRoutesEngagementIdInfluencerXhsRoute
  '/_private-routes/engagement/$id/influencer/youtube': typeof PrivateRoutesEngagementIdInfluencerYoutubeRoute
  '/_private-routes/engagement/$id/posts/$postId': typeof PrivateRoutesEngagementIdPostsPostIdRoute
  '/_private-routes/engagement/$id/posts/add': typeof PrivateRoutesEngagementIdPostsAddRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFacebookfeedpostRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdFeedvideosRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramfeedpostRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramreelpostRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdInstagramstorypostRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdScrapingfeedpostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/shorts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdShortsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredfeedpostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdSponsoredyoutubefeedpostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdTwitterfeedpostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhspicturepostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdXhsvideopostsRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosRouteWithChildren
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubetagsrankingRoute
  '/_private-routes/influencer/$id/profile/campaigns': typeof PrivateRoutesInfluencerIdProfileCampaignsRoute
  '/_private-routes/influencer/$id/profile/compare': typeof PrivateRoutesInfluencerIdProfileCompareRoute
  '/_private-routes/marketplace/$id/posts/$postId': typeof PrivateRoutesMarketplaceIdPostsPostIdRoute
  '/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId': typeof PrivateRoutesMarketplaceAddECommerceAutoMangedCampaignIdRoute
  '/_private-routes/package/$id/content/list': typeof PrivateRoutesPackageIdContentListRoute
  '/_private-routes/package/$id/content/search': typeof PrivateRoutesPackageIdContentSearchRouteWithChildren
  '/_private-routes/tiktok/ad-campaign-report/$id/$groupId': typeof PrivateRoutesTiktokAdCampaignReportIdGroupIdRoute
  '/_private-routes/tiktok/campaigns/$id/influencers': typeof PrivateRoutesTiktokCampaignsIdInfluencersRoute
  '/_private-routes/tiktok/campaigns/$id/posts': typeof PrivateRoutesTiktokCampaignsIdPostsRouteWithChildren
  '/_private-routes/tiktok/campaigns/$id/report': typeof PrivateRoutesTiktokCampaignsIdReportRoute
  '/_private-routes/engagement/$id/influencer/': typeof PrivateRoutesEngagementIdInfluencerIndexRoute
  '/_private-routes/engagement/$id/posts/': typeof PrivateRoutesEngagementIdPostsIndexRoute
  '/_private-routes/influencer/$id/profile/': typeof PrivateRoutesInfluencerIdProfileIndexRoute
  '/_private-routes/marketplace/$id/posts/': typeof PrivateRoutesMarketplaceIdPostsIndexRoute
  '/_private-routes/marketplace/add/e-commerce/': typeof PrivateRoutesMarketplaceAddECommerceIndexRoute
  '/_private-routes/package/$id/content/': typeof PrivateRoutesPackageIdContentIndexRoute
  '/_private-routes/tiktok/ad-campaign-report/$id/': typeof PrivateRoutesTiktokAdCampaignReportIdIndexRoute
  '/_private-routes/tiktok/campaigns/$id/': typeof PrivateRoutesTiktokCampaignsIdIndexRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdHashtagRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdTrendRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdUgcRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosCompareIdRoute
  '/_private-routes/package/$id/content/search/douyin': typeof PrivateRoutesPackageIdContentSearchDouyinRoute
  '/_private-routes/package/$id/content/search/facebook': typeof PrivateRoutesPackageIdContentSearchFacebookRoute
  '/_private-routes/package/$id/content/search/facebook_page': typeof PrivateRoutesPackageIdContentSearchFacebookpageRoute
  '/_private-routes/package/$id/content/search/instagram': typeof PrivateRoutesPackageIdContentSearchInstagramRoute
  '/_private-routes/package/$id/content/search/threads': typeof PrivateRoutesPackageIdContentSearchThreadsRoute
  '/_private-routes/package/$id/content/search/tiktok': typeof PrivateRoutesPackageIdContentSearchTiktokRoute
  '/_private-routes/package/$id/content/search/twitter': typeof PrivateRoutesPackageIdContentSearchTwitterRoute
  '/_private-routes/package/$id/content/search/xhs': typeof PrivateRoutesPackageIdContentSearchXhsRoute
  '/_private-routes/package/$id/content/search/youtube': typeof PrivateRoutesPackageIdContentSearchYoutubeRoute
  '/_private-routes/tiktok/campaigns/$id/posts/$postId': typeof PrivateRoutesTiktokCampaignsIdPostsPostIdRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdIndexRoute
  '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/': typeof PrivateRoutesInfluencerIdSocialMediaAccountIdYoutubecomparefeedvideosIndexRoute
  '/_private-routes/package/$id/content/search/': typeof PrivateRoutesPackageIdContentSearchIndexRoute
  '/_private-routes/tiktok/campaigns/$id/posts/': typeof PrivateRoutesTiktokCampaignsIdPostsIndexRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareInstagramcomparefeedpostRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareYoutubecomparefeedvideosRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFacebookfeedpostRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardFeedvideosRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramcommentsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramfeedpostRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramreelpostRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardInstagramstorypostRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardShortsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTagrankinglistRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardTwitterfeedpostsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardYoutubecommentsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionBrandAmbassadorsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionMentionedpostsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionTaggedpostsRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdCompareIndexRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdDashboardIndexRoute
  '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/': typeof PrivateRoutesAnalyticsBrandAccountIdSocialMediaSocialMediaAccountIdInteractionIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/not-found'
    | '/advertiser'
    | '/agency'
    | '/analytics'
    | '/asset-preview'
    | '/auto-managed'
    | '/campaign-posts-discovery'
    | '/chat-demo'
    | '/hashtag-analytics'
    | '/influencer'
    | '/influencers'
    | '/invoices'
    | '/package'
    | '/partner'
    | '/payments'
    | '/posts'
    | '/posts-insight'
    | '/report'
    | '/settings'
    | '/talent-agency'
    | '/tiktok'
    | '/trending'
    | '/user'
    | '/user-settings'
    | '/youtube-cms'
    | '/privacy'
    | '/redirect_to'
    | '/sign-in'
    | '/terms'
    | '/terms-combined'
    | '/tiktok-onboarding'
    | '/advertiser/$id'
    | '/advertiser/add'
    | '/agency/$id'
    | '/agency/add'
    | '/analytics/add'
    | '/auto-managed/$id'
    | '/chat-demo/chat'
    | '/engagement/$id'
    | '/engagement/add'
    | '/hashtag-analytics/instagram'
    | '/influencer/$id'
    | '/influencer/add'
    | '/influencers/proposal'
    | '/influencers/search'
    | '/marketplace/$id'
    | '/marketplace/add'
    | '/marketplace/reorder'
    | '/package/$id'
    | '/package/add'
    | '/partner/$id'
    | '/posts-insight/$id'
    | '/talent-agency/$id'
    | '/talent-agency/add'
    | '/tiktok/ad-accounts'
    | '/tiktok/ad-campaign-report'
    | '/tiktok/advertiser-details'
    | '/tiktok/budget-allocation'
    | '/tiktok/campaigns'
    | '/tiktok/failed-payment'
    | '/tiktok/subscription-billing'
    | '/trending/influencers'
    | '/trending/posts'
    | '/user/$id'
    | '/user/add'
    | '/user/linked'
    | '/youtube-cms/$cmsChannelId'
    | '/youtube-cms/confirmed-revenue'
    | '/password_forgot'
    | '/egg/referral'
    | '/mkp/referral'
    | '/sign-up/success'
    | '/tiktok-onboarding/ad-account'
    | '/tiktok-onboarding/password-enter'
    | '/tiktok-onboarding/select-plan'
    | '/tiktok-onboarding/success'
    | '/advertiser/'
    | '/agency/'
    | '/analytics/'
    | '/auto-managed/'
    | '/chat-demo/'
    | '/engagement'
    | '/hashtag-analytics/'
    | '/influencer/'
    | '/influencers/'
    | '/marketplace'
    | '/package/'
    | '/partner/'
    | '/posts-insight/'
    | '/talent-agency/'
    | '/trending/'
    | '/user/'
    | '/youtube-cms/'
    | '/sign-up'
    | '/tiktok-onboarding/'
    | '/advertiser/$id/budget-allocation'
    | '/advertiser/$id/users'
    | '/agency/$id/business'
    | '/agency/$id/users'
    | '/auto-managed/$id/commissions'
    | '/auto-managed/$id/joined-influencers'
    | '/auto-managed/$id/report'
    | '/engagement/$id/influencer'
    | '/engagement/$id/posts'
    | '/engagement/$id/proposal'
    | '/engagement/$id/report'
    | '/engagement/$id/tracking'
    | '/engagement/$id/tracking-referral-codes'
    | '/hashtag-analytics/tiktok/hashtag'
    | '/hashtag-analytics/tiktok/keyword'
    | '/influencer/$id/profile'
    | '/influencers/search/douyin'
    | '/influencers/search/facebook'
    | '/influencers/search/facebook_page'
    | '/influencers/search/instagram'
    | '/influencers/search/threads'
    | '/influencers/search/tiktok'
    | '/influencers/search/twitter'
    | '/influencers/search/xhs'
    | '/influencers/search/youtube'
    | '/marketplace/$id/applicant'
    | '/marketplace/$id/commissions'
    | '/marketplace/$id/influencer'
    | '/marketplace/$id/joined-influencers'
    | '/marketplace/$id/posts'
    | '/marketplace/$id/report'
    | '/marketplace/$id/report-affiliate'
    | '/marketplace/$id/tracking'
    | '/marketplace/$id/tracking-referral-codes'
    | '/marketplace/add/referral-code-tracking'
    | '/marketplace/add/social-media-tracking'
    | '/marketplace/add/url-tracking'
    | '/package/$id/content'
    | '/partner/$id/business'
    | '/partner/$id/users'
    | '/posts-insight/$id/$postId'
    | '/redirect/analytics-connect/$provider'
    | '/redirect/analytics-list-connect/$provider'
    | '/redirect/analytics-signin/$provider'
    | '/redirect/connect/tiktok-business'
    | '/redirect/hidden/youtube-list-connect'
    | '/redirect/talent-analytics-connect/$provider'
    | '/redirect/talent-signin/$provider'
    | '/talent-agency/$id/business'
    | '/talent-agency/$id/users'
    | '/tiktok/campaigns/$id'
    | '/tiktok/campaigns/add'
    | '/tiktok/campaigns/review-influencers'
    | '/password_register/$token'
    | '/password_reset/$token'
    | '/tiktok-onboarding/password-register/$token'
    | '/advertiser/$id/'
    | '/agency/$id/'
    | '/analytics/$brandAccountId'
    | '/auto-managed/$id/'
    | '/engagement/$id/'
    | '/hashtag-analytics/tiktok'
    | '/influencer/$id/'
    | '/influencers/search/'
    | '/marketplace/$id/'
    | '/marketplace/add/'
    | '/package/$id/'
    | '/partner/$id/'
    | '/talent-agency/$id/'
    | '/tiktok/ad-campaign-report/'
    | '/tiktok/campaigns/'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
    | '/engagement/$id/influencer/campaigns'
    | '/engagement/$id/influencer/douyin'
    | '/engagement/$id/influencer/facebook'
    | '/engagement/$id/influencer/facebook_page'
    | '/engagement/$id/influencer/instagram'
    | '/engagement/$id/influencer/packages'
    | '/engagement/$id/influencer/posts'
    | '/engagement/$id/influencer/threads'
    | '/engagement/$id/influencer/tiktok'
    | '/engagement/$id/influencer/twitter'
    | '/engagement/$id/influencer/xhs'
    | '/engagement/$id/influencer/youtube'
    | '/engagement/$id/posts/$postId'
    | '/engagement/$id/posts/add'
    | '/influencer/$id/$socialMediaAccountId/facebook_feed_post'
    | '/influencer/$id/$socialMediaAccountId/feed_videos'
    | '/influencer/$id/$socialMediaAccountId/instagram_feed_post'
    | '/influencer/$id/$socialMediaAccountId/instagram_reel_post'
    | '/influencer/$id/$socialMediaAccountId/instagram_story_post'
    | '/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/shorts'
    | '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
    | '/influencer/$id/$socialMediaAccountId/xhs_video_posts'
    | '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
    | '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
    | '/influencer/$id/profile/campaigns'
    | '/influencer/$id/profile/compare'
    | '/marketplace/$id/posts/$postId'
    | '/marketplace/add/e-commerce/$autoMangedCampaignId'
    | '/package/$id/content/list'
    | '/package/$id/content/search'
    | '/tiktok/ad-campaign-report/$id/$groupId'
    | '/tiktok/campaigns/$id/influencers'
    | '/tiktok/campaigns/$id/posts'
    | '/tiktok/campaigns/$id/report'
    | '/engagement/$id/influencer/'
    | '/engagement/$id/posts/'
    | '/influencer/$id/profile/'
    | '/marketplace/$id/posts/'
    | '/marketplace/add/e-commerce'
    | '/package/$id/content/'
    | '/tiktok/ad-campaign-report/$id'
    | '/tiktok/campaigns/$id/'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
    | '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
    | '/package/$id/content/search/douyin'
    | '/package/$id/content/search/facebook'
    | '/package/$id/content/search/facebook_page'
    | '/package/$id/content/search/instagram'
    | '/package/$id/content/search/threads'
    | '/package/$id/content/search/tiktok'
    | '/package/$id/content/search/twitter'
    | '/package/$id/content/search/xhs'
    | '/package/$id/content/search/youtube'
    | '/tiktok/campaigns/$id/posts/$postId'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/'
    | '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/'
    | '/package/$id/content/search/'
    | '/tiktok/campaigns/$id/posts/'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/not-found'
    | '/asset-preview'
    | '/campaign-posts-discovery'
    | '/invoices'
    | '/payments'
    | '/posts'
    | '/report'
    | '/settings'
    | '/tiktok'
    | '/user-settings'
    | '/privacy'
    | '/redirect_to'
    | '/sign-in'
    | '/terms'
    | '/terms-combined'
    | '/advertiser/add'
    | '/agency/add'
    | '/analytics/add'
    | '/chat-demo/chat'
    | '/engagement/add'
    | '/hashtag-analytics/instagram'
    | '/influencer/add'
    | '/influencers/proposal'
    | '/marketplace/reorder'
    | '/package/add'
    | '/posts-insight/$id'
    | '/talent-agency/add'
    | '/tiktok/ad-accounts'
    | '/tiktok/advertiser-details'
    | '/tiktok/budget-allocation'
    | '/tiktok/failed-payment'
    | '/tiktok/subscription-billing'
    | '/trending/influencers'
    | '/trending/posts'
    | '/user/$id'
    | '/user/add'
    | '/user/linked'
    | '/youtube-cms/$cmsChannelId'
    | '/youtube-cms/confirmed-revenue'
    | '/password_forgot'
    | '/egg/referral'
    | '/mkp/referral'
    | '/sign-up/success'
    | '/tiktok-onboarding/ad-account'
    | '/tiktok-onboarding/password-enter'
    | '/tiktok-onboarding/select-plan'
    | '/tiktok-onboarding/success'
    | '/advertiser'
    | '/agency'
    | '/analytics'
    | '/auto-managed'
    | '/chat-demo'
    | '/engagement'
    | '/hashtag-analytics'
    | '/influencer'
    | '/influencers'
    | '/marketplace'
    | '/package'
    | '/partner'
    | '/posts-insight'
    | '/talent-agency'
    | '/trending'
    | '/user'
    | '/youtube-cms'
    | '/sign-up'
    | '/tiktok-onboarding'
    | '/advertiser/$id/budget-allocation'
    | '/advertiser/$id/users'
    | '/agency/$id/business'
    | '/agency/$id/users'
    | '/auto-managed/$id/commissions'
    | '/auto-managed/$id/joined-influencers'
    | '/auto-managed/$id/report'
    | '/engagement/$id/proposal'
    | '/engagement/$id/report'
    | '/engagement/$id/tracking'
    | '/engagement/$id/tracking-referral-codes'
    | '/hashtag-analytics/tiktok/hashtag'
    | '/hashtag-analytics/tiktok/keyword'
    | '/influencers/search/douyin'
    | '/influencers/search/facebook'
    | '/influencers/search/facebook_page'
    | '/influencers/search/instagram'
    | '/influencers/search/threads'
    | '/influencers/search/tiktok'
    | '/influencers/search/twitter'
    | '/influencers/search/xhs'
    | '/influencers/search/youtube'
    | '/marketplace/$id/applicant'
    | '/marketplace/$id/commissions'
    | '/marketplace/$id/influencer'
    | '/marketplace/$id/joined-influencers'
    | '/marketplace/$id/report'
    | '/marketplace/$id/report-affiliate'
    | '/marketplace/$id/tracking'
    | '/marketplace/$id/tracking-referral-codes'
    | '/marketplace/add/referral-code-tracking'
    | '/marketplace/add/social-media-tracking'
    | '/marketplace/add/url-tracking'
    | '/partner/$id/business'
    | '/partner/$id/users'
    | '/posts-insight/$id/$postId'
    | '/redirect/analytics-connect/$provider'
    | '/redirect/analytics-list-connect/$provider'
    | '/redirect/analytics-signin/$provider'
    | '/redirect/connect/tiktok-business'
    | '/redirect/hidden/youtube-list-connect'
    | '/redirect/talent-analytics-connect/$provider'
    | '/redirect/talent-signin/$provider'
    | '/talent-agency/$id/business'
    | '/talent-agency/$id/users'
    | '/tiktok/campaigns/add'
    | '/tiktok/campaigns/review-influencers'
    | '/password_register/$token'
    | '/password_reset/$token'
    | '/tiktok-onboarding/password-register/$token'
    | '/advertiser/$id'
    | '/agency/$id'
    | '/analytics/$brandAccountId'
    | '/auto-managed/$id'
    | '/engagement/$id'
    | '/hashtag-analytics/tiktok'
    | '/influencer/$id'
    | '/influencers/search'
    | '/marketplace/$id'
    | '/marketplace/add'
    | '/package/$id'
    | '/partner/$id'
    | '/talent-agency/$id'
    | '/tiktok/ad-campaign-report'
    | '/tiktok/campaigns'
    | '/engagement/$id/influencer/campaigns'
    | '/engagement/$id/influencer/douyin'
    | '/engagement/$id/influencer/facebook'
    | '/engagement/$id/influencer/facebook_page'
    | '/engagement/$id/influencer/instagram'
    | '/engagement/$id/influencer/packages'
    | '/engagement/$id/influencer/posts'
    | '/engagement/$id/influencer/threads'
    | '/engagement/$id/influencer/tiktok'
    | '/engagement/$id/influencer/twitter'
    | '/engagement/$id/influencer/xhs'
    | '/engagement/$id/influencer/youtube'
    | '/engagement/$id/posts/$postId'
    | '/engagement/$id/posts/add'
    | '/influencer/$id/$socialMediaAccountId/facebook_feed_post'
    | '/influencer/$id/$socialMediaAccountId/feed_videos'
    | '/influencer/$id/$socialMediaAccountId/instagram_feed_post'
    | '/influencer/$id/$socialMediaAccountId/instagram_reel_post'
    | '/influencer/$id/$socialMediaAccountId/instagram_story_post'
    | '/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/shorts'
    | '/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
    | '/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
    | '/influencer/$id/$socialMediaAccountId/xhs_video_posts'
    | '/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
    | '/influencer/$id/profile/campaigns'
    | '/influencer/$id/profile/compare'
    | '/marketplace/$id/posts/$postId'
    | '/marketplace/add/e-commerce/$autoMangedCampaignId'
    | '/package/$id/content/list'
    | '/tiktok/ad-campaign-report/$id/$groupId'
    | '/tiktok/campaigns/$id/influencers'
    | '/tiktok/campaigns/$id/report'
    | '/engagement/$id/influencer'
    | '/engagement/$id/posts'
    | '/influencer/$id/profile'
    | '/marketplace/$id/posts'
    | '/marketplace/add/e-commerce'
    | '/package/$id/content'
    | '/tiktok/ad-campaign-report/$id'
    | '/tiktok/campaigns/$id'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
    | '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
    | '/package/$id/content/search/douyin'
    | '/package/$id/content/search/facebook'
    | '/package/$id/content/search/facebook_page'
    | '/package/$id/content/search/instagram'
    | '/package/$id/content/search/threads'
    | '/package/$id/content/search/tiktok'
    | '/package/$id/content/search/twitter'
    | '/package/$id/content/search/xhs'
    | '/package/$id/content/search/youtube'
    | '/tiktok/campaigns/$id/posts/$postId'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
    | '/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
    | '/package/$id/content/search'
    | '/tiktok/campaigns/$id/posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard'
    | '/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction'
  id:
    | '__root__'
    | '/'
    | '/_private-routes'
    | '/_public-routes'
    | '/not-found'
    | '/_private-routes/advertiser'
    | '/_private-routes/agency'
    | '/_private-routes/analytics'
    | '/_private-routes/asset-preview'
    | '/_private-routes/auto-managed'
    | '/_private-routes/campaign-posts-discovery'
    | '/_private-routes/chat-demo'
    | '/_private-routes/hashtag-analytics'
    | '/_private-routes/influencer'
    | '/_private-routes/influencers'
    | '/_private-routes/invoices'
    | '/_private-routes/package'
    | '/_private-routes/partner'
    | '/_private-routes/payments'
    | '/_private-routes/posts'
    | '/_private-routes/posts-insight'
    | '/_private-routes/report'
    | '/_private-routes/settings'
    | '/_private-routes/talent-agency'
    | '/_private-routes/tiktok'
    | '/_private-routes/trending'
    | '/_private-routes/user'
    | '/_private-routes/user-settings'
    | '/_private-routes/youtube-cms'
    | '/_public-routes/privacy'
    | '/_public-routes/redirect_to'
    | '/_public-routes/sign-in'
    | '/_public-routes/terms'
    | '/_public-routes/terms-combined'
    | '/_public-routes/tiktok-onboarding'
    | '/_private-routes/advertiser/$id'
    | '/_private-routes/advertiser/add'
    | '/_private-routes/agency/$id'
    | '/_private-routes/agency/add'
    | '/_private-routes/analytics/add'
    | '/_private-routes/auto-managed/$id'
    | '/_private-routes/chat-demo/chat'
    | '/_private-routes/engagement/$id'
    | '/_private-routes/engagement/add'
    | '/_private-routes/hashtag-analytics/instagram'
    | '/_private-routes/influencer/$id'
    | '/_private-routes/influencer/add'
    | '/_private-routes/influencers/proposal'
    | '/_private-routes/influencers/search'
    | '/_private-routes/marketplace/$id'
    | '/_private-routes/marketplace/add'
    | '/_private-routes/marketplace/reorder'
    | '/_private-routes/package/$id'
    | '/_private-routes/package/add'
    | '/_private-routes/partner/$id'
    | '/_private-routes/posts-insight/$id'
    | '/_private-routes/talent-agency/$id'
    | '/_private-routes/talent-agency/add'
    | '/_private-routes/tiktok/ad-accounts'
    | '/_private-routes/tiktok/ad-campaign-report'
    | '/_private-routes/tiktok/advertiser-details'
    | '/_private-routes/tiktok/budget-allocation'
    | '/_private-routes/tiktok/campaigns'
    | '/_private-routes/tiktok/failed-payment'
    | '/_private-routes/tiktok/subscription-billing'
    | '/_private-routes/trending/influencers'
    | '/_private-routes/trending/posts'
    | '/_private-routes/user/$id'
    | '/_private-routes/user/add'
    | '/_private-routes/user/linked'
    | '/_private-routes/youtube-cms/$cmsChannelId'
    | '/_private-routes/youtube-cms/confirmed-revenue'
    | '/_public-routes/_password/password_forgot'
    | '/_public-routes/egg/referral'
    | '/_public-routes/mkp/referral'
    | '/_public-routes/sign-up/success'
    | '/_public-routes/tiktok-onboarding/ad-account'
    | '/_public-routes/tiktok-onboarding/password-enter'
    | '/_public-routes/tiktok-onboarding/select-plan'
    | '/_public-routes/tiktok-onboarding/success'
    | '/_private-routes/advertiser/'
    | '/_private-routes/agency/'
    | '/_private-routes/analytics/'
    | '/_private-routes/auto-managed/'
    | '/_private-routes/chat-demo/'
    | '/_private-routes/engagement/'
    | '/_private-routes/hashtag-analytics/'
    | '/_private-routes/influencer/'
    | '/_private-routes/influencers/'
    | '/_private-routes/marketplace/'
    | '/_private-routes/package/'
    | '/_private-routes/partner/'
    | '/_private-routes/posts-insight/'
    | '/_private-routes/talent-agency/'
    | '/_private-routes/trending/'
    | '/_private-routes/user/'
    | '/_private-routes/youtube-cms/'
    | '/_public-routes/sign-up/'
    | '/_public-routes/tiktok-onboarding/'
    | '/_private-routes/advertiser/$id/budget-allocation'
    | '/_private-routes/advertiser/$id/users'
    | '/_private-routes/agency/$id/business'
    | '/_private-routes/agency/$id/users'
    | '/_private-routes/auto-managed/$id/commissions'
    | '/_private-routes/auto-managed/$id/joined-influencers'
    | '/_private-routes/auto-managed/$id/report'
    | '/_private-routes/engagement/$id/influencer'
    | '/_private-routes/engagement/$id/posts'
    | '/_private-routes/engagement/$id/proposal'
    | '/_private-routes/engagement/$id/report'
    | '/_private-routes/engagement/$id/tracking'
    | '/_private-routes/engagement/$id/tracking-referral-codes'
    | '/_private-routes/hashtag-analytics/tiktok/hashtag'
    | '/_private-routes/hashtag-analytics/tiktok/keyword'
    | '/_private-routes/influencer/$id/profile'
    | '/_private-routes/influencers/search/douyin'
    | '/_private-routes/influencers/search/facebook'
    | '/_private-routes/influencers/search/facebook_page'
    | '/_private-routes/influencers/search/instagram'
    | '/_private-routes/influencers/search/threads'
    | '/_private-routes/influencers/search/tiktok'
    | '/_private-routes/influencers/search/twitter'
    | '/_private-routes/influencers/search/xhs'
    | '/_private-routes/influencers/search/youtube'
    | '/_private-routes/marketplace/$id/applicant'
    | '/_private-routes/marketplace/$id/commissions'
    | '/_private-routes/marketplace/$id/influencer'
    | '/_private-routes/marketplace/$id/joined-influencers'
    | '/_private-routes/marketplace/$id/posts'
    | '/_private-routes/marketplace/$id/report'
    | '/_private-routes/marketplace/$id/report-affiliate'
    | '/_private-routes/marketplace/$id/tracking'
    | '/_private-routes/marketplace/$id/tracking-referral-codes'
    | '/_private-routes/marketplace/add/referral-code-tracking'
    | '/_private-routes/marketplace/add/social-media-tracking'
    | '/_private-routes/marketplace/add/url-tracking'
    | '/_private-routes/package/$id/content'
    | '/_private-routes/partner/$id/business'
    | '/_private-routes/partner/$id/users'
    | '/_private-routes/posts-insight/$id/$postId'
    | '/_private-routes/redirect/analytics-connect/$provider'
    | '/_private-routes/redirect/analytics-list-connect/$provider'
    | '/_private-routes/redirect/analytics-signin/$provider'
    | '/_private-routes/redirect/connect/tiktok-business'
    | '/_private-routes/redirect/hidden/youtube-list-connect'
    | '/_private-routes/redirect/talent-analytics-connect/$provider'
    | '/_private-routes/redirect/talent-signin/$provider'
    | '/_private-routes/talent-agency/$id/business'
    | '/_private-routes/talent-agency/$id/users'
    | '/_private-routes/tiktok/campaigns/$id'
    | '/_private-routes/tiktok/campaigns/add'
    | '/_private-routes/tiktok/campaigns/review-influencers'
    | '/_public-routes/_password/password_register/$token'
    | '/_public-routes/_password/password_reset/$token'
    | '/_public-routes/tiktok-onboarding/password-register/$token'
    | '/_private-routes/advertiser/$id/'
    | '/_private-routes/agency/$id/'
    | '/_private-routes/analytics/$brandAccountId/'
    | '/_private-routes/auto-managed/$id/'
    | '/_private-routes/engagement/$id/'
    | '/_private-routes/hashtag-analytics/tiktok/'
    | '/_private-routes/influencer/$id/'
    | '/_private-routes/influencers/search/'
    | '/_private-routes/marketplace/$id/'
    | '/_private-routes/marketplace/add/'
    | '/_private-routes/package/$id/'
    | '/_private-routes/partner/$id/'
    | '/_private-routes/talent-agency/$id/'
    | '/_private-routes/tiktok/ad-campaign-report/'
    | '/_private-routes/tiktok/campaigns/'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId'
    | '/_private-routes/engagement/$id/influencer/campaigns'
    | '/_private-routes/engagement/$id/influencer/douyin'
    | '/_private-routes/engagement/$id/influencer/facebook'
    | '/_private-routes/engagement/$id/influencer/facebook_page'
    | '/_private-routes/engagement/$id/influencer/instagram'
    | '/_private-routes/engagement/$id/influencer/packages'
    | '/_private-routes/engagement/$id/influencer/posts'
    | '/_private-routes/engagement/$id/influencer/threads'
    | '/_private-routes/engagement/$id/influencer/tiktok'
    | '/_private-routes/engagement/$id/influencer/twitter'
    | '/_private-routes/engagement/$id/influencer/xhs'
    | '/_private-routes/engagement/$id/influencer/youtube'
    | '/_private-routes/engagement/$id/posts/$postId'
    | '/_private-routes/engagement/$id/posts/add'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/shorts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking'
    | '/_private-routes/influencer/$id/profile/campaigns'
    | '/_private-routes/influencer/$id/profile/compare'
    | '/_private-routes/marketplace/$id/posts/$postId'
    | '/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId'
    | '/_private-routes/package/$id/content/list'
    | '/_private-routes/package/$id/content/search'
    | '/_private-routes/tiktok/ad-campaign-report/$id/$groupId'
    | '/_private-routes/tiktok/campaigns/$id/influencers'
    | '/_private-routes/tiktok/campaigns/$id/posts'
    | '/_private-routes/tiktok/campaigns/$id/report'
    | '/_private-routes/engagement/$id/influencer/'
    | '/_private-routes/engagement/$id/posts/'
    | '/_private-routes/influencer/$id/profile/'
    | '/_private-routes/marketplace/$id/posts/'
    | '/_private-routes/marketplace/add/e-commerce/'
    | '/_private-routes/package/$id/content/'
    | '/_private-routes/tiktok/ad-campaign-report/$id/'
    | '/_private-routes/tiktok/campaigns/$id/'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId'
    | '/_private-routes/package/$id/content/search/douyin'
    | '/_private-routes/package/$id/content/search/facebook'
    | '/_private-routes/package/$id/content/search/facebook_page'
    | '/_private-routes/package/$id/content/search/instagram'
    | '/_private-routes/package/$id/content/search/threads'
    | '/_private-routes/package/$id/content/search/tiktok'
    | '/_private-routes/package/$id/content/search/twitter'
    | '/_private-routes/package/$id/content/search/xhs'
    | '/_private-routes/package/$id/content/search/youtube'
    | '/_private-routes/tiktok/campaigns/$id/posts/$postId'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/'
    | '/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/'
    | '/_private-routes/package/$id/content/search/'
    | '/_private-routes/tiktok/campaigns/$id/posts/'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/'
    | '/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  PrivateRoutesRoute: typeof PrivateRoutesRouteWithChildren
  PublicRoutesRoute: typeof PublicRoutesRouteWithChildren
  NotFoundRoute: typeof NotFoundRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  PrivateRoutesRoute: PrivateRoutesRouteWithChildren,
  PublicRoutesRoute: PublicRoutesRouteWithChildren,
  NotFoundRoute: NotFoundRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_private-routes",
        "/_public-routes",
        "/not-found"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_private-routes": {
      "filePath": "_private-routes.tsx",
      "children": [
        "/_private-routes/advertiser",
        "/_private-routes/agency",
        "/_private-routes/analytics",
        "/_private-routes/asset-preview",
        "/_private-routes/auto-managed",
        "/_private-routes/campaign-posts-discovery",
        "/_private-routes/chat-demo",
        "/_private-routes/hashtag-analytics",
        "/_private-routes/influencer",
        "/_private-routes/influencers",
        "/_private-routes/invoices",
        "/_private-routes/package",
        "/_private-routes/partner",
        "/_private-routes/payments",
        "/_private-routes/posts",
        "/_private-routes/posts-insight",
        "/_private-routes/report",
        "/_private-routes/settings",
        "/_private-routes/talent-agency",
        "/_private-routes/tiktok",
        "/_private-routes/trending",
        "/_private-routes/user",
        "/_private-routes/user-settings",
        "/_private-routes/youtube-cms",
        "/_private-routes/engagement/$id",
        "/_private-routes/engagement/add",
        "/_private-routes/marketplace/$id",
        "/_private-routes/marketplace/add",
        "/_private-routes/marketplace/reorder",
        "/_private-routes/engagement/",
        "/_private-routes/marketplace/",
        "/_private-routes/redirect/analytics-connect/$provider",
        "/_private-routes/redirect/analytics-list-connect/$provider",
        "/_private-routes/redirect/analytics-signin/$provider",
        "/_private-routes/redirect/connect/tiktok-business",
        "/_private-routes/redirect/hidden/youtube-list-connect",
        "/_private-routes/redirect/talent-analytics-connect/$provider",
        "/_private-routes/redirect/talent-signin/$provider"
      ]
    },
    "/_public-routes": {
      "filePath": "_public-routes.tsx",
      "children": [
        "/_public-routes/privacy",
        "/_public-routes/redirect_to",
        "/_public-routes/sign-in",
        "/_public-routes/terms",
        "/_public-routes/terms-combined",
        "/_public-routes/tiktok-onboarding",
        "/_public-routes/_password/password_forgot",
        "/_public-routes/egg/referral",
        "/_public-routes/mkp/referral",
        "/_public-routes/sign-up/success",
        "/_public-routes/sign-up/",
        "/_public-routes/_password/password_register/$token",
        "/_public-routes/_password/password_reset/$token"
      ]
    },
    "/not-found": {
      "filePath": "not-found.tsx"
    },
    "/_private-routes/advertiser": {
      "filePath": "_private-routes/advertiser.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/advertiser/$id",
        "/_private-routes/advertiser/add",
        "/_private-routes/advertiser/"
      ]
    },
    "/_private-routes/agency": {
      "filePath": "_private-routes/agency.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/agency/$id",
        "/_private-routes/agency/add",
        "/_private-routes/agency/"
      ]
    },
    "/_private-routes/analytics": {
      "filePath": "_private-routes/analytics.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/analytics/add",
        "/_private-routes/analytics/",
        "/_private-routes/analytics/$brandAccountId/",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
      ]
    },
    "/_private-routes/asset-preview": {
      "filePath": "_private-routes/asset-preview.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/auto-managed": {
      "filePath": "_private-routes/auto-managed.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/auto-managed/$id",
        "/_private-routes/auto-managed/"
      ]
    },
    "/_private-routes/campaign-posts-discovery": {
      "filePath": "_private-routes/campaign-posts-discovery.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/chat-demo": {
      "filePath": "_private-routes/chat-demo.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/chat-demo/chat",
        "/_private-routes/chat-demo/"
      ]
    },
    "/_private-routes/hashtag-analytics": {
      "filePath": "_private-routes/hashtag-analytics.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/hashtag-analytics/instagram",
        "/_private-routes/hashtag-analytics/",
        "/_private-routes/hashtag-analytics/tiktok/hashtag",
        "/_private-routes/hashtag-analytics/tiktok/keyword",
        "/_private-routes/hashtag-analytics/tiktok/"
      ]
    },
    "/_private-routes/influencer": {
      "filePath": "_private-routes/influencer.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/influencer/$id",
        "/_private-routes/influencer/add",
        "/_private-routes/influencer/"
      ]
    },
    "/_private-routes/influencers": {
      "filePath": "_private-routes/influencers.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/influencers/proposal",
        "/_private-routes/influencers/search",
        "/_private-routes/influencers/"
      ]
    },
    "/_private-routes/invoices": {
      "filePath": "_private-routes/invoices.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/package": {
      "filePath": "_private-routes/package.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/package/$id",
        "/_private-routes/package/add",
        "/_private-routes/package/"
      ]
    },
    "/_private-routes/partner": {
      "filePath": "_private-routes/partner.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/partner/$id",
        "/_private-routes/partner/"
      ]
    },
    "/_private-routes/payments": {
      "filePath": "_private-routes/payments.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/posts": {
      "filePath": "_private-routes/posts.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/posts-insight": {
      "filePath": "_private-routes/posts-insight.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/posts-insight/$id",
        "/_private-routes/posts-insight/"
      ]
    },
    "/_private-routes/report": {
      "filePath": "_private-routes/report.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/settings": {
      "filePath": "_private-routes/settings.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/talent-agency": {
      "filePath": "_private-routes/talent-agency.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/talent-agency/$id",
        "/_private-routes/talent-agency/add",
        "/_private-routes/talent-agency/"
      ]
    },
    "/_private-routes/tiktok": {
      "filePath": "_private-routes/tiktok.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/tiktok/ad-accounts",
        "/_private-routes/tiktok/ad-campaign-report",
        "/_private-routes/tiktok/advertiser-details",
        "/_private-routes/tiktok/budget-allocation",
        "/_private-routes/tiktok/campaigns",
        "/_private-routes/tiktok/failed-payment",
        "/_private-routes/tiktok/subscription-billing"
      ]
    },
    "/_private-routes/trending": {
      "filePath": "_private-routes/trending.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/trending/influencers",
        "/_private-routes/trending/posts",
        "/_private-routes/trending/"
      ]
    },
    "/_private-routes/user": {
      "filePath": "_private-routes/user.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/user/$id",
        "/_private-routes/user/add",
        "/_private-routes/user/linked",
        "/_private-routes/user/"
      ]
    },
    "/_private-routes/user-settings": {
      "filePath": "_private-routes/user-settings.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/youtube-cms": {
      "filePath": "_private-routes/youtube-cms.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/youtube-cms/$cmsChannelId",
        "/_private-routes/youtube-cms/confirmed-revenue",
        "/_private-routes/youtube-cms/"
      ]
    },
    "/_public-routes/privacy": {
      "filePath": "_public-routes/privacy.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/redirect_to": {
      "filePath": "_public-routes/redirect_to.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/sign-in": {
      "filePath": "_public-routes/sign-in.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/terms": {
      "filePath": "_public-routes/terms.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/terms-combined": {
      "filePath": "_public-routes/terms-combined.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/tiktok-onboarding": {
      "filePath": "_public-routes/tiktok-onboarding.tsx",
      "parent": "/_public-routes",
      "children": [
        "/_public-routes/tiktok-onboarding/ad-account",
        "/_public-routes/tiktok-onboarding/password-enter",
        "/_public-routes/tiktok-onboarding/select-plan",
        "/_public-routes/tiktok-onboarding/success",
        "/_public-routes/tiktok-onboarding/",
        "/_public-routes/tiktok-onboarding/password-register/$token"
      ]
    },
    "/_private-routes/advertiser/$id": {
      "filePath": "_private-routes/advertiser/$id.tsx",
      "parent": "/_private-routes/advertiser",
      "children": [
        "/_private-routes/advertiser/$id/budget-allocation",
        "/_private-routes/advertiser/$id/users",
        "/_private-routes/advertiser/$id/"
      ]
    },
    "/_private-routes/advertiser/add": {
      "filePath": "_private-routes/advertiser/add.tsx",
      "parent": "/_private-routes/advertiser"
    },
    "/_private-routes/agency/$id": {
      "filePath": "_private-routes/agency/$id.tsx",
      "parent": "/_private-routes/agency",
      "children": [
        "/_private-routes/agency/$id/business",
        "/_private-routes/agency/$id/users",
        "/_private-routes/agency/$id/"
      ]
    },
    "/_private-routes/agency/add": {
      "filePath": "_private-routes/agency/add.tsx",
      "parent": "/_private-routes/agency"
    },
    "/_private-routes/analytics/add": {
      "filePath": "_private-routes/analytics/add.tsx",
      "parent": "/_private-routes/analytics"
    },
    "/_private-routes/auto-managed/$id": {
      "filePath": "_private-routes/auto-managed/$id.tsx",
      "parent": "/_private-routes/auto-managed",
      "children": [
        "/_private-routes/auto-managed/$id/commissions",
        "/_private-routes/auto-managed/$id/joined-influencers",
        "/_private-routes/auto-managed/$id/report",
        "/_private-routes/auto-managed/$id/"
      ]
    },
    "/_private-routes/chat-demo/chat": {
      "filePath": "_private-routes/chat-demo/chat.tsx",
      "parent": "/_private-routes/chat-demo"
    },
    "/_private-routes/engagement/$id": {
      "filePath": "_private-routes/engagement/$id.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/engagement/$id/influencer",
        "/_private-routes/engagement/$id/posts",
        "/_private-routes/engagement/$id/proposal",
        "/_private-routes/engagement/$id/report",
        "/_private-routes/engagement/$id/tracking",
        "/_private-routes/engagement/$id/tracking-referral-codes",
        "/_private-routes/engagement/$id/"
      ]
    },
    "/_private-routes/engagement/add": {
      "filePath": "_private-routes/engagement/add.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/hashtag-analytics/instagram": {
      "filePath": "_private-routes/hashtag-analytics/instagram.tsx",
      "parent": "/_private-routes/hashtag-analytics"
    },
    "/_private-routes/influencer/$id": {
      "filePath": "_private-routes/influencer/$id.tsx",
      "parent": "/_private-routes/influencer",
      "children": [
        "/_private-routes/influencer/$id/profile",
        "/_private-routes/influencer/$id/",
        "/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post",
        "/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos",
        "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post",
        "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post",
        "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post",
        "/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/shorts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts",
        "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos",
        "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking"
      ]
    },
    "/_private-routes/influencer/add": {
      "filePath": "_private-routes/influencer/add.tsx",
      "parent": "/_private-routes/influencer"
    },
    "/_private-routes/influencers/proposal": {
      "filePath": "_private-routes/influencers/proposal.tsx",
      "parent": "/_private-routes/influencers"
    },
    "/_private-routes/influencers/search": {
      "filePath": "_private-routes/influencers/search.tsx",
      "parent": "/_private-routes/influencers",
      "children": [
        "/_private-routes/influencers/search/douyin",
        "/_private-routes/influencers/search/facebook",
        "/_private-routes/influencers/search/facebook_page",
        "/_private-routes/influencers/search/instagram",
        "/_private-routes/influencers/search/threads",
        "/_private-routes/influencers/search/tiktok",
        "/_private-routes/influencers/search/twitter",
        "/_private-routes/influencers/search/xhs",
        "/_private-routes/influencers/search/youtube",
        "/_private-routes/influencers/search/"
      ]
    },
    "/_private-routes/marketplace/$id": {
      "filePath": "_private-routes/marketplace/$id.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/marketplace/$id/applicant",
        "/_private-routes/marketplace/$id/commissions",
        "/_private-routes/marketplace/$id/influencer",
        "/_private-routes/marketplace/$id/joined-influencers",
        "/_private-routes/marketplace/$id/posts",
        "/_private-routes/marketplace/$id/report",
        "/_private-routes/marketplace/$id/report-affiliate",
        "/_private-routes/marketplace/$id/tracking",
        "/_private-routes/marketplace/$id/tracking-referral-codes",
        "/_private-routes/marketplace/$id/"
      ]
    },
    "/_private-routes/marketplace/add": {
      "filePath": "_private-routes/marketplace/add.tsx",
      "parent": "/_private-routes",
      "children": [
        "/_private-routes/marketplace/add/referral-code-tracking",
        "/_private-routes/marketplace/add/social-media-tracking",
        "/_private-routes/marketplace/add/url-tracking",
        "/_private-routes/marketplace/add/",
        "/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId",
        "/_private-routes/marketplace/add/e-commerce/"
      ]
    },
    "/_private-routes/marketplace/reorder": {
      "filePath": "_private-routes/marketplace/reorder.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/package/$id": {
      "filePath": "_private-routes/package/$id.tsx",
      "parent": "/_private-routes/package",
      "children": [
        "/_private-routes/package/$id/content",
        "/_private-routes/package/$id/"
      ]
    },
    "/_private-routes/package/add": {
      "filePath": "_private-routes/package/add.tsx",
      "parent": "/_private-routes/package"
    },
    "/_private-routes/partner/$id": {
      "filePath": "_private-routes/partner/$id.tsx",
      "parent": "/_private-routes/partner",
      "children": [
        "/_private-routes/partner/$id/business",
        "/_private-routes/partner/$id/users",
        "/_private-routes/partner/$id/"
      ]
    },
    "/_private-routes/posts-insight/$id": {
      "filePath": "_private-routes/posts-insight/$id.tsx",
      "parent": "/_private-routes/posts-insight",
      "children": [
        "/_private-routes/posts-insight/$id/$postId"
      ]
    },
    "/_private-routes/talent-agency/$id": {
      "filePath": "_private-routes/talent-agency/$id.tsx",
      "parent": "/_private-routes/talent-agency",
      "children": [
        "/_private-routes/talent-agency/$id/business",
        "/_private-routes/talent-agency/$id/users",
        "/_private-routes/talent-agency/$id/"
      ]
    },
    "/_private-routes/talent-agency/add": {
      "filePath": "_private-routes/talent-agency/add.tsx",
      "parent": "/_private-routes/talent-agency"
    },
    "/_private-routes/tiktok/ad-accounts": {
      "filePath": "_private-routes/tiktok/ad-accounts.tsx",
      "parent": "/_private-routes/tiktok"
    },
    "/_private-routes/tiktok/ad-campaign-report": {
      "filePath": "_private-routes/tiktok/ad-campaign-report.tsx",
      "parent": "/_private-routes/tiktok",
      "children": [
        "/_private-routes/tiktok/ad-campaign-report/",
        "/_private-routes/tiktok/ad-campaign-report/$id/$groupId",
        "/_private-routes/tiktok/ad-campaign-report/$id/"
      ]
    },
    "/_private-routes/tiktok/advertiser-details": {
      "filePath": "_private-routes/tiktok/advertiser-details.tsx",
      "parent": "/_private-routes/tiktok"
    },
    "/_private-routes/tiktok/budget-allocation": {
      "filePath": "_private-routes/tiktok/budget-allocation.tsx",
      "parent": "/_private-routes/tiktok"
    },
    "/_private-routes/tiktok/campaigns": {
      "filePath": "_private-routes/tiktok/campaigns.tsx",
      "parent": "/_private-routes/tiktok",
      "children": [
        "/_private-routes/tiktok/campaigns/$id",
        "/_private-routes/tiktok/campaigns/add",
        "/_private-routes/tiktok/campaigns/review-influencers",
        "/_private-routes/tiktok/campaigns/"
      ]
    },
    "/_private-routes/tiktok/failed-payment": {
      "filePath": "_private-routes/tiktok/failed-payment.tsx",
      "parent": "/_private-routes/tiktok"
    },
    "/_private-routes/tiktok/subscription-billing": {
      "filePath": "_private-routes/tiktok/subscription-billing.tsx",
      "parent": "/_private-routes/tiktok"
    },
    "/_private-routes/trending/influencers": {
      "filePath": "_private-routes/trending/influencers.tsx",
      "parent": "/_private-routes/trending"
    },
    "/_private-routes/trending/posts": {
      "filePath": "_private-routes/trending/posts.tsx",
      "parent": "/_private-routes/trending"
    },
    "/_private-routes/user/$id": {
      "filePath": "_private-routes/user/$id.tsx",
      "parent": "/_private-routes/user"
    },
    "/_private-routes/user/add": {
      "filePath": "_private-routes/user/add.tsx",
      "parent": "/_private-routes/user"
    },
    "/_private-routes/user/linked": {
      "filePath": "_private-routes/user/linked.tsx",
      "parent": "/_private-routes/user"
    },
    "/_private-routes/youtube-cms/$cmsChannelId": {
      "filePath": "_private-routes/youtube-cms/$cmsChannelId.tsx",
      "parent": "/_private-routes/youtube-cms"
    },
    "/_private-routes/youtube-cms/confirmed-revenue": {
      "filePath": "_private-routes/youtube-cms/confirmed-revenue.tsx",
      "parent": "/_private-routes/youtube-cms"
    },
    "/_public-routes/_password/password_forgot": {
      "filePath": "_public-routes/_password/password_forgot.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/egg/referral": {
      "filePath": "_public-routes/egg.referral.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/mkp/referral": {
      "filePath": "_public-routes/mkp.referral.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/sign-up/success": {
      "filePath": "_public-routes/sign-up/success.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/tiktok-onboarding/ad-account": {
      "filePath": "_public-routes/tiktok-onboarding/ad-account.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_public-routes/tiktok-onboarding/password-enter": {
      "filePath": "_public-routes/tiktok-onboarding/password-enter.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_public-routes/tiktok-onboarding/select-plan": {
      "filePath": "_public-routes/tiktok-onboarding/select-plan.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_public-routes/tiktok-onboarding/success": {
      "filePath": "_public-routes/tiktok-onboarding/success.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_private-routes/advertiser/": {
      "filePath": "_private-routes/advertiser/index.tsx",
      "parent": "/_private-routes/advertiser"
    },
    "/_private-routes/agency/": {
      "filePath": "_private-routes/agency/index.tsx",
      "parent": "/_private-routes/agency"
    },
    "/_private-routes/analytics/": {
      "filePath": "_private-routes/analytics/index.tsx",
      "parent": "/_private-routes/analytics"
    },
    "/_private-routes/auto-managed/": {
      "filePath": "_private-routes/auto-managed/index.tsx",
      "parent": "/_private-routes/auto-managed"
    },
    "/_private-routes/chat-demo/": {
      "filePath": "_private-routes/chat-demo/index.tsx",
      "parent": "/_private-routes/chat-demo"
    },
    "/_private-routes/engagement/": {
      "filePath": "_private-routes/engagement/index.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/hashtag-analytics/": {
      "filePath": "_private-routes/hashtag-analytics/index.tsx",
      "parent": "/_private-routes/hashtag-analytics"
    },
    "/_private-routes/influencer/": {
      "filePath": "_private-routes/influencer/index.tsx",
      "parent": "/_private-routes/influencer"
    },
    "/_private-routes/influencers/": {
      "filePath": "_private-routes/influencers/index.tsx",
      "parent": "/_private-routes/influencers"
    },
    "/_private-routes/marketplace/": {
      "filePath": "_private-routes/marketplace/index.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/package/": {
      "filePath": "_private-routes/package/index.tsx",
      "parent": "/_private-routes/package"
    },
    "/_private-routes/partner/": {
      "filePath": "_private-routes/partner/index.tsx",
      "parent": "/_private-routes/partner"
    },
    "/_private-routes/posts-insight/": {
      "filePath": "_private-routes/posts-insight/index.tsx",
      "parent": "/_private-routes/posts-insight"
    },
    "/_private-routes/talent-agency/": {
      "filePath": "_private-routes/talent-agency/index.tsx",
      "parent": "/_private-routes/talent-agency"
    },
    "/_private-routes/trending/": {
      "filePath": "_private-routes/trending/index.tsx",
      "parent": "/_private-routes/trending"
    },
    "/_private-routes/user/": {
      "filePath": "_private-routes/user/index.tsx",
      "parent": "/_private-routes/user"
    },
    "/_private-routes/youtube-cms/": {
      "filePath": "_private-routes/youtube-cms/index.tsx",
      "parent": "/_private-routes/youtube-cms"
    },
    "/_public-routes/sign-up/": {
      "filePath": "_public-routes/sign-up/index.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/tiktok-onboarding/": {
      "filePath": "_public-routes/tiktok-onboarding/index.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_private-routes/advertiser/$id/budget-allocation": {
      "filePath": "_private-routes/advertiser/$id/budget-allocation.tsx",
      "parent": "/_private-routes/advertiser/$id"
    },
    "/_private-routes/advertiser/$id/users": {
      "filePath": "_private-routes/advertiser/$id/users.tsx",
      "parent": "/_private-routes/advertiser/$id"
    },
    "/_private-routes/agency/$id/business": {
      "filePath": "_private-routes/agency/$id/business.tsx",
      "parent": "/_private-routes/agency/$id"
    },
    "/_private-routes/agency/$id/users": {
      "filePath": "_private-routes/agency/$id/users.tsx",
      "parent": "/_private-routes/agency/$id"
    },
    "/_private-routes/auto-managed/$id/commissions": {
      "filePath": "_private-routes/auto-managed/$id/commissions.tsx",
      "parent": "/_private-routes/auto-managed/$id"
    },
    "/_private-routes/auto-managed/$id/joined-influencers": {
      "filePath": "_private-routes/auto-managed/$id/joined-influencers.tsx",
      "parent": "/_private-routes/auto-managed/$id"
    },
    "/_private-routes/auto-managed/$id/report": {
      "filePath": "_private-routes/auto-managed/$id/report.tsx",
      "parent": "/_private-routes/auto-managed/$id"
    },
    "/_private-routes/engagement/$id/influencer": {
      "filePath": "_private-routes/engagement/$id/influencer.tsx",
      "parent": "/_private-routes/engagement/$id",
      "children": [
        "/_private-routes/engagement/$id/influencer/campaigns",
        "/_private-routes/engagement/$id/influencer/douyin",
        "/_private-routes/engagement/$id/influencer/facebook",
        "/_private-routes/engagement/$id/influencer/facebook_page",
        "/_private-routes/engagement/$id/influencer/instagram",
        "/_private-routes/engagement/$id/influencer/packages",
        "/_private-routes/engagement/$id/influencer/posts",
        "/_private-routes/engagement/$id/influencer/threads",
        "/_private-routes/engagement/$id/influencer/tiktok",
        "/_private-routes/engagement/$id/influencer/twitter",
        "/_private-routes/engagement/$id/influencer/xhs",
        "/_private-routes/engagement/$id/influencer/youtube",
        "/_private-routes/engagement/$id/influencer/"
      ]
    },
    "/_private-routes/engagement/$id/posts": {
      "filePath": "_private-routes/engagement/$id/posts.tsx",
      "parent": "/_private-routes/engagement/$id",
      "children": [
        "/_private-routes/engagement/$id/posts/$postId",
        "/_private-routes/engagement/$id/posts/add",
        "/_private-routes/engagement/$id/posts/"
      ]
    },
    "/_private-routes/engagement/$id/proposal": {
      "filePath": "_private-routes/engagement/$id/proposal.tsx",
      "parent": "/_private-routes/engagement/$id"
    },
    "/_private-routes/engagement/$id/report": {
      "filePath": "_private-routes/engagement/$id/report.tsx",
      "parent": "/_private-routes/engagement/$id"
    },
    "/_private-routes/engagement/$id/tracking": {
      "filePath": "_private-routes/engagement/$id/tracking.tsx",
      "parent": "/_private-routes/engagement/$id"
    },
    "/_private-routes/engagement/$id/tracking-referral-codes": {
      "filePath": "_private-routes/engagement/$id/tracking-referral-codes.tsx",
      "parent": "/_private-routes/engagement/$id"
    },
    "/_private-routes/hashtag-analytics/tiktok/hashtag": {
      "filePath": "_private-routes/hashtag-analytics/tiktok/hashtag.tsx",
      "parent": "/_private-routes/hashtag-analytics"
    },
    "/_private-routes/hashtag-analytics/tiktok/keyword": {
      "filePath": "_private-routes/hashtag-analytics/tiktok/keyword.tsx",
      "parent": "/_private-routes/hashtag-analytics"
    },
    "/_private-routes/influencer/$id/profile": {
      "filePath": "_private-routes/influencer/$id/profile.tsx",
      "parent": "/_private-routes/influencer/$id",
      "children": [
        "/_private-routes/influencer/$id/profile/campaigns",
        "/_private-routes/influencer/$id/profile/compare",
        "/_private-routes/influencer/$id/profile/"
      ]
    },
    "/_private-routes/influencers/search/douyin": {
      "filePath": "_private-routes/influencers/search/douyin.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/facebook": {
      "filePath": "_private-routes/influencers/search/facebook.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/facebook_page": {
      "filePath": "_private-routes/influencers/search/facebook_page.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/instagram": {
      "filePath": "_private-routes/influencers/search/instagram.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/threads": {
      "filePath": "_private-routes/influencers/search/threads.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/tiktok": {
      "filePath": "_private-routes/influencers/search/tiktok.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/twitter": {
      "filePath": "_private-routes/influencers/search/twitter.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/xhs": {
      "filePath": "_private-routes/influencers/search/xhs.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/influencers/search/youtube": {
      "filePath": "_private-routes/influencers/search/youtube.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/marketplace/$id/applicant": {
      "filePath": "_private-routes/marketplace/$id/applicant.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/commissions": {
      "filePath": "_private-routes/marketplace/$id/commissions.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/influencer": {
      "filePath": "_private-routes/marketplace/$id/influencer.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/joined-influencers": {
      "filePath": "_private-routes/marketplace/$id/joined-influencers.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/posts": {
      "filePath": "_private-routes/marketplace/$id/posts.tsx",
      "parent": "/_private-routes/marketplace/$id",
      "children": [
        "/_private-routes/marketplace/$id/posts/$postId",
        "/_private-routes/marketplace/$id/posts/"
      ]
    },
    "/_private-routes/marketplace/$id/report": {
      "filePath": "_private-routes/marketplace/$id/report.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/report-affiliate": {
      "filePath": "_private-routes/marketplace/$id/report-affiliate.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/tracking": {
      "filePath": "_private-routes/marketplace/$id/tracking.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/$id/tracking-referral-codes": {
      "filePath": "_private-routes/marketplace/$id/tracking-referral-codes.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/add/referral-code-tracking": {
      "filePath": "_private-routes/marketplace/add/referral-code-tracking.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/marketplace/add/social-media-tracking": {
      "filePath": "_private-routes/marketplace/add/social-media-tracking.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/marketplace/add/url-tracking": {
      "filePath": "_private-routes/marketplace/add/url-tracking.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/package/$id/content": {
      "filePath": "_private-routes/package/$id/content.tsx",
      "parent": "/_private-routes/package/$id",
      "children": [
        "/_private-routes/package/$id/content/list",
        "/_private-routes/package/$id/content/search",
        "/_private-routes/package/$id/content/"
      ]
    },
    "/_private-routes/partner/$id/business": {
      "filePath": "_private-routes/partner/$id/business.tsx",
      "parent": "/_private-routes/partner/$id"
    },
    "/_private-routes/partner/$id/users": {
      "filePath": "_private-routes/partner/$id/users.tsx",
      "parent": "/_private-routes/partner/$id"
    },
    "/_private-routes/posts-insight/$id/$postId": {
      "filePath": "_private-routes/posts-insight/$id.$postId.tsx",
      "parent": "/_private-routes/posts-insight/$id"
    },
    "/_private-routes/redirect/analytics-connect/$provider": {
      "filePath": "_private-routes/redirect/analytics-connect.$provider.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/analytics-list-connect/$provider": {
      "filePath": "_private-routes/redirect/analytics-list-connect.$provider.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/analytics-signin/$provider": {
      "filePath": "_private-routes/redirect/analytics-signin.$provider.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/connect/tiktok-business": {
      "filePath": "_private-routes/redirect/connect.tiktok-business.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/hidden/youtube-list-connect": {
      "filePath": "_private-routes/redirect/hidden.youtube-list-connect.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/talent-analytics-connect/$provider": {
      "filePath": "_private-routes/redirect/talent-analytics-connect.$provider.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/redirect/talent-signin/$provider": {
      "filePath": "_private-routes/redirect/talent-signin.$provider.tsx",
      "parent": "/_private-routes"
    },
    "/_private-routes/talent-agency/$id/business": {
      "filePath": "_private-routes/talent-agency/$id/business.tsx",
      "parent": "/_private-routes/talent-agency/$id"
    },
    "/_private-routes/talent-agency/$id/users": {
      "filePath": "_private-routes/talent-agency/$id/users.tsx",
      "parent": "/_private-routes/talent-agency/$id"
    },
    "/_private-routes/tiktok/campaigns/$id": {
      "filePath": "_private-routes/tiktok/campaigns/$id.tsx",
      "parent": "/_private-routes/tiktok/campaigns",
      "children": [
        "/_private-routes/tiktok/campaigns/$id/influencers",
        "/_private-routes/tiktok/campaigns/$id/posts",
        "/_private-routes/tiktok/campaigns/$id/report",
        "/_private-routes/tiktok/campaigns/$id/"
      ]
    },
    "/_private-routes/tiktok/campaigns/add": {
      "filePath": "_private-routes/tiktok/campaigns/add.tsx",
      "parent": "/_private-routes/tiktok/campaigns"
    },
    "/_private-routes/tiktok/campaigns/review-influencers": {
      "filePath": "_private-routes/tiktok/campaigns/review-influencers.tsx",
      "parent": "/_private-routes/tiktok/campaigns"
    },
    "/_public-routes/_password/password_register/$token": {
      "filePath": "_public-routes/_password/password_register.$token.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/_password/password_reset/$token": {
      "filePath": "_public-routes/_password/password_reset.$token.tsx",
      "parent": "/_public-routes"
    },
    "/_public-routes/tiktok-onboarding/password-register/$token": {
      "filePath": "_public-routes/tiktok-onboarding/password-register.$token.tsx",
      "parent": "/_public-routes/tiktok-onboarding"
    },
    "/_private-routes/advertiser/$id/": {
      "filePath": "_private-routes/advertiser/$id/index.tsx",
      "parent": "/_private-routes/advertiser/$id"
    },
    "/_private-routes/agency/$id/": {
      "filePath": "_private-routes/agency/$id/index.tsx",
      "parent": "/_private-routes/agency/$id"
    },
    "/_private-routes/analytics/$brandAccountId/": {
      "filePath": "_private-routes/analytics/$brandAccountId/index.tsx",
      "parent": "/_private-routes/analytics"
    },
    "/_private-routes/auto-managed/$id/": {
      "filePath": "_private-routes/auto-managed/$id/index.tsx",
      "parent": "/_private-routes/auto-managed/$id"
    },
    "/_private-routes/engagement/$id/": {
      "filePath": "_private-routes/engagement/$id/index.tsx",
      "parent": "/_private-routes/engagement/$id"
    },
    "/_private-routes/hashtag-analytics/tiktok/": {
      "filePath": "_private-routes/hashtag-analytics/tiktok/index.tsx",
      "parent": "/_private-routes/hashtag-analytics"
    },
    "/_private-routes/influencer/$id/": {
      "filePath": "_private-routes/influencer/$id/index.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencers/search/": {
      "filePath": "_private-routes/influencers/search/index.tsx",
      "parent": "/_private-routes/influencers/search"
    },
    "/_private-routes/marketplace/$id/": {
      "filePath": "_private-routes/marketplace/$id/index.tsx",
      "parent": "/_private-routes/marketplace/$id"
    },
    "/_private-routes/marketplace/add/": {
      "filePath": "_private-routes/marketplace/add/index.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/package/$id/": {
      "filePath": "_private-routes/package/$id/index.tsx",
      "parent": "/_private-routes/package/$id"
    },
    "/_private-routes/partner/$id/": {
      "filePath": "_private-routes/partner/$id/index.tsx",
      "parent": "/_private-routes/partner/$id"
    },
    "/_private-routes/talent-agency/$id/": {
      "filePath": "_private-routes/talent-agency/$id/index.tsx",
      "parent": "/_private-routes/talent-agency/$id"
    },
    "/_private-routes/tiktok/ad-campaign-report/": {
      "filePath": "_private-routes/tiktok/ad-campaign-report/index.tsx",
      "parent": "/_private-routes/tiktok/ad-campaign-report"
    },
    "/_private-routes/tiktok/campaigns/": {
      "filePath": "_private-routes/tiktok/campaigns/index.tsx",
      "parent": "/_private-routes/tiktok/campaigns"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId.tsx",
      "parent": "/_private-routes/analytics",
      "children": [
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/",
        "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/"
      ]
    },
    "/_private-routes/engagement/$id/influencer/campaigns": {
      "filePath": "_private-routes/engagement/$id/influencer/campaigns.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/douyin": {
      "filePath": "_private-routes/engagement/$id/influencer/douyin.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/facebook": {
      "filePath": "_private-routes/engagement/$id/influencer/facebook.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/facebook_page": {
      "filePath": "_private-routes/engagement/$id/influencer/facebook_page.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/instagram": {
      "filePath": "_private-routes/engagement/$id/influencer/instagram.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/packages": {
      "filePath": "_private-routes/engagement/$id/influencer/packages.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/posts": {
      "filePath": "_private-routes/engagement/$id/influencer/posts.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/threads": {
      "filePath": "_private-routes/engagement/$id/influencer/threads.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/tiktok": {
      "filePath": "_private-routes/engagement/$id/influencer/tiktok.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/twitter": {
      "filePath": "_private-routes/engagement/$id/influencer/twitter.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/xhs": {
      "filePath": "_private-routes/engagement/$id/influencer/xhs.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/influencer/youtube": {
      "filePath": "_private-routes/engagement/$id/influencer/youtube.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/posts/$postId": {
      "filePath": "_private-routes/engagement/$id/posts/$postId.tsx",
      "parent": "/_private-routes/engagement/$id/posts"
    },
    "/_private-routes/engagement/$id/posts/add": {
      "filePath": "_private-routes/engagement/$id/posts/add.tsx",
      "parent": "/_private-routes/engagement/$id/posts"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/facebook_feed_post.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/feed_videos": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/feed_videos.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/instagram_feed_post.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/instagram_reel_post.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/instagram_story_post.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/scraping_feed_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/shorts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/shorts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/sponsored_feed_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/sponsored_youtube_feed_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/twitter_feed_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/xhs_picture_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/xhs_video_posts.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos.tsx",
      "parent": "/_private-routes/influencer/$id",
      "children": [
        "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId",
        "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/"
      ]
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/youtube_tags_ranking.tsx",
      "parent": "/_private-routes/influencer/$id"
    },
    "/_private-routes/influencer/$id/profile/campaigns": {
      "filePath": "_private-routes/influencer/$id/profile/campaigns.tsx",
      "parent": "/_private-routes/influencer/$id/profile"
    },
    "/_private-routes/influencer/$id/profile/compare": {
      "filePath": "_private-routes/influencer/$id/profile/compare.tsx",
      "parent": "/_private-routes/influencer/$id/profile"
    },
    "/_private-routes/marketplace/$id/posts/$postId": {
      "filePath": "_private-routes/marketplace/$id/posts/$postId.tsx",
      "parent": "/_private-routes/marketplace/$id/posts"
    },
    "/_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId": {
      "filePath": "_private-routes/marketplace/add/e-commerce/$autoMangedCampaignId.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/package/$id/content/list": {
      "filePath": "_private-routes/package/$id/content/list.tsx",
      "parent": "/_private-routes/package/$id/content"
    },
    "/_private-routes/package/$id/content/search": {
      "filePath": "_private-routes/package/$id/content/search.tsx",
      "parent": "/_private-routes/package/$id/content",
      "children": [
        "/_private-routes/package/$id/content/search/douyin",
        "/_private-routes/package/$id/content/search/facebook",
        "/_private-routes/package/$id/content/search/facebook_page",
        "/_private-routes/package/$id/content/search/instagram",
        "/_private-routes/package/$id/content/search/threads",
        "/_private-routes/package/$id/content/search/tiktok",
        "/_private-routes/package/$id/content/search/twitter",
        "/_private-routes/package/$id/content/search/xhs",
        "/_private-routes/package/$id/content/search/youtube",
        "/_private-routes/package/$id/content/search/"
      ]
    },
    "/_private-routes/tiktok/ad-campaign-report/$id/$groupId": {
      "filePath": "_private-routes/tiktok/ad-campaign-report/$id/$groupId.tsx",
      "parent": "/_private-routes/tiktok/ad-campaign-report"
    },
    "/_private-routes/tiktok/campaigns/$id/influencers": {
      "filePath": "_private-routes/tiktok/campaigns/$id/influencers.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id"
    },
    "/_private-routes/tiktok/campaigns/$id/posts": {
      "filePath": "_private-routes/tiktok/campaigns/$id/posts.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id",
      "children": [
        "/_private-routes/tiktok/campaigns/$id/posts/$postId",
        "/_private-routes/tiktok/campaigns/$id/posts/"
      ]
    },
    "/_private-routes/tiktok/campaigns/$id/report": {
      "filePath": "_private-routes/tiktok/campaigns/$id/report.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id"
    },
    "/_private-routes/engagement/$id/influencer/": {
      "filePath": "_private-routes/engagement/$id/influencer/index.tsx",
      "parent": "/_private-routes/engagement/$id/influencer"
    },
    "/_private-routes/engagement/$id/posts/": {
      "filePath": "_private-routes/engagement/$id/posts/index.tsx",
      "parent": "/_private-routes/engagement/$id/posts"
    },
    "/_private-routes/influencer/$id/profile/": {
      "filePath": "_private-routes/influencer/$id/profile/index.tsx",
      "parent": "/_private-routes/influencer/$id/profile"
    },
    "/_private-routes/marketplace/$id/posts/": {
      "filePath": "_private-routes/marketplace/$id/posts/index.tsx",
      "parent": "/_private-routes/marketplace/$id/posts"
    },
    "/_private-routes/marketplace/add/e-commerce/": {
      "filePath": "_private-routes/marketplace/add/e-commerce/index.tsx",
      "parent": "/_private-routes/marketplace/add"
    },
    "/_private-routes/package/$id/content/": {
      "filePath": "_private-routes/package/$id/content/index.tsx",
      "parent": "/_private-routes/package/$id/content"
    },
    "/_private-routes/tiktok/ad-campaign-report/$id/": {
      "filePath": "_private-routes/tiktok/ad-campaign-report/$id/index.tsx",
      "parent": "/_private-routes/tiktok/ad-campaign-report"
    },
    "/_private-routes/tiktok/campaigns/$id/": {
      "filePath": "_private-routes/tiktok/campaigns/$id/index.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/hashtag.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/trend.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/ugc.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/$compareId.tsx",
      "parent": "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos"
    },
    "/_private-routes/package/$id/content/search/douyin": {
      "filePath": "_private-routes/package/$id/content/search/douyin.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/facebook": {
      "filePath": "_private-routes/package/$id/content/search/facebook.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/facebook_page": {
      "filePath": "_private-routes/package/$id/content/search/facebook_page.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/instagram": {
      "filePath": "_private-routes/package/$id/content/search/instagram.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/threads": {
      "filePath": "_private-routes/package/$id/content/search/threads.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/tiktok": {
      "filePath": "_private-routes/package/$id/content/search/tiktok.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/twitter": {
      "filePath": "_private-routes/package/$id/content/search/twitter.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/xhs": {
      "filePath": "_private-routes/package/$id/content/search/xhs.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/package/$id/content/search/youtube": {
      "filePath": "_private-routes/package/$id/content/search/youtube.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/tiktok/campaigns/$id/posts/$postId": {
      "filePath": "_private-routes/tiktok/campaigns/$id/posts/$postId.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id/posts"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/index.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/": {
      "filePath": "_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos/index.tsx",
      "parent": "/_private-routes/influencer/$id/$socialMediaAccountId/youtube_compare_feed_videos"
    },
    "/_private-routes/package/$id/content/search/": {
      "filePath": "_private-routes/package/$id/content/search/index.tsx",
      "parent": "/_private-routes/package/$id/content/search"
    },
    "/_private-routes/tiktok/campaigns/$id/posts/": {
      "filePath": "_private-routes/tiktok/campaigns/$id/posts/index.tsx",
      "parent": "/_private-routes/tiktok/campaigns/$id/posts"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/instagram_compare_feed_post.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/facebook_feed_post.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/feed_videos.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_comments.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_feed_post.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_reel_post.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/instagram_story_post.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/shorts.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/tag_ranking_list.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/twitter_feed_posts.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/youtube_comments.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/brand-ambassadors.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/mentioned_posts.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/tagged_posts.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/index.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/dashboard/index.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    },
    "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/": {
      "filePath": "_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/interaction/index.tsx",
      "parent": "/_private-routes/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId"
    }
  }
}
ROUTE_MANIFEST_END */
