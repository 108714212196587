import { useTranslation } from 'react-i18next';
import { InstagramReelPostItem, InstagramReelPostSortField } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { usePostDetailsParam } from '@/shared/hooks';
import { formatDurationToDaysHoursMinutesSeconds, formatIntNumber, formatPercent } from '@/shared/utils';

type CustomColumnProps = ColumnProps<InstagramReelPostSortField, keyof InstagramReelPostItem>;
interface Props {
  data: readonly InstagramReelPostItem[];
}

export const useAnalyticsInstagramReelPostsListTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { setPostDetailsParam } = usePostDetailsParam();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    { title: t('HeaderColumn.Post'), sticky: { active: true, hasBorderRight: true } },
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      help: t<string>('Tooltip.ViewHelp'),
      sortField: InstagramReelPostSortField.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Views by Followers'),
      help: t<string>('Tooltip.viewsByFollowersHelp'),
      sortField: InstagramReelPostSortField.VIEWS_BY_FOLLOWERS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Views by Non-Followers'),
      help: t<string>('Tooltip.viewsByNonFollowersHelp'),
      sortField: InstagramReelPostSortField.VIEWS_BY_NON_FOLLOWERS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Reach'),
      help: t<string>('Tooltip.ReachPostHelp'),
      sortField: InstagramReelPostSortField.REACH
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Watch Time'),
      help: t<string>('Tooltip.WatchTimeHelp'),
      sortField: InstagramReelPostSortField.VIDEO_VIEW_TOTAL_TIME
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Avg Watch Time'),
      help: t<string>('Tooltip.AvgWatchTimeHelp'),
      sortField: InstagramReelPostSortField.AVG_WATCH_TIME
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Interaction'),
      help: t<string>('Tooltip.interactionHelp'),
      sortField: InstagramReelPostSortField.INTERACTIONS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      help: t<string>('Tooltip.LikeHelp'),
      sortField: InstagramReelPostSortField.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      help: t<string>('Tooltip.CommentHelp'),
      sortField: InstagramReelPostSortField.COMMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.share'),
      help: t<string>('Tooltip.shareHelp'),
      sortField: InstagramReelPostSortField.SHARES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      help: t<string>('Tooltip.SaveHelp'),
      sortField: InstagramReelPostSortField.SAVED
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Accounts Engaged'),
      help: t<string>('Tooltip.accountsEngagedHelp'),
      sortField: InstagramReelPostSortField.ACCOUNTS_ENGAGED
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Profile Activity'),
      help: t<string>('Tooltip.profileActivityHelp'),
      sortField: InstagramReelPostSortField.PROFILE_ACTIVITY
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Follow'),
      help: t<string>('Tooltip.FollowHelp'),
      sortField: InstagramReelPostSortField.FOLLOWS
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      help: t<string>('Tooltip.PostDateHelp'),
      sortField: InstagramReelPostSortField.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({
      id,
      views,
      shares,
      reach,
      likes,
      saved,
      content,
      postDate,
      comments,
      thumbNail,
      viewsByFollowers,
      viewsByNonFollowers,
      avgWatchTime,
      videoViewTotalTime,
      interactions,
      follows,
      accountsEngaged,
      profileActivity
    }) => [
      <TableCell.Post title={content || ''} thumbnail={thumbNail || ''} onClick={() => setPostDetailsParam(id)} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatPercent(viewsByFollowers, false)} unit="%" />,
      <TableCell.Number value={formatPercent(viewsByNonFollowers, false)} unit="%" />,
      <TableCell.Number value={formatIntNumber(reach)} />,
      <TableCell.Number value={formatDurationToDaysHoursMinutesSeconds(videoViewTotalTime, t)} />,
      <TableCell.Number value={formatDurationToDaysHoursMinutesSeconds(avgWatchTime, t)} />,
      <TableCell.Number value={formatIntNumber(interactions)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Number value={formatIntNumber(saved)} />,
      <TableCell.Number value={formatIntNumber(accountsEngaged)} />,
      <TableCell.Number value={formatIntNumber(profileActivity)} />,
      <TableCell.Number value={formatIntNumber(follows)} />,
      <TableCell.Date css={{ minWidth: '80px' }} value={postDate} />
    ]
  );

  return { rows, columns };
};
