import * as Sentry from '@sentry/browser';
import { useAuthData } from '@/auth';

export const useSentry = () => {
  const { auth } = useAuthData();

  Sentry.setUser({
    id: String(auth?.userId),
    role: auth?.role
  });
};
