import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/DeleteTiktokUserKeyword.graphql';
import { Mutation, MutationdeleteTiktokUserKeywordArgs } from '../../__generated__/globalTypes';

export const useDeleteTiktokUserKeywordMutation = (
  options?: MutationHookOptions<Mutation, MutationdeleteTiktokUserKeywordArgs>
) => {
  const [callDeleteTiktokUserKeyword, result] = useMutation<Mutation, MutationdeleteTiktokUserKeywordArgs>(
    MUTATION,
    options
  );

  return { callDeleteTiktokUserKeyword, ...result };
};

export type DeleteTiktokUserKeywordMutationFunction = Mutation['deleteTiktokUserKeyword'];
