import { z } from 'zod';
import { SearchFilterDefaults } from '@/shared/validations';
import { InstagramUserHashtagPostsSortOrder, Order } from '@/graphql';
import { TagsAnalyticsSearchSchema } from '../../shared';

export const InstagramHashtagsAnalyticsSearchSchema = TagsAnalyticsSearchSchema.extend({
  sort: SearchFilterDefaults.sort(InstagramUserHashtagPostsSortOrder).default({
    order: Order.ASC,
    field: InstagramUserHashtagPostsSortOrder.RANK
  })
});
export type InstagramHashtagsAnalyticsSearchSchemaType = z.infer<typeof InstagramHashtagsAnalyticsSearchSchema>;
