export type ParamType = string | null | undefined;
export type ParamsType<T extends string> = Partial<Record<T, ParamType>>;
export enum RedirectType {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  CONNECT = 'CONNECT',
  RECONNECT_IG = 'RECONNECT_IG',
  TALENT_SIGNIN = 'TALENT_SIGNIN',
  TALENT_SIGNUP = 'TALENT_SIGNUP',
  SIGNUP_CONNECT = 'SIGNUP_CONNECT',
  SIGNUP_ENABLED = 'SIGNUP_ENABLED',
  ANALYTICS_SIGNIN = 'ANALYTICS_SIGNIN',
  ANALYTICS_CONNECT = 'ANALYTICS_CONNECT',
  ANALYTICS_LIST_CONNECT = 'ANALYTICS_LIST_CONNECT'
}
export const SocialMediaProvider = ['twitter', 'youtube', 'facebook', 'instagram'] as const;
export type SocialMediaProviderType = (typeof SocialMediaProvider)[number];
