import { THEME } from '@/shared/constants';
import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useInstagramHashtagsAnalyticsFilter } from './hooks';

export interface InstagramHashtagsAnalyticsFilterProps extends Pick<TemplateProps, 'hashtags'> {
  className?: string;
}

export const InstagramHashtagsAnalyticsFilter = ({
  className,
  ...templateProps
}: InstagramHashtagsAnalyticsFilterProps) => {
  const { filter, setFilterForm } = useInstagramHashtagsAnalyticsFilter();

  return (
    <FilterWrapper
      css={{
        borderRadius: 0,
        overflow: 'auto',
        boxShadow: 'none',
        maxHeight: '40vh',
        borderTop: THEME.border.base
      }}
      className={className}
    >
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template {...templateProps} />} />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export { useInstagramHashtagsAnalyticsFilter };
