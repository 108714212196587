import { useUserRoles } from '@/auth';
import { APP_NAME } from '@/shared/constants';
import { checkSignedIn } from '@/router';
import { useParamsSearch } from '@/shared/hooks';
import { useAgencyLogoQuery, useGetLogoQuery } from '@/graphql';
import { LogoSchemaType } from './schemaTypes';

export const useCustomLogo = () => {
  const isSignedIn = checkSignedIn();
  const { isAgency, isPartner, isAdvertiser } = useUserRoles();
  const { hash } = useParamsSearch<LogoSchemaType>();
  const { data, loading } = useGetLogoQuery({
    errorPolicy: 'ignore', // We will replace our logo silently, if failed we still use default logo so no need raise the error
    fetchPolicy: 'cache-and-network',
    skip: !isSignedIn || !(isAgency || isPartner || isAdvertiser)
  });

  const { data: agencyLogoData, loading: loadingAgencyLogo } = useAgencyLogoQuery({
    variables: { hash },
    errorPolicy: 'ignore', // We will replace our logo silently, if failed we still use default logo so no need raise the error
    skip: isSignedIn || !hash,
    fetchPolicy: 'cache-and-network'
  });

  const logo = data?.logo?.logoUrl;
  const agencyLogo = agencyLogoData?.agencyLogo?.logoUrl;

  return {
    loading: loading || loadingAgencyLogo,
    customLogo: {
      url: logo || agencyLogo || null,
      alt: (logo && isAgency) || agencyLogo ? `${APP_NAME} Agency Logo` : null
    }
  };
};
