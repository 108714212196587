import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTiktokUserKeyword.graphql';
import { Mutation, MutationcreateTiktokUserKeywordArgs } from '../../__generated__/globalTypes';

export const useCreateTiktokUserKeywordMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTiktokUserKeywordArgs>
) => {
  const [callCreateTiktokUserKeyword, result] = useMutation<Mutation, MutationcreateTiktokUserKeywordArgs>(
    MUTATION,
    options
  );

  return { callCreateTiktokUserKeyword, ...result };
};

export type CreateTiktokUserKeywordMutationFunction = Mutation['createTiktokUserKeyword'];
