import { Link } from '@/router';
import { TextCutter } from '@/shared/atoms';
import { useParamsSearch } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { ProfilePostsTabs, ProfilePostsTabType } from '@/shared/organisms';
import { MainAccountTemplate } from './MainAccountTemplate';
import { CompareAccountTemplate } from './CompareAccountTemplate';
import { AnalyticsYoutubeMainSearchSchemaType, CompareAccountType } from './schemaTypes';

export interface TemplateProps {
  mainSocialAccountId: number;
  comparableAccounts: CompareAccountType[];
}

export const Template = ({ comparableAccounts, mainSocialAccountId }: TemplateProps) => {
  const { filter } = useParamsSearch<AnalyticsYoutubeMainSearchSchemaType>();

  const isMainAccount = mainSocialAccountId === Number(filter.accountId);
  const tabOptions = comparableAccounts.map<ProfilePostsTabType>(({ accountId, channelName, avatarUrl }, i) => ({
    value: String(accountId),
    label: (props) => (
      <Link
        key={`${accountId}_${i}`}
        to="/analytics/$brandAccountId/$socialMedia/$socialMediaAccountId/compare/youtube_compare_feed_videos"
        activeOptions={{ exact: true, includeSearch: true }}
        params={{ compareId: String(accountId) }}
        search={(curr: AnalyticsYoutubeMainSearchSchemaType) => ({
          ...curr,
          filter: { ...curr.filter, accountId: String(accountId) }
        })}
        {...props}
      >
        <InfluencerAvatar size="32px" css={{ marginRight: '4px', borderRadius: '50%' }} src={avatarUrl} asChild />
        <TextCutter text={channelName} />
      </Link>
    )
  }));

  return (
    <>
      <ProfilePostsTabs options={tabOptions} />

      {isMainAccount ? (
        <MainAccountTemplate mainSocialAccountId={mainSocialAccountId} />
      ) : (
        <CompareAccountTemplate mainSocialAccountId={mainSocialAccountId} />
      )}
    </>
  );
};
