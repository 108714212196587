import { CSSObject, SerializedStyles, css } from '@emotion/react';
import { ReactNode } from 'react';
import { DefaultAppRoute, LinkRouterProps } from '@/router';
import { Anchor } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { Logo } from '@/shared/molecules';

const headerRoutes = ['/sign-in', '/sign-up'] as const satisfies LinkRouterProps['to'][];
export interface SignInSignUpHeaderProps {
  to?: (typeof headerRoutes)[number] | DefaultAppRoute;
  className?: string | CSSObject | SerializedStyles;
  signLink?: { description?: ReactNode; title?: ReactNode; to?: (typeof headerRoutes)[number] };
}

export const SignInSignUpHeader = ({ to, signLink, className }: SignInSignUpHeaderProps) => (
  <div css={[styles.wrapper, className]}>
    <Logo type="black" to={to} css={{ maxWidth: '185px', height: '32px' }} />

    {signLink ? (
      <div css={styles.content}>
        {signLink.description}
        <Anchor css={styles.link} variant="blue" to={signLink.to}>
          {signLink.title}
        </Anchor>
      </div>
    ) : null}
  </div>
);
const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px',
    justifyContent: 'space-between',
    width: THEME.container.dashboard.maxWidth
  }),
  content: css({
    display: 'flex',
    fontSize: '14px',
    lineHeight: '100%',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'normal',
    fontStretch: 'normal',
    alignSelf: 'flex-end',
    letterSpacing: 'normal'
  }),
  link: css({
    marginLeft: '8px',
    fontStretch: 'normal',
    '&:hover': { opacity: 0.8, textDecoration: 'none' }
  })
};
