import { css } from '@emotion/react';
import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Icon, SocialIcon, SocialIconProps } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useChatDemo } from '@/shared/hooks';
import { MenuTitle, MenuType } from '@/router';
import { getMenuIcon } from './utils';

interface MenuItemProps extends MenuType {
  size?: number;
  target?: string;
  hasIcon?: boolean;
  keyPrefix?: string;
  className?: string;
  rightNode?: ReactNode;
  activeIcon?: boolean;
  activeTitle?: MenuTitle;
  disabled?: boolean | null;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export const MenuItem = ({
  to,
  href,
  params,
  title,
  rightNode,
  className,
  groupTitle,
  activeIcon,
  activeTitle,
  hasIcon = true,
  target,
  keyPrefix = 'Routes',
  onClick,
  disabled,
  size = 20
}: MenuItemProps) => {
  const { t } = useTranslation();
  const { enabledMenus, isChatDemoAccount } = useChatDemo(); // TODO DEMO: remove later

  const menuTitle = groupTitle || title;
  const socialIcon: SocialIconProps['type'] | undefined = (() => {
    switch (menuTitle) {
      case MenuTitle.TikTok:
        return 'TIKTOK';
      case MenuTitle.Instagram:
        return 'INSTAGRAM';
      default:
        return undefined;
    }
  })();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!(href || to)) {
      e.preventDefault();
    }

    onClick?.(e);
  };

  return (
    <Anchor
      to={to}
      href={href}
      params={params}
      target={target}
      onClick={handleClick}
      className={className}
      activeOptions={{ includeSearch: false }}
      css={[
        styles.anchor,
        activeTitle === title && !disabled && styles.active,
        disabled && { opacity: 0.2, pointerEvents: 'none' }
      ]}
      // TODO DEMO: it’s fine to make other pages unclickable too.
      // https://anymindgroup.slack.com/archives/C02C5J5B7KL/p1719887826612529?thread_ts=1719831163.643959&cid=C02C5J5B7KL
      disabled={isChatDemoAccount && !enabledMenus.includes(title as MenuTitle)} // TODO DEMO: remove later
    >
      {hasIcon ? (
        // hasIcon means it is a top level menu item, we need to align them vertically same with/without icon
        <div css={{ width: '20px', display: 'flex', alignItems: 'center' }}>
          {socialIcon ? (
            <SocialIcon size={size} type={socialIcon} variant={activeIcon && !disabled ? 'original' : 'gray'} />
          ) : (
            <Icon
              icon={getMenuIcon(menuTitle)}
              color={activeIcon && !disabled ? '#3892e5' : '#6E7C89'}
              width={size}
              height={size}
            />
          )}
        </div>
      ) : null}
      <p css={styles.title} className="menu-item-title">
        {t(`${keyPrefix ? `${keyPrefix}.` : ''}${menuTitle}`)}
      </p>
      {rightNode}
    </Anchor>
  );
};

export const styles = {
  anchor: css({
    height: '32px',
    display: 'flex',
    padding: '0 8px',
    cursor: 'pointer',
    marginBottom: '4px',
    borderRadius: '3px',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: THEME.text.colors.black.lv1,
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: 0.7,
      textDecoration: 'none',
      color: THEME.text.colors.gray.lv3,
      backgroundColor: THEME.background.colors.gray.lv1
    }
  }),
  active: css({
    color: `${THEME.text.colors.blue} !important`,
    backgroundColor: THEME.background.colors.gray.lv1,
    '&:hover': { opacity: 1, color: THEME.text.colors.blue }
  }),
  title: css({
    fontWeight: 600,
    flex: '1 1 auto',
    color: 'inherit',
    fontSize: '14px',
    marginLeft: '8px',
    lineHeight: '18px',
    '&:lang(ja)': { fontSize: '12px', lineHeight: '14px' }
  })
};
