import { linkOptions, useParams } from '@tanstack/react-router';
import { useUpdateDocumentTitle, useQueryHelper } from '@/shared/hooks';
import { LinkRouterProps } from '@/router';

export const Props = [
  {
    influencerProfileUrl: '/influencer/$id/profile'
  },
  {
    influencerProfileUrl: '/influencer/$id/profile/compare'
  }
] as const satisfies ReadonlyArray<{
  influencerProfileUrl: LinkRouterProps['to'];
}>;

export const useInitialInfluencerPostsList = ({ influencerProfileUrl }: (typeof Props)[number]) => {
  const params = useParams({ strict: false });
  const influencerId = Number(params.id || 0);
  const socialAccountId = Number(params.socialMediaAccountId || 0);

  const goBackUrl = influencerId
    ? linkOptions({ to: influencerProfileUrl, params: { id: influencerId.toString() } })
    : linkOptions({ to: '/influencers', params: {} });
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({});
  const { navigate } = useQueryHelper();

  // We just get some common fields, others should be got from place where it used

  // useUpdateDocumentTitle({
  //   href: goBackUrl.to,
  //   params: goBackUrl.params,
  //   interpolation: { title: filter.name },
  //   title: filter.name ? 'appHeader.PostListWithAccountName' : 'documentTitle.Post List',
  //   appHeader: filter.name ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
  //   ...(document ? document(filter.name) : {})
  // });

  if (!influencerId || !socialAccountId) {
    navigate({ to: goBackUrl.to, params: goBackUrl.params });
  }

  return { goBackUrl, influencerId, socialAccountId, params, setDocumentAndAppTitles };
};
