import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_50 } from '@/shared/constants';
import { getYesterday } from '@/shared/utils';
import { FilterValidations, SearchFilterDefaults } from '@/shared/validations';

export enum TagType {
  HASHTAG = 'HASHTAG',
  KEYWORD = 'KEYWORD'
}
export interface TagAnalyticsType {
  id: number;
  label: string;
}
export const TagsAnalyticsFilterSchema = z.object({
  date: FilterValidations.string(),
  tagId: FilterValidations.number(),
  keyword: FilterValidations.string()
});
export type TagsAnalyticsFilterFormValues = z.infer<typeof TagsAnalyticsFilterSchema>;
export type TagsAnalyticsFilterFormKeys = UnionOfKeys<TagsAnalyticsFilterFormValues>;
export const TagsAnalyticsSearchSchema = z.object({
  filter: SearchFilterDefaults.filter(TagsAnalyticsFilterSchema, {
    tagId: '',
    keyword: '',
    date: getYesterday()
  }),
  ...SearchFilterDefaults.pageLimit(LIMIT_50)
});
export type TagsAnalyticsSearchSchemaType = z.infer<typeof TagsAnalyticsSearchSchema>;
