import { useTranslation } from 'react-i18next';
import { TableData } from '@/shared/types';
import { formatIntNumber } from '@/shared/utils';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { TiktokUserTagListPostsItemPayload, TiktokUserTagPostsSortOrder } from '@/graphql';
import { PostCell } from '../../../shared';

type CustomColumnProps = ColumnProps<TiktokUserTagPostsSortOrder>;
type Props = Pick<TableData<TiktokUserTagListPostsItemPayload>, 'data'>;

export const useTiktokTagsAnalyticsPostsListTable = ({ data = [] }: Props) => {
  const { t } = useTranslation();

  const getMetricColumnProps = ({ styles, ...columnProps }: CustomColumnProps): CustomColumnProps => ({
    ...columnProps,
    styles: { textAlign: 'right', ...styles }
  });

  const columns: CustomColumnProps[] = [
    getMetricColumnProps({
      title: t('HeaderColumn.Rank'),
      styles: { textAlign: 'center', paddingRight: '16px' },
      sortField: TiktokUserTagPostsSortOrder.RANK
    }),
    { title: t('HeaderColumn.Post') },
    getMetricColumnProps({
      title: t('HeaderColumn.Engagement'),
      sortField: TiktokUserTagPostsSortOrder.ENGAGEMENT
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.View'),
      sortField: TiktokUserTagPostsSortOrder.VIEWS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Like'),
      sortField: TiktokUserTagPostsSortOrder.LIKES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Comment'),
      sortField: TiktokUserTagPostsSortOrder.COMMENTS
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Save'),
      sortField: TiktokUserTagPostsSortOrder.SAVES
    }),
    getMetricColumnProps({
      title: t('HeaderColumn.Share'),
      sortField: TiktokUserTagPostsSortOrder.SHARES
    }),
    getMetricColumnProps({
      styles: { textAlign: 'left' },
      title: t('HeaderColumn.Post date'),
      sortField: TiktokUserTagPostsSortOrder.POST_DATE
    })
  ];
  const rows: RowProps[] = data.map(
    ({ rank, views, likes, saves, shares, thumbnail, postId, content, comments, postDate, engagement }) => [
      <div>{formatIntNumber(rank + 1)}</div>,
      <PostCell postId={postId} content={content} thumbnail={thumbnail} />,
      <TableCell.Number value={formatIntNumber(engagement)} />,
      <TableCell.Number value={formatIntNumber(views)} />,
      <TableCell.Number value={formatIntNumber(likes)} />,
      <TableCell.Number value={formatIntNumber(comments)} />,
      <TableCell.Number value={formatIntNumber(saves)} />,
      <TableCell.Number value={formatIntNumber(shares)} />,
      <TableCell.Date value={postDate} />
    ]
  );

  return { rows, columns };
};
