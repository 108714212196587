import { css } from '@emotion/react';
import { MediaPreview } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { bigIntFormatter, formatPercent, getGrowthArrow, intlNumberFormat } from '@/shared/utils';
import { AnalyticsOverviewEngagementType } from './types';

export interface AnalyticsOverviewEngagementProps {
  className?: string;
  engagement: AnalyticsOverviewEngagementType[];
}

export const AnalyticsOverviewEngagement = ({ className, engagement }: AnalyticsOverviewEngagementProps) => (
  <div css={styles.wrapper} className={className}>
    {engagement.map(({ title, growth, total, css, icon, percentage, isPercentage }, index) => {
      const isPositiveGrowth = !growth ? undefined : growth > 0;
      const formattedTotal = isPercentage ? formatPercent(total) : bigIntFormatter(total, 0);
      const formattedGrowth = isPercentage ? formatPercent(growth) : bigIntFormatter(growth, 0);

      return (
        <div css={[styles.item, css]} key={index}>
          <div css={{ width: 'max-content' }}>
            <p css={{ fontSize: '11px', fontWeight: 600, letterSpacing: '0.28px' }}>{title}</p>
            <p
              title={intlNumberFormat(total)}
              css={{ marginTop: '4px', fontSize: '20px', lineHeight: 1.2, fontWeight: 600, letterSpacing: '-0.3px' }}
            >
              {formattedTotal}
            </p>
          </div>

          {icon ? <MediaPreview css={styles.icon} className="icon" src={icon} /> : null}

          <div css={styles.growth(isPositiveGrowth)} title={intlNumberFormat(growth)}>
            <span>
              {isPositiveGrowth ? '+' : null}
              {formattedGrowth}
            </span>
            <span>{getGrowthArrow(growth)}</span>
            <span>{`(${formatPercent(percentage)})`}</span>
          </div>
        </div>
      );
    })}
  </div>
);
const styles = {
  wrapper: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(164px, 1fr))'
  }),
  item: css({
    height: '98px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderWidth: '0 1px 1px 0',
    borderStyle: 'solid',
    borderColor: THEME.border.colors.gray.lv1,
    marginRight: '-1px',
    position: 'relative'
  }),
  icon: css({
    top: 2,
    right: 2,
    width: '32px',
    height: '32px',
    position: 'absolute',
    background: 'transparent'
  }),
  growth: (isPositive?: boolean) =>
    css({
      gap: '4px',
      fontWeight: 600,
      display: 'flex',
      marginTop: '4px',
      fontSize: '13px',
      lineHeight: 1.38,
      width: 'max-content',
      whiteSpace: 'pre-wrap',
      letterSpacing: 'normal',
      color: isPositive === undefined ? 'inherit' : isPositive ? '#40b87c' : '#ff2b52'
    })
};
