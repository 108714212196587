import { TableData } from '@/shared/types';
import { InstagramUserHashtagListPostsItemPayload } from '@/graphql';
import { InstagramHashtagPostDetailsModal } from '@/shared/molecules';
import { TagsTable } from '../../shared';
import { ProfilePostsCount } from '../../../ProfilePostsList';
import { useInstagramHashtagsAnalyticsPostsListTable } from './hooks';

export type InstagramHashtagsAnalyticsPostsListProps = TableData<InstagramUserHashtagListPostsItemPayload>;

export const InstagramHashtagsAnalyticsPostsList = ({
  loading,
  data = [],
  totalRecords
}: InstagramHashtagsAnalyticsPostsListProps) => {
  const { rows, columns } = useInstagramHashtagsAnalyticsPostsListTable({ data });

  return (
    <>
      <ProfilePostsCount count={totalRecords} css={{ boxShadow: 'none' }} />
      <TagsTable loading={loading} hasBorder={!!totalRecords} data={{ rows, columns }} />
      <InstagramHashtagPostDetailsModal posts={data.map(({ postId }) => ({ id: postId }))} />
    </>
  );
};
