import { useEffect, useState } from 'react';
import { THEME } from '@/shared/constants';
import { MenuTitle, MenuType } from '@/router';
import { MenuItem } from './MenuItem';

interface MenuGroupProps extends MenuType {
  activeTitle?: MenuTitle;
  disabled?: boolean | null;
}

export const MenuGroup = ({ title, groupTitle, to, href, subMenus: child, activeTitle, disabled }: MenuGroupProps) => {
  const [showChilds, setShowChilds] = useState<boolean>(false);

  const childTitles = child?.map((item) => item?.title) || [];
  const activeIcon = !!activeTitle && [title, ...childTitles].includes(activeTitle);
  const length = child?.length || 0;

  useEffect(() => {
    setShowChilds(activeIcon);
  }, [activeIcon]);

  const toggleChildsMenu = () => {
    if (activeTitle === title || !showChilds) {
      setShowChilds((prevState) => !prevState);
    }
  };

  return (
    <>
      <MenuItem
        to={to}
        href={href}
        title={title}
        groupTitle={groupTitle}
        activeIcon={activeIcon}
        activeTitle={activeTitle}
        onClick={toggleChildsMenu}
        disabled={disabled}
      />
      {!disabled ? (
        <div
          css={[{ overflow: 'hidden', transition: 'height 0.3s ease', height: showChilds ? `${length * 40}px` : 0 }]}
        >
          {child && child?.length > 0
            ? child.map((item, i) => (
                <MenuItem
                  key={i}
                  {...item}
                  hasIcon={false}
                  activeTitle={activeTitle}
                  css={{ color: THEME.text.colors.gray.lv3, marginLeft: '24px' }}
                />
              ))
            : null}
        </div>
      ) : null}
    </>
  );
};
