import { createFileRoute, redirect } from '@tanstack/react-router';
import { checkSignedIn, manageLocalStorage } from '../utils';
export const Route = createFileRoute('/_private-routes')({
  beforeLoad: async ({
    location
  }) => {
    const isSignedIn = checkSignedIn();
    if (!isSignedIn) {
      manageLocalStorage('set', 'redirectUrlBeforeSignIn', location.href);
      throw redirect({
        to: '/sign-in'
      });
    }
  }
});