import { useTranslation } from 'react-i18next';
import { Tabs } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { ListWithPagination } from '@/shared/molecules';
import { TiktokTagsAnalyticsSearchSchemaType, useTiktokTagsAnalyticsFilter } from '@/shared/organisms';

interface TiktokTagsAnalyticsTemplateProps {
  children: React.ReactNode;
}

export const TiktokTagsAnalyticsTemplate = ({ children }: TiktokTagsAnalyticsTemplateProps) => {
  const { t } = useTranslation();
  const { filter } = useTiktokTagsAnalyticsFilter();

  const tabSearch = { filter: { ...filter, tagId: '' } } satisfies Pick<TiktokTagsAnalyticsSearchSchemaType, 'filter'>;

  return (
    <ListWithPagination css={{ backgroundColor: THEME.background.colors.white }}>
      <div css={{ display: 'flex', gap: '4px', padding: '21px 24px', boxSizing: 'border-box' }}>
        <div css={{ flex: 1 }}>
          <p css={{ fontSize: '18px', fontWeight: 600, lineHeight: '100%' }}>
            {t('TikTok Hashtag & Keyword analysis')}
          </p>
          <p css={{ fontSize: '14px', marginTop: '8px', lineHeight: '100%', color: THEME.text.colors.gray.lv3 }}>
            {t('Analyze TikTok hashtags & Keywords')}
          </p>
        </div>
      </div>

      <Tabs
        css={{ borderBottom: 'none' }}
        options={[
          {
            end: false,
            search: tabSearch,
            label: t('Hashtag'),
            to: '/hashtag-analytics/tiktok/hashtag'
          },
          {
            end: false,
            search: tabSearch,
            label: t('Keyword'),
            to: '/hashtag-analytics/tiktok/keyword'
          }
        ]}
      />

      {children}
    </ListWithPagination>
  );
};
