import { ReactElement } from 'react';
import { Navigate, useLocation } from '@tanstack/react-router';
import { checkSignedIn } from '@/router';

export const WithTikTokOnboardingToken = ({ children }: { children: ReactElement }) => {
  const isSignedIn = checkSignedIn();
  const { pathname } = useLocation();

  if (!isSignedIn && pathname.includes('/tiktok-onboarding')) {
    return <Navigate to="/tiktok-onboarding/password-enter" />;
  }

  return children;
};
