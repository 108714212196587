import {
  createRouter as createTanStackRouter,
  createBrowserHistory,
  RouteIds,
  Link,
  LinkProps
} from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { RouterContext } from './types';

const browserHistory = createBrowserHistory();

export const createRouter = (ctx: RouterContext) =>
  createTanStackRouter({
    routeTree,
    context: ctx,
    notFoundMode: 'root',
    history: browserHistory,
    defaultPreload: 'intent',
    scrollRestoration: true,
    defaultStaleTime: 60_000, // 1 minute for Loader data to be valid
    defaultPreloadStaleTime: 60_000, // 1 minute for Loader preload=true data to be valid
    defaultOnCatch(error, errorInfo) {
      console.error(error, errorInfo);
    }
    // TODO: add defaultErrorComponent and defaultNotFoundComponent
    // defaultErrorComponent: DefaultCatchBoundary,
    // defaultNotFoundComponent: () => <NotFound />
  });

export type RouterType = ReturnType<typeof createRouter>;
export type RouterIds = RouteIds<RouterType['routeTree']>;
export type LinkRouterProps = LinkProps<'a', RouterType>;
export { Link };
