import {
  namedOperations,
  useCreateInstagramUserHashtagMutation,
  useDeleteInstagramUserHashtagMutation
} from '@/graphql';
import { TagsListProps } from '../../../shared';

export const useCreateDeleteInstagramHashtag = () => {
  const { callCreateInstagramUserHashtag, loading: creating } = useCreateInstagramUserHashtagMutation({
    refetchQueries: [namedOperations.Query.InstagramUserHashtags]
  });
  const { callDeleteInstagramUserHashtag, loading: deleting } = useDeleteInstagramUserHashtagMutation({
    refetchQueries: [namedOperations.Query.InstagramUserHashtags]
  });

  const handleCreateHashtag: TagsListProps['onCreateTag'] = async (label) => {
    await callCreateInstagramUserHashtag({ variables: { input: { hashtag: label } } });
  };

  const handleDeleteHashtag: TagsListProps['onDeleteTag'] = async (tag) => {
    await callDeleteInstagramUserHashtag({ variables: { input: { hashtagId: tag.id } } });
  };

  return {
    creating,
    deleting,
    handleCreateHashtag,
    handleDeleteHashtag
  };
};
