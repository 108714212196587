import { InstagramFeedPost } from '@/graphql';

export const defaultSelectedAnalyticsInstagramPostsColumns: Array<keyof InstagramFeedPost> = [
  'accountsEngaged',
  'comments',
  'engagement',
  'engagementRateByFollowers',
  'engagementRateByViews',
  'externalLinkTaps',
  'follow',
  'interaction',
  'likes',
  'nonFollowerReach',
  'postDate',
  'productButtonClick',
  'productPageView',
  'profileActivity',
  'profileVisits',
  'reach',
  'saved',
  'share',
  'views',
  'viewsByFollowers',
  'viewsByNonFollowers',
  'viewsFromDiscovery',
  'viewsFromHome',
  'viewsFromOthers',
  'viewsFromProfile',
  'viewsByNonFollowers',
  'viewsFromDiscovery',
  'viewsFromHome',
  'viewsFromOthers',
  'viewsFromProfile'
];
export const defaultSelectedAnalyticsInstagramComparePostsColumns: Array<keyof InstagramFeedPost> = [
  'engagement',
  'engagementRateByFollowers',
  // 'engagementRateByViews', TODO: we don't have this metric on BE yet
  'likes',
  'comments',
  'postDate'
];
