import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/CreateTiktokUserHashtag.graphql';
import { Mutation, MutationcreateTiktokUserHashtagArgs } from '../../__generated__/globalTypes';

export const useCreateTiktokUserHashtagMutation = (
  options?: MutationHookOptions<Mutation, MutationcreateTiktokUserHashtagArgs>
) => {
  const [callCreateTiktokUserHashtag, result] = useMutation<Mutation, MutationcreateTiktokUserHashtagArgs>(
    MUTATION,
    options
  );

  return { callCreateTiktokUserHashtag, ...result };
};

export type CreateTiktokUserHashtagMutationFunction = Mutation['createTiktokUserHashtag'];
