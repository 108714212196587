import { InstagramReelPostItem } from '@/graphql';
import { Table } from '@/shared/atoms';
import { InfluencerInstagramAnalyticsReelDetailsModal } from '@/shared/molecules';
import { PostDetailsType, TableData } from '@/shared/types';
import { useAnalyticsInstagramReelPostsListTable } from './hooks';

export interface AnalyticsInstagramReelPostsListProps extends TableData<InstagramReelPostItem> {
  mainSocialAccountId?: number;
}

export const AnalyticsInstagramReelPostsList = ({
  loading,
  data = [],
  mainSocialAccountId
}: AnalyticsInstagramReelPostsListProps) => {
  const { rows, columns } = useAnalyticsInstagramReelPostsListTable({ data });

  return (
    <>
      <Table loading={loading} data={{ rows, columns, rowHeight: '62px' }} noTopBorderRadius hasBorder />
      <InfluencerInstagramAnalyticsReelDetailsModal
        posts={data.map(({ id }): PostDetailsType => ({ id: String(id), mainSocialAccountId }))}
        isAnalytics
      />
    </>
  );
};
