import { css, Global } from '@emotion/react';
import { forwardRef, MouseEventHandler, ReactNode, Ref } from 'react';
import { IMaskInput } from 'react-imask';
import { NavProps } from 'react-day-picker';
import { ClearIcon, Icon, IconProps } from '@/shared/atoms';
import { DATE_PICKER_INPUT_MASK_FORMAT, THEME } from '@/shared/constants';
import { parseDate } from './helpers';

export const WithGlobalStyles = ({ children }: { children: ReactNode }) => (
  <>
    <Global
      styles={css({
        // https://react-day-picker.js.org/basics/styling
        '.rdp-root': {
          '.rdp-day': {
            fontSize: '12px'
          },
          '.rdp-selected': {
            fontSize: '12px',
            fontWeight: 'normal'
          },
          '.rdp-selected:not(.rdp-range_middle)': {
            fontSize: '12px',
            fontWeight: 'normal',

            '&.rdp-focused > .rdp-day_button:': {
              backgroundColor: THEME.datePicker.colors.active.background,
              color: THEME.datePicker.colors.active.text
            },
            // when we open the picker, the selected day may not have focused class
            '> button[aria-label$=", selected"]': {
              backgroundColor: THEME.datePicker.colors.active.background,
              color: THEME.datePicker.colors.active.text
            }
          },
          '.rdp-range_end': {
            '> .rdp-day_button': {
              borderRadius: '0 12px 12px 0',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          },
          '.rdp-range_start': {
            '> .rdp-day_button': {
              borderRadius: '12px 0 0 12px',
              fontSize: '12px',
              fontWeight: 'normal'
            }
          },
          '--rdp-day-width': '32px' /* Width of the day cells. */,
          '--rdp-day-height': '32px' /* Height of the day cells. */,
          '--rdp-day_button-height': '32px' /* Height of the day cells button. */,
          '--rdp-day_button-width': '32px' /* Width of the day cells button. */,
          '--rdp-caption_label': '14px' /* Font size for the caption labels. */,
          '--rdp-accent-color':
            THEME.datePicker.colors.active.background /* Accent color for the background of selected days. */,
          '--rdp-accent-background-color':
            THEME.datePicker.colors.hover.background /* Background color for the hovered/focused elements. */,
          '--rdp-outline': 'none' /* Outline border for focused elements */,
          '--rdp-outline-selected': 'none' /* Outline border for focused _and_ selected elements */,
          margin: '20px 16px 12px' /* Root container's margin */
        },
        '.rdp-month_caption': {
          height: '22px',
          marginBottom: '8px',
          justifyContent: 'center',
          '.rdp-caption_label': {
            fontSize: '14px',
            fontWeight: 'normal'
          }
        },

        '.rdp-range_middle': {
          color: THEME.datePicker.colors.range.text,
          background: THEME.datePicker.colors.range.background,
          '&:hover': { backgroundColor: THEME.datePicker.colors.hover.background }
        }
      })}
    />
    {children}
  </>
);
export interface CustomInputProps {
  hasError: boolean;
  disabled: boolean;
  clearable?: boolean;
  onClear: () => void;
  value: string;
}

export const CustomInput = forwardRef((props: CustomInputProps, ref: Ref<HTMLInputElement>) => {
  const { hasError, disabled, clearable = true, onClear, ...rest } = props;

  return (
    <StyledPickerInput hasError={hasError} disabled={disabled} ref={ref}>
      <Icon icon="calendar" size="16px" color="#6e7c89" className="calendar-icon" />
      <input {...rest} ref={ref} disabled={disabled} />
      {!!rest.value && clearable && !disabled ? (
        <ClearIcon
          id="clear-indicator"
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
          css={{ position: 'absolute', right: 0, top: '7px' }}
        />
      ) : null}
    </StyledPickerInput>
  );
});
CustomInput.displayName = 'CustomInput';

interface StyledInputWithMaskProps {
  className?: string;
  value: string;
  pattern?: string;
  placeholder?: string;
  onAcceptCallback: (parsedDate: Date) => void;
}
export const StyledInputWithMask = ({
  value,
  className,
  onAcceptCallback,
  pattern = 'Y/`m/`dd',
  placeholder = 'yyyy/mm/dd'
}: StyledInputWithMaskProps) => (
  // @ts-expect-error it looks type issue of IMask, mask={undefined} is OK but mask={Date || undefined} is not
  <IMaskInput
    css={{
      width: '73px',
      '::placeholder': {
        fontWeight: 400,
        color: THEME.input.text.colors.placeholder,
        fontSize: '12px' // the InputMask has some fixed input width, so bigger font size will not fit
      },
      cursor: 'pointer',
      ':hover': { boxShadow: '0 1px 2px -1px #dee5ec' }
    }}
    mask={value ? Date : undefined}
    value={value}
    pattern={pattern} // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    onAccept={
      // depending on prop above first argument is
      // `value` if `unmask=false`,
      // `unmaskedValue` if `unmask=true`,
      // `typedValue` if `unmask='typed'`
      (currentValue) => {
        const parsedDate = parseDate(currentValue, DATE_PICKER_INPUT_MASK_FORMAT);

        if (parsedDate) {
          onAcceptCallback(parsedDate);
        }
      }
    }
    placeholder={placeholder}
    className={className}
  />
);

export const StyledPickerInput = forwardRef(
  (
    {
      disabled,
      hasError,
      ...restProps
    }: React.HTMLAttributes<HTMLDivElement> & { disabled?: boolean; hasError?: boolean },
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      css={{
        height: '30px',
        fontSize: '13px',
        lineHeight: '30px',
        alignSelf: 'center',
        borderRadius: '3px',
        paddingLeft: '30px',
        position: 'relative',
        border: '1px solid #e0e8ed',
        color: THEME.input.text.colors.default,
        backgroundColor: THEME.input.background.colors.default,
        'span, .calendar-icon': { top: '6px', left: '8px', position: 'absolute' },
        ...(disabled
          ? { backgroundColor: THEME.input.background.colors.disabled, cursor: 'default', pointerEvents: 'none' }
          : {}),
        ...(hasError ? { borderColor: THEME.input.border.colors.error } : {})
      }}
      {...restProps}
    />
  )
);
StyledPickerInput.displayName = 'StyledPickerInput';

interface ArrowIconProps extends Pick<IconProps, 'icon'> {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'previous' | 'next';
}
export const ArrowIcon = ({ onClick, icon, type = 'next' }: ArrowIconProps) => (
  <Icon
    icon={icon}
    size="10px"
    color={THEME.datePicker.colors.arrow}
    onClick={(e) => {
      e.stopPropagation();
      onClick?.(e as any);
    }}
    css={{
      position: 'absolute',
      right: type === 'next' ? 4 : undefined,
      left: type === 'previous' ? 4 : undefined,
      top: 6,
      '&:hover': {
        cursor: 'pointer',
        fill: `${THEME.colors.black[90]} !important`
      }
    }}
  />
);

export const CustomDayPickerNav = (props: NavProps) => (
  <>
    <ArrowIcon onClick={props.onPreviousClick} icon="caret-left" type="previous" />
    <ArrowIcon onClick={props.onNextClick} icon="caret-right" type="next" />
  </>
);
