import { LinkRouterProps } from '@/router';

export const enum MenuTitle {
  Report = 'Report',
  Marketplace = 'Marketplace',
  Engagement = 'Engagement',
  AutoManaged = 'Affiliate',
  TikTokCampaigns = 'TikTok Special',
  CampaignPosts = 'Campaign Posts',
  Influencers = 'Influencers',
  Posts = 'Post',
  Packages = 'Package',
  TrendingPosts = 'Trending Posts',
  TrendingInfluencers = 'Trending Influencers',
  Analytics = 'Analytics',
  Partner = 'Partner',
  TalentAgency = 'Talent Agency',
  Agency = 'Agency',
  Advertiser = 'Advertiser',
  User = 'User',
  Payment = 'Payment',
  Settings = 'Settings',
  ReleaseNotes = 'Release Notes',
  HelpCenter = 'Help Center',
  UserSettings = 'User Settings',
  Language = 'languageTitle',
  SignOut = 'Sign Out',
  ChatDemo = 'AI Chat', // TODO: remove later
  // TikTok in-app
  TikTokAdsReport = 'TikTok Ads',
  BudgetAllocation = 'Budget Allocation',
  AdAccounts = 'Ad Accounts',
  Billing = 'Billing',
  Account = 'Account',
  InfluencerCampaigns = 'Influencer Campaign',
  // Dev only
  AssetPreview = 'Asset Preview',
  // CMS Dashboard
  YoutubeCMS = 'Youtube CMS',
  Invoice = 'Invoice',
  // Hashtags Dashboard
  Instagram = 'Instagram',
  TikTok = 'TikTok'
}

export enum MenuGroup {
  Campaigns = 'Campaigns',
  Influencers = 'Influencers',
  Trends = 'Trends',
  Accounts = 'Accounts',
  InfluencerCampaign = 'Influencer Campaign'
}

interface SubMenuType {
  to?: (typeof MENU_LINKS)[number];
  href?: LinkRouterProps['href'];
  params?: LinkRouterProps['params'];
  title?: MenuTitle;
  groupTitle?: MenuGroup;
  activeTitle?: MenuTitle;
}

const MENU_LINKS = [
  '/report',
  '/marketplace',
  '/engagement',
  '/auto-managed',
  '/influencers',
  '/tiktok/campaigns',
  '/analytics',
  '/trending/influencers',
  '/tiktok/ad-campaign-report',
  '/tiktok/ad-accounts',
  '/tiktok/budget-allocation',
  '/tiktok/advertiser-details',
  '/tiktok/subscription-billing',
  '/youtube-cms',
  '/campaign-posts-discovery',
  '/package',
  '/posts',
  '/trending/posts',
  '/advertiser',
  '/partner',
  '/invoices',
  '/asset-preview',
  '/talent-agency',
  '/agency',
  '/user',
  '/payments',
  '/settings',
  '/chat-demo',
  '/hashtag-analytics/instagram',
  '/hashtag-analytics/tiktok/hashtag'
] as const satisfies Array<LinkRouterProps['to']>;
export interface MenuType extends SubMenuType {
  to?: (typeof MENU_LINKS)[number];
  params?: LinkRouterProps['params'];
  title?: MenuTitle;
  groupTitle?: MenuGroup;
  activeTitle?: MenuTitle;
  subMenus?: Partial<SubMenuType[]>;
}
