import { SocialAccountType, XhsInfluencer } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { XhsInfluencerProfile, XhsInfluencersFilterFormValues } from './schemaTypes';

export const getInitialXhsInfluencersFormValues = (
  defaultFilter?: Partial<XhsInfluencersFilterFormValues>
): Partial<XhsInfluencersFilterFormValues> => ({
  tab: InfluencersTabType.XHS,
  ...defaultFilter
});

export const formatInfluencerProfile = (infl: XhsInfluencer): XhsInfluencerProfile => ({
  id: infl.id,
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  avatar: infl.avatar,
  country: infl.country,
  averageLikes: infl.averageLikes,
  averageViews: infl.averageViews,
  socialAccountId: infl.socialAccountId,
  socialAccountType: SocialAccountType.XHS,
  socialAccountStatus: infl.socialAccountStatus,
  socialAccountFollowers: infl.socialAccountFollowers,
  socialAccountEngagementViewsRate: infl.socialAccountEngagementViewsRate,
  socialAccountEngagementFollowersRate: infl.socialAccountEngagementFollowersRate,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, type, caption }) => ({
      type,
      caption,
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
