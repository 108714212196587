import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { LinkRouterProps } from '@/router';
import { useCopy } from '@/shared/hooks';
import { THEME } from '@/shared/constants';
import { Anchor, Button, FormStyle, MediaPreview } from '@/shared/atoms';
import { engReferralSettingsImage, mkpReferralSettingsImage } from '@/shared/assets';
import { REFERRAL_TRACKING_PARAMS } from './schemaTypes';

export interface TrackingReferralProps {
  className?: string;
  campaign: {
    id: number;
    title: string;
    type?: 'engagement' | 'marketplace';
  };
}

const campaignRoutes = {
  eggTrackingReferral: '/egg/referral',
  mkpTrackingReferral: '/mkp/referral'
} as const satisfies Record<string, LinkRouterProps['to']>;

export const TrackingReferral = ({ className, campaign: { id, title, type } }: TrackingReferralProps) => {
  const { t } = useTranslation();
  const { copyToClipboard } = useCopy();

  const isEngagementPage = type === 'engagement';
  const { shopifyIframe, otherWebsitesIframe } = (() => {
    switch (type) {
      case 'engagement':
        return {
          shopifyIframe: `<!-- campaign name: "${title}"  -->
        <iframe src="${window.location.origin}${campaignRoutes.eggTrackingReferral}?${REFERRAL_TRACKING_PARAMS.referral_code}={{ discount_applications[0].title }}
          &${REFERRAL_TRACKING_PARAMS.transaction_id}={{ order_number }}
          &${REFERRAL_TRACKING_PARAMS.campaign_id}=${id}
          &${REFERRAL_TRACKING_PARAMS.sale_amount}={{ total_price | money_without_currency | remove: "," }}"
          scrolling="no" frameborder="0" width="1" height="1">
        </iframe>`,
          otherWebsitesIframe: `<!-- campaign name: "${title}"  -->
          <iframe src="${window.location.origin}${campaignRoutes.eggTrackingReferral}?${REFERRAL_TRACKING_PARAMS.referral_code}=REPLACE_HERE_WITH_REFERRAL_CODE
            &${REFERRAL_TRACKING_PARAMS.transaction_id}=REPLACE_HERE_WITH_ORDER_NUMBER
            &${REFERRAL_TRACKING_PARAMS.campaign_id}=${id}
            &${REFERRAL_TRACKING_PARAMS.sale_amount}=REPLACE_HERE_WITH_SALES_AMOUNT"
            scrolling="no" frameborder="0" width="1" height="1">
          </iframe>
        `
        };
      case 'marketplace':
        return {
          shopifyIframe: `<!-- campaign name: "${title}"  -->
            <iframe src="${window.location.origin}${campaignRoutes.mkpTrackingReferral}?${REFERRAL_TRACKING_PARAMS.referral_code}={{ discount_applications[0].title }}
              &${REFERRAL_TRACKING_PARAMS.transaction_id}={{ order_number }}
              &${REFERRAL_TRACKING_PARAMS.campaign_id}=${id}
              &${REFERRAL_TRACKING_PARAMS.sale_amount}={{ total_price | money_without_currency | remove: "," }}"
              scrolling="no" frameborder="0" width="1" height="1">
            </iframe>
          `,
          otherWebsitesIframe: `<!-- campaign name: "${title}"  -->
            <iframe src="${window.location.origin}${campaignRoutes.mkpTrackingReferral}?${REFERRAL_TRACKING_PARAMS.referral_code}=REPLACE_HERE_WITH_REFERRAL_CODE
              &${REFERRAL_TRACKING_PARAMS.transaction_id}=REPLACE_HERE_WITH_ORDER_NUMBER
              &${REFERRAL_TRACKING_PARAMS.campaign_id}=${id}
              &${REFERRAL_TRACKING_PARAMS.sale_amount}=REPLACE_HERE_WITH_SALES_AMOUNT"
              scrolling="no" frameborder="0" width="1" height="1">
            </iframe>
          `
        };
      default:
        return { shopifyIframe: '', otherWebsitesIframe: '' };
    }
  })();

  return (
    <FormStyle.Wrapper css={{ padding: '27px 24px 24px' }} className={className} hasBorder>
      <h2 css={styles.title}>{t('TrackingSettings.Conversion Tracking Settings')}</h2>
      <p css={styles.description}>
        <Trans i18nKey="TrackingSettings.Description" components={{ br: <br /> }} />
      </p>
      <p css={[styles.description, { margin: '16px 0' }]}>{t('The tracking structure looks like that')}:</p>
      <iframe
        width="960"
        height="376"
        frameBorder="0"
        allowFullScreen
        css={{ width: '100%' }}
        src="https://docs.google.com/presentation/d/e/2PACX-1vQx7b84ZLPyTSwHKLMHrqbyzuHsZ7o3r_JV5ujJOzTl_SrVU0uUfeSrsq9r1ZlkTecEemd5g5ZhYgBg/embed?start=false&loop=false&delayms=3000"
      ></iframe>
      <ol css={styles.orderList}>
        <Trans i18nKey="TrackingSettings.Steps" components={{ b: <b />, li: <li /> }} />
      </ol>

      <hr css={styles.hr} />

      <h2 css={[styles.title, { fontWeight: 600 }]}>{t('If your client uses Shopify website')}</h2>
      <ol css={styles.orderList}>
        <Trans i18nKey="TrackingSettings.Shopify Steps" components={{ b: <b />, li: <li /> }} />
      </ol>
      <textarea css={styles.code} rows={8} readOnly defaultValue={shopifyIframe} />
      <Button
        variant="white"
        title={t('Button.Copy')}
        onClick={() => copyToClipboard(shopifyIframe)}
        css={[styles.copyButton, { marginBottom: '24px' }]}
      />
      <MediaPreview
        alt="Shopify"
        css={{ borderRadius: '5px', backgroundColor: THEME.background.colors.gray.lv1 }}
        src={isEngagementPage ? engReferralSettingsImage : mkpReferralSettingsImage}
      />
      <p css={[styles.description, { marginTop: '16px' }]}>
        <Trans
          i18nKey="TrackingSettings.Shopify Description"
          components={{
            a: (
              <Anchor
                target="_blank"
                href="https://shopify.dev/api/liquid"
                css={{ fontSize: '14px', lineHeight: '18px', display: 'inline-block', fontWeight: 'normal' }}
              />
            )
          }}
        />
      </p>

      <hr css={styles.hr} />

      <h2 css={[styles.title, { fontWeight: 600 }]}>{t('If your client does not use Shopify website')}</h2>
      <p css={styles.description}>{t('Client needs to put original metricises that are provided on their website')}</p>
      <p css={[styles.description, { marginTop: '16px' }]}>
        <b>REPLACE_HERE_WITH_REFERRAL_CODE:</b>
        <br />
        {t('Please replace this position with the referral code that is used for the order')}
      </p>
      <p css={[styles.description, { marginTop: '16px' }]}>
        <b>REPLACE_HERE_WITH_ORDER_NUMBER:</b>
        <br />
        {t('Please replace this position with the order number or something else that makes the purchase unique')}
      </p>
      <p css={[styles.description, { marginTop: '16px' }]}>
        <b>REPLACE_HERE_WITH_SALES_AMOUNT:</b>
        <br />
        {t('Please replace this position with the sales amount')}
      </p>
      <textarea css={styles.code} rows={7} readOnly defaultValue={otherWebsitesIframe} />
      <Button
        variant="white"
        css={styles.copyButton}
        title={t('Button.Copy')}
        onClick={() => copyToClipboard(otherWebsitesIframe)}
      />
    </FormStyle.Wrapper>
  );
};

const styles = {
  hr: css({ height: '1px', margin: '24px 0', backgroundColor: '#eef3f7' }),
  description: css({ color: THEME.text.colors.black.lv1, fontSize: '14px', lineHeight: '18px' }),
  copyButton: css({ minWidth: '85px', marginTop: '8px', textTransform: 'uppercase' }),
  title: css({
    fontSize: '18px',
    marginBottom: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: THEME.text.colors.black.lv1
  }),
  code: css({
    width: '100%',
    lineHeight: 1.38,
    fontSize: '13px',
    marginTop: '16px',
    resize: 'vertical',
    borderRadius: '2px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    padding: '8px 16px 8px 14px',
    color: THEME.text.colors.black.lv1,
    backgroundColor: THEME.background.colors.gray.lv1
  }),
  orderList: css({
    fontSize: '14px',
    marginTop: '16px',
    li: { lineHeight: '22px', listStyle: 'decimal', listStylePosition: 'inside' }
  })
};
